import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatLabel } from '@angular/material/form-field';
import { ConversationDtoOutputForDetail } from '@app/api/models';

@Component({
    selector: 'app-admin-close-conv-dialog',
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, MatLabel],
    templateUrl: './admin-close-conversation-dialog.component.html',
    styleUrl: './admin-close-conversation-dialog.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminCloseConversationDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<AdminCloseConversationDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConversationDtoOutputForDetail | null 
    ) {}

    onDismiss(): void {
        this.dialogRef.close(false);
    }
    onConfirm(): void {

        this.dialogRef.close(true);
    }

    getUserName(): string {
        return this.data?.contact?.firstName + ' ' + this.data?.contact?.lastName?.charAt(0).toUpperCase() + '.';
    }
}
