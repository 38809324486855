import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AdminCategoriesGet$Json$Params } from '@app/api/fn/admin/admin-categories-get-json';
import {
    CategorieDtoOutputForDatagrid,
    CategorieDtoOutputForDatagridOdataResponse,
    ReferenceDtoOutputForAdminDatagridForList,
    ReferenceDtoOutputForAdminDatagridForListOdataResponse,
} from '@app/api/models';
import { AdminService } from '@app/api/services';
import { GridApi, ColDef, GridReadyEvent, SortChangedEvent, ValueGetterParams, FilterChangedEvent } from 'ag-grid-community';
import { DatagridComponent } from '../../components/datagrid/datagrid.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormsModule } from '@angular/forms';
import { CategorieChildrenComponent } from '@app/components/datagrid/category-children/category-children.component';
import { DataSharingService } from '@app/utils/services/data-sharing.services';
import { CategoryColorComponent } from '@app/components/datagrid/category-color/category-color.component';
import { SortDirection } from '@ag-grid-community/core';
import { mapAgGridFiltersToOData } from '@app/utils/aggrid-to-odatafilter';
import { MatButtonModule } from '@angular/material/button';
import { ExportButtonComponent } from '../../export-button/export-button.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import DownloadFile from '@app/utils/http-headers-utils';
import { MatMenuModule } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { SnackBarService } from '@app/utils/services/snackbar.service';
import { take, timer } from 'rxjs';

@Component({
    selector: 'app-reference',
    standalone: true,
    templateUrl: './references.component.html',
    styleUrl: './references.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DatagridComponent, MatSlideToggleModule, FormsModule, MatButtonModule, ExportButtonComponent, MatMenuModule, MatIcon],
})
export class ReferencesComponent implements OnInit {
    private gridApi!: GridApi<ReferenceDtoOutputForAdminDatagridForList>;

    // Column Definitions
    colDefsReferences: ColDef[] = [
        { headerName: 'Libellé', field: 'libelle', filter: true, sortable: true, onCellClicked: this.rowClick.bind(this) },
        { headerName: 'Référence', field: 'reference', filter: true, sortable: true, onCellClicked: this.rowClick.bind(this) },
        {
            headerName: 'Catégorie',
            cellRenderer: CategoryColorComponent,
            onCellClicked: this.rowClick.bind(this),
            field: 'categorie/nom',
            filter: true,
            sortable: true,
            filterValueGetter: (p) => p.data.categorie?.nom,
            valueGetter: (p: ValueGetterParams<ReferenceDtoOutputForAdminDatagridForList, any>) => p.data?.categorie?.nom,
        },
        {
            headerName: 'Taille',
            valueGetter: (p: ValueGetterParams<ReferenceDtoOutputForAdminDatagridForList, any>) => p.data?.tailles?.join(', '),
            filter: false,
            sortable: false,
            onCellClicked: this.rowClick.bind(this),
        },
    ];

    colDefsCategories: ColDef[] = [
        {
            headerName: 'Catégorie',
            autoHeight: true,
            cellRenderer: CategorieChildrenComponent,
            onCellClicked: this.rowClick.bind(this),
            filter: true,
            sortable: true,
            filterValueGetter: (p) => p.data.libelle,
            valueGetter: (p: ValueGetterParams<CategorieDtoOutputForDatagrid, any>) => p.data?.libelle,
        },
    ];

    nbRecords = 50;
    fetchParams: AdminCategoriesGet$Json$Params = {
        $top: this.nbRecords,
    };

    rawData: ReferenceDtoOutputForAdminDatagridForListOdataResponse | null = null;
    rawDataCategories: CategorieDtoOutputForDatagridOdataResponse | null = null;

    data: ReferenceDtoOutputForAdminDatagridForList[] | null = null;
    showCategorie = false;

    activeSort: { field: string; sort: SortDirection | undefined }[] = [];
    oDataFilters: string | null = null;

    guideDesTaillesURL = '';

    constructor(
        private router: Router,
        private adminService: AdminService,
        private cdr: ChangeDetectorRef,
        private dataSharingService: DataSharingService,
        private _snackBar: SnackBarService,
    ) {
        this.dataSharingService.resizeRequired.subscribe(() => {
            if (this.gridApi) {
                this.gridApi.sizeColumnsToFit();
            }
        });
    }

    ngOnInit(): void {
        this.fetchReferences();
        this.fetchCategories();
        this.loadGuideDesTailles();
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    fetchReferences(params = this.fetchParams) {
        this.adminService.adminReferencesGet$Json(params).subscribe((res: ReferenceDtoOutputForAdminDatagridForListOdataResponse) => {
            this.rawData = res;
            this.cdr.detectChanges();
        });
    }

    fetchCategories(params = this.fetchParams) {
        this.adminService.adminCategoriesGet$Json(params).subscribe((res: CategorieDtoOutputForDatagridOdataResponse) => {
            this.rawDataCategories = res;
            this.cdr.detectChanges();
        });
    }

    rowClick(event: any) {
        this.router.navigate(['/reference', event.data.reference]);
    }

    addReference() {
        this.router.navigate(['/reference']);
    }

    export() {
        this.adminService.adminExportReferencesGet$Response().subscribe((res) => {
            DownloadFile(res);
        });
    }

    exportReferencesList() {
        this.adminService.adminExportDernieresReferencesGet$Response().subscribe((res) => {
            DownloadFile(res);
        });
    }

    onFilterChanged(event: FilterChangedEvent<any, any>) {
        if (event.columns) {
            const filtersSelected = event.api.getFilterModel();
            if (Object.keys(filtersSelected).length === 0) {
                this.resetSort(false, true);
                if (this.showCategorie) {
                    this.fetchCategories();
                } else {
                    this.fetchReferences();
                }
                return;
            }
            this.oDataFilters = Object.entries(filtersSelected)
                .map(([key, filter]) => {
                    return mapAgGridFiltersToOData(filter.type, filter.filter ?? new Date(filter.dateFrom).toISOString(), key, filter.filterType);
                })
                .join(' and ');
            this.fetchParams = {
                ...this.fetchParams,
                $skip: 0,
                $filter: this.oDataFilters,
            };
            if (this.showCategorie) {
                this.fetchCategories();
            } else {
                this.fetchReferences();
            }
        }
    }

    onSortChanged(event: SortChangedEvent<any, any>) {
        if (event.columns?.length) {
            const columnsChanged = event.columns.map((col) => {
                return {
                    field: col.getColId(),
                    sort: col.getSort(),
                };
            });
            if (this.activeSort.find((s) => s.field === columnsChanged[0].field && s.sort === columnsChanged[0].sort)) {
                this.activeSort = this.activeSort.filter((s) => s.field !== columnsChanged[0].field);
            } else {
                // filter out the column if it already exists and sort is different
                this.activeSort = this.activeSort.filter((s) => s.field !== columnsChanged[0].field);
                if (columnsChanged[0].sort) {
                    this.activeSort.push(...columnsChanged);
                }
            }
        }
        if (this.activeSort.length) {
            this.fetchParams = {
                ...this.fetchParams,
                $orderby: this.activeSort.map((s) => `${s.field} ${s.sort}`).join(','),
            };
        } else {
            this.resetSort(true, false);
        }
        if (this.showCategorie) {
            this.fetchCategories();
        } else {
            this.fetchReferences();
        }
    }

    onPageSizeChange(event: PageEvent) {
        if (this.nbRecords !== event.pageSize) {
            this.nbRecords = event.pageSize;
            this.fetchParams = {
                ...this.fetchParams,
                $top: event.pageSize,
            };
            if (this.showCategorie) {
                this.fetchCategories();
            } else {
                this.fetchReferences();
            }
        }
        if (event.pageIndex > (event.previousPageIndex ?? -1)) {
            if (this.showCategorie) {
                this.fetchPage(this.rawDataCategories?.nextLink ?? null);
            } else {
                this.fetchPage(this.rawData?.nextLink ?? null);
            }
        } else if (event.pageIndex < (event.previousPageIndex ?? -1)) {
            if (this.showCategorie) {
                this.fetchPage(this.rawDataCategories?.previousLink ?? null);
            } else {
                this.fetchPage(this.rawData?.previousLink ?? null);
            }
        }
    }

    toggleChange() {
        this.resetSort();
        if (this.showCategorie) {
            this.fetchCategories();
        } else {
            this.fetchReferences();
        }
    }

    resetSort(keepFilters = false, keepSort = false) {
        if (this.activeSort.length !== 0 && this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $filter: keepFilters ? (this.oDataFilters ?? undefined) : undefined,
                $orderby: keepSort ? this.activeSort.map((s) => `${s.field} ${s.sort}`).join(',') : undefined,
            };
        } else if (this.activeSort.length === 0 && this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $filter: keepFilters ? (this.oDataFilters ?? undefined) : undefined,
            };
        } else if (this.activeSort.length !== 0 && !this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $orderby: keepSort ? this.activeSort.map((s) => `${s.field} ${s.sort}`).join(',') : undefined,
            };
        } else {
            this.fetchParams = {
                $top: this.nbRecords,
            };
        }
    }

    fetchPage(link: string | null) {
        if (link) {
            const url = new URL(link);
            const params = url.searchParams;
            const skip = params.get('skip');
            const top = params.get('top');
            if (skip && top) {
                this.fetchParams = {
                    ...this.fetchParams,
                    $top: +top,
                    $skip: +skip,
                };
                if (this.showCategorie) {
                    this.fetchCategories();
                } else {
                    this.fetchReferences();
                }
            }
        }
    }
    voirPDF() {
        window.open(this.guideDesTaillesURL, '_blank');
    }

    loadGuideDesTailles() {
        this.adminService.adminGuideDesTaillesGet$Json().subscribe({
            next: (response) => {
                this.guideDesTaillesURL = response;
                this.cdr.detectChanges();
            },
            error: (error) => {
                this._snackBar.openSnackBar('Erreur lors du chargement du guide des tailles', 'Fermer', true, 3000);
            },
            complete: () => {},
        });
    }

    remplacerPDF(event: any) {
        const file: File = event.target.files[0];
        if (file.type !== 'application/pdf') {
            this._snackBar.openSnackBar('Le fichier doit être un PDF', 'Fermer', true, 3000);
            return;
        } else {
            const params = { body: { 'file': file } };
            this.adminService.adminGuideDesTaillesPost(params).subscribe({
                next: (response) => {
                    this.loadGuideDesTailles();
                },
                error: (error) => {
                    this._snackBar.openSnackBar('Erreur lors de la modification du guide des tailles', 'Fermer', true, 3000);
                },
                complete: () => {
                    this.cdr.detectChanges();
                },
            });
        }
    }

    importReferences(event: any) {
        const fileInput = event.target;
        const file: File = fileInput.files[0];
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel') {
            this._snackBar.openSnackBar('Le fichier doit être au format Excel', 'Fermer', true, 3000);
            return;
        } else {
            const params = { body: { 'formFile': file } };
            this.adminService.adminImportReferencesPost$Json$Response(params).subscribe({
                next: (response) => {
                    this._snackBar.openSnackBar('Fichier importé avec succès', 'Fermer', false, 3000);
                    if (this.showCategorie) {
                        this.fetchCategories();
                    } else {
                        this.fetchReferences();
                    }                },
                error: (error) => {
                    const refErrors = error.error[''];
                    const snackTimer = timer(0, 5000).pipe(take(refErrors.length));

                    const sub = snackTimer.subscribe((i) => {
                        this._snackBar.openSnackBar(refErrors[i], 'Fermer', true, 5000);
                        if (i == refErrors[i].length) {
                            sub.unsubscribe();
                        }
                    });
                },
                complete: () => {
                    this.cdr.detectChanges();
                },
            });
        }
    }
}
