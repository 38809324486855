import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { AccountsService } from 'src/app/api/services/accounts.service';
import { SnackBarService } from '@app/utils/services/snackbar.service';
import { take, timer } from 'rxjs';
import { P } from '@angular/cdk/keycodes';

@Component({
    selector: 'app-form-password-recovery',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatIconModule, RouterLink, RouterLinkActive, RouterOutlet],
    templateUrl: './form-password-recovery.component.html',
    styleUrls: ['./form-password-recovery.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPasswordRecoveryComponent implements OnInit {
    hide = signal(true);
    passwordRecoveryForm: FormGroup;
    isMobile: boolean | undefined;
    userId: string | null = null;
    resetToken: string | null = null;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private accountsService: AccountsService,
        private _snackBar: SnackBarService,

        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        this.passwordRecoveryForm = this.fb.group({
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            userId: [this.userId],
            resetToken: [this.resetToken],
        });
    }

    ngOnInit(): void {
        localStorage.clear();
        this.isMobile = isPlatformBrowser(this.platformId) && window.innerWidth < 768;
        this.route.queryParams.subscribe((params) => {
            this.userId = params['userId'];
            this.resetToken = params['resetToken'];
            this.passwordRecoveryForm.patchValue({
                userId: this.userId,
                resetToken: this.resetToken,
            });
        });
    }

    clickEvent(event: MouseEvent) {
        this.hide.set(!this.hide());
        event.stopPropagation();
    }

    onSubmit() {
        if (this.passwordRecoveryForm.valid && this.userId != null && this.resetToken != null) {
            this.accountsService
                .accountsMotDePasseReinitialiserPost$Response({
                    body: this.passwordRecoveryForm.value,
                })
                .subscribe({
                    next: () => {
                        this._snackBar.openSnackBar('Mot de passe enregistré avec succès', 'Fermer', false, 5000);
                    },
                    error: (error) => {
                        this._snackBar.displayErrors(error.error, this.passwordRecoveryForm);
                    },
                });
        }
    }
}
