import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LieuEchangeDtoOuputAvecGeolocalisation, LieuEchangeDtoOuputAvecGeolocalisationOdataResponse, UserOutputForAdminDataGrid, UserOutputForAdminDataGridOdataResponse } from '@app/api/models';
import { AdminService } from '@app/api/services';
import { ContactButtonComponent } from '@app/components/datagrid/contact-button/contact-button.component';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { ColDef, GridReadyEvent, GridApi, SortChangedEvent, SortDirection, FilterChangedEvent } from 'ag-grid-community';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { AdminUtilisateursGet$Json$Params } from '@app/api/fn/admin/admin-utilisateurs-get-json';
import { DatagridComponent } from '../../components/datagrid/datagrid.component';
import { PageEvent } from '@angular/material/paginator';
import { mapAgGridFiltersToOData } from '@app/utils/aggrid-to-odatafilter';
import { MatButtonModule } from '@angular/material/button';
import { ExportButtonComponent } from '../../export-button/export-button.component';
import DownloadFile from '@app/utils/http-headers-utils';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminAdressesPubliquesGet$Json$Params } from '@app/api/fn/admin/admin-adresses-publiques-get-json';
import { SnackBarService } from '@app/utils/services/snackbar.service';
import { take, timer } from 'rxjs';

@Component({
    selector: 'app-adresses',
    standalone: true,
    templateUrl: './adresses.component.html',
    styleUrl: './adresses.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DatagridComponent, FormsModule, MatInputModule, MatIconModule, AgGridAngular, MatButtonModule, ExportButtonComponent, MatMenuModule],
})
export class AdressesComponent implements OnInit {
    private gridApi!: GridApi<LieuEchangeDtoOuputAvecGeolocalisation>;

    // Column Definitions
    colDefs: ColDef[] = [
        { headerName: 'Libellé', type: 'text', field: 'libelle', valueGetter: (p) => p.data.libelle, filter: true, sortable: true },
        { headerName: 'Rue', type: 'text', field: 'rue', filter: true, sortable: true },
        { headerName: 'Ville', type: 'text', field: 'ville', filter: true, sortable: true },
        { headerName: 'Latitude/Longitude', type: 'number', valueGetter: (p) => this.getLatLongString(p.data),  filter: false, sortable: false },
    ];
    nbRecords = 50;
    fetchParams: AdminAdressesPubliquesGet$Json$Params = {
        $top: this.nbRecords,
    };

    rawData: LieuEchangeDtoOuputAvecGeolocalisationOdataResponse | null = null;

    data: LieuEchangeDtoOuputAvecGeolocalisation[] | null = null;
    activeSort: { field: string; sort: SortDirection | undefined }[] = [];
    oDataFilters: string | undefined;
    activeFilter: string[] = [];

    constructor(
        private router: Router,
        private adminService: AdminService,
        private cdr: ChangeDetectorRef,
        private _snackBar: MatSnackBar,
        private snackBar: SnackBarService,
    ) {}

    ngOnInit(): void {
        this.fetchAdresses();
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    fetchAdresses(params = this.fetchParams) {
        this.adminService.adminAdressesPubliquesGet$Json(params).subscribe((res: LieuEchangeDtoOuputAvecGeolocalisationOdataResponse) => {
            this.rawData = res;
            this.cdr.detectChanges();
        });
    }


    importAddress(event:any) {
        const file: File = event.target.files[0];
        if (file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' && file.type !== 'application/vnd.ms-excel') {
            this._snackBar.open('Le fichier doit être au format Excel', 'Fermer', {
                duration: 3000,
                panelClass: ['red-snackbar', 'snackbar'],
            });
            return;
        }
        else {
            const params = { body: { 'file': file } };

            this._snackBar.open('Fichier en cours d\'importation...', undefined, {
                panelClass: ['blue-snackbar', 'snackbar'],
            });

            this.adminService.adminImportAdressesPost$Json$Response(params).subscribe({
                next: (response) => {
                    this._snackBar.open('Fichier importé avec succès !', 'Fermer', {
                        duration: 3000,
                        panelClass: ['green-snackbar', 'snackbar'],
                    });
                    this.fetchAdresses();
                },
                error: (error) => {
                    const refErrors = error.error[''];

                    const snackTimer = timer(0, 5000).pipe(take(refErrors.length));

                    const sub = snackTimer.subscribe((i) => {
                        this.snackBar.openSnackBar(refErrors[i], 'Fermer', true, 5000);
                        if (i == refErrors[i].length -1) {
                            sub.unsubscribe();
                        }});
                },
                complete: () => {
                    this.cdr.detectChanges();
                },
            });
        }
    }

    exportLatestAddressList() {
        this.adminService.adminExportDerniereListeAdressesGet$Response().subscribe((res) => {
            DownloadFile(res);
        });
    }


    onSortChanged(event: SortChangedEvent<any, any>) {
        if (event.columns?.length) {
            const columnsChanged = event.columns.map((col) => {
                return {
                    field: col.getColId(),
                    sort: col.getSort(),
                };
            });
            if (this.activeSort.find((s) => s.field === columnsChanged[0].field && s.sort === columnsChanged[0].sort)) {
                this.activeSort = this.activeSort.filter((s) => s.field !== columnsChanged[0].field);
            } else {
                // filter out the column if it already exists and sort is different
                this.activeSort = this.activeSort.filter((s) => s.field !== columnsChanged[0].field);
                if (columnsChanged[0].sort) {
                    this.activeSort.push(...columnsChanged);
                }
            }
        }
        if (this.activeSort.length) {
            this.fetchParams = {
                ...this.fetchParams,
                $orderby: this.activeSort.map((s) => `${s.field} ${s.sort}`).join(','),
            };
        } else {
            this.resetSort(true, false);
        }
        this.fetchAdresses();
    }
    resetSort(keepFilters = false, keepSort = false) {
        if (this.activeSort.length !== 0 && this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $filter: keepFilters ? (this.oDataFilters ?? undefined) : undefined,
                $orderby: keepSort ? this.activeSort.map((s) => `${s.field} ${s.sort}`).join(',') : undefined,
            };
        } else if (this.activeSort.length === 0 && this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $filter: keepFilters ? (this.oDataFilters ?? undefined) : undefined,
            };
        } else if (this.activeSort.length !== 0 && !this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $orderby: keepSort ? this.activeSort.map((s) => `${s.field} ${s.sort}`).join(',') : undefined,
            };
        } else {
            this.fetchParams = {
                $top: this.nbRecords,
            };
        }
    }

    onFilterChanged(event: FilterChangedEvent<any, any>) {
        if (event.columns) {
            const filtersSelected = event.api.getFilterModel();
            if (Object.keys(filtersSelected).length === 0) {
                this.resetSort(false, true);
                this.fetchAdresses();
                return;
            }
            const oDataFilters = Object.entries(filtersSelected)
                .map(([key, filter]) => {
                    return mapAgGridFiltersToOData(filter.type, filter.filter ?? new Date(filter.dateFrom).toISOString(), key, filter.filterType);
                })
                .join(' and ');
            this.fetchParams = {
                ...this.fetchParams,
                $skip: 0,
                $filter: oDataFilters,
            };
            this.fetchAdresses();
        }
    }

    onPageSizeChange(event: PageEvent) {
        if (this.nbRecords !== event.pageSize) {
            this.nbRecords = event.pageSize;
            this.fetchParams = {
                ...this.fetchParams,
                $top: event.pageSize,
            };
            this.fetchAdresses();
        }
        if (event.pageIndex > (event.previousPageIndex ?? -1)) {
            this.fetchPage(this.rawData?.nextLink ?? null);
        } else if (event.pageIndex < (event.previousPageIndex ?? -1)) {
            this.fetchPage(this.rawData?.previousLink ?? null);
        }
    }
    fetchPage(link: string | null) {
        if (link) {
            const url = new URL(link);
            const params = url.searchParams;
            const skip = params.get('skip');
            const top = params.get('top');
            if (skip && top) {
                this.fetchParams = {
                    ...this.fetchParams,
                    $top: +top,
                    $skip: +skip,
                };
                this.fetchAdresses(this.fetchParams);
            }
        }
    }

    getLatLongString(data: LieuEchangeDtoOuputAvecGeolocalisation) {
        return `${data.lat}/${data.lng}`;
    }
}
