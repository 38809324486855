<div class="w-full">
  @if(loading) {
  <div class="flex items-center justify-center h-40">
    <mat-spinner id="mat-spinner" diameter="32"></mat-spinner>
  </div>
  }
  @else if (conversation) {
  <div class="flex flex-col gap-4 divide-y h-[calc(100vh-170px)] w-full py-10">
    <div class="flex justify-between items-center px-8">
      <div class="flex gap-4 items-center">
        <div class="lg:hidden">
          <button mat-icon-button (click)="handleReturn()">
            <mat-icon>arrow_back</mat-icon>
          </button>
        </div>
        <mat-icon class="bg-[#F2F2F2] flex items-center rounded-full text-[#AEAEAE] custom-icon">person</mat-icon>
        <div class="flex gap-2 flex-col">
          <p class="text-[14px] font-bold flex items-center gap-4">
            {{ getDisplayedName() }}
          </p>
        </div>
      </div>
      <div>
        <button mat-stroked-button class=" !border-primary !text-primary !bg-white" (click)="handleCloseConv()">
        Fermer la conversation
        </button>
      </div>
    </div>
    <div class="flex flex-col w-full h-[90%] gap-4 ">
      <div
        class="flex overflow-y-scroll grow shrink basis-auto min-h-0 px-8 py-4 flex-col-reverse gap-4 items-center scrollbar-visible">
        @for (message of conversation.messages; track $index) {
        <div class="flex flex-col" [ngClass]="message.envoyeParMoi ? 'self-end': 'self-start' ">
          <div class="text-[10px] px-1 " [ngClass]="message.envoyeParMoi ? 'self-end': 'self-start' ">{{message.date
            | date:
            'HH:mm le dd/MM/YY'}}</div>
          <div [ngClass]="message.envoyeParMoi ? 'bg-tertiary text-white self-end': 'bg-[#F2F2F2] self-start' "
            class="p-4 w-fit rounded-md">
            {{message.message}}</div>
        </div>
        }

      </div>

      <form [formGroup]="chatForm" (ngSubmit)="sendMessage()" class="relative flex items-center px-8 gap-4">
        @if(isUserTyping()){
        <div class="text-[10px] px-2 -top-6 left-0 z-30
        absolute">{{conversation.contact?.firstName}} est en train d'écrire...</div>
        }
        <input matInput placeholder="Entrez votre message" type="text"
          class="w-full border botder-  bg-[#F7FCFA] px-4 py-3 rounded placeholder-[#333]" formControlName="message"
          (ngModelChange)="typing()" />
        <button class="bg-tertiary h-full p-3 flex justify-center items-center">
          <mat-icon class="text-white">send</mat-icon>
        </button>
      </form>
    </div>
  </div>
  }
  @else {
  <div class="flex items-center justify-center h-40">
    <p class="text-[14px]">Aucune conversation sélectionnée.</p>
  </div>
  }
</div>