/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ANomenclatureDtoOutputForList } from '../models/a-nomenclature-dto-output-for-list';
import { ArticleEtatDtoOutPutForList } from '../models/article-etat-dto-out-put-for-list';
import { CategorieDictionaryWithTailles } from '../models/categorie-dictionary-with-tailles';
import { CategoryGroupingDtoOutputForCatalogue } from '../models/category-grouping-dto-output-for-catalogue';
import { EspeceDtoOuputForListOdataResponse } from '../models/espece-dto-ouput-for-list-odata-response';
import { FamilleDtoOutputForDetail } from '../models/famille-dto-output-for-detail';
import { ReferenceDtoOutputForDetail } from '../models/reference-dto-output-for-detail';
import { referencesArticleEtatsGet$Any } from '../fn/references/references-article-etats-get-any';
import { ReferencesArticleEtatsGet$Any$Params } from '../fn/references/references-article-etats-get-any';
import { referencesArticleEtatsGet$Json } from '../fn/references/references-article-etats-get-json';
import { ReferencesArticleEtatsGet$Json$Params } from '../fn/references/references-article-etats-get-json';
import { referencesArticleEtatsGet$Plain } from '../fn/references/references-article-etats-get-plain';
import { ReferencesArticleEtatsGet$Plain$Params } from '../fn/references/references-article-etats-get-plain';
import { referencesCategoriesTaillesGet$Any } from '../fn/references/references-categories-tailles-get-any';
import { ReferencesCategoriesTaillesGet$Any$Params } from '../fn/references/references-categories-tailles-get-any';
import { referencesCategoriesTaillesGet$Json } from '../fn/references/references-categories-tailles-get-json';
import { ReferencesCategoriesTaillesGet$Json$Params } from '../fn/references/references-categories-tailles-get-json';
import { referencesCategoriesTaillesGet$Plain } from '../fn/references/references-categories-tailles-get-plain';
import { ReferencesCategoriesTaillesGet$Plain$Params } from '../fn/references/references-categories-tailles-get-plain';
import { referencesConformationsGet$Any } from '../fn/references/references-conformations-get-any';
import { ReferencesConformationsGet$Any$Params } from '../fn/references/references-conformations-get-any';
import { referencesConformationsGet$Json } from '../fn/references/references-conformations-get-json';
import { ReferencesConformationsGet$Json$Params } from '../fn/references/references-conformations-get-json';
import { referencesConformationsGet$Plain } from '../fn/references/references-conformations-get-plain';
import { ReferencesConformationsGet$Plain$Params } from '../fn/references/references-conformations-get-plain';
import { referencesEspecesGet$Any } from '../fn/references/references-especes-get-any';
import { ReferencesEspecesGet$Any$Params } from '../fn/references/references-especes-get-any';
import { referencesEspecesGet$Json } from '../fn/references/references-especes-get-json';
import { ReferencesEspecesGet$Json$Params } from '../fn/references/references-especes-get-json';
import { referencesEspecesGet$Plain } from '../fn/references/references-especes-get-plain';
import { ReferencesEspecesGet$Plain$Params } from '../fn/references/references-especes-get-plain';
import { referencesFamillesGet$Any } from '../fn/references/references-familles-get-any';
import { ReferencesFamillesGet$Any$Params } from '../fn/references/references-familles-get-any';
import { referencesFamillesGet$Json } from '../fn/references/references-familles-get-json';
import { ReferencesFamillesGet$Json$Params } from '../fn/references/references-familles-get-json';
import { referencesFamillesGet$Plain } from '../fn/references/references-familles-get-plain';
import { ReferencesFamillesGet$Plain$Params } from '../fn/references/references-familles-get-plain';
import { referencesForcesGet$Any } from '../fn/references/references-forces-get-any';
import { ReferencesForcesGet$Any$Params } from '../fn/references/references-forces-get-any';
import { referencesForcesGet$Json } from '../fn/references/references-forces-get-json';
import { ReferencesForcesGet$Json$Params } from '../fn/references/references-forces-get-json';
import { referencesForcesGet$Plain } from '../fn/references/references-forces-get-plain';
import { ReferencesForcesGet$Plain$Params } from '../fn/references/references-forces-get-plain';
import { referencesReferencesCatalogueGet$Any } from '../fn/references/references-references-catalogue-get-any';
import { ReferencesReferencesCatalogueGet$Any$Params } from '../fn/references/references-references-catalogue-get-any';
import { referencesReferencesCatalogueGet$Json } from '../fn/references/references-references-catalogue-get-json';
import { ReferencesReferencesCatalogueGet$Json$Params } from '../fn/references/references-references-catalogue-get-json';
import { referencesReferencesCatalogueGet$Plain } from '../fn/references/references-references-catalogue-get-plain';
import { ReferencesReferencesCatalogueGet$Plain$Params } from '../fn/references/references-references-catalogue-get-plain';
import { referencesReferencesGet$Any } from '../fn/references/references-references-get-any';
import { ReferencesReferencesGet$Any$Params } from '../fn/references/references-references-get-any';
import { referencesReferencesGet$Json } from '../fn/references/references-references-get-json';
import { ReferencesReferencesGet$Json$Params } from '../fn/references/references-references-get-json';
import { referencesReferencesGet$Plain } from '../fn/references/references-references-get-plain';
import { ReferencesReferencesGet$Plain$Params } from '../fn/references/references-references-get-plain';
import { referencesSousFamillesGet$Any } from '../fn/references/references-sous-familles-get-any';
import { ReferencesSousFamillesGet$Any$Params } from '../fn/references/references-sous-familles-get-any';
import { referencesSousFamillesGet$Json } from '../fn/references/references-sous-familles-get-json';
import { ReferencesSousFamillesGet$Json$Params } from '../fn/references/references-sous-familles-get-json';
import { referencesSousFamillesGet$Plain } from '../fn/references/references-sous-familles-get-plain';
import { ReferencesSousFamillesGet$Plain$Params } from '../fn/references/references-sous-familles-get-plain';
import { referencesTaillesGet$Any } from '../fn/references/references-tailles-get-any';
import { ReferencesTaillesGet$Any$Params } from '../fn/references/references-tailles-get-any';
import { referencesTaillesGet$Json } from '../fn/references/references-tailles-get-json';
import { ReferencesTaillesGet$Json$Params } from '../fn/references/references-tailles-get-json';
import { referencesTaillesGet$Plain } from '../fn/references/references-tailles-get-plain';
import { ReferencesTaillesGet$Plain$Params } from '../fn/references/references-tailles-get-plain';
import { SousFamilleDtoOutputForDetail } from '../models/sous-famille-dto-output-for-detail';

@Injectable({ providedIn: 'root' })
export class ReferencesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `referencesForcesGet()` */
  static readonly ReferencesForcesGetPath = '/references/forces';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesForcesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesForcesGet$Json$Response(params?: ReferencesForcesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>> {
    return referencesForcesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesForcesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesForcesGet$Json(params?: ReferencesForcesGet$Json$Params, context?: HttpContext): Observable<Array<ANomenclatureDtoOutputForList>> {
    return this.referencesForcesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>): Array<ANomenclatureDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesForcesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesForcesGet$Plain$Response(params?: ReferencesForcesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>> {
    return referencesForcesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesForcesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesForcesGet$Plain(params?: ReferencesForcesGet$Plain$Params, context?: HttpContext): Observable<Array<ANomenclatureDtoOutputForList>> {
    return this.referencesForcesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>): Array<ANomenclatureDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesForcesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesForcesGet$Any$Response(params?: ReferencesForcesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>> {
    return referencesForcesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesForcesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesForcesGet$Any(params?: ReferencesForcesGet$Any$Params, context?: HttpContext): Observable<Array<ANomenclatureDtoOutputForList>> {
    return this.referencesForcesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>): Array<ANomenclatureDtoOutputForList> => r.body)
    );
  }

  /** Path part for operation `referencesFamillesGet()` */
  static readonly ReferencesFamillesGetPath = '/references/familles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesFamillesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesFamillesGet$Json$Response(params?: ReferencesFamillesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FamilleDtoOutputForDetail>>> {
    return referencesFamillesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesFamillesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesFamillesGet$Json(params?: ReferencesFamillesGet$Json$Params, context?: HttpContext): Observable<Array<FamilleDtoOutputForDetail>> {
    return this.referencesFamillesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FamilleDtoOutputForDetail>>): Array<FamilleDtoOutputForDetail> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesFamillesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesFamillesGet$Plain$Response(params?: ReferencesFamillesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FamilleDtoOutputForDetail>>> {
    return referencesFamillesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesFamillesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesFamillesGet$Plain(params?: ReferencesFamillesGet$Plain$Params, context?: HttpContext): Observable<Array<FamilleDtoOutputForDetail>> {
    return this.referencesFamillesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FamilleDtoOutputForDetail>>): Array<FamilleDtoOutputForDetail> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesFamillesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesFamillesGet$Any$Response(params?: ReferencesFamillesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FamilleDtoOutputForDetail>>> {
    return referencesFamillesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesFamillesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesFamillesGet$Any(params?: ReferencesFamillesGet$Any$Params, context?: HttpContext): Observable<Array<FamilleDtoOutputForDetail>> {
    return this.referencesFamillesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FamilleDtoOutputForDetail>>): Array<FamilleDtoOutputForDetail> => r.body)
    );
  }

  /** Path part for operation `referencesSousFamillesGet()` */
  static readonly ReferencesSousFamillesGetPath = '/references/sous-familles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesSousFamillesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesSousFamillesGet$Json$Response(params?: ReferencesSousFamillesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>> {
    return referencesSousFamillesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesSousFamillesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesSousFamillesGet$Json(params?: ReferencesSousFamillesGet$Json$Params, context?: HttpContext): Observable<Array<SousFamilleDtoOutputForDetail>> {
    return this.referencesSousFamillesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>): Array<SousFamilleDtoOutputForDetail> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesSousFamillesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesSousFamillesGet$Plain$Response(params?: ReferencesSousFamillesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>> {
    return referencesSousFamillesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesSousFamillesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesSousFamillesGet$Plain(params?: ReferencesSousFamillesGet$Plain$Params, context?: HttpContext): Observable<Array<SousFamilleDtoOutputForDetail>> {
    return this.referencesSousFamillesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>): Array<SousFamilleDtoOutputForDetail> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesSousFamillesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesSousFamillesGet$Any$Response(params?: ReferencesSousFamillesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>> {
    return referencesSousFamillesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesSousFamillesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesSousFamillesGet$Any(params?: ReferencesSousFamillesGet$Any$Params, context?: HttpContext): Observable<Array<SousFamilleDtoOutputForDetail>> {
    return this.referencesSousFamillesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>): Array<SousFamilleDtoOutputForDetail> => r.body)
    );
  }

  /** Path part for operation `referencesArticleEtatsGet()` */
  static readonly ReferencesArticleEtatsGetPath = '/references/article-etats';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesArticleEtatsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesArticleEtatsGet$Json$Response(params?: ReferencesArticleEtatsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArticleEtatDtoOutPutForList>>> {
    return referencesArticleEtatsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesArticleEtatsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesArticleEtatsGet$Json(params?: ReferencesArticleEtatsGet$Json$Params, context?: HttpContext): Observable<Array<ArticleEtatDtoOutPutForList>> {
    return this.referencesArticleEtatsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleEtatDtoOutPutForList>>): Array<ArticleEtatDtoOutPutForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesArticleEtatsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesArticleEtatsGet$Plain$Response(params?: ReferencesArticleEtatsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArticleEtatDtoOutPutForList>>> {
    return referencesArticleEtatsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesArticleEtatsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesArticleEtatsGet$Plain(params?: ReferencesArticleEtatsGet$Plain$Params, context?: HttpContext): Observable<Array<ArticleEtatDtoOutPutForList>> {
    return this.referencesArticleEtatsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleEtatDtoOutPutForList>>): Array<ArticleEtatDtoOutPutForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesArticleEtatsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesArticleEtatsGet$Any$Response(params?: ReferencesArticleEtatsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ArticleEtatDtoOutPutForList>>> {
    return referencesArticleEtatsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesArticleEtatsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesArticleEtatsGet$Any(params?: ReferencesArticleEtatsGet$Any$Params, context?: HttpContext): Observable<Array<ArticleEtatDtoOutPutForList>> {
    return this.referencesArticleEtatsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ArticleEtatDtoOutPutForList>>): Array<ArticleEtatDtoOutPutForList> => r.body)
    );
  }

  /** Path part for operation `referencesReferencesGet()` */
  static readonly ReferencesReferencesGetPath = '/references/references';

  /**
   * Obtient la liste des références (nomenclature) correspondants à la saisie sous forme de liste.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesReferencesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesGet$Json$Response(params?: ReferencesReferencesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReferenceDtoOutputForDetail>>> {
    return referencesReferencesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtient la liste des références (nomenclature) correspondants à la saisie sous forme de liste.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesReferencesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesGet$Json(params?: ReferencesReferencesGet$Json$Params, context?: HttpContext): Observable<Array<ReferenceDtoOutputForDetail>> {
    return this.referencesReferencesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReferenceDtoOutputForDetail>>): Array<ReferenceDtoOutputForDetail> => r.body)
    );
  }

  /**
   * Obtient la liste des références (nomenclature) correspondants à la saisie sous forme de liste.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesReferencesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesGet$Plain$Response(params?: ReferencesReferencesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReferenceDtoOutputForDetail>>> {
    return referencesReferencesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtient la liste des références (nomenclature) correspondants à la saisie sous forme de liste.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesReferencesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesGet$Plain(params?: ReferencesReferencesGet$Plain$Params, context?: HttpContext): Observable<Array<ReferenceDtoOutputForDetail>> {
    return this.referencesReferencesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReferenceDtoOutputForDetail>>): Array<ReferenceDtoOutputForDetail> => r.body)
    );
  }

  /**
   * Obtient la liste des références (nomenclature) correspondants à la saisie sous forme de liste.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesReferencesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesGet$Any$Response(params?: ReferencesReferencesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReferenceDtoOutputForDetail>>> {
    return referencesReferencesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtient la liste des références (nomenclature) correspondants à la saisie sous forme de liste.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesReferencesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesGet$Any(params?: ReferencesReferencesGet$Any$Params, context?: HttpContext): Observable<Array<ReferenceDtoOutputForDetail>> {
    return this.referencesReferencesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReferenceDtoOutputForDetail>>): Array<ReferenceDtoOutputForDetail> => r.body)
    );
  }

  /** Path part for operation `referencesReferencesCatalogueGet()` */
  static readonly ReferencesReferencesCatalogueGetPath = '/references/references/catalogue';

  /**
   * Obtient le catalogue des références (nommnclature) correspondants à la saisie sous forme de groupe avec Famille → sous famille.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesReferencesCatalogueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesCatalogueGet$Json$Response(params?: ReferencesReferencesCatalogueGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryGroupingDtoOutputForCatalogue>>> {
    return referencesReferencesCatalogueGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtient le catalogue des références (nommnclature) correspondants à la saisie sous forme de groupe avec Famille → sous famille.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesReferencesCatalogueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesCatalogueGet$Json(params?: ReferencesReferencesCatalogueGet$Json$Params, context?: HttpContext): Observable<Array<CategoryGroupingDtoOutputForCatalogue>> {
    return this.referencesReferencesCatalogueGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryGroupingDtoOutputForCatalogue>>): Array<CategoryGroupingDtoOutputForCatalogue> => r.body)
    );
  }

  /**
   * Obtient le catalogue des références (nommnclature) correspondants à la saisie sous forme de groupe avec Famille → sous famille.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesReferencesCatalogueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesCatalogueGet$Plain$Response(params?: ReferencesReferencesCatalogueGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryGroupingDtoOutputForCatalogue>>> {
    return referencesReferencesCatalogueGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtient le catalogue des références (nommnclature) correspondants à la saisie sous forme de groupe avec Famille → sous famille.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesReferencesCatalogueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesCatalogueGet$Plain(params?: ReferencesReferencesCatalogueGet$Plain$Params, context?: HttpContext): Observable<Array<CategoryGroupingDtoOutputForCatalogue>> {
    return this.referencesReferencesCatalogueGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryGroupingDtoOutputForCatalogue>>): Array<CategoryGroupingDtoOutputForCatalogue> => r.body)
    );
  }

  /**
   * Obtient le catalogue des références (nommnclature) correspondants à la saisie sous forme de groupe avec Famille → sous famille.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesReferencesCatalogueGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesCatalogueGet$Any$Response(params?: ReferencesReferencesCatalogueGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategoryGroupingDtoOutputForCatalogue>>> {
    return referencesReferencesCatalogueGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtient le catalogue des références (nommnclature) correspondants à la saisie sous forme de groupe avec Famille → sous famille.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesReferencesCatalogueGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesReferencesCatalogueGet$Any(params?: ReferencesReferencesCatalogueGet$Any$Params, context?: HttpContext): Observable<Array<CategoryGroupingDtoOutputForCatalogue>> {
    return this.referencesReferencesCatalogueGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategoryGroupingDtoOutputForCatalogue>>): Array<CategoryGroupingDtoOutputForCatalogue> => r.body)
    );
  }

  /** Path part for operation `referencesCategoriesTaillesGet()` */
  static readonly ReferencesCategoriesTaillesGetPath = '/references/categories-tailles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesCategoriesTaillesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesCategoriesTaillesGet$Json$Response(params?: ReferencesCategoriesTaillesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategorieDictionaryWithTailles>>> {
    return referencesCategoriesTaillesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesCategoriesTaillesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesCategoriesTaillesGet$Json(params?: ReferencesCategoriesTaillesGet$Json$Params, context?: HttpContext): Observable<Array<CategorieDictionaryWithTailles>> {
    return this.referencesCategoriesTaillesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategorieDictionaryWithTailles>>): Array<CategorieDictionaryWithTailles> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesCategoriesTaillesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesCategoriesTaillesGet$Plain$Response(params?: ReferencesCategoriesTaillesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategorieDictionaryWithTailles>>> {
    return referencesCategoriesTaillesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesCategoriesTaillesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesCategoriesTaillesGet$Plain(params?: ReferencesCategoriesTaillesGet$Plain$Params, context?: HttpContext): Observable<Array<CategorieDictionaryWithTailles>> {
    return this.referencesCategoriesTaillesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategorieDictionaryWithTailles>>): Array<CategorieDictionaryWithTailles> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesCategoriesTaillesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesCategoriesTaillesGet$Any$Response(params?: ReferencesCategoriesTaillesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategorieDictionaryWithTailles>>> {
    return referencesCategoriesTaillesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesCategoriesTaillesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesCategoriesTaillesGet$Any(params?: ReferencesCategoriesTaillesGet$Any$Params, context?: HttpContext): Observable<Array<CategorieDictionaryWithTailles>> {
    return this.referencesCategoriesTaillesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CategorieDictionaryWithTailles>>): Array<CategorieDictionaryWithTailles> => r.body)
    );
  }

  /** Path part for operation `referencesTaillesGet()` */
  static readonly ReferencesTaillesGetPath = '/references/tailles';

  /**
   * Demande les tailles possibles en fonction des attributs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesTaillesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesTaillesGet$Json$Response(params?: ReferencesTaillesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return referencesTaillesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Demande les tailles possibles en fonction des attributs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesTaillesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesTaillesGet$Json(params?: ReferencesTaillesGet$Json$Params, context?: HttpContext): Observable<Array<string>> {
    return this.referencesTaillesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * Demande les tailles possibles en fonction des attributs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesTaillesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesTaillesGet$Plain$Response(params?: ReferencesTaillesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return referencesTaillesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Demande les tailles possibles en fonction des attributs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesTaillesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesTaillesGet$Plain(params?: ReferencesTaillesGet$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.referencesTaillesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * Demande les tailles possibles en fonction des attributs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesTaillesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesTaillesGet$Any$Response(params?: ReferencesTaillesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return referencesTaillesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Demande les tailles possibles en fonction des attributs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesTaillesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesTaillesGet$Any(params?: ReferencesTaillesGet$Any$Params, context?: HttpContext): Observable<Array<string>> {
    return this.referencesTaillesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `referencesConformationsGet()` */
  static readonly ReferencesConformationsGetPath = '/references/conformations';

  /**
   * Demande les conformations possibles en fonction des attributs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesConformationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesConformationsGet$Json$Response(params?: ReferencesConformationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>> {
    return referencesConformationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Demande les conformations possibles en fonction des attributs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesConformationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesConformationsGet$Json(params?: ReferencesConformationsGet$Json$Params, context?: HttpContext): Observable<Array<ANomenclatureDtoOutputForList>> {
    return this.referencesConformationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>): Array<ANomenclatureDtoOutputForList> => r.body)
    );
  }

  /**
   * Demande les conformations possibles en fonction des attributs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesConformationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesConformationsGet$Plain$Response(params?: ReferencesConformationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>> {
    return referencesConformationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Demande les conformations possibles en fonction des attributs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesConformationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesConformationsGet$Plain(params?: ReferencesConformationsGet$Plain$Params, context?: HttpContext): Observable<Array<ANomenclatureDtoOutputForList>> {
    return this.referencesConformationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>): Array<ANomenclatureDtoOutputForList> => r.body)
    );
  }

  /**
   * Demande les conformations possibles en fonction des attributs.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesConformationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesConformationsGet$Any$Response(params?: ReferencesConformationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>> {
    return referencesConformationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Demande les conformations possibles en fonction des attributs.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesConformationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesConformationsGet$Any(params?: ReferencesConformationsGet$Any$Params, context?: HttpContext): Observable<Array<ANomenclatureDtoOutputForList>> {
    return this.referencesConformationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ANomenclatureDtoOutputForList>>): Array<ANomenclatureDtoOutputForList> => r.body)
    );
  }

  /** Path part for operation `referencesEspecesGet()` */
  static readonly ReferencesEspecesGetPath = '/references/especes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesEspecesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesEspecesGet$Json$Response(params?: ReferencesEspecesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<EspeceDtoOuputForListOdataResponse>> {
    return referencesEspecesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesEspecesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesEspecesGet$Json(params?: ReferencesEspecesGet$Json$Params, context?: HttpContext): Observable<EspeceDtoOuputForListOdataResponse> {
    return this.referencesEspecesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EspeceDtoOuputForListOdataResponse>): EspeceDtoOuputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesEspecesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesEspecesGet$Plain$Response(params?: ReferencesEspecesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EspeceDtoOuputForListOdataResponse>> {
    return referencesEspecesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesEspecesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesEspecesGet$Plain(params?: ReferencesEspecesGet$Plain$Params, context?: HttpContext): Observable<EspeceDtoOuputForListOdataResponse> {
    return this.referencesEspecesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EspeceDtoOuputForListOdataResponse>): EspeceDtoOuputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `referencesEspecesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesEspecesGet$Any$Response(params?: ReferencesEspecesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<EspeceDtoOuputForListOdataResponse>> {
    return referencesEspecesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `referencesEspecesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  referencesEspecesGet$Any(params?: ReferencesEspecesGet$Any$Params, context?: HttpContext): Observable<EspeceDtoOuputForListOdataResponse> {
    return this.referencesEspecesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<EspeceDtoOuputForListOdataResponse>): EspeceDtoOuputForListOdataResponse => r.body)
    );
  }

}
