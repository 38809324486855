import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { DataSharingService } from '@app/utils/services/data-sharing.services';
import { CommonModule } from '@angular/common';

@Component({
    standalone: true,
    templateUrl: './category-color.component.html',
    imports: [CommonModule],
    styleUrls: ['./category-color.component.css'],
})
export class CategoryColorComponent implements ICellRendererAngularComp {
    params: ICellRendererParams | undefined;
    constructor() {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
    getColor(color: string): string {
        return `${color}55`;
    }
}
