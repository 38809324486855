<div class="relative flex w-screen h-full gap-4 flex-col justify-center  px-16">
  <div class="flex items-center justify-end gap-6">
    <button mat-flat-button class="!bg-primary" (click)="addNotif()">Nouvelle notification</button>
    <app-export-button (export)="export()"></app-export-button>
  </div>
  @if(rawData){
  <app-datagrid class="h-4/5" [data]="rawData" [columns]="colDefs" [nbRecords]="nbRecords"
    (sortChanged)="onSortChanged($event)" (filterChanged)="onFilterChanged($event)"
    (pageSizeChange)="onPageSizeChange($event)" (gridReady)="onGridReady($event)"></app-datagrid>
  }
</div>