/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LivreurXOffreInput } from '../../models/livreur-x-offre-input';

export interface OffresIdPut$Any$Params {
  id: string;
  OrderedFileNamesOrURLs?: Array<string>;
  Description: string;
  EtatArticle: number;
  ModalitePayements?: Array<string>;
  ModaliteLivraisons?: Array<LivreurXOffreInput>;
  LieuxEchangeMainPropre?: Array<string>;
      body?: {
'Files'?: Array<Blob>;
}
}

export function offresIdPut$Any(http: HttpClient, rootUrl: string, params: OffresIdPut$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
  const rb = new RequestBuilder(rootUrl, offresIdPut$Any.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('OrderedFileNamesOrURLs', params.OrderedFileNamesOrURLs, {});
    rb.query('Description', params.Description, {});
    rb.query('EtatArticle', params.EtatArticle, {});
    rb.query('ModalitePayements', params.ModalitePayements, {});
    rb.query('ModaliteLivraisons', params.ModaliteLivraisons, {});
    rb.query('LieuxEchangeMainPropre', params.LieuxEchangeMainPropre, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<string>>;
    })
  );
}

offresIdPut$Any.PATH = '/offres/{id}';
