import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ApplicationRef } from '@angular/core';
import { MessagerieWallComponent } from './messagerie-wall/messagerie-wall.component';
import {
    ConversationAvecAdminDtoOutputWithLastMessageForList,
    ConversationDtoOutputForDetail,
    ConversationDtoOutputWithLastMessageForList,
    OffreDtoOutputForList,
    UserOutputForDetail,
} from '@app/api/models';
import { AdminService, MessagerieService } from '@app/api/services';
import { MessagerieChatComponent } from './messagerie-chat/messagerie-chat.component';
import { MessagerieMesMessagesConversationGet$Json$Params } from '@app/api/fn/messagerie/messagerie-mes-messages-conversation-get-json';
import { CommonModule } from '@angular/common';
// import { DataSharingService } from '@app/utils/data-sharing.services';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RealTimeClientService } from '@app/utils/services/realtime-client.services';
import { MessagerieOffresIdContacterPost$Params } from '@app/api/fn/messagerie/messagerie-offres-id-contacter-post';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DataSharingService } from '@app/utils/services/data-sharing.services';
import { AdminMessagesUtilisateursUtilisateurIdGet$Json$Params } from '@app/api/fn/admin/admin-messages-utilisateurs-utilisateur-id-get-json';
import { AdminMessagesUtilisateursUtilisateurIdDelete$Params } from '@app/api/fn/admin/admin-messages-utilisateurs-utilisateur-id-delete';
import { SnackBarService } from '@app/utils/services/snackbar.service';

@Component({
    selector: 'app-messagerie',
    standalone: true,
    templateUrl: './messagerie.component.html',
    styleUrl: './messagerie.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [MessagerieWallComponent, MessagerieChatComponent, CommonModule, MatProgressSpinnerModule],
})
export class MessagerieComponent implements OnInit, OnDestroy {
    private messageSubscription: Subscription | null = null;
    private typingSubscription: Subscription | null = null;
    private wallSubscription: Subscription | null = null;
    private realTime: RealTimeClientService | undefined;

    conversations: ConversationAvecAdminDtoOutputWithLastMessageForList[] = [];
    selectedConversation: ConversationAvecAdminDtoOutputWithLastMessageForList | null = null;
    conversationContent: ConversationDtoOutputForDetail | null = null;
    loading = false;
    convLoading = false;
    currentUserTyping: string[] = [];

    constructor(
        private messagerieService: MessagerieService,
        private adminService: AdminService,
        private cdr: ChangeDetectorRef,
        private dataSharingService: DataSharingService,
        private router: Router,
        private appRef: ApplicationRef,
        private snackbar: SnackBarService
    ) {
        this.realTime = new RealTimeClientService();
    }

    offre: OffreDtoOutputForList | undefined;
    destinataire: UserOutputForDetail | undefined;

    ngOnInit() {
        this.subscriteToWall();
        this.loadConversations();
        this.dataSharingService.getData().subscribe((data) => {
            if (data) {
                this.destinataire = data;
                this.conversationContent = {
                    contact: this.destinataire,
                    messages: [],
                };
                this.conversations.push({
                    contact: this.destinataire,
                    messages: null,
                });
                this.selectedConversation = this.conversations[0];
                this.openConversation(this.conversations[0]);
                this.cdr.detectChanges();
            }
        });
    }

    loadConversations() {
        this.loading = true;
        this.adminService.adminMessagesMurDesConversationsGet$Json().subscribe({
            next: (conversations) => {
                const alreadyTalkedForThisOffer = conversations.find((conversation) => conversation.contact?.id === this.destinataire?.id);
                if (alreadyTalkedForThisOffer) {
                    this.conversations = conversations;
                    this.openConversation(alreadyTalkedForThisOffer);
                } else {
                    this.conversations = [...this.conversations, ...conversations];
                }
            },
            error: (error) => {
                console.error('error', error);
                if (error.status === 401) {
                    this.router.navigate(['/login']);
                }
                this.loading = false;
            },
            complete: () => {
                this.loading = false;
                this.cdr.detectChanges();
            },
        });
    }

    async openConversation(conversation: ConversationDtoOutputWithLastMessageForList | ConversationAvecAdminDtoOutputWithLastMessageForList) {
        this.convLoading = true;
        this.selectedConversation = conversation;
        if (this.realTime) await this.realTime.joinRoom('', conversation.contact?.id ?? '');
        if (this.selectedConversation.contact) {
            const params: AdminMessagesUtilisateursUtilisateurIdGet$Json$Params = {
                utilisateurId: this.selectedConversation.contact.id ?? '',
            };
            // Si la conversation n'a pas encore de messages, on a crée une conversation vide
            if (this.selectedConversation.messages === null) {
                this.conversationContent = {
                    contact: this.destinataire,
                    messages: [],
                };
                this.convLoading = false;
                this.cdr.detectChanges();
            } else {
                this.adminService.adminMessagesUtilisateursUtilisateurIdGet$Json(params).subscribe({
                    next: (conversationContent) => {
                        this.conversationContent = conversationContent;
                        this.subscribeToMessages();
                        this.subscribeToTyping();
                    },
                    error: (error) => {
                        console.error('error', error);
                        if (error.status === 401) {
                            this.router.navigate(['/login']);
                        }
                        this.convLoading = false;
                    },
                    complete: () => {
                        this.convLoading = false;
                        if (this.selectedConversation && this.selectedConversation.messages && this.selectedConversation.messages.length > 0) {
                            this.selectedConversation.messages[0].lu = true;
                        }
                        //passer tout les messages en lu de la conversation selectionée en lu
                        this.cdr.detectChanges();
                    },
                });
            }
        }
    }

    backToList() {
        this.selectedConversation = null;
        this.conversationContent = null;
        this.cdr.detectChanges();
    }

    async sendMessage(event: MessagerieOffresIdContacterPost$Params) {
        const pasDeMessageAvantEnvoi = this.selectedConversation?.messages === null;
        if (this.realTime) {
            await this.realTime.sendMessage(event.message ?? '', event.destinataireId ?? '');
            if (pasDeMessageAvantEnvoi) {
                if (this.selectedConversation) {
                    await this.openConversation(this.selectedConversation);
                    this.cdr.detectChanges();
                }
            }
        }
    }
    subscribeToMessages() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe(); // Désabonnement préventif
        }
        if (this.realTime)
            this.messageSubscription = this.realTime.message$.subscribe({
                next: (message) => {
                    this.conversationContent?.messages?.unshift(message);
                    this.cdr.detectChanges();
                },
                error: (error) => console.error('Erreur lors de la réception d’un message', error),
            });
    }

    subscribeToTyping() {
        if (this.typingSubscription) {
            this.typingSubscription.unsubscribe();
        }
        if (this.realTime)
            this.typingSubscription = this.realTime.userTyping$.subscribe({
                next: (userTyping) => {
                    this.currentUserTyping = userTyping;
                    this.cdr.detectChanges();
                },
                error: (error) => {
                    console.error('error', error);
                    if (error.status === 401) {
                        this.router.navigate(['/login']);
                    }
                },
            });
    }
    subscriteToWall() {
        if (this.wallSubscription) {
            this.wallSubscription.unsubscribe();
        }
        if (this.realTime)
            this.wallSubscription = this.realTime.wall$.subscribe({
                next: (wall) => {
                    const wasSelected = this.selectedConversation?.contact?.id === wall.contact?.id;
                    this.conversations = this.conversations.filter((conversation) => conversation.contact?.id !== wall.contact?.id);
                    this.conversations.unshift(wall);
                    if (wasSelected) {
                        this.selectedConversation = wall;
                    }
                    this.cdr.detectChanges();
                },
                error: (error) => {
                    console.error('error', error);
                    if (error.status === 401) {
                        this.router.navigate(['/login']);
                    }
                },
            });
    }

    ngOnDestroy() {
        if (this.messageSubscription) {
            this.messageSubscription.unsubscribe();
        }
        if (this.typingSubscription) {
            this.typingSubscription.unsubscribe();
        }
        if (this.wallSubscription) {
            this.wallSubscription.unsubscribe();
        }
    }

    async sendTypingInfo(typing: boolean) {
        if (this.realTime) await this.realTime.imTyping(typing, '', this.selectedConversation?.contact?.id ?? '');
    }

    closeConversation(userId: string) {
        const params: AdminMessagesUtilisateursUtilisateurIdDelete$Params = {
            utilisateurId: userId,
        };
        this.adminService.adminMessagesUtilisateursUtilisateurIdDelete(params).subscribe({
            next: () => {
                this.selectedConversation = null;
                this.conversationContent = null;
                this.cdr.detectChanges();
                this.snackbar.openSnackBar('Conversation fermée', 'Fermer');
            }
        });
    }
}
