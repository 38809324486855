import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { AbstractControl, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormService } from '@app/utils/services/form-service';
import { EditTextFieldComponent } from '@app/components/fields/edit-text-field/edit-text-field.component';
import { EditAreaFieldComponent } from '@app/components/fields/edit-area-field/edit-area-field.component';
import { EditSelectFieldComponent } from '@app/components/fields/edit-select-field/edit-select-field.component';
import { DeltaTimeFieldComponent } from '../../../components/fields/delta-time-field/delta-time-field.component';
import { AdminService, DepartementCommuneRegionService, NotificationsService } from '@app/api/services';
import { SnackBarService } from '@app/utils/services/snackbar.service';
import { AddressService } from '@app/utils/services/address.service';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatOption } from '@angular/material/core';
import { EditMultiSelectFieldComponent } from '../../../components/fields/edit-multi-select-field/edit-multi-select-field.component';
import { CommuneDto } from '@app/utils/models/commune-dto';
import { EditMultiSelectAutocompleteFieldComponent } from '../../../components/fields/edit-multi-select-autocomplete-field/edit-multi-select-autocomplete-field.component';
import { RecursiveTreeFilteredFiledComponent } from '../../../components/fields/recursive-tree-filtered-filed/recursive-tree-filtered-filed.component';
import { TaskNode } from '@app/components/fields/recursive-tree-filtered-filed/recursive-tree/recursive-tree.component';
import { DepartementCommuneRegionRegionsDepartementsGet$Json$Params } from '@app/api/fn/departement-commune-region/departement-commune-region-regions-departements-get-json';
import { DepartementDtoOutputForList, NotificationTypeDtoOutput, RegionDtoOutputWithDepartements } from '@app/api/models';
import { MatLabel } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { AdminNotificationsProgrammeesIdGet$Json$Params } from '@app/api/fn/admin/admin-notifications-programmees-id-get-json';
import { catchError, Observable, of, tap } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { AdminNotificationsProgrammeesIdPut$Params } from '@app/api/fn/admin/admin-notifications-programmees-id-put';
import { AdminNotificationsProgrammeesPost$Params } from '@app/api/fn/admin/admin-notifications-programmees-post';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MatInputModule } from '@angular/material/input';
import { DateFieldComponent } from "../../../components/fields/date-field/date-field.component";
import moment from 'moment';

export function joursControlRequiredValidator(modeRecurrence: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (modeRecurrence === 'days' && !control.value) {
            return { required: true };
        }
        return null;
    };
}
export function moisControlRequiredValidator(modeRecurrence: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if (modeRecurrence === 'months' && !control.value) {
            return { required: true };
        }
        return null;
    };
}

export function dateFinRequiredValidator(modeRecurrence: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        if ((modeRecurrence === 'jour' || modeRecurrence === 'mois') && !control.value) {
            return { required: true };
        }
        return null;
    };
}

@Component({
    selector: 'app-notifications-detail',
    standalone: true,
    imports: [
    MatLabel,
    MatIconModule,
    MatDividerModule,
    EditTextFieldComponent,
    EditAreaFieldComponent,
    EditSelectFieldComponent,
    MatButtonModule,
    DeltaTimeFieldComponent,
    EditMultiSelectFieldComponent,
    EditMultiSelectAutocompleteFieldComponent,
    RecursiveTreeFilteredFiledComponent,
    DateFieldComponent,
],
    templateUrl: './notifications-detail.component.html',
    styleUrl: './notifications-detail.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'fr' }, provideMomentDateAdapter()],
})
export class NotificationsDetailComponent implements OnInit {
    notifId: string | undefined;
    title: string | undefined;

    editForm: FormGroup;

    editMode = false;

    LabelControl: FormControl = new FormControl('', Validators.required);
    TypeControl: FormControl = new FormControl('', Validators.required);
    joursControl: FormControl = new FormControl('');
    moisControl: FormControl = new FormControl('');
    gradeControl: FormControl = new FormControl('');
    geoFormControl = new FormControl<TaskNode[]>([]);
    geoSearchBarFormControl = new FormControl('');
    urlControl: FormControl = new FormControl('');
    contenuControl: FormControl = new FormControl('', Validators.required);
    dateDebutControl: FormControl = new FormControl<moment.Moment | null>(null, Validators.required);
    dateFinControl: FormControl = new  FormControl<moment.Moment | null>(null);
    HoursControl: FormControl = new FormControl<MatOption<string> | null>(null);
    MinutesControl: FormControl = new FormControl<MatOption<string> | null>(null);
    modeRecurrence = 'one';

    selectedNodes: WritableSignal<TaskNode[]> = signal([]);
    gradesOptions: string[] = [
        'Gendarme adjoint volontaire (GAV)',
        'Brigadier',
        'Brigadier-chef',
        'Gendarme',
        'Maréchal des logis',
        'Maréchal des logis-chef',
        'Adjudant',
        'Adjudant-chef',
        'Major',
        'Aspirant',
        'Sous-lieutenant',
        'Lieutenant',
        'Capitaine',
        "Commandant (ou Chef d'escadron)",
        'Lieutenant-colonel',
        'Colonel',
        'Général de brigade',
        'Général de division',
        "Général de corps d'armée",
        "Général d'armée",
    ];

    hours: string[] = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
    minutes: string[] = ['00', '15', '30', '45'];
    hoursOptions: MatOption<string>[] = [];
    minutesOptions: MatOption<string>[] = [];
    grades: MatOption<string>[] = [];
    notifTypeOptions: MatOption<string>[] = [];
    departements: TaskNode[] = [];
    villes: MatOption<CommuneDto>[] = [];
    regions: TaskNode[] = [];
    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private formService: FormService,
        private router: Router,
        private notificationsService: NotificationsService,
        private snackBarService: SnackBarService,
        private adminService: AdminService,
        private departementCommuneRegionService: DepartementCommuneRegionService,
        private cdr: ChangeDetectorRef,
    ) {
        this.editForm = this.fb.group({
            label: this.LabelControl,
            type: this.TypeControl,
            dateDebut : this.dateDebutControl,
            dateFin: this.dateFinControl,
            jours: this.joursControl,
            heure:this.HoursControl,
            minute:this.MinutesControl,
            mois: this.moisControl,
            geo: this.geoFormControl,
            url: this.urlControl,
            description: this.contenuControl,
        });
    }

    ngOnInit(): void {
        this.getHoursAndMinutes();
        this.getRegionsEtDepartements();
        this.updateJoursControlValidator();
        this.updateMoisControlValidator();
        this.updateDateFinControlValidator();

        this.route.params.subscribe((params) => {
            this.notifId = params['id'];
        });

        // permet de recuperer les données de la notification après le chargement des types
        this.getNotificationsTypes().subscribe(() => {
            this.getNotificationDetails();
        });
        this.getGrades();
        this.setNotifTitle();
    }

    getNotificationDetails() {
        if (this.notifId) {
            const params: AdminNotificationsProgrammeesIdGet$Json$Params = {
                id: this.notifId,
            };
            this.adminService.adminNotificationsProgrammeesIdGet$Json(params).subscribe({
                next: (notif) => {
                    this.LabelControl.setValue(notif.titre);
                    const typeOption = this.notifTypeOptions.find((option) => option.id === notif.type?.type);
                    if (typeOption) {
                        this.TypeControl.setValue(typeOption);
                    }
                    this.joursControl.setValue(notif.recurrenceJour);
                    this.moisControl.setValue(notif.recurrenceMois);
                    this.urlControl.setValue(notif.url);
                    this.dateDebutControl.setValue(moment(notif.datePublication));
                    this.dateFinControl.setValue(moment(notif.dateFinPublication));
                    this.contenuControl.setValue(notif.description);
                    this.getRecurrenceMode();
                    this.setSelectedHoursAndMinutes(notif.datePublication);
                    this.setSelectedDepartements(notif.departements);
                    this.cdr.detectChanges();
                },
                error: (err) => {
                    this.snackBarService.openSnackBar(err.error[''] || 'Error fetching notification details', 'Fermer');
                },
            });
        }
    }
    updateJoursControlValidator(): void {
        this.joursControl.setValidators([joursControlRequiredValidator(this.modeRecurrence)]);
        this.joursControl.updateValueAndValidity();
    }
    updateMoisControlValidator(): void {
        this.moisControl.setValidators([moisControlRequiredValidator(this.modeRecurrence)]);
        this.moisControl.updateValueAndValidity();
    }
    updateDateFinControlValidator(): void {
        this.dateFinControl.setValidators([dateFinRequiredValidator(this.modeRecurrence)]);
        this.dateFinControl.updateValueAndValidity();
    }

    getRegionsEtDepartements() {
        const params: DepartementCommuneRegionRegionsDepartementsGet$Json$Params = {
            search: '',
        };
        this.departementCommuneRegionService.departementCommuneRegionRegionsDepartementsGet$Json(params).subscribe({
            next: (data) => {
                this.regions = data.map((region) => {
                    const option = {
                        id: region.nom,
                        value: region,
                    } as MatOption<RegionDtoOutputWithDepartements>;

                    const parent = new TaskNode(
                        option,
                        new FormControl<boolean>(false), // control
                        new FormControl<boolean>(false), // hiddenControl
                    );
                    parent.setChildren(this.getDepartementOptions(option));
                    return parent;
                });
            },
            error: (err) => {
                this.snackBarService.openSnackBar(err.error[''] || 'Error fetching regions', 'Fermer');
            },
            complete: () => {
                this.cdr.detectChanges();
            },
        });
    }

    getDepartementOptions(regionOption: MatOption<RegionDtoOutputWithDepartements>): TaskNode[] {
        const departements: DepartementDtoOutputForList[] = regionOption.value.departements ?? [];
        const departementOptions = departements.map((departement) => {
            const option = {
                id: `${departement.nom} (${departement.codeDepartement})`,
                value: departement,
            } as MatOption<DepartementDtoOutputForList>;

            return new TaskNode(option);
        });
        return departementOptions;
    }

    setNotifTitle() {
        if (this.notifId) {
            this.title = 'Edition notification';
        } else {
            this.title = 'Nouvelle notification';
            this.editMode = true;
        }
    }

    setSelectedHoursAndMinutes(date: string | undefined) {
        const momentDate = moment(date);
        const heureSelected = this.hoursOptions.find((option) => option.id === momentDate.format('HH'));    
        const minuteSelected = this.minutesOptions.find((option) => option.id === momentDate.format('mm'));
        this.HoursControl.setValue(heureSelected);
        this.MinutesControl.setValue(minuteSelected);
        this.cdr.detectChanges();
    }

    getGrades() {
        this.grades = this.gradesOptions.map((garde) => ({ id: garde, value: garde }) as MatOption);
    }

    getHoursAndMinutes() {
        this.hoursOptions = this.hours.map((hour) => ({ id: hour, value: hour }) as MatOption);
        this.minutesOptions = this.minutes.map((minute) => ({ id: minute, value: minute }) as MatOption);
    }

    // getDepartments() {
    //     this.addressService.getDepartments().subscribe({
    //         next: (data) => {
    //             this.departements = data.map((departement) => {
    //                 const option = {
    //                     id: `${departement.nom} (${departement.code})`,
    //                     value: departement,
    //                 } as MatOption<DepartementDto>;

    //                 return new TaskNode(
    //                     option,
    //                     new FormControl<boolean>(false), // control
    //                     new FormControl<boolean>(false), // hiddenControl
    //                     //() => this.getCitiesByDepartments(option), // children
    //                 );
    //             });

    //             this.cdr.detectChanges();
    //         },
    //         error: (err) => {
    //             this.snackBarService.openSnackBar(err.error[''] || 'Error fetching departments', 'Fermer');
    //         },
    //     });
    // }

    // getCitiesByDepartments(departmentOption: MatOption<DepartementDto>): Observable<TaskNode[]> {
    //     const departmentCode = departmentOption.value.code;
    //     return this.addressService.getCitiesByDepartment(departmentCode).pipe(
    //         map((dataArray) => {
    //             // dataArray est un tableau de résultats, un tableau par département
    //             return dataArray.flat().map((ville) => {
    //                 const option = {
    //                     id: `${ville.nom} (${ville.code})`,
    //                     value: ville,
    //                 } as MatOption<CommuneDto>;

    //                 return new TaskNode(option);
    //             });
    //         }),
    //         catchError((err) => {
    //             // Gestion des erreurs
    //             this.snackBarService.openSnackBar(err.error[''] || 'Error occurred while fetching cities', 'Fermer');
    //             // Retourner un Observable vide ou avec un message d'erreur
    //             return of([]); // or throwError(err) if you want to propagate the error
    //         }),
    //     );
    // }

    onCancel() {
        if (this.notifId) {
            this.editMode = false;
        } else {
            this.retour();
        }
    }

    retour() {
        this.router.navigate(['/notifications']);
    }

    getNotificationsTypes(): Observable<HttpResponse<NotificationTypeDtoOutput[]>> {
        return this.notificationsService.notificationsTypeDeNotificationsGet$Json$Response().pipe(
            tap((response) => {
                this.notifTypeOptions = response.body
                    .map(
                        (notification) =>
                            ({
                                id: notification.type ?? '',
                                value: notification.type ?? '',
                            }) as MatOption,
                    )
                    .filter((option) => option.id !== '');
                this.cdr.detectChanges();
            }),
            catchError((error) => {
                this.snackBarService.openSnackBar(error.error[''] || 'Erreur lors de la création de la notification', 'Fermer');
                return of();
            }),
        );
    }

    onSubmit() {
        if (this.editMode) {
            if (!this.editForm) return;
            if (this.editForm.valid) {
                this.editMode = true;
                const departement = this.selectedNodes().map((node) => node.value); 
                const allCodes = departement.map((dep) => dep.value.codeDepartement).filter((code) => code !== undefined);
                const heure = this.HoursControl.value.value;
                const minute = this.MinutesControl.value.value;
                const dateInitiale = moment(this.dateDebutControl.value);
                
                dateInitiale.hour(heure).minute(minute);
        
                const dateDebut = dateInitiale.toISOString();
                const dateFin = moment(this.dateFinControl.value).toISOString();
                if(this.modeRecurrence === 'one') {
                    this.joursControl.setValue(null);
                    this.moisControl.setValue(null);
                }
                else if(this.modeRecurrence === 'days') {
                    this.moisControl.setValue(null);
                }
                else if(this.modeRecurrence === 'months') {
                    this.joursControl.setValue(null);
                }

                if (this.notifId) {
                    const params :AdminNotificationsProgrammeesIdPut$Params = {
                        id: this.notifId,
                        body: {
                            codeDepartements: allCodes,
                            titre: this.LabelControl.value,
                            type: this.TypeControl.value.id,
                            recurrenceJour: this.joursControl.value,
                            recurrenceMois: this.moisControl.value,
                            url: this.urlControl.value,
                            contenu: this.contenuControl.value,
                            datePublication: dateDebut,
                            dateFinPublication: dateFin,
                        },
                    }
                    this.adminService.adminNotificationsProgrammeesIdPut$Response(params).subscribe({
                        next: (response) => {
                            this.editMode = false;
                            this.cdr.detectChanges();
                        }
                    });
                    // this.putReference(this.ReferenceId, this.libelleFormControl.value, this.gradeFormControl.value, (success) => {
                    //     this.editMode = !success;
                    //     this.cdr.detectChanges();
                    // });
                }
                else {
                    const params: AdminNotificationsProgrammeesPost$Params = {
                        body: {
                            codeDepartements: allCodes,
                            titre: this.LabelControl.value,
                            type: this.TypeControl.value.id,
                            recurrenceJour: this.joursControl.value,
                            recurrenceMois: this.moisControl.value,
                            url: this.urlControl.value,
                            contenu: this.contenuControl.value,
                            datePublication: dateDebut,
                            dateFinPublication: dateFin,
                        },
                    }
                    this.adminService.adminNotificationsProgrammeesPost$Response(params).subscribe({
                        next: (response) => {
                            this.editMode = false;
                            this.cdr.detectChanges();
                        }
                    });
                }
            } else {
                this.formService.markAllAsTouched(this.editForm);
            }
        } else {
            this.editMode = true;
        }
    }

    getRecurrenceMode() {
        if (this.joursControl.value) {
            this.modeRecurrence = 'days';
        } else if (this.moisControl.value) {
            this.modeRecurrence = 'months';
        } else {
            this.modeRecurrence = 'one';
        }
        this.cdr.detectChanges();
    }

    setSelectedDepartements(departements: DepartementDtoOutputForList[] | null | undefined) {
        if (departements) {
            // remplir selectedNodes avec les departements, en comparant les departements avec les departements de la liste des departements qui ont un parent region
            const nodes = this.regions.flatMap((region) => region.children).filter((departement) => departements.some((dep) => dep.codeDepartement === departement.value.value.codeDepartement));
            this.selectedNodes.set(nodes);
        }
        this.cdr.detectChanges();
    }
    changeRecurrence(mode: string) {
        this.modeRecurrence = mode;
        this.updateJoursControlValidator();
        this.updateMoisControlValidator();
        this.updateDateFinControlValidator();
        this.cdr.detectChanges();
    }
}
