import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { ArticleCarrouselPopupComponent } from '../article-carrousel-popup/article-carrousel-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OffreDtoOutputForDetail } from '@app/api/models';
import { SnackBarService } from '@app/utils/services/snackbar.service';

export interface Image {
    cols: number;
    rows: number;
    url: string;
    text: string;
    color: string;
    index: number;
}

@Component({
    selector: 'dynamic-photo-grid-list',
    standalone: true,
    imports: [MatGridListModule, MatIconModule],
    templateUrl: './dynamic-photo-grid-list.component.html',
    styleUrl: './dynamic-photo-grid-list.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicPhotoGridListComponent implements OnInit {
    @Input() offre: OffreDtoOutputForDetail | undefined;

    currentIndex: number = 0;

    nbCols: number = 4;
    rowHeight: string | number = 0;
    ListUrls: string[] = [];

    @ViewChild('myDiv', { static: true }) myDiv: ElementRef | undefined;
    divWidth: number | undefined;

    constructor(
        public dialog: MatDialog,
        private location: Location,
        private snackBarServices: SnackBarService,
    ) {}

    ngOnInit(): void {
        this.calculateDivWidth();
        this.getImages();
    }

    @HostListener('window:resize', ['$event'])
    onResize(): void {
        this.calculateDivWidth();
    }

    calculateDivWidth(): void {
        if (!this.myDiv) return;
        this.divWidth = this.myDiv.nativeElement.offsetWidth;

        if (!this.divWidth) return;
        this.rowHeight = this.divWidth / this.nbCols;
    }

    getMaxLength(length: number): { size: number; reste: number } {
        const maxLength = 3;
        if (length <= maxLength) {
            return { size: length, reste: 0 };
        } else {
            return { size: maxLength, reste: length - maxLength };
        }
    }

    getNbCols(maxImages: number, i: number, restLength: number): number {
        if (maxImages < 1) return 0;
        if (maxImages === 1) return 4;
        if (restLength > 0 && i == 2) return 1;
        return 2;
    }

    getNbRows(maxImages: number, i: number): number {
        if (maxImages <= 2 || i === 0) {
            return 2;
        } else if (maxImages > 2) {
            return 1;
        }
        return 0;
    }

    getImages(): Image[] {
        let images: Image[] = [];
        this.ListUrls = this.offre?.imagesURls ?? [];
        const { size: maxImages, reste: restLength } = this.getMaxLength(this.ListUrls.length);

        //this.nbCols = 2 ** (maxImages - 1);

        for (let i = 0; i < maxImages; i++) {
            images.push({
                cols: this.getNbCols(maxImages, i, restLength),
                rows: this.getNbRows(maxImages, i),
                url: this.ListUrls[i],
                text: '',
                color: '#FFFFFF',
                index: i,
            });
        }

        if (restLength > 0) {
            images.push({
                cols: 1,
                rows: 1,
                url: '',
                text: `+ ${restLength}`,
                color: '#133F70',
                index: maxImages,
            });
        }

        return images;
    }

    OpenCarrousel(index: number) {
        this.dialog.open(ArticleCarrouselPopupComponent, {
            data: { images: this.ListUrls, index: index },
            panelClass: 'full-screen-modal',
        });
    }

    goBack(): void {
        this.location.back();
    }

    copyCurrentLink() {
        const currentLink = window.location.href;
        navigator.clipboard
            .writeText(currentLink)
            .then(() => {
                this.snackBarServices.openSnackBar('Lien copié', 'Fermer', false, 2500);
            })
            .catch((err) => {
                this.snackBarServices.openSnackBar('Erreur lors de la copie du lien', 'Fermer', true, 2500);
            });
    }

    share() {
        if (navigator.share) {
            navigator
                .share({
                    title: this.offre?.libelle ?? '',
                    text: `${this.offre?.libelle ?? ''} - ${this.offre?.description ?? ''}`,
                    url: window.location.href,
                })
                .then(() => {
                })
                .catch((error) => {
                    console.error('Erreur lors du partage:', error);
                });
        } else {
            console.error("Le partage Web n'est pas supporté sur ce navigateur.");
        }
    }
}
