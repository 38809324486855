<div class="dialog">
    <h2 mat-dialog-title class="dialog-title">Fermer la conversation</h2>
    <mat-dialog-content class="dialog-content">
        <p>Êtes-vous sûr de vouloir fermer la conversation avec <strong>{{getUserName()}}</strong>  ?</p>
        <p class="flex items-center gap-2 text-xs font-semibold text-red-700">
            <span class="material-icons-outlined">report_problem</span>
            Une conversation fermée n'est plus visible pour vous et pour votre interlocuteur.
        </p>
    

    </mat-dialog-content>
    <mat-dialog-actions class="modal-actions">
        <button mat-stroked-button class="!border-primary !bg-white" (click)="onDismiss()">Annuler</button>
        <button mat-flat-button class="!border-primary !bg-primary !text-white" [mat-dialog-close]="true" cdkFocusInitial (click)="onConfirm()">Fermer la conversation</button>
    </mat-dialog-actions>
</div>