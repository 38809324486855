import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';

interface SimpleError {
    [key: string]: string[];
}

interface DetailedError {
    type: string;
    title: string;
    status: number;
    errors: { [key: string]: string[] };
    traceId: string;
}

@Injectable({
    providedIn: 'root',
})
export class SnackBarService {
    constructor(private _snackBar: MatSnackBar) {}

    public openSnackBar(message: string, action: string, error = true, duration: number = 2500) {
        this._snackBar.open(message, action, {
            duration: duration,
            panelClass: error ? ['red-snackbar', 'snackbar'] : ['green-snackbar', 'snackbar'],
        });
    }

    public parseError = (errorResponse: string): SimpleError | DetailedError => {
        try {
            const errorObject = typeof errorResponse === 'string' ? JSON.parse(errorResponse) : errorResponse;
            return errorObject.type ? (errorObject as DetailedError) : (errorObject as SimpleError);
        } catch (e) {
            console.error('Failed to parse error:', e);
            return {} as SimpleError;
        }
    };

    public updateFormErrors(errors: { [key: string]: string[] }, formGroup: any) {
        for (const [field, messages] of Object.entries(errors)) {
            const toCamelCase = (field: string) => field.charAt(0).toLowerCase() + field.slice(1);
            const control = formGroup.get(toCamelCase(field));
            if (control) {
                control.setErrors({ server: messages.join(', ') });
            }
        }
    }

    public displayErrors(error: string, formGroup: any) {
        const parsedErrors = this.parseError(error);
        if ('type' in parsedErrors && parsedErrors.errors) {
            if (typeof parsedErrors.errors === 'object' && !Array.isArray(parsedErrors.errors)) {
                this.updateFormErrors(parsedErrors.errors, formGroup);
            }
        } else {
            const errorMessages = Object.values(parsedErrors).flat();
            if (!('type' in parsedErrors) && errorMessages.length > 0) {
                const snackTimer = timer(0, 5000).pipe(take(errorMessages.length));
                const sub = snackTimer.subscribe((i) => {
                    this.openSnackBar(errorMessages[i], 'Fermer', true, 5000);
                    if (i === errorMessages.length - 1) {
                        sub.unsubscribe();
                    }
                });
            } else {
                this.openSnackBar('Une erreur est survenue', 'Fermer', true, 5000);
            }
        }
    }
}
