/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConversationDtoOutputForDetail } from '../../models/conversation-dto-output-for-detail';

export interface MessagerieMesMessagesConversationGet$Plain$Params {
  OffreId?: string;
  destinataireId?: string;
}

export function messagerieMesMessagesConversationGet$Plain(http: HttpClient, rootUrl: string, params?: MessagerieMesMessagesConversationGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationDtoOutputForDetail>> {
  const rb = new RequestBuilder(rootUrl, messagerieMesMessagesConversationGet$Plain.PATH, 'get');
  if (params) {
    rb.query('OffreId', params.OffreId, {});
    rb.query('destinataireId', params.destinataireId, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConversationDtoOutputForDetail>;
    })
  );
}

messagerieMesMessagesConversationGet$Plain.PATH = '/messagerie/mes-messages/conversation';
