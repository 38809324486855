<!-- fullscreen-modal.component.html -->
<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
    <div class="w-full h-full flex items-center justify-center">
        <button (click)="close()" class="fixed top-5 right-5 text-white p-2">
            ✕
        </button>
        <div class="carousel relative w-4/5 h-4/5 mx-auto">
            <details-article-carousel class="h-full w-full" [imageList]="imageList"
                [currentIndex]="currentIndex"></details-article-carousel>
        </div>
    </div>
</div>