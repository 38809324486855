/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EditOffreDtoInputForForm } from '../models/edit-offre-dto-input-for-form';
import { OffreBrouillonDtoOutputForDetail } from '../models/offre-brouillon-dto-output-for-detail';
import { OffreBrouillonDtoOutputForList } from '../models/offre-brouillon-dto-output-for-list';
import { offresBrouillonsGet$Any } from '../fn/offres-brouillons/offres-brouillons-get-any';
import { OffresBrouillonsGet$Any$Params } from '../fn/offres-brouillons/offres-brouillons-get-any';
import { offresBrouillonsGet$Json } from '../fn/offres-brouillons/offres-brouillons-get-json';
import { OffresBrouillonsGet$Json$Params } from '../fn/offres-brouillons/offres-brouillons-get-json';
import { offresBrouillonsGet$Plain } from '../fn/offres-brouillons/offres-brouillons-get-plain';
import { OffresBrouillonsGet$Plain$Params } from '../fn/offres-brouillons/offres-brouillons-get-plain';
import { offresBrouillonsIdDelete } from '../fn/offres-brouillons/offres-brouillons-id-delete';
import { OffresBrouillonsIdDelete$Params } from '../fn/offres-brouillons/offres-brouillons-id-delete';
import { offresBrouillonsIdGet$Any } from '../fn/offres-brouillons/offres-brouillons-id-get-any';
import { OffresBrouillonsIdGet$Any$Params } from '../fn/offres-brouillons/offres-brouillons-id-get-any';
import { offresBrouillonsIdGet$Json } from '../fn/offres-brouillons/offres-brouillons-id-get-json';
import { OffresBrouillonsIdGet$Json$Params } from '../fn/offres-brouillons/offres-brouillons-id-get-json';
import { offresBrouillonsIdGet$Plain } from '../fn/offres-brouillons/offres-brouillons-id-get-plain';
import { OffresBrouillonsIdGet$Plain$Params } from '../fn/offres-brouillons/offres-brouillons-id-get-plain';
import { offresBrouillonsOffreIdEditionGet$Any } from '../fn/offres-brouillons/offres-brouillons-offre-id-edition-get-any';
import { OffresBrouillonsOffreIdEditionGet$Any$Params } from '../fn/offres-brouillons/offres-brouillons-offre-id-edition-get-any';
import { offresBrouillonsOffreIdEditionGet$Json } from '../fn/offres-brouillons/offres-brouillons-offre-id-edition-get-json';
import { OffresBrouillonsOffreIdEditionGet$Json$Params } from '../fn/offres-brouillons/offres-brouillons-offre-id-edition-get-json';
import { offresBrouillonsOffreIdEditionGet$Plain } from '../fn/offres-brouillons/offres-brouillons-offre-id-edition-get-plain';
import { OffresBrouillonsOffreIdEditionGet$Plain$Params } from '../fn/offres-brouillons/offres-brouillons-offre-id-edition-get-plain';
import { offresBrouillonsPost } from '../fn/offres-brouillons/offres-brouillons-post';
import { OffresBrouillonsPost$Params } from '../fn/offres-brouillons/offres-brouillons-post';

@Injectable({ providedIn: 'root' })
export class OffresBrouillonsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `offresBrouillonsIdGet()` */
  static readonly OffresBrouillonsIdGetPath = '/offres-brouillons/{Id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdGet$Json$Response(params: OffresBrouillonsIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OffreBrouillonDtoOutputForDetail>>> {
    return offresBrouillonsIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdGet$Json(params: OffresBrouillonsIdGet$Json$Params, context?: HttpContext): Observable<Array<OffreBrouillonDtoOutputForDetail>> {
    return this.offresBrouillonsIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OffreBrouillonDtoOutputForDetail>>): Array<OffreBrouillonDtoOutputForDetail> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdGet$Plain$Response(params: OffresBrouillonsIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OffreBrouillonDtoOutputForDetail>>> {
    return offresBrouillonsIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdGet$Plain(params: OffresBrouillonsIdGet$Plain$Params, context?: HttpContext): Observable<Array<OffreBrouillonDtoOutputForDetail>> {
    return this.offresBrouillonsIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OffreBrouillonDtoOutputForDetail>>): Array<OffreBrouillonDtoOutputForDetail> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdGet$Any$Response(params: OffresBrouillonsIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OffreBrouillonDtoOutputForDetail>>> {
    return offresBrouillonsIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdGet$Any(params: OffresBrouillonsIdGet$Any$Params, context?: HttpContext): Observable<Array<OffreBrouillonDtoOutputForDetail>> {
    return this.offresBrouillonsIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OffreBrouillonDtoOutputForDetail>>): Array<OffreBrouillonDtoOutputForDetail> => r.body)
    );
  }

  /** Path part for operation `offresBrouillonsIdDelete()` */
  static readonly OffresBrouillonsIdDeletePath = '/offres-brouillons/{Id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdDelete$Response(params: OffresBrouillonsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return offresBrouillonsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsIdDelete(params: OffresBrouillonsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.offresBrouillonsIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `offresBrouillonsGet()` */
  static readonly OffresBrouillonsGetPath = '/offres-brouillons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsGet$Json$Response(params?: OffresBrouillonsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OffreBrouillonDtoOutputForList>>> {
    return offresBrouillonsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsGet$Json(params?: OffresBrouillonsGet$Json$Params, context?: HttpContext): Observable<Array<OffreBrouillonDtoOutputForList>> {
    return this.offresBrouillonsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OffreBrouillonDtoOutputForList>>): Array<OffreBrouillonDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsGet$Plain$Response(params?: OffresBrouillonsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OffreBrouillonDtoOutputForList>>> {
    return offresBrouillonsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsGet$Plain(params?: OffresBrouillonsGet$Plain$Params, context?: HttpContext): Observable<Array<OffreBrouillonDtoOutputForList>> {
    return this.offresBrouillonsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OffreBrouillonDtoOutputForList>>): Array<OffreBrouillonDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsGet$Any$Response(params?: OffresBrouillonsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OffreBrouillonDtoOutputForList>>> {
    return offresBrouillonsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsGet$Any(params?: OffresBrouillonsGet$Any$Params, context?: HttpContext): Observable<Array<OffreBrouillonDtoOutputForList>> {
    return this.offresBrouillonsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OffreBrouillonDtoOutputForList>>): Array<OffreBrouillonDtoOutputForList> => r.body)
    );
  }

  /** Path part for operation `offresBrouillonsPost()` */
  static readonly OffresBrouillonsPostPath = '/offres-brouillons';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresBrouillonsPost$Response(params?: OffresBrouillonsPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return offresBrouillonsPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresBrouillonsPost(params?: OffresBrouillonsPost$Params, context?: HttpContext): Observable<void> {
    return this.offresBrouillonsPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `offresBrouillonsOffreIdEditionGet()` */
  static readonly OffresBrouillonsOffreIdEditionGetPath = '/offres-brouillons/{offreId}/edition';

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsOffreIdEditionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsOffreIdEditionGet$Json$Response(params: OffresBrouillonsOffreIdEditionGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<EditOffreDtoInputForForm>> {
    return offresBrouillonsOffreIdEditionGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsOffreIdEditionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsOffreIdEditionGet$Json(params: OffresBrouillonsOffreIdEditionGet$Json$Params, context?: HttpContext): Observable<EditOffreDtoInputForForm> {
    return this.offresBrouillonsOffreIdEditionGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditOffreDtoInputForForm>): EditOffreDtoInputForForm => r.body)
    );
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsOffreIdEditionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsOffreIdEditionGet$Plain$Response(params: OffresBrouillonsOffreIdEditionGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EditOffreDtoInputForForm>> {
    return offresBrouillonsOffreIdEditionGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsOffreIdEditionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsOffreIdEditionGet$Plain(params: OffresBrouillonsOffreIdEditionGet$Plain$Params, context?: HttpContext): Observable<EditOffreDtoInputForForm> {
    return this.offresBrouillonsOffreIdEditionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditOffreDtoInputForForm>): EditOffreDtoInputForForm => r.body)
    );
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresBrouillonsOffreIdEditionGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsOffreIdEditionGet$Any$Response(params: OffresBrouillonsOffreIdEditionGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<EditOffreDtoInputForForm>> {
    return offresBrouillonsOffreIdEditionGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresBrouillonsOffreIdEditionGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresBrouillonsOffreIdEditionGet$Any(params: OffresBrouillonsOffreIdEditionGet$Any$Params, context?: HttpContext): Observable<EditOffreDtoInputForForm> {
    return this.offresBrouillonsOffreIdEditionGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditOffreDtoInputForForm>): EditOffreDtoInputForForm => r.body)
    );
  }

}
