<div class="flex flex-col gap-2">
    <mat-label class="text-primary font-bold whitespace-nowrap">{{label}}</mat-label>

    @if(IsEditMode && !IsReadOnly){
    <mat-form-field class="w-full">
        <mat-select [formControl]="formControl" placeholder="Sélectionner {{errorName}}" [disabled]="IsDisabled"
            multiple>
            <mat-select-trigger>
                {{formControl.value?.[0]?.id || ''}}
                @if((formControl.value?.length || 0) > 1){
                <span class="example-additional-selection">
                    (+{{(formControl.value?.length || 0) - 1}} autres)
                </span>
                }
            </mat-select-trigger>
            @for(option of options; track $index){
            <mat-option [value]="option" [disabled]="IsDisabled">{{option.id}}</mat-option>
            }
        </mat-select>
    </mat-form-field>

    <mat-chip-set aria-label="selection">
        @for (chip of formControl.value; track $index) {
        <mat-chip (removed)="remove(chip)">
            <span class="text-lg">
                {{chip.id}}
            </span>
            <button matChipRemove [attr.aria-label]="'remove ' + chip.id">
                <mat-icon>close</mat-icon>
            </button>
        </mat-chip>
        }
    </mat-chip-set>

    @if(!formControl.valid && formControl.touched){
    <mat-error>
        Veuillez sélectionner au moins {{errorName}}.
    </mat-error>
    }
    } @else {
        <p class="text-sm whitespace-nowrap">
            {{ getFormattedValue() }}
        </p>
    }

</div>