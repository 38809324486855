import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOption, MatSelectModule } from '@angular/material/select';

@Component({
    selector: 'app-edit-select-field',
    standalone: true,
    imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatIconModule],
    templateUrl: './edit-select-field.component.html',
    styleUrl: './edit-select-field.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => EditSelectFieldComponent),
            multi: true
        }
    ]
})
export class EditSelectFieldComponent implements OnInit {

    @Input() IsEditMode = false;
    @Input() IsReadOnly = false;
    @Input() label: string | undefined;
    @Input() formControl: FormControl<MatOption<string> | null | undefined> = new FormControl<MatOption<string> | null | undefined>(null);
    @Input() colorFormControl: FormControl = new FormControl();
    @Input() options: MatOption[] | undefined;
    @Input() IsDisabled = false;

    constructor(private cdr: ChangeDetectorRef) {}

    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};

    writeValue(value: any): void {
        this.formControl.setValue(value);
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    onValueChange(value: any): void {
        this.onChange(value);
        this.onTouched();
    }

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
        this.colorFormControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    getColor(color: string): string {
        return `${color}32`;
    }
}
