import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { DateFieldComponent } from '../date-field/date-field.component';
import { momentToDate } from '@app/utils/momentToDate';
@Component({
    selector: 'app-delta-time-field',
    standalone: true,
    imports: [CommonModule, MatLabel, MatFormFieldModule, ReactiveFormsModule, FormsModule, MatInputModule, MatRadioModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, DateFieldComponent],
    templateUrl: './delta-time-field.component.html',
    styleUrl: './delta-time-field.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeltaTimeFieldComponent implements OnInit {
    @Input() label: string | undefined;
    @Input() IsEditMode = false;
    @Input() IsReadOnly = false;
    @Input() joursControl: FormControl = new FormControl();
    @Input() moisControl: FormControl = new FormControl();
    @Input() modeRecurrence = 'one';
    @Input() dateFinControl = new FormControl<moment.Moment | null>(null);
    @Output() newRecurrence = new EventEmitter<string>();

    radioFormControl = new FormControl();
    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.joursControl.valueChanges.subscribe(() => {
            // this.cdr.markForCheck();
            this.cdr.detectChanges();
        });

        this.moisControl.valueChanges.subscribe(() => {
            // this.cdr.markForCheck();
            this.cdr.detectChanges();
        });
    }

    getJoursErrorMessage() {
        if (this.joursControl.hasError('required')) {
            return 'Vous devez entrer un nombre de jours.';
        }
        if (this.joursControl.hasError('max')) {
            return 'Le nombre de jours ne peut pas dépasser 31.';
        }
        return '';
    }

    getMoisErrorMessage() {
        if (this.moisControl.hasError('required')) {
            return 'Vous devez entrer un nombre de mois.';
        }
        if (this.moisControl.hasError('max')) {
            return 'Le nombre de mois ne peut pas dépasser 12.';
        }
        return '';
    }

    modeRecurrenceChange(event: string) {
        this.newRecurrence.emit(event);
        this.cdr.detectChanges();
    }
    momentToDate(date: moment.Moment | null): Date | undefined {
        return momentToDate(date)
    }
}
