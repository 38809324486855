import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MessagerieOffresIdContacterPost$Params } from '@app/api/fn/messagerie/messagerie-offres-id-contacter-post';
import { ConversationDtoOutputForDetail } from '@app/api/models';
import { MessagerieService } from '@app/api/services';
import { AdminCloseConversationDialogComponent } from '@app/components/pop-ups/admin-close-conversation-dialog/admin-close-conversation-dialog.component';
import { ConfirmDialogComponent } from '@app/components/pop-ups/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'app-messagerie-chat',
    standalone: true,
    imports: [MatIconModule, CommonModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatProgressSpinner],
    templateUrl: './messagerie-chat.component.html',
    styleUrl: './messagerie-chat.component.css',
})
export class MessagerieChatComponent {
    @Input() conversation: ConversationDtoOutputForDetail | null = null;
    @Input() userTyping: string[] = [];
    @Input() loading = false;
    @Output() return = new EventEmitter<void>();
    @Output() closeConversation = new EventEmitter<string>();
    @Output() typingEvent = new EventEmitter<boolean>();
    @Output() sendMessageEvent = new EventEmitter<MessagerieOffresIdContacterPost$Params>();
    messageToSend = '';
    typingTimeout: any;
    chatForm: FormGroup;
    constructor(
        private messagerieService: MessagerieService,
        private dialog: MatDialog,
        private router: Router,
        private fb: FormBuilder,
        private _snackBar: MatSnackBar,
    ) {
        this.chatForm = this.fb.group({
            message: ['', Validators.required],
        });
    }

    isUserTyping() {
        return this.userTyping.includes(this.conversation?.contact?.id ?? '');
    }

    getDisplayedName() {
        return this.conversation?.contact?.firstName + ' ' + this.conversation?.contact?.lastName?.charAt(0) + '.';
    }

    navigateToOffre() {
        this.router.navigate(['/article-page', this.conversation?.offre?.id]);
    }
    getDisplayedOfferName() {
        return (this.conversation?.offre?.nom ?? '').charAt(0).toUpperCase() + (this.conversation?.offre?.nom ?? '').slice(1);
    }


    isTyping = false;

    typing() {
        if (!this.isTyping) {
            this.typingEvent.emit(true);
            this.isTyping = true;
        }
        clearTimeout(this.typingTimeout);
        this.typingTimeout = setTimeout(() => {
            this.typingEvent.emit(false);
            this.isTyping = false;
        }, 1000);
    }

    sendMessage() {
        if (this.chatForm.valid) {
            const message = this.chatForm.value.message;
            const params: MessagerieOffresIdContacterPost$Params = {
                id: this.conversation?.offre?.id ?? '',
                destinataireId: this.conversation?.contact?.id ?? '',
                message: message,
            };
            this.sendMessageEvent.emit(params);
        }
        // reset form
        this.chatForm.reset();
    }

    handleReturn() {
        this.return.emit();
    }

    handleCloseConv() {
        const dialogRef = this.dialog.open(AdminCloseConversationDialogComponent, {
            data: this.conversation
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if(this.conversation?.contact?.id){
                    this.closeConversation.emit(this.conversation?.contact?.id)
                }
            }
        });
    
    }
}
