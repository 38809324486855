import { Component } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './components/header/header.component';

@Component({
    selector: 'app-root',
    standalone: true,
    imports: [RouterOutlet, HeaderComponent],
    templateUrl: './app.component.html',
    styles: [],
})
export class AppComponent {
    title = 'vetichange-admin';
    constructor(private router: Router) {}
    displayHeader() {
      return this.router.url !== '/login' && this.router.url !== '/register';
  }
}
