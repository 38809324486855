import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AccountsService } from '@app/api/services';
import { SnackBarService } from '@app/utils/services/snackbar.service';

@Component({
    selector: 'app-forgot-password-dialog',
    standalone: true,
    imports: [MatFormFieldModule, ReactiveFormsModule, MatInputModule, CommonModule, MatButtonModule],
    templateUrl: './forgot-password-dialog.component.html',
    styleUrl: './forgot-password-dialog.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordDialogComponent {
    forgotPasswordForm: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<ForgotPasswordDialogComponent>,
        private fb: FormBuilder,
        private accountsService: AccountsService,
        private _snackBar: SnackBarService,
    ) {
        this.forgotPasswordForm = this.fb.group({
            email: ['', Validators.required],
        });
    }

    onSubmit() {
        if (this.forgotPasswordForm.valid) {
            const emailValue = this.forgotPasswordForm.get('email')?.value;
            const body = {
                email: emailValue,
            };

            this.accountsService.accountsMotDePasseOubliPost$Response({ body }).subscribe({
                next: (response) => {
                    this.dialogRef.close();
                    this._snackBar.openSnackBar('Un e-mail vous a été envoyé si vous êtes déjà inscrit.', 'Fermer', false, 5000);
                },
                error: (error) => {
                    this._snackBar.displayErrors(error.error, this.forgotPasswordForm);
                },
            });
        }
    }
}
