import { Component, signal, ViewChild, ElementRef, Input, ChangeDetectorRef, EventEmitter, Output, OnChanges } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { SnackBarService } from '@app/utils/services/snackbar.service';

@Component({
    standalone: true,
    selector: 'app-photo-uploader',
    templateUrl: './photo-uploader.component.html',
    styleUrls: ['./photo-uploader.component.scss'],
    imports: [MatSnackBarModule, MatIconModule, CommonModule],
})
export class PhotoUploaderComponent implements OnChanges {
    @Input() IsEditMode = false;
    @Input() thumbnailUrl = '';
    @Output() thumbnailUrlChange = new EventEmitter<File>();
    imageName = signal('');
    uploadProgress = signal(0);
    imagePreview = signal('');
    @ViewChild('fileInput') fileInput: ElementRef | undefined;
    selectedFile: File | null = null;
    uploadSuccess = false;
    uploadError = false;

    constructor(private snarbarService: SnackBarService, private cdr: ChangeDetectorRef) {}

    ngOnChanges(): void {
        if (this.thumbnailUrl) {
            this.imagePreview.set(this.thumbnailUrl);
            this.cdr.detectChanges();
        }
    }

    onFileChange(event: any): void {
        const file = event.target.files[0] as File | null;
        this.uploadFile(file);
    }

    onFileDrop(event: DragEvent): void {
        event.preventDefault();
        const file = event.dataTransfer?.files[0] as File | null;
        this.uploadFile(file);
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
    }

    uploadFile(file: File | null): void {
        if (file && file.type.startsWith('image/')) {
            this.selectedFile = file;

            const reader = new FileReader();
            reader.onload = (e) => {
                this.imagePreview.set(e.target?.result as string); 
                this.thumbnailUrl = e.target?.result as string; 
                this.cdr.detectChanges(); 
            };
            reader.readAsDataURL(file);

            this.uploadSuccess = true;
            this.uploadError = false;
            this.imageName.set(file.name); 
            this.thumbnailUrlChange.emit(file); 
        } else {
            this.uploadSuccess = false;
            this.uploadError = true;
            this.snarbarService.openSnackBar('Vous ne pouvez ajouter que des images !', 'Fermer', true, 5000);
        }
    }

    removeImage(event: MouseEvent): void {
        event?.stopPropagation();
        this.selectedFile = null;
        this.imageName.set('');
        this.imagePreview.set('');
        this.thumbnailUrl = '';
        this.uploadSuccess = false;
        this.uploadError = false;
        this.uploadProgress.set(0);
        this.cdr.detectChanges(); // Force change detection
    }
}