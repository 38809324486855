/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DetailModalitesDemandesLieesOffreDtoOutput } from '../../models/detail-modalites-demandes-liees-offre-dto-output';

export interface OffresMesOffresEnCoursOffreIdForcerCloturePost$Any$Params {
  offreId: string;
}

export function offresMesOffresEnCoursOffreIdForcerCloturePost$Any(http: HttpClient, rootUrl: string, params: OffresMesOffresEnCoursOffreIdForcerCloturePost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
  const rb = new RequestBuilder(rootUrl, offresMesOffresEnCoursOffreIdForcerCloturePost$Any.PATH, 'post');
  if (params) {
    rb.path('offreId', params.offreId, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>;
    })
  );
}

offresMesOffresEnCoursOffreIdForcerCloturePost$Any.PATH = '/offres/mes-offres/en-cours/{offreId}/forcer-cloture';
