/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getWeatherForecast$Any } from '../fn/weather-forecast/get-weather-forecast-any';
import { GetWeatherForecast$Any$Params } from '../fn/weather-forecast/get-weather-forecast-any';
import { getWeatherForecast$Json } from '../fn/weather-forecast/get-weather-forecast-json';
import { GetWeatherForecast$Json$Params } from '../fn/weather-forecast/get-weather-forecast-json';
import { getWeatherForecast$Plain } from '../fn/weather-forecast/get-weather-forecast-plain';
import { GetWeatherForecast$Plain$Params } from '../fn/weather-forecast/get-weather-forecast-plain';
import { WeatherForecast } from '../models/weather-forecast';
import { weatherForecastAmIAdminGet$Any } from '../fn/weather-forecast/weather-forecast-am-i-admin-get-any';
import { WeatherForecastAmIAdminGet$Any$Params } from '../fn/weather-forecast/weather-forecast-am-i-admin-get-any';
import { weatherForecastAmIAdminGet$Json } from '../fn/weather-forecast/weather-forecast-am-i-admin-get-json';
import { WeatherForecastAmIAdminGet$Json$Params } from '../fn/weather-forecast/weather-forecast-am-i-admin-get-json';
import { weatherForecastAmIAdminGet$Plain } from '../fn/weather-forecast/weather-forecast-am-i-admin-get-plain';
import { WeatherForecastAmIAdminGet$Plain$Params } from '../fn/weather-forecast/weather-forecast-am-i-admin-get-plain';
import { weatherForecastAmILoggedInGet$Any } from '../fn/weather-forecast/weather-forecast-am-i-logged-in-get-any';
import { WeatherForecastAmILoggedInGet$Any$Params } from '../fn/weather-forecast/weather-forecast-am-i-logged-in-get-any';
import { weatherForecastAmILoggedInGet$Json } from '../fn/weather-forecast/weather-forecast-am-i-logged-in-get-json';
import { WeatherForecastAmILoggedInGet$Json$Params } from '../fn/weather-forecast/weather-forecast-am-i-logged-in-get-json';
import { weatherForecastAmILoggedInGet$Plain } from '../fn/weather-forecast/weather-forecast-am-i-logged-in-get-plain';
import { WeatherForecastAmILoggedInGet$Plain$Params } from '../fn/weather-forecast/weather-forecast-am-i-logged-in-get-plain';
import { weatherForecastCreerEntreeEspaceStockageGet } from '../fn/weather-forecast/weather-forecast-creer-entree-espace-stockage-get';
import { WeatherForecastCreerEntreeEspaceStockageGet$Params } from '../fn/weather-forecast/weather-forecast-creer-entree-espace-stockage-get';
import { weatherForecastSeedGeoCommunesPost$Any } from '../fn/weather-forecast/weather-forecast-seed-geo-communes-post-any';
import { WeatherForecastSeedGeoCommunesPost$Any$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-communes-post-any';
import { weatherForecastSeedGeoCommunesPost$Json } from '../fn/weather-forecast/weather-forecast-seed-geo-communes-post-json';
import { WeatherForecastSeedGeoCommunesPost$Json$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-communes-post-json';
import { weatherForecastSeedGeoCommunesPost$Plain } from '../fn/weather-forecast/weather-forecast-seed-geo-communes-post-plain';
import { WeatherForecastSeedGeoCommunesPost$Plain$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-communes-post-plain';
import { weatherForecastSeedGeoDepartementsPost$Any } from '../fn/weather-forecast/weather-forecast-seed-geo-departements-post-any';
import { WeatherForecastSeedGeoDepartementsPost$Any$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-departements-post-any';
import { weatherForecastSeedGeoDepartementsPost$Json } from '../fn/weather-forecast/weather-forecast-seed-geo-departements-post-json';
import { WeatherForecastSeedGeoDepartementsPost$Json$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-departements-post-json';
import { weatherForecastSeedGeoDepartementsPost$Plain } from '../fn/weather-forecast/weather-forecast-seed-geo-departements-post-plain';
import { WeatherForecastSeedGeoDepartementsPost$Plain$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-departements-post-plain';
import { weatherForecastSeedGeoRegionsPost$Any } from '../fn/weather-forecast/weather-forecast-seed-geo-regions-post-any';
import { WeatherForecastSeedGeoRegionsPost$Any$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-regions-post-any';
import { weatherForecastSeedGeoRegionsPost$Json } from '../fn/weather-forecast/weather-forecast-seed-geo-regions-post-json';
import { WeatherForecastSeedGeoRegionsPost$Json$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-regions-post-json';
import { weatherForecastSeedGeoRegionsPost$Plain } from '../fn/weather-forecast/weather-forecast-seed-geo-regions-post-plain';
import { WeatherForecastSeedGeoRegionsPost$Plain$Params } from '../fn/weather-forecast/weather-forecast-seed-geo-regions-post-plain';
import { weatherForecastSeedPost$Any } from '../fn/weather-forecast/weather-forecast-seed-post-any';
import { WeatherForecastSeedPost$Any$Params } from '../fn/weather-forecast/weather-forecast-seed-post-any';
import { weatherForecastSeedPost$Json } from '../fn/weather-forecast/weather-forecast-seed-post-json';
import { WeatherForecastSeedPost$Json$Params } from '../fn/weather-forecast/weather-forecast-seed-post-json';
import { weatherForecastSeedPost$Plain } from '../fn/weather-forecast/weather-forecast-seed-post-plain';
import { WeatherForecastSeedPost$Plain$Params } from '../fn/weather-forecast/weather-forecast-seed-post-plain';

@Injectable({ providedIn: 'root' })
export class WeatherForecastService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getWeatherForecast()` */
  static readonly GetWeatherForecastPath = '/weather-forecast';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWeatherForecast$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWeatherForecast$Json$Response(params?: GetWeatherForecast$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WeatherForecast>>> {
    return getWeatherForecast$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWeatherForecast$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWeatherForecast$Json(params?: GetWeatherForecast$Json$Params, context?: HttpContext): Observable<Array<WeatherForecast>> {
    return this.getWeatherForecast$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WeatherForecast>>): Array<WeatherForecast> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWeatherForecast$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWeatherForecast$Plain$Response(params?: GetWeatherForecast$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WeatherForecast>>> {
    return getWeatherForecast$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWeatherForecast$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWeatherForecast$Plain(params?: GetWeatherForecast$Plain$Params, context?: HttpContext): Observable<Array<WeatherForecast>> {
    return this.getWeatherForecast$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WeatherForecast>>): Array<WeatherForecast> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getWeatherForecast$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWeatherForecast$Any$Response(params?: GetWeatherForecast$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<WeatherForecast>>> {
    return getWeatherForecast$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getWeatherForecast$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getWeatherForecast$Any(params?: GetWeatherForecast$Any$Params, context?: HttpContext): Observable<Array<WeatherForecast>> {
    return this.getWeatherForecast$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<WeatherForecast>>): Array<WeatherForecast> => r.body)
    );
  }

  /** Path part for operation `weatherForecastCreerEntreeEspaceStockageGet()` */
  static readonly WeatherForecastCreerEntreeEspaceStockageGetPath = '/weather-forecast/creer-entree-espace-stockage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastCreerEntreeEspaceStockageGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastCreerEntreeEspaceStockageGet$Response(params?: WeatherForecastCreerEntreeEspaceStockageGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return weatherForecastCreerEntreeEspaceStockageGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastCreerEntreeEspaceStockageGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastCreerEntreeEspaceStockageGet(params?: WeatherForecastCreerEntreeEspaceStockageGet$Params, context?: HttpContext): Observable<void> {
    return this.weatherForecastCreerEntreeEspaceStockageGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `weatherForecastAmILoggedInGet()` */
  static readonly WeatherForecastAmILoggedInGetPath = '/weather-forecast/am-i-logged-in';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastAmILoggedInGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmILoggedInGet$Json$Response(params?: WeatherForecastAmILoggedInGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastAmILoggedInGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastAmILoggedInGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmILoggedInGet$Json(params?: WeatherForecastAmILoggedInGet$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastAmILoggedInGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastAmILoggedInGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmILoggedInGet$Plain$Response(params?: WeatherForecastAmILoggedInGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastAmILoggedInGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastAmILoggedInGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmILoggedInGet$Plain(params?: WeatherForecastAmILoggedInGet$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastAmILoggedInGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastAmILoggedInGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmILoggedInGet$Any$Response(params?: WeatherForecastAmILoggedInGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastAmILoggedInGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastAmILoggedInGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmILoggedInGet$Any(params?: WeatherForecastAmILoggedInGet$Any$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastAmILoggedInGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `weatherForecastSeedGeoRegionsPost()` */
  static readonly WeatherForecastSeedGeoRegionsPostPath = '/weather-forecast/seed-geo-regions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoRegionsPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoRegionsPost$Json$Response(params?: WeatherForecastSeedGeoRegionsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoRegionsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoRegionsPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoRegionsPost$Json(params?: WeatherForecastSeedGeoRegionsPost$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoRegionsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoRegionsPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoRegionsPost$Plain$Response(params?: WeatherForecastSeedGeoRegionsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoRegionsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoRegionsPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoRegionsPost$Plain(params?: WeatherForecastSeedGeoRegionsPost$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoRegionsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoRegionsPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoRegionsPost$Any$Response(params?: WeatherForecastSeedGeoRegionsPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoRegionsPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoRegionsPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoRegionsPost$Any(params?: WeatherForecastSeedGeoRegionsPost$Any$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoRegionsPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `weatherForecastSeedGeoDepartementsPost()` */
  static readonly WeatherForecastSeedGeoDepartementsPostPath = '/weather-forecast/seed-geo-departements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoDepartementsPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoDepartementsPost$Json$Response(params?: WeatherForecastSeedGeoDepartementsPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoDepartementsPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoDepartementsPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoDepartementsPost$Json(params?: WeatherForecastSeedGeoDepartementsPost$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoDepartementsPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoDepartementsPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoDepartementsPost$Plain$Response(params?: WeatherForecastSeedGeoDepartementsPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoDepartementsPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoDepartementsPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoDepartementsPost$Plain(params?: WeatherForecastSeedGeoDepartementsPost$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoDepartementsPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoDepartementsPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoDepartementsPost$Any$Response(params?: WeatherForecastSeedGeoDepartementsPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoDepartementsPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoDepartementsPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoDepartementsPost$Any(params?: WeatherForecastSeedGeoDepartementsPost$Any$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoDepartementsPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `weatherForecastSeedGeoCommunesPost()` */
  static readonly WeatherForecastSeedGeoCommunesPostPath = '/weather-forecast/seed-geo-communes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoCommunesPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoCommunesPost$Json$Response(params?: WeatherForecastSeedGeoCommunesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoCommunesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoCommunesPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoCommunesPost$Json(params?: WeatherForecastSeedGeoCommunesPost$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoCommunesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoCommunesPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoCommunesPost$Plain$Response(params?: WeatherForecastSeedGeoCommunesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoCommunesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoCommunesPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoCommunesPost$Plain(params?: WeatherForecastSeedGeoCommunesPost$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoCommunesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedGeoCommunesPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoCommunesPost$Any$Response(params?: WeatherForecastSeedGeoCommunesPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastSeedGeoCommunesPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedGeoCommunesPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  weatherForecastSeedGeoCommunesPost$Any(params?: WeatherForecastSeedGeoCommunesPost$Any$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastSeedGeoCommunesPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `weatherForecastAmIAdminGet()` */
  static readonly WeatherForecastAmIAdminGetPath = '/weather-forecast/am-i-admin';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastAmIAdminGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmIAdminGet$Json$Response(params?: WeatherForecastAmIAdminGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastAmIAdminGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastAmIAdminGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmIAdminGet$Json(params?: WeatherForecastAmIAdminGet$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastAmIAdminGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastAmIAdminGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmIAdminGet$Plain$Response(params?: WeatherForecastAmIAdminGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastAmIAdminGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastAmIAdminGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmIAdminGet$Plain(params?: WeatherForecastAmIAdminGet$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastAmIAdminGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastAmIAdminGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmIAdminGet$Any$Response(params?: WeatherForecastAmIAdminGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return weatherForecastAmIAdminGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastAmIAdminGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastAmIAdminGet$Any(params?: WeatherForecastAmIAdminGet$Any$Params, context?: HttpContext): Observable<boolean> {
    return this.weatherForecastAmIAdminGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `weatherForecastSeedPost()` */
  static readonly WeatherForecastSeedPostPath = '/weather-forecast/seed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastSeedPost$Json$Response(params?: WeatherForecastSeedPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return weatherForecastSeedPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastSeedPost$Json(params?: WeatherForecastSeedPost$Json$Params, context?: HttpContext): Observable<number> {
    return this.weatherForecastSeedPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastSeedPost$Plain$Response(params?: WeatherForecastSeedPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return weatherForecastSeedPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastSeedPost$Plain(params?: WeatherForecastSeedPost$Plain$Params, context?: HttpContext): Observable<number> {
    return this.weatherForecastSeedPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `weatherForecastSeedPost$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastSeedPost$Any$Response(params?: WeatherForecastSeedPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return weatherForecastSeedPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `weatherForecastSeedPost$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  weatherForecastSeedPost$Any(params?: WeatherForecastSeedPost$Any$Params, context?: HttpContext): Observable<number> {
    return this.weatherForecastSeedPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
