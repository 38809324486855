import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { EditTextFieldComponent } from '../../../components/fields/edit-text-field/edit-text-field.component';
import { EditSelectFieldComponent } from '../../../components/fields/edit-select-field/edit-select-field.component';
import { EditMultiSelectFieldComponent } from '../../../components/fields/edit-multi-select-field/edit-multi-select-field.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService, ReferencesService } from '@app/api/services';
import { SnackBarService } from '@app/utils/services/snackbar.service';
import { AdminReferencesReferenceGet$Json$Params } from '@app/api/fn/admin/admin-references-reference-get-json';
import { ReferenceDtoOutputForAdminDatagridForDetail } from '@app/api/models';
import { AdminReferencesPut$Params } from '@app/api/fn/admin/admin-references-put';
import { ColDef } from 'ag-grid-community';
import { MatLabel } from '@angular/material/form-field';
import { DatagridComponent } from '../../../components/datagrid/datagrid.component';
import { DataGridService } from '@app/utils/services/data-grid-service';
import { FormService } from '@app/utils/services/form-service';
import { MatOption } from '@angular/material/core';
import { PhotoUploaderComponent } from '@app/components/photo-uploader/photo-uploader.component';

@Component({
    selector: 'app-reference-detail',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatDividerModule, EditTextFieldComponent, EditSelectFieldComponent, EditMultiSelectFieldComponent, MatLabel, DatagridComponent, PhotoUploaderComponent],
    templateUrl: './reference-detail.component.html',
    styleUrl: './reference-detail.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferenceDetailComponent implements OnInit {
    ReferenceId: string | undefined;
    Reference: ReferenceDtoOutputForAdminDatagridForDetail | undefined;
    title: string | undefined;

    editForm: FormGroup | undefined;
    libelleFormControl: FormControl = new FormControl('', Validators.required);
    referenceFormControl: FormControl = new FormControl('', Validators.required);
    categorieFormControl: FormControl = new FormControl('', Validators.required);
    taillesFormControl = new FormControl<MatOption[]>([], Validators.required);
    conformitesFormControl = new FormControl<MatOption[]>([], Validators.required);

    editMode = false;
    thumbnail = '';

    // gradesOptions: string[] = [
    //     'Gendarme adjoint volontaire (GAV)',
    //     'Brigadier',
    //     'Brigadier-chef',
    //     'Gendarme',
    //     'Maréchal des logis',
    //     'Maréchal des logis-chef',
    //     'Adjudant',
    //     'Adjudant-chef',
    //     'Major',
    //     'Aspirant',
    //     'Sous-lieutenant',
    //     'Lieutenant',
    //     'Capitaine',
    //     "Commandant (ou Chef d'escadron)",
    //     'Lieutenant-colonel',
    //     'Colonel',
    //     'Général de brigade',
    //     'Général de division',
    //     "Général de corps d'armée",
    //     "Général d'armée",
    // ];

    grades: MatOption[] = [];

    newPhoto: File | undefined;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private adminService: AdminService,
        private snackBarServices: SnackBarService,
        private cdr: ChangeDetectorRef,
        public dataService: DataGridService,
        private formService: FormService,
        private referencesService: ReferencesService,
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.ReferenceId = params['id'];
            if (this.ReferenceId) {
                this.getReferenceById(this.ReferenceId);
            } else {
                this.editMode = true;
            }
        });

        this.editForm = this.fb.group({
            libelle: this.libelleFormControl,
            reference: this.referenceFormControl,
            categorie: this.categorieFormControl,
            tailles: this.taillesFormControl,
            conformites: this.conformitesFormControl,
        });

        // this.getGrades();
        // this.getTailles();
        // this.getConformations();
    }

    // getGrades() {
    //     this.grades = this.gradesOptions.map((garde) => ({ id: garde, value: garde }) as MatOption);
    // }

    retour() {
        this.router.navigate(['/references']);
    }

    onSubmit(): void {
        if (this.editMode) {
            if (!this.editForm) return;
            if (this.editForm.valid) {
                if (this.ReferenceId)
                    this.putReference(this.ReferenceId, this.libelleFormControl.value, '', (success) => {
                        this.editMode = !success;
                        this.cdr.detectChanges();
                    });
            } else {
                this.formService.markAllAsTouched(this.editForm);
            }
        } else {
            this.editMode = true;
        }
    }

    getReferenceById(referenceId: string) {
        const params: AdminReferencesReferenceGet$Json$Params = {
            reference: referenceId,
        };

        this.adminService.adminReferencesReferenceGet$Json$Response(params).subscribe({
            next: (response) => {
                this.Reference = response.body;
                this.setFields(this.Reference);
            },
            error: (error) => {
                this.snackBarServices.openSnackBar(error.error[''] || 'Erreur lors de la création du brouillon', 'Fermer');
            },
            complete: () => {},
        });
    }

    async putReference(referenceId: string, nouveauLibelle: string, grade: string, callback: (success: boolean) => void): Promise<void> {
        const deletePhoto = this.newPhoto ? true : false;
        const photo = this.newPhoto ? this.newPhoto : undefined;
        const params: AdminReferencesPut$Params = {
            ForceId: referenceId.substring(0, 1),
            FamilleId: referenceId.substring(1, 2),
            SousFamilleId: referenceId.substring(2, 3),
            Groupe: referenceId.substring(3, 4),
            Genre: referenceId.substring(4, 5),
            SousGroupe: referenceId.substring(5, 6),
            Grade: grade,
            NouveauLibelle: nouveauLibelle,
            DeleteExistingImages: deletePhoto,
            body: {
                Photo: photo,
            },
        };

        this.adminService.adminReferencesPut$Response(params).subscribe({
            next: (response) => {
                this.getReferenceById(referenceId);
                callback(response.ok);
            },
            error: (error) => {
                this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
                callback(false);
            },
            complete: () => {},
        });
    }

    setFields(reference: ReferenceDtoOutputForAdminDatagridForDetail) {
        this.libelleFormControl.setValue(reference.libelle);
        this.referenceFormControl.setValue(reference.reference);
        this.categorieFormControl.setValue(reference.categorie?.nom);
        this.taillesFormControl.setValue(
            reference.tailles?.map(
                (taille) =>
                    ({
                        id: taille,
                        value: taille,
                    }) as MatOption,
            ) ?? [],
        );
        this.conformitesFormControl.setValue(
            reference.conformations?.map(
                (conformation) =>
                    ({
                        id: conformation,
                        value: conformation,
                    }) as MatOption,
            ) ?? [],
        );
        this.thumbnail = reference.photoUrl ?? '';

        this.cdr.detectChanges();
    }

    onCancel() {
        if (this.ReferenceId) {
            this.editMode = false;
            this.getReferenceById(this.ReferenceId);
        } else {
            this.retour();
        }
    }

    photoChanged($event: File) {
        this.newPhoto = $event;
    }

    // getTailles() {
    //     const params: ReferencesTaillesGet$Json$Params = {};

    //     this.referencesService.referencesTaillesGet$Json$Response(params).subscribe({
    //         next: (response) => {
    //             this.taillesOptions = response.body;
    //             this.cdr.detectChanges();
    //         },
    //         error: (error) => {
    //             this.snackBarServices.openSnackBar(error.error[''] || 'Erreur lors de la création du brouillon', 'Fermer');
    //         },
    //         complete: () => {},
    //     });
    // }

    // getConformations() {
    //     const params: ReferencesTaillesGet$Json$Params = {};

    //     this.referencesService.referencesConformationsGet$Json$Response(params).subscribe({
    //         next: (response) => {
    //             if (response?.body) {
    //                 this.conformationsOptions = response.body.map((nomenclature) => nomenclature?.nomenclature ?? '').filter((nomenclature) => nomenclature !== '');
    //                 this.cdr.detectChanges();
    //             } else {
    //                 this.snackBarServices.openSnackBar('Réponse invalide pour les conformations.', 'Fermer');
    //             }
    //         },
    //         error: (error) => {
    //             const errorMessage = error.error?.message || 'Erreur lors de la récupération des conformations';
    //             this.snackBarServices.openSnackBar(errorMessage, 'Fermer');
    //         },
    //     });
    // }

    // getDonations() {
    //     if (!this.utilisateurId) return;
    //     const params: AdminUtilisateursIdDemandesGet$Json$Params = {
    //         id: this.utilisateurId,
    //     };

    //     this.adminService.adminUtilisateursIdDemandesGet$Json$Response(params).subscribe({
    //         next: (response) => {
    //             this.DonationsRawData = response.body;
    //             this.cdr.detectChanges();
    //         },
    //         error: (error) => {
    //             this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
    //         },
    //         complete: () => {},
    //     });
    // }

    // getDemandes() {
    //     if (!this.utilisateurId) return;
    //     const params: AdminUtilisateursIdDonationsGet$Json$Params = {
    //         id: this.utilisateurId,
    //     };

    //     this.adminService.adminUtilisateursIdDonationsGet$Json$Response(params).subscribe({
    //         next: (response) => {
    //             this.DemandesRawData = response.body;
    //             this.cdr.detectChanges();
    //         },
    //         error: (error) => {
    //             this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
    //         },
    //         complete: () => {},
    //     });
    // }
}
