<div class="relative flex w-screen h-[calc(100vh-80px)] gap-4 flex-col justify-center  px-16">
  <div class="flex items-center justify-between">
    <p class="flex gap-4 items-center">Références <mat-slide-toggle [hideIcon]="true" [(ngModel)]="showCategorie"
        (change)="toggleChange()" aria-label="trier par références ou catégories"></mat-slide-toggle>Catégories</p>
    <div class="flex items-center justify-end gap-6">

      <button mat-stroked-button [matMenuTriggerFor]="refsMenu" class="flex flex-row-reverse gap-4 !border-primary !text-primary !bg-white">       
                <span class="whitespace-nowrap">Gestion des références</span>
                <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #refsMenu="matMenu">
        <input type="file" (change)="importReferences($event)" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden #fileInput>
        <button mat-menu-item (click)="fileInput.click()">Importer un fichier de mise à jour</button>
        <button mat-menu-item (click)="exportReferencesList()">Télécharger le dernier fichier de mise à jour</button>
      </mat-menu>

      <button mat-stroked-button [matMenuTriggerFor]="taillesMenu" class="flex flex-row-reverse gap-4 !border-primary !text-primary !bg-white">
        <span class="whitespace-nowrap">Guide des tailles</span>
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #taillesMenu = "matMenu">
        <input type="file" (change)="remplacerPDF($event)" accept="application/pdf" hidden #fileInputGuide>
        <button mat-menu-item (click)="fileInputGuide.click()">Remplacer le guide des tailles</button>
        <button mat-menu-item (click)="voirPDF()">Voir le guide des tailles</button>
      </mat-menu>
      <app-export-button (export)="export()"></app-export-button>
    </div>
  </div>
  @if(rawData && !showCategorie){
  <app-datagrid class="h-4/5" [data]="rawData" [columns]="colDefsReferences" [nbRecords]="nbRecords"
    (filterChanged)="onFilterChanged($event)" (pageSizeChange)="onPageSizeChange($event)"
    (sortChanged)="onSortChanged($event)" (gridReady)="onGridReady($event)"></app-datagrid>
  }
  @else if(rawDataCategories && showCategorie){
  <app-datagrid class="h-4/5" [data]="rawDataCategories" [hasChild]="true" [columns]="colDefsCategories"
    [nbRecords]="nbRecords" (pageSizeChange)="onPageSizeChange($event)"
    (gridReady)="onGridReady($event)"></app-datagrid>
  }
</div>