/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface AdminReferencesPost$Params {
  ForceId?: string;
  FamilleId?: string;
  SousFamilleId?: string;
  Groupe?: string;
  Genre?: string;
  SousGroupe?: string;
  NouveauLibelle?: string;
  Grade?: string;
  DeleteExistingImages?: boolean;
      body?: {
'Photo'?: Blob;
}
}

export function adminReferencesPost(http: HttpClient, rootUrl: string, params?: AdminReferencesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, adminReferencesPost.PATH, 'post');
  if (params) {
    rb.query('ForceId', params.ForceId, {});
    rb.query('FamilleId', params.FamilleId, {});
    rb.query('SousFamilleId', params.SousFamilleId, {});
    rb.query('Groupe', params.Groupe, {});
    rb.query('Genre', params.Genre, {});
    rb.query('SousGroupe', params.SousGroupe, {});
    rb.query('NouveauLibelle', params.NouveauLibelle, {});
    rb.query('Grade', params.Grade, {});
    rb.query('DeleteExistingImages', params.DeleteExistingImages, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

adminReferencesPost.PATH = '/admin/references';
