import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';
import { API_URL } from 'src/environments/environment';
import { ConversationDtoOutputWithLastMessageForList, MessageDtoOutput } from '@app/api/models';

@Injectable({
    providedIn: 'root',
})
export class RealTimeClientService {
    private hubConnection: signalR.HubConnection = RealTimeClientService.getInstance();
    public static readonly baseUrl = API_URL;
    public static readonly chatHubUrl = `${RealTimeClientService.baseUrl}/chatHub`;
    private messageSource = new Subject<MessageDtoOutput>();
    message$ = this.messageSource.asObservable();

    private wallSource = new Subject<ConversationDtoOutputWithLastMessageForList>();
    wall$ = this.wallSource.asObservable();
    private userTypingSource = new BehaviorSubject<string[]>([]);
    userTyping$ = this.userTypingSource.asObservable();
    private static instance: signalR.HubConnection;

    public static getInstance(): signalR.HubConnection {
        if (!RealTimeClientService.instance) {
            const url = `${RealTimeClientService.chatHubUrl}`;
            RealTimeClientService.instance = new signalR.HubConnectionBuilder()
                .withUrl(url, {
                    skipNegotiation: true,
                    transport: signalR.HttpTransportType.WebSockets,
                })
                .build();
        }
        return RealTimeClientService.instance;
    }
    constructor() {
        this.connect();
        if (this.hubConnection.state === signalR.HubConnectionState.Disconnected) {
            this.hubConnection.start();
        }
    }

    connect() {
        {
            this.hubConnection.on('received_message_chat', (message: any) => {
                const parsedMessage = JSON.parse(message);
                this.messageSource.next(parsedMessage);
            });

            this.hubConnection.on('received_message_wall_admin', (wall: any) => {
                const parsedWall = JSON.parse(wall);
                this.wallSource.next(parsedWall);
            });

            this.hubConnection.on('UserTyping', (userId: string) => {
                //ajoute le user à la liste des users en train de taper si il n'y est pas déjà
                if (!this.userTypingSource.value.includes(userId)) this.userTypingSource.next([...this.userTypingSource.value, userId]);
            });

            this.hubConnection.on('UserStoppedTyping', (userId: string) => {
                //retire le user de la liste des users en train de taper
                this.userTypingSource.next(this.userTypingSource.value.filter((user) => user !== userId));
            });
        }
    }

    async joinRoom(offreId: string, destinataireId: string) {
        if (this.hubConnection) {
            if(offreId && destinataireId){
                await this.hubConnection.invoke('JoinOfferChatRoom', offreId, destinataireId);
            }
            else{
                await this.hubConnection.invoke('JoinAdminChatRoom', destinataireId);
            }
        }
    }

    async sendMessage(message: string, destinataireId: string) {
        if (this.hubConnection) {
            await this.hubConnection.invoke('SendMessageAdmin', destinataireId, message);
        }
    }
    async imTyping(typing: boolean, offreId: string, destinataireId: string) {
        if (this.hubConnection) {
            if (typing) {
                await this.hubConnection.invoke('UserTyping', offreId, destinataireId);
            } else {
                await this.hubConnection.invoke('UserStoppedTyping', offreId, destinataireId);
            }
        }
    }
}
