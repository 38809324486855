import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'details-article-carousel',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: './details-article-carousel.component.html',
    styleUrl: './details-article-carousel.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsArticleCarouselComponent {
    @Input() currentIndex: number = 0;
    @Input() imageList: string[] = [];

    constructor() {}

    next(): void {
        this.currentIndex = (this.currentIndex + 1) % this.imageList.length;
    }

    prev(): void {
        this.currentIndex = (this.currentIndex - 1 + this.imageList.length) % this.imageList.length;
    }
}
