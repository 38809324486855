<div class="flex w-full h-full flex-col rounded-[10px] bg-white py-6 px-8 custom-card gap-3 ">
  <div class="flex flex-col gap-4">
    <p class=" text-sm font-bold text-primary">Signalements</p>
    <div class="flex flex-col w-full overflow-y-auto max-h-80 divide-y-2 cursor-pointer">
      @for (signalement of data; track $index) {
        <div class="flex w-full items-center justify-between py-2 pr-2 hover:bg-[#DBE4F0]" role="presentation" (click)="redirectToSignalement(signalement.id)">
          <div class="h-full w-1" [ngStyle]="{'background-color': signalement.type?.couleur ?? '#CCC'}"></div>
  
          <div class="w-3/5">
            <p class="font-bold text-sm">{{signalement?.type?.nom}}</p>
            <p class=" text-sm max-content">{{signalement?.raison}}</p>
          </div>
          <p class=" text-xs ">{{getDatetime(signalement)}}</p>
        </div>
        }

    </div>
  </div>
</div>