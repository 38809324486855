import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
// Ajoutez ici les autres modules Material que vous utilisez

@NgModule({
  exports: [
    MatButtonModule,
    MatIconModule,
    // Ajoutez ici les autres modules Material que vous utilisez
  ]
})
export class MaterialModule { }
