<div class="flex flex-col">
  <div class="px-4">
    <button class="flex items-center" mat-icon-button (click)="toggleView($event)">
      <mat-icon class="mat-icon-rtl-mirror">
        {{ showChildren ? 'expand_more' : 'chevron_right' }}
      </mat-icon>
      {{categorie?.libelle}} ({{categorie?.nb_de_references}})
    </button>
  </div>
  @if(showChildren) {
  @for (item of categorie?.children; track $index) {
  <div role="presentation" class="grid grid-cols-3 px-8 bg-[#F2F4F7] divide-y hover:bg-[#E6EAF6]" (click)="rowClick(item.reference)">
    <div class="m-w-1/4">
      Libellé : {{getLibelle(item.libelle??'')}}
    </div>
    <div class="m-w-1/4">
      Référence : {{item.reference}}
    </div>
    <div class="m-w-1/4">
      Tailles : {{item.tailles?.toString()}}
    </div>
  </div>
  }
  }
</div>