<div class="w-full min-h-[calc(100vh-80px)] flex flex-col items-center p-12 gap-8">
  <div class="flex w-full justify-center flex-col xl:flex-row gap-4">
    <div class="stat-grid xl-w-4/5 w-full">
      <app-stats-header class="header" (exportClicked)="export()" (dateChange)="onDateChange($event)" (toggleComparaison)="toggleComparaison($event)"
        (date2Change)="onDateChange2($event)"></app-stats-header>
      <div class="signalement bg-white rounded-xl">
        <app-stat-report [data]="stats?.signalements" class="w-full h-full"></app-stat-report>
      </div>
      <div class="graph min-w-full  bg-white rounded-xl">
        <app-graph-card class="w-full h-full" [stats]="stats"></app-graph-card>
      </div>
      <app-number-card class="user " title="Nombre d'utilisateurs inscrits" [value]="stats?.nombreUtilisateursInscrits"
        suffix="utilisateurs"></app-number-card>
      <app-number-card class="carbon" title="Nombre de Kg CO2 évités" [value]="stats?.kgCo2Evites "
        suffix="Kg"></app-number-card>
      <app-number-card class="trade" title="Nombre d'échanges" [value]="stats?.nombreEchanges "
        suffix="Echange"></app-number-card>
      <app-number-card class="obsolete" title="Nombre d'articles obsolètes (> 100 jours)" [value]="stats?.nombreArticlesObsoletes "
        suffix="articles"></app-number-card>

      <app-number-card class="duration" title="Durée moyenne d'un échange" [value]="stats?.dureeMoyenneEchangesEnJour "
        unit="j"></app-number-card>
        <app-number-card class="kpi" title="Taux de conversion (offres données / offres publiées)" [value]="stats?.ratioEchange" [multiple]="100"
        unit="%"></app-number-card>
    </div>
    <div class="flex h-full xl:w-1/5 w-fit flex-col  gap-4">
      <div class="flex w-full xl:justify-end gap-4 xl:gap-0 items-center">
        <app-export-button (export)="export()"></app-export-button>
      </div>
      <div class="flex xl:flex-col gap-4">
      <app-stat-rank [greenValue]="true" [title]="'plus données'"
        [data]="stats?.referencesLesPlusDonnees ?? []"></app-stat-rank>
      <app-stat-rank [title]="'moins données'" [data]="stats?.referencesLesMoinsDonnees ?? []"></app-stat-rank>
      <app-number-card class="h-fit" title="Espace de stockage restant" [value]="stats?.espaceStockageRestant " [multiple]="100"
        unit="%" suffix="D'espace restant"></app-number-card>
      </div>
    </div>
  </div>
</div>