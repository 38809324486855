import { CommonModule, isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormLoginComponent } from '@app/components/form-login/form-login.component';
import { firstValueFrom } from 'rxjs';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [CommonModule, FormLoginComponent],
    templateUrl: 'login.component.html',
    styleUrls: ['./login.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent {

    version: string = "";

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        private cdr: ChangeDetectorRef,
        private http: HttpClient
    ) {
        this.getVersion();
    }

    getVersion(): void {
            this.http.get('assets/version', { responseType: 'text' }).subscribe((data) => {
                this.version = data;
                this.cdr.detectChanges();
            });
    }

    isMobile(): boolean {
        return isPlatformBrowser(this.platformId) ? window.innerWidth <= 768 : false;
    }
}
