import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOption, MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';

@Component({
    selector: 'app-edit-multi-select-field',
    standalone: true,
    imports: [MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatIconModule, MatChipsModule],
    templateUrl: './edit-multi-select-field.component.html',
    styleUrl: './edit-multi-select-field.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultiSelectFieldComponent implements OnInit {
    @Input() IsEditMode = false;
    @Input() IsReadOnly = false;
    @Input() label: string | undefined;
    @Input() errorName: string | undefined;
    @Input() formControl: FormControl<MatOption[] | null> = new FormControl<MatOption[]>([]);
    @Input() options: MatOption[] | undefined;
    @Input() IsDisabled = false;

    constructor(private cdr: ChangeDetectorRef) {}

    remove(taille: MatOption): void {
        if (this.formControl && this.formControl.value) {
            const index = this.formControl.value.indexOf(taille);

            if (index >= 0) {
                const value = this.formControl.value.slice();
                value.splice(index, 1); // Correctly remove the item using splice
                this.formControl.setValue(value);
            }
        }
    }

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    getFormattedValue(): string {
        const values = this.formControl.value;
        if (values && Array.isArray(values)) {
            return values.map((val) => val.id).join(', ');
        }
        return '';
    }
}
