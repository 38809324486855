
import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CommonModule } from '@angular/common';
import { SignalementTypeDtoOuput } from '@app/api/models';

@Component({
    standalone: true,
    selector: 'app-type-color',
    templateUrl: './type-color.component.html',
    imports: [CommonModule],
    styleUrls: ['./type-color.component.css'],
})
export class TypeColorComponent implements ICellRendererAngularComp {
    params: ICellRendererParams | undefined;
    @Input() value: SignalementTypeDtoOuput | undefined;
    constructor(
    ) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
    getColor(color: string): string {
        return `${color}32`;
    }

}
