import { I } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { SignalementOutputForDetail } from '@app/api/models';

@Component({
    selector: 'app-stat-report',
    standalone: true,
    imports: [CommonModule, MatIconModule],
    templateUrl: './stat-report.component.html',
    styleUrls: ['./stat-report.component.css'],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatReportComponent implements OnInit {
    @Input() data: SignalementOutputForDetail[] | null | undefined = null;
    constructor(private cdr: ChangeDetectorRef, private router: Router) {}

    ngOnInit(): void {}

    getDatetime(signalement: SignalementOutputForDetail): string {
        if (signalement.creeeLe) {
            return new Date(signalement.creeeLe).toLocaleString('fr-FR').slice(0, -3);
        }
        return '';
    }

    redirectToSignalement(signalementId: number | undefined): void {
        if (!signalementId) {
            return;
        }
        this.router.navigate(['/signalements', signalementId]);
    }
}
