<div class="custom-card flex h-full w-full flex-col rounded-[10px] bg-white px-8 py-6">
    @if (stats && chartOptions) {
        <apx-chart
            class="h-full w-full"
            #chart
            [series]="chartOptions.series"
            [chart]="chartOptions.chart"
            [title]="chartOptions.title"
            [plotOptions]="chartOptions.plotOptions"
            [fill]="chartOptions.fill"
            [xaxis]="chartOptions.xaxis"
            [stroke]="chartOptions.stroke"
            [markers]="chartOptions.markers"
        ></apx-chart>
    }
</div>
