<div class="relative flex w-screen h-[calc(100vh-80px)] gap-4 flex-col justify-center  px-16">
    <div class="flex items-center justify-end gap-6">

        <button mat-stroked-button [matMenuTriggerFor]="menu"
                class="flex flex-row-reverse gap-4 !border-primary !text-primary !bg-white">
            <span class="whitespace-nowrap">Gestion des adresses</span>
            <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
            <input type="file" (change)="importAddress($event)" accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" hidden #fileInput>
            <button mat-menu-item (click)="fileInput.click()">Importer un fichier de mise à jour</button>
            <button mat-menu-item (click)="exportLatestAddressList()">Télécharger le dernier fichier de mise à jour</button>

        </mat-menu>
    </div>
  @if(rawData){
  <app-datagrid class="h-4/5" [data]="rawData" [columns]="colDefs" [nbRecords]="nbRecords"
    (sortChanged)="onSortChanged($event)" (filterChanged)="onFilterChanged($event)"
    (pageSizeChange)="onPageSizeChange($event)" (gridReady)="onGridReady($event)"></app-datagrid>
  }
</div>