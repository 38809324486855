/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DetailModalitesDemandesLieesOffreDtoOutput } from '../models/detail-modalites-demandes-liees-offre-dto-output';
import { EditOffreDtoInputForForm } from '../models/edit-offre-dto-input-for-form';
import { LieuEchangeXOffreDtoOutputForMap } from '../models/lieu-echange-x-offre-dto-output-for-map';
import { OffreClotureeDtoOutputForListOdataResponse } from '../models/offre-cloturee-dto-output-for-list-odata-response';
import { OffreDtoOutputForDetailAsUserPointOfView } from '../models/offre-dto-output-for-detail-as-user-point-of-view';
import { OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse } from '../models/offre-dto-output-with-favorites-and-modality-icons-for-list-odata-response';
import { OffreEnAttenteDtoOutputForListOdataResponse } from '../models/offre-en-attente-dto-output-for-list-odata-response';
import { OffreEnCoursDtoOutputForListOdataResponse } from '../models/offre-en-cours-dto-output-for-list-odata-response';
import { offresAProximiteGet$Any } from '../fn/offres/offres-a-proximite-get-any';
import { OffresAProximiteGet$Any$Params } from '../fn/offres/offres-a-proximite-get-any';
import { offresAProximiteGet$Json } from '../fn/offres/offres-a-proximite-get-json';
import { OffresAProximiteGet$Json$Params } from '../fn/offres/offres-a-proximite-get-json';
import { offresAProximiteGet$Plain } from '../fn/offres/offres-a-proximite-get-plain';
import { OffresAProximiteGet$Plain$Params } from '../fn/offres/offres-a-proximite-get-plain';
import { offresFavorisGet$Any } from '../fn/offres/offres-favoris-get-any';
import { OffresFavorisGet$Any$Params } from '../fn/offres/offres-favoris-get-any';
import { offresFavorisGet$Json } from '../fn/offres/offres-favoris-get-json';
import { OffresFavorisGet$Json$Params } from '../fn/offres/offres-favoris-get-json';
import { offresFavorisGet$Plain } from '../fn/offres/offres-favoris-get-plain';
import { OffresFavorisGet$Plain$Params } from '../fn/offres/offres-favoris-get-plain';
import { offresGet$Any } from '../fn/offres/offres-get-any';
import { OffresGet$Any$Params } from '../fn/offres/offres-get-any';
import { offresGet$Json } from '../fn/offres/offres-get-json';
import { OffresGet$Json$Params } from '../fn/offres/offres-get-json';
import { offresGet$Plain } from '../fn/offres/offres-get-plain';
import { OffresGet$Plain$Params } from '../fn/offres/offres-get-plain';
import { offresIdPut$Any } from '../fn/offres/offres-id-put-any';
import { OffresIdPut$Any$Params } from '../fn/offres/offres-id-put-any';
import { offresIdPut$Json } from '../fn/offres/offres-id-put-json';
import { OffresIdPut$Json$Params } from '../fn/offres/offres-id-put-json';
import { offresIdPut$Plain } from '../fn/offres/offres-id-put-plain';
import { OffresIdPut$Plain$Params } from '../fn/offres/offres-id-put-plain';
import { offresMesOffresArchiveesClotureesGet$Any } from '../fn/offres/offres-mes-offres-archivees-cloturees-get-any';
import { OffresMesOffresArchiveesClotureesGet$Any$Params } from '../fn/offres/offres-mes-offres-archivees-cloturees-get-any';
import { offresMesOffresArchiveesClotureesGet$Json } from '../fn/offres/offres-mes-offres-archivees-cloturees-get-json';
import { OffresMesOffresArchiveesClotureesGet$Json$Params } from '../fn/offres/offres-mes-offres-archivees-cloturees-get-json';
import { offresMesOffresArchiveesClotureesGet$Plain } from '../fn/offres/offres-mes-offres-archivees-cloturees-get-plain';
import { OffresMesOffresArchiveesClotureesGet$Plain$Params } from '../fn/offres/offres-mes-offres-archivees-cloturees-get-plain';
import { offresMesOffresArchiveesSupprimeesGet$Any } from '../fn/offres/offres-mes-offres-archivees-supprimees-get-any';
import { OffresMesOffresArchiveesSupprimeesGet$Any$Params } from '../fn/offres/offres-mes-offres-archivees-supprimees-get-any';
import { offresMesOffresArchiveesSupprimeesGet$Json } from '../fn/offres/offres-mes-offres-archivees-supprimees-get-json';
import { OffresMesOffresArchiveesSupprimeesGet$Json$Params } from '../fn/offres/offres-mes-offres-archivees-supprimees-get-json';
import { offresMesOffresArchiveesSupprimeesGet$Plain } from '../fn/offres/offres-mes-offres-archivees-supprimees-get-plain';
import { OffresMesOffresArchiveesSupprimeesGet$Plain$Params } from '../fn/offres/offres-mes-offres-archivees-supprimees-get-plain';
import { offresMesOffresEnAttenteGet$Any } from '../fn/offres/offres-mes-offres-en-attente-get-any';
import { OffresMesOffresEnAttenteGet$Any$Params } from '../fn/offres/offres-mes-offres-en-attente-get-any';
import { offresMesOffresEnAttenteGet$Json } from '../fn/offres/offres-mes-offres-en-attente-get-json';
import { OffresMesOffresEnAttenteGet$Json$Params } from '../fn/offres/offres-mes-offres-en-attente-get-json';
import { offresMesOffresEnAttenteGet$Plain } from '../fn/offres/offres-mes-offres-en-attente-get-plain';
import { OffresMesOffresEnAttenteGet$Plain$Params } from '../fn/offres/offres-mes-offres-en-attente-get-plain';
import { offresMesOffresEnAttenteIdGet$Any } from '../fn/offres/offres-mes-offres-en-attente-id-get-any';
import { OffresMesOffresEnAttenteIdGet$Any$Params } from '../fn/offres/offres-mes-offres-en-attente-id-get-any';
import { offresMesOffresEnAttenteIdGet$Json } from '../fn/offres/offres-mes-offres-en-attente-id-get-json';
import { OffresMesOffresEnAttenteIdGet$Json$Params } from '../fn/offres/offres-mes-offres-en-attente-id-get-json';
import { offresMesOffresEnAttenteIdGet$Plain } from '../fn/offres/offres-mes-offres-en-attente-id-get-plain';
import { OffresMesOffresEnAttenteIdGet$Plain$Params } from '../fn/offres/offres-mes-offres-en-attente-id-get-plain';
import { offresMesOffresEnAttenteOffreIdChoisirDemandePost$Any } from '../fn/offres/offres-mes-offres-en-attente-offre-id-choisir-demande-post-any';
import { OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Any$Params } from '../fn/offres/offres-mes-offres-en-attente-offre-id-choisir-demande-post-any';
import { offresMesOffresEnAttenteOffreIdChoisirDemandePost$Json } from '../fn/offres/offres-mes-offres-en-attente-offre-id-choisir-demande-post-json';
import { OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Json$Params } from '../fn/offres/offres-mes-offres-en-attente-offre-id-choisir-demande-post-json';
import { offresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain } from '../fn/offres/offres-mes-offres-en-attente-offre-id-choisir-demande-post-plain';
import { OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain$Params } from '../fn/offres/offres-mes-offres-en-attente-offre-id-choisir-demande-post-plain';
import { offresMesOffresEnAttenteOffreIdRefuserPost$Any } from '../fn/offres/offres-mes-offres-en-attente-offre-id-refuser-post-any';
import { OffresMesOffresEnAttenteOffreIdRefuserPost$Any$Params } from '../fn/offres/offres-mes-offres-en-attente-offre-id-refuser-post-any';
import { offresMesOffresEnAttenteOffreIdRefuserPost$Json } from '../fn/offres/offres-mes-offres-en-attente-offre-id-refuser-post-json';
import { OffresMesOffresEnAttenteOffreIdRefuserPost$Json$Params } from '../fn/offres/offres-mes-offres-en-attente-offre-id-refuser-post-json';
import { offresMesOffresEnAttenteOffreIdRefuserPost$Plain } from '../fn/offres/offres-mes-offres-en-attente-offre-id-refuser-post-plain';
import { OffresMesOffresEnAttenteOffreIdRefuserPost$Plain$Params } from '../fn/offres/offres-mes-offres-en-attente-offre-id-refuser-post-plain';
import { offresMesOffresEnCoursGet$Any } from '../fn/offres/offres-mes-offres-en-cours-get-any';
import { OffresMesOffresEnCoursGet$Any$Params } from '../fn/offres/offres-mes-offres-en-cours-get-any';
import { offresMesOffresEnCoursGet$Json } from '../fn/offres/offres-mes-offres-en-cours-get-json';
import { OffresMesOffresEnCoursGet$Json$Params } from '../fn/offres/offres-mes-offres-en-cours-get-json';
import { offresMesOffresEnCoursGet$Plain } from '../fn/offres/offres-mes-offres-en-cours-get-plain';
import { OffresMesOffresEnCoursGet$Plain$Params } from '../fn/offres/offres-mes-offres-en-cours-get-plain';
import { offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost } from '../fn/offres/offres-mes-offres-en-cours-id-ajouter-numero-de-suivi-post';
import { OffresMesOffresEnCoursIdAjouterNumeroDeSuiviPost$Params } from '../fn/offres/offres-mes-offres-en-cours-id-ajouter-numero-de-suivi-post';
import { offresMesOffresEnCoursIdDeclarerEnvoiPost } from '../fn/offres/offres-mes-offres-en-cours-id-declarer-envoi-post';
import { OffresMesOffresEnCoursIdDeclarerEnvoiPost$Params } from '../fn/offres/offres-mes-offres-en-cours-id-declarer-envoi-post';
import { offresMesOffresEnCoursOffreIdForcerCloturePost$Any } from '../fn/offres/offres-mes-offres-en-cours-offre-id-forcer-cloture-post-any';
import { OffresMesOffresEnCoursOffreIdForcerCloturePost$Any$Params } from '../fn/offres/offres-mes-offres-en-cours-offre-id-forcer-cloture-post-any';
import { offresMesOffresEnCoursOffreIdForcerCloturePost$Json } from '../fn/offres/offres-mes-offres-en-cours-offre-id-forcer-cloture-post-json';
import { OffresMesOffresEnCoursOffreIdForcerCloturePost$Json$Params } from '../fn/offres/offres-mes-offres-en-cours-offre-id-forcer-cloture-post-json';
import { offresMesOffresEnCoursOffreIdForcerCloturePost$Plain } from '../fn/offres/offres-mes-offres-en-cours-offre-id-forcer-cloture-post-plain';
import { OffresMesOffresEnCoursOffreIdForcerCloturePost$Plain$Params } from '../fn/offres/offres-mes-offres-en-cours-offre-id-forcer-cloture-post-plain';
import { offresMesOffresIdDelete } from '../fn/offres/offres-mes-offres-id-delete';
import { OffresMesOffresIdDelete$Params } from '../fn/offres/offres-mes-offres-id-delete';
import { offresOffreIdArticlesSimilairesGet$Any } from '../fn/offres/offres-offre-id-articles-similaires-get-any';
import { OffresOffreIdArticlesSimilairesGet$Any$Params } from '../fn/offres/offres-offre-id-articles-similaires-get-any';
import { offresOffreIdArticlesSimilairesGet$Json } from '../fn/offres/offres-offre-id-articles-similaires-get-json';
import { OffresOffreIdArticlesSimilairesGet$Json$Params } from '../fn/offres/offres-offre-id-articles-similaires-get-json';
import { offresOffreIdArticlesSimilairesGet$Plain } from '../fn/offres/offres-offre-id-articles-similaires-get-plain';
import { OffresOffreIdArticlesSimilairesGet$Plain$Params } from '../fn/offres/offres-offre-id-articles-similaires-get-plain';
import { offresOffreIdEditionGet$Any } from '../fn/offres/offres-offre-id-edition-get-any';
import { OffresOffreIdEditionGet$Any$Params } from '../fn/offres/offres-offre-id-edition-get-any';
import { offresOffreIdEditionGet$Json } from '../fn/offres/offres-offre-id-edition-get-json';
import { OffresOffreIdEditionGet$Json$Params } from '../fn/offres/offres-offre-id-edition-get-json';
import { offresOffreIdEditionGet$Plain } from '../fn/offres/offres-offre-id-edition-get-plain';
import { OffresOffreIdEditionGet$Plain$Params } from '../fn/offres/offres-offre-id-edition-get-plain';
import { offresOffreIdFavorisPut$Any } from '../fn/offres/offres-offre-id-favoris-put-any';
import { OffresOffreIdFavorisPut$Any$Params } from '../fn/offres/offres-offre-id-favoris-put-any';
import { offresOffreIdFavorisPut$Json } from '../fn/offres/offres-offre-id-favoris-put-json';
import { OffresOffreIdFavorisPut$Json$Params } from '../fn/offres/offres-offre-id-favoris-put-json';
import { offresOffreIdFavorisPut$Plain } from '../fn/offres/offres-offre-id-favoris-put-plain';
import { OffresOffreIdFavorisPut$Plain$Params } from '../fn/offres/offres-offre-id-favoris-put-plain';
import { offresOffreIdGet$Any } from '../fn/offres/offres-offre-id-get-any';
import { OffresOffreIdGet$Any$Params } from '../fn/offres/offres-offre-id-get-any';
import { offresOffreIdGet$Json } from '../fn/offres/offres-offre-id-get-json';
import { OffresOffreIdGet$Json$Params } from '../fn/offres/offres-offre-id-get-json';
import { offresOffreIdGet$Plain } from '../fn/offres/offres-offre-id-get-plain';
import { OffresOffreIdGet$Plain$Params } from '../fn/offres/offres-offre-id-get-plain';
import { offresOffreIdSignalerPost } from '../fn/offres/offres-offre-id-signaler-post';
import { OffresOffreIdSignalerPost$Params } from '../fn/offres/offres-offre-id-signaler-post';
import { offresPost$Any } from '../fn/offres/offres-post-any';
import { OffresPost$Any$Params } from '../fn/offres/offres-post-any';
import { offresPost$Json } from '../fn/offres/offres-post-json';
import { OffresPost$Json$Params } from '../fn/offres/offres-post-json';
import { offresPost$Plain } from '../fn/offres/offres-post-plain';
import { OffresPost$Plain$Params } from '../fn/offres/offres-post-plain';
import { OffreSuprimeeDtoOutputForListOdataResponse } from '../models/offre-suprimee-dto-output-for-list-odata-response';
import { offresVueCarteGet$Any } from '../fn/offres/offres-vue-carte-get-any';
import { OffresVueCarteGet$Any$Params } from '../fn/offres/offres-vue-carte-get-any';
import { offresVueCarteGet$Json } from '../fn/offres/offres-vue-carte-get-json';
import { OffresVueCarteGet$Json$Params } from '../fn/offres/offres-vue-carte-get-json';
import { offresVueCarteGet$Plain } from '../fn/offres/offres-vue-carte-get-plain';
import { OffresVueCarteGet$Plain$Params } from '../fn/offres/offres-vue-carte-get-plain';

@Injectable({ providedIn: 'root' })
export class OffresService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `offresGet()` */
  static readonly OffresGetPath = '/offres';

  /**
   * Donne suffisament d'informations des offres pour afficher les tuiles (Favoris et modalité d'échange compris).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresGet$Json$Response(params?: OffresGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Donne suffisament d'informations des offres pour afficher les tuiles (Favoris et modalité d'échange compris).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresGet$Json(params?: OffresGet$Json$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * Donne suffisament d'informations des offres pour afficher les tuiles (Favoris et modalité d'échange compris).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresGet$Plain$Response(params?: OffresGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Donne suffisament d'informations des offres pour afficher les tuiles (Favoris et modalité d'échange compris).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresGet$Plain(params?: OffresGet$Plain$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * Donne suffisament d'informations des offres pour afficher les tuiles (Favoris et modalité d'échange compris).
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresGet$Any$Response(params?: OffresGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Donne suffisament d'informations des offres pour afficher les tuiles (Favoris et modalité d'échange compris).
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresGet$Any(params?: OffresGet$Any$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `offresPost()` */
  static readonly OffresPostPath = '/offres';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresPost$Json$Response(params: OffresPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return offresPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresPost$Json(params: OffresPost$Json$Params, context?: HttpContext): Observable<string> {
    return this.offresPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresPost$Plain$Response(params: OffresPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return offresPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresPost$Plain(params: OffresPost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.offresPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresPost$Any$Response(params: OffresPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return offresPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresPost$Any(params: OffresPost$Any$Params, context?: HttpContext): Observable<string> {
    return this.offresPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `offresIdPut()` */
  static readonly OffresIdPutPath = '/offres/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresIdPut$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresIdPut$Json$Response(params: OffresIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return offresIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresIdPut$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresIdPut$Json(params: OffresIdPut$Json$Params, context?: HttpContext): Observable<Array<string>> {
    return this.offresIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresIdPut$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresIdPut$Plain$Response(params: OffresIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return offresIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresIdPut$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresIdPut$Plain(params: OffresIdPut$Plain$Params, context?: HttpContext): Observable<Array<string>> {
    return this.offresIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresIdPut$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresIdPut$Any$Response(params: OffresIdPut$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return offresIdPut$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresIdPut$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  offresIdPut$Any(params: OffresIdPut$Any$Params, context?: HttpContext): Observable<Array<string>> {
    return this.offresIdPut$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `offresOffreIdFavorisPut()` */
  static readonly OffresOffreIdFavorisPutPath = '/offres/{offreId}/favoris';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdFavorisPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdFavorisPut$Json$Response(params: OffresOffreIdFavorisPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offresOffreIdFavorisPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdFavorisPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdFavorisPut$Json(params: OffresOffreIdFavorisPut$Json$Params, context?: HttpContext): Observable<boolean> {
    return this.offresOffreIdFavorisPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdFavorisPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdFavorisPut$Plain$Response(params: OffresOffreIdFavorisPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offresOffreIdFavorisPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdFavorisPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdFavorisPut$Plain(params: OffresOffreIdFavorisPut$Plain$Params, context?: HttpContext): Observable<boolean> {
    return this.offresOffreIdFavorisPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdFavorisPut$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdFavorisPut$Any$Response(params: OffresOffreIdFavorisPut$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return offresOffreIdFavorisPut$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdFavorisPut$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdFavorisPut$Any(params: OffresOffreIdFavorisPut$Any$Params, context?: HttpContext): Observable<boolean> {
    return this.offresOffreIdFavorisPut$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `offresFavorisGet()` */
  static readonly OffresFavorisGetPath = '/offres/favoris';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresFavorisGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresFavorisGet$Json$Response(params?: OffresFavorisGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresFavorisGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresFavorisGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresFavorisGet$Json(params?: OffresFavorisGet$Json$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresFavorisGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresFavorisGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresFavorisGet$Plain$Response(params?: OffresFavorisGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresFavorisGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresFavorisGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresFavorisGet$Plain(params?: OffresFavorisGet$Plain$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresFavorisGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresFavorisGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresFavorisGet$Any$Response(params?: OffresFavorisGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresFavorisGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresFavorisGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresFavorisGet$Any(params?: OffresFavorisGet$Any$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresFavorisGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `offresOffreIdGet()` */
  static readonly OffresOffreIdGetPath = '/offres/{offreId}';

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdGet$Json$Response(params: OffresOffreIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>> {
    return offresOffreIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdGet$Json(params: OffresOffreIdGet$Json$Params, context?: HttpContext): Observable<OffreDtoOutputForDetailAsUserPointOfView> {
    return this.offresOffreIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>): OffreDtoOutputForDetailAsUserPointOfView => r.body)
    );
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdGet$Plain$Response(params: OffresOffreIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>> {
    return offresOffreIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdGet$Plain(params: OffresOffreIdGet$Plain$Params, context?: HttpContext): Observable<OffreDtoOutputForDetailAsUserPointOfView> {
    return this.offresOffreIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>): OffreDtoOutputForDetailAsUserPointOfView => r.body)
    );
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdGet$Any$Response(params: OffresOffreIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>> {
    return offresOffreIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdGet$Any(params: OffresOffreIdGet$Any$Params, context?: HttpContext): Observable<OffreDtoOutputForDetailAsUserPointOfView> {
    return this.offresOffreIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>): OffreDtoOutputForDetailAsUserPointOfView => r.body)
    );
  }

  /** Path part for operation `offresOffreIdEditionGet()` */
  static readonly OffresOffreIdEditionGetPath = '/offres/{offreId}/edition';

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdEditionGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdEditionGet$Json$Response(params: OffresOffreIdEditionGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<EditOffreDtoInputForForm>> {
    return offresOffreIdEditionGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdEditionGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdEditionGet$Json(params: OffresOffreIdEditionGet$Json$Params, context?: HttpContext): Observable<EditOffreDtoInputForForm> {
    return this.offresOffreIdEditionGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditOffreDtoInputForForm>): EditOffreDtoInputForForm => r.body)
    );
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdEditionGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdEditionGet$Plain$Response(params: OffresOffreIdEditionGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EditOffreDtoInputForForm>> {
    return offresOffreIdEditionGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdEditionGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdEditionGet$Plain(params: OffresOffreIdEditionGet$Plain$Params, context?: HttpContext): Observable<EditOffreDtoInputForForm> {
    return this.offresOffreIdEditionGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditOffreDtoInputForForm>): EditOffreDtoInputForForm => r.body)
    );
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdEditionGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdEditionGet$Any$Response(params: OffresOffreIdEditionGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<EditOffreDtoInputForForm>> {
    return offresOffreIdEditionGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Informations pour l'écran de détail de l'offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdEditionGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdEditionGet$Any(params: OffresOffreIdEditionGet$Any$Params, context?: HttpContext): Observable<EditOffreDtoInputForForm> {
    return this.offresOffreIdEditionGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<EditOffreDtoInputForForm>): EditOffreDtoInputForForm => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnAttenteGet()` */
  static readonly OffresMesOffresEnAttenteGetPath = '/offres/mes-offres/en-attente';

  /**
   * Obtenir la liste de mes offres en attente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteGet$Json$Response(params?: OffresMesOffresEnAttenteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreEnAttenteDtoOutputForListOdataResponse>> {
    return offresMesOffresEnAttenteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres en attente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteGet$Json(params?: OffresMesOffresEnAttenteGet$Json$Params, context?: HttpContext): Observable<OffreEnAttenteDtoOutputForListOdataResponse> {
    return this.offresMesOffresEnAttenteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreEnAttenteDtoOutputForListOdataResponse>): OffreEnAttenteDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste de mes offres en attente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteGet$Plain$Response(params?: OffresMesOffresEnAttenteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreEnAttenteDtoOutputForListOdataResponse>> {
    return offresMesOffresEnAttenteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres en attente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteGet$Plain(params?: OffresMesOffresEnAttenteGet$Plain$Params, context?: HttpContext): Observable<OffreEnAttenteDtoOutputForListOdataResponse> {
    return this.offresMesOffresEnAttenteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreEnAttenteDtoOutputForListOdataResponse>): OffreEnAttenteDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste de mes offres en attente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteGet$Any$Response(params?: OffresMesOffresEnAttenteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreEnAttenteDtoOutputForListOdataResponse>> {
    return offresMesOffresEnAttenteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres en attente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteGet$Any(params?: OffresMesOffresEnAttenteGet$Any$Params, context?: HttpContext): Observable<OffreEnAttenteDtoOutputForListOdataResponse> {
    return this.offresMesOffresEnAttenteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreEnAttenteDtoOutputForListOdataResponse>): OffreEnAttenteDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnAttenteIdGet()` */
  static readonly OffresMesOffresEnAttenteIdGetPath = '/offres/mes-offres/en-attente/{id}';

  /**
   * Obtenir la liste des modalités de demande d'une de mes offres en attente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteIdGet$Json$Response(params: OffresMesOffresEnAttenteIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste des modalités de demande d'une de mes offres en attente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteIdGet$Json(params: OffresMesOffresEnAttenteIdGet$Json$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * Obtenir la liste des modalités de demande d'une de mes offres en attente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteIdGet$Plain$Response(params: OffresMesOffresEnAttenteIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste des modalités de demande d'une de mes offres en attente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteIdGet$Plain(params: OffresMesOffresEnAttenteIdGet$Plain$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * Obtenir la liste des modalités de demande d'une de mes offres en attente.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteIdGet$Any$Response(params: OffresMesOffresEnAttenteIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste des modalités de demande d'une de mes offres en attente.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteIdGet$Any(params: OffresMesOffresEnAttenteIdGet$Any$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnAttenteOffreIdChoisirDemandePost()` */
  static readonly OffresMesOffresEnAttenteOffreIdChoisirDemandePostPath = '/offres/mes-offres/en-attente/{offreId}/choisir-demande';

  /**
   * Choisir une des modalité de demande. L'offre sera : "En Cours".
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteOffreIdChoisirDemandePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdChoisirDemandePost$Json$Response(params: OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteOffreIdChoisirDemandePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Choisir une des modalité de demande. L'offre sera : "En Cours".
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteOffreIdChoisirDemandePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdChoisirDemandePost$Json(params: OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Json$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteOffreIdChoisirDemandePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * Choisir une des modalité de demande. L'offre sera : "En Cours".
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain$Response(params: OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Choisir une des modalité de demande. L'offre sera : "En Cours".
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain(params: OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteOffreIdChoisirDemandePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * Choisir une des modalité de demande. L'offre sera : "En Cours".
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteOffreIdChoisirDemandePost$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdChoisirDemandePost$Any$Response(params: OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteOffreIdChoisirDemandePost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Choisir une des modalité de demande. L'offre sera : "En Cours".
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteOffreIdChoisirDemandePost$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdChoisirDemandePost$Any(params: OffresMesOffresEnAttenteOffreIdChoisirDemandePost$Any$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteOffreIdChoisirDemandePost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnAttenteOffreIdRefuserPost()` */
  static readonly OffresMesOffresEnAttenteOffreIdRefuserPostPath = '/offres/mes-offres/en-attente/{offreId}/refuser';

  /**
   * Désapprouver un utilisateur sur une offre : Les demandes seront cachées.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteOffreIdRefuserPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdRefuserPost$Json$Response(params: OffresMesOffresEnAttenteOffreIdRefuserPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteOffreIdRefuserPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Désapprouver un utilisateur sur une offre : Les demandes seront cachées.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteOffreIdRefuserPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdRefuserPost$Json(params: OffresMesOffresEnAttenteOffreIdRefuserPost$Json$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteOffreIdRefuserPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * Désapprouver un utilisateur sur une offre : Les demandes seront cachées.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteOffreIdRefuserPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdRefuserPost$Plain$Response(params: OffresMesOffresEnAttenteOffreIdRefuserPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteOffreIdRefuserPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Désapprouver un utilisateur sur une offre : Les demandes seront cachées.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteOffreIdRefuserPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdRefuserPost$Plain(params: OffresMesOffresEnAttenteOffreIdRefuserPost$Plain$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteOffreIdRefuserPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * Désapprouver un utilisateur sur une offre : Les demandes seront cachées.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnAttenteOffreIdRefuserPost$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdRefuserPost$Any$Response(params: OffresMesOffresEnAttenteOffreIdRefuserPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnAttenteOffreIdRefuserPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Désapprouver un utilisateur sur une offre : Les demandes seront cachées.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnAttenteOffreIdRefuserPost$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnAttenteOffreIdRefuserPost$Any(params: OffresMesOffresEnAttenteOffreIdRefuserPost$Any$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnAttenteOffreIdRefuserPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnCoursGet()` */
  static readonly OffresMesOffresEnCoursGetPath = '/offres/mes-offres/en-cours';

  /**
   * Obtenir la liste de mes offres en cours.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursGet$Json$Response(params?: OffresMesOffresEnCoursGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreEnCoursDtoOutputForListOdataResponse>> {
    return offresMesOffresEnCoursGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres en cours.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursGet$Json(params?: OffresMesOffresEnCoursGet$Json$Params, context?: HttpContext): Observable<OffreEnCoursDtoOutputForListOdataResponse> {
    return this.offresMesOffresEnCoursGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreEnCoursDtoOutputForListOdataResponse>): OffreEnCoursDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste de mes offres en cours.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursGet$Plain$Response(params?: OffresMesOffresEnCoursGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreEnCoursDtoOutputForListOdataResponse>> {
    return offresMesOffresEnCoursGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres en cours.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursGet$Plain(params?: OffresMesOffresEnCoursGet$Plain$Params, context?: HttpContext): Observable<OffreEnCoursDtoOutputForListOdataResponse> {
    return this.offresMesOffresEnCoursGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreEnCoursDtoOutputForListOdataResponse>): OffreEnCoursDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste de mes offres en cours.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursGet$Any$Response(params?: OffresMesOffresEnCoursGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreEnCoursDtoOutputForListOdataResponse>> {
    return offresMesOffresEnCoursGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres en cours.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursGet$Any(params?: OffresMesOffresEnCoursGet$Any$Params, context?: HttpContext): Observable<OffreEnCoursDtoOutputForListOdataResponse> {
    return this.offresMesOffresEnCoursGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreEnCoursDtoOutputForListOdataResponse>): OffreEnCoursDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnCoursIdDeclarerEnvoiPost()` */
  static readonly OffresMesOffresEnCoursIdDeclarerEnvoiPostPath = '/offres/mes-offres/en-cours/{id}/declarer-envoi';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursIdDeclarerEnvoiPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursIdDeclarerEnvoiPost$Response(params: OffresMesOffresEnCoursIdDeclarerEnvoiPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return offresMesOffresEnCoursIdDeclarerEnvoiPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursIdDeclarerEnvoiPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursIdDeclarerEnvoiPost(params: OffresMesOffresEnCoursIdDeclarerEnvoiPost$Params, context?: HttpContext): Observable<void> {
    return this.offresMesOffresEnCoursIdDeclarerEnvoiPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost()` */
  static readonly OffresMesOffresEnCoursIdAjouterNumeroDeSuiviPostPath = '/offres/mes-offres/en-cours/{id}/ajouter-numero-de-suivi';

  /**
   * Déclarer un numéro de suivi d'un envois.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost$Response(params: OffresMesOffresEnCoursIdAjouterNumeroDeSuiviPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Déclarer un numéro de suivi d'un envois.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost(params: OffresMesOffresEnCoursIdAjouterNumeroDeSuiviPost$Params, context?: HttpContext): Observable<void> {
    return this.offresMesOffresEnCoursIdAjouterNumeroDeSuiviPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `offresMesOffresEnCoursOffreIdForcerCloturePost()` */
  static readonly OffresMesOffresEnCoursOffreIdForcerCloturePostPath = '/offres/mes-offres/en-cours/{offreId}/forcer-cloture';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursOffreIdForcerCloturePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursOffreIdForcerCloturePost$Json$Response(params: OffresMesOffresEnCoursOffreIdForcerCloturePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnCoursOffreIdForcerCloturePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursOffreIdForcerCloturePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursOffreIdForcerCloturePost$Json(params: OffresMesOffresEnCoursOffreIdForcerCloturePost$Json$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnCoursOffreIdForcerCloturePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursOffreIdForcerCloturePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursOffreIdForcerCloturePost$Plain$Response(params: OffresMesOffresEnCoursOffreIdForcerCloturePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnCoursOffreIdForcerCloturePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursOffreIdForcerCloturePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursOffreIdForcerCloturePost$Plain(params: OffresMesOffresEnCoursOffreIdForcerCloturePost$Plain$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnCoursOffreIdForcerCloturePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresEnCoursOffreIdForcerCloturePost$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursOffreIdForcerCloturePost$Any$Response(params: OffresMesOffresEnCoursOffreIdForcerCloturePost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>> {
    return offresMesOffresEnCoursOffreIdForcerCloturePost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresEnCoursOffreIdForcerCloturePost$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresEnCoursOffreIdForcerCloturePost$Any(params: OffresMesOffresEnCoursOffreIdForcerCloturePost$Any$Params, context?: HttpContext): Observable<DetailModalitesDemandesLieesOffreDtoOutput> {
    return this.offresMesOffresEnCoursOffreIdForcerCloturePost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DetailModalitesDemandesLieesOffreDtoOutput>): DetailModalitesDemandesLieesOffreDtoOutput => r.body)
    );
  }

  /** Path part for operation `offresMesOffresArchiveesClotureesGet()` */
  static readonly OffresMesOffresArchiveesClotureesGetPath = '/offres/mes-offres/archivees/cloturees';

  /**
   * Obtenir la liste de mes offres dont la date d'envoi et de réception ne sont pas nulles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresArchiveesClotureesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesClotureesGet$Json$Response(params?: OffresMesOffresArchiveesClotureesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreClotureeDtoOutputForListOdataResponse>> {
    return offresMesOffresArchiveesClotureesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres dont la date d'envoi et de réception ne sont pas nulles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresArchiveesClotureesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesClotureesGet$Json(params?: OffresMesOffresArchiveesClotureesGet$Json$Params, context?: HttpContext): Observable<OffreClotureeDtoOutputForListOdataResponse> {
    return this.offresMesOffresArchiveesClotureesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreClotureeDtoOutputForListOdataResponse>): OffreClotureeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste de mes offres dont la date d'envoi et de réception ne sont pas nulles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresArchiveesClotureesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesClotureesGet$Plain$Response(params?: OffresMesOffresArchiveesClotureesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreClotureeDtoOutputForListOdataResponse>> {
    return offresMesOffresArchiveesClotureesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres dont la date d'envoi et de réception ne sont pas nulles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresArchiveesClotureesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesClotureesGet$Plain(params?: OffresMesOffresArchiveesClotureesGet$Plain$Params, context?: HttpContext): Observable<OffreClotureeDtoOutputForListOdataResponse> {
    return this.offresMesOffresArchiveesClotureesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreClotureeDtoOutputForListOdataResponse>): OffreClotureeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste de mes offres dont la date d'envoi et de réception ne sont pas nulles.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresArchiveesClotureesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesClotureesGet$Any$Response(params?: OffresMesOffresArchiveesClotureesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreClotureeDtoOutputForListOdataResponse>> {
    return offresMesOffresArchiveesClotureesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste de mes offres dont la date d'envoi et de réception ne sont pas nulles.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresArchiveesClotureesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesClotureesGet$Any(params?: OffresMesOffresArchiveesClotureesGet$Any$Params, context?: HttpContext): Observable<OffreClotureeDtoOutputForListOdataResponse> {
    return this.offresMesOffresArchiveesClotureesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreClotureeDtoOutputForListOdataResponse>): OffreClotureeDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `offresMesOffresArchiveesSupprimeesGet()` */
  static readonly OffresMesOffresArchiveesSupprimeesGetPath = '/offres/mes-offres/archivees/supprimees';

  /**
   * Obtenir la liste des offres que j'ai annulées.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresArchiveesSupprimeesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesSupprimeesGet$Json$Response(params?: OffresMesOffresArchiveesSupprimeesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreSuprimeeDtoOutputForListOdataResponse>> {
    return offresMesOffresArchiveesSupprimeesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste des offres que j'ai annulées.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresArchiveesSupprimeesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesSupprimeesGet$Json(params?: OffresMesOffresArchiveesSupprimeesGet$Json$Params, context?: HttpContext): Observable<OffreSuprimeeDtoOutputForListOdataResponse> {
    return this.offresMesOffresArchiveesSupprimeesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreSuprimeeDtoOutputForListOdataResponse>): OffreSuprimeeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste des offres que j'ai annulées.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresArchiveesSupprimeesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesSupprimeesGet$Plain$Response(params?: OffresMesOffresArchiveesSupprimeesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreSuprimeeDtoOutputForListOdataResponse>> {
    return offresMesOffresArchiveesSupprimeesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste des offres que j'ai annulées.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresArchiveesSupprimeesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesSupprimeesGet$Plain(params?: OffresMesOffresArchiveesSupprimeesGet$Plain$Params, context?: HttpContext): Observable<OffreSuprimeeDtoOutputForListOdataResponse> {
    return this.offresMesOffresArchiveesSupprimeesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreSuprimeeDtoOutputForListOdataResponse>): OffreSuprimeeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Obtenir la liste des offres que j'ai annulées.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresArchiveesSupprimeesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesSupprimeesGet$Any$Response(params?: OffresMesOffresArchiveesSupprimeesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreSuprimeeDtoOutputForListOdataResponse>> {
    return offresMesOffresArchiveesSupprimeesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir la liste des offres que j'ai annulées.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresArchiveesSupprimeesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresArchiveesSupprimeesGet$Any(params?: OffresMesOffresArchiveesSupprimeesGet$Any$Params, context?: HttpContext): Observable<OffreSuprimeeDtoOutputForListOdataResponse> {
    return this.offresMesOffresArchiveesSupprimeesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreSuprimeeDtoOutputForListOdataResponse>): OffreSuprimeeDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `offresMesOffresIdDelete()` */
  static readonly OffresMesOffresIdDeletePath = '/offres/mes-offres/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresMesOffresIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresIdDelete$Response(params: OffresMesOffresIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return offresMesOffresIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresMesOffresIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresMesOffresIdDelete(params: OffresMesOffresIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.offresMesOffresIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `offresOffreIdSignalerPost()` */
  static readonly OffresOffreIdSignalerPostPath = '/offres/{offreId}/signaler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdSignalerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offresOffreIdSignalerPost$Response(params: OffresOffreIdSignalerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return offresOffreIdSignalerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdSignalerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  offresOffreIdSignalerPost(params: OffresOffreIdSignalerPost$Params, context?: HttpContext): Observable<void> {
    return this.offresOffreIdSignalerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `offresVueCarteGet()` */
  static readonly OffresVueCarteGetPath = '/offres/vue-carte';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresVueCarteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresVueCarteGet$Json$Response(params?: OffresVueCarteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>> {
    return offresVueCarteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresVueCarteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresVueCarteGet$Json(params?: OffresVueCarteGet$Json$Params, context?: HttpContext): Observable<Array<LieuEchangeXOffreDtoOutputForMap>> {
    return this.offresVueCarteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>): Array<LieuEchangeXOffreDtoOutputForMap> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresVueCarteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresVueCarteGet$Plain$Response(params?: OffresVueCarteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>> {
    return offresVueCarteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresVueCarteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresVueCarteGet$Plain(params?: OffresVueCarteGet$Plain$Params, context?: HttpContext): Observable<Array<LieuEchangeXOffreDtoOutputForMap>> {
    return this.offresVueCarteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>): Array<LieuEchangeXOffreDtoOutputForMap> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresVueCarteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresVueCarteGet$Any$Response(params?: OffresVueCarteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>> {
    return offresVueCarteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresVueCarteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresVueCarteGet$Any(params?: OffresVueCarteGet$Any$Params, context?: HttpContext): Observable<Array<LieuEchangeXOffreDtoOutputForMap>> {
    return this.offresVueCarteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>): Array<LieuEchangeXOffreDtoOutputForMap> => r.body)
    );
  }

  /** Path part for operation `offresOffreIdArticlesSimilairesGet()` */
  static readonly OffresOffreIdArticlesSimilairesGetPath = '/offres/{offreId}/articles-similaires';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdArticlesSimilairesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdArticlesSimilairesGet$Json$Response(params: OffresOffreIdArticlesSimilairesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresOffreIdArticlesSimilairesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdArticlesSimilairesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdArticlesSimilairesGet$Json(params: OffresOffreIdArticlesSimilairesGet$Json$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresOffreIdArticlesSimilairesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdArticlesSimilairesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdArticlesSimilairesGet$Plain$Response(params: OffresOffreIdArticlesSimilairesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresOffreIdArticlesSimilairesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdArticlesSimilairesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdArticlesSimilairesGet$Plain(params: OffresOffreIdArticlesSimilairesGet$Plain$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresOffreIdArticlesSimilairesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresOffreIdArticlesSimilairesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdArticlesSimilairesGet$Any$Response(params: OffresOffreIdArticlesSimilairesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresOffreIdArticlesSimilairesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresOffreIdArticlesSimilairesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresOffreIdArticlesSimilairesGet$Any(params: OffresOffreIdArticlesSimilairesGet$Any$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresOffreIdArticlesSimilairesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `offresAProximiteGet()` */
  static readonly OffresAProximiteGetPath = '/offres/a-proximite';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresAProximiteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresAProximiteGet$Json$Response(params?: OffresAProximiteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresAProximiteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresAProximiteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresAProximiteGet$Json(params?: OffresAProximiteGet$Json$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresAProximiteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresAProximiteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresAProximiteGet$Plain$Response(params?: OffresAProximiteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresAProximiteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresAProximiteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresAProximiteGet$Plain(params?: OffresAProximiteGet$Plain$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresAProximiteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `offresAProximiteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresAProximiteGet$Any$Response(params?: OffresAProximiteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
    return offresAProximiteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `offresAProximiteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  offresAProximiteGet$Any(params?: OffresAProximiteGet$Any$Params, context?: HttpContext): Observable<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse> {
    return this.offresAProximiteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>): OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse => r.body)
    );
  }

}
