<mat-card class="cursor-pointer !bg-white p-3 !shadow-none !flex-row !items-center !justify-between" (click)="onCardClick(notification.url ?? '')">
    <div class="h-full w-fit">
    <mat-card-header class="relative m-3">
        <p [ngClass]="isMenuCard ? 'text-[16px]' : 'text-[18px]'" class="font-semibold text-primary">{{
            notification.titre }}</p>
    </mat-card-header>

    <mat-card-content class="relative">
        <div class="flex items-center">
            <div [ngClass]="isMenuCard ? '' : 'flex-col'" class="flex w-full items-center gap-4 lg:flex-row">
                <div class="w-full flex-1">
                    <span [ngClass]="isMenuCard ? 'mr-4 text-[14px]' : 'mr-8 text-[16px]'"
                        class="text-black max-content">
                        {{ notification.contenu }}
                    </span>
                </div>

            </div>

        </div>
    </mat-card-content>
</div>
    <div role="presentation" [ngClass]="isMenuCard ? '' : 'w-1/3'" class="flex flex-col lg:flex-row items-end lg:w-fit"
    (click)="$event.stopPropagation()">
    <div [ngClass]="isMenuCard ? '' : 'w-full justify-start lg:w-32'">
        <mat-checkbox [(ngModel)]="isChecked" (change)="checkNotification($event.checked)" class="!-ml-3">
            @if (!isMenuCard) {
            @if (isChecked) {
            {{ 'Lue' }}
            } @else {
            {{ 'Non lue' }}
            }
            }
        </mat-checkbox>
    </div>

    @if (!!notification.id) {
    @if (isMenuCard) {
    <button mat-icon-button (click)="deleteNotification(notification.id)" class="!p-0">
        <mat-icon class="items-center !text-red-800">delete</mat-icon>
    </button>
    } @else {
    <button mat-stroked-button color="error"
        class="w-full !border-[#A33333] !text-[#A33333] hover:!bg-[#a3333330] lg:w-32"
        (click)="deleteNotification(notification.id)">
        Supprimer
    </button>
    }
    }
</div>
</mat-card>