<mat-label class="text-primary font-bold whitespace-nowrap">{{label}}</mat-label>

@if (!!conversation && !!conversation.messages){
<div class="details-conversation max-w-[60%] bg-white p-6">
    <div class="flex justify-between my-5">
        <p class="justify-self-start font-bold">{{user?.firstName}} {{user?.lastName}}</p>
        <p class="justify-self-end font-bold">{{conversation.contact?.firstName}} {{conversation.contact?.lastName}}</p>
    </div>
    <div class="flex  max-h-[450px] overflow-y-auto flex-col-reverse gap-1">
        @for (message of conversation.messages; track message) {
        <div [ngClass]="message.envoyeParMoi ? 'self-start bg-primary text-white' : 'self-end bg-[#F2F2F2]'"
            class=" rounded-md p-4">{{message.message}}</div>
        <div class="px-1 text-[10px]" [ngClass]="message.envoyeParMoi ? 'self-start' : 'self-end'">{{
            message.date | date: 'HH:mm le dd/MM/YY' }}</div>
        }
    </div>
</div>
}