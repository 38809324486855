/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DemandeArchiveeDtoOutputForListOdataResponse } from '../models/demande-archivee-dto-output-for-list-odata-response';
import { DemandeDtoOutputForListOdataResponse } from '../models/demande-dto-output-for-list-odata-response';
import { DemandeEnCoursDtoOutputForListOdataResponse } from '../models/demande-en-cours-dto-output-for-list-odata-response';
import { demandesMesDemandesArchiveesGet$Any } from '../fn/demandes/demandes-mes-demandes-archivees-get-any';
import { DemandesMesDemandesArchiveesGet$Any$Params } from '../fn/demandes/demandes-mes-demandes-archivees-get-any';
import { demandesMesDemandesArchiveesGet$Json } from '../fn/demandes/demandes-mes-demandes-archivees-get-json';
import { DemandesMesDemandesArchiveesGet$Json$Params } from '../fn/demandes/demandes-mes-demandes-archivees-get-json';
import { demandesMesDemandesArchiveesGet$Plain } from '../fn/demandes/demandes-mes-demandes-archivees-get-plain';
import { DemandesMesDemandesArchiveesGet$Plain$Params } from '../fn/demandes/demandes-mes-demandes-archivees-get-plain';
import { demandesMesDemandesEnAttenteGet$Any } from '../fn/demandes/demandes-mes-demandes-en-attente-get-any';
import { DemandesMesDemandesEnAttenteGet$Any$Params } from '../fn/demandes/demandes-mes-demandes-en-attente-get-any';
import { demandesMesDemandesEnAttenteGet$Json } from '../fn/demandes/demandes-mes-demandes-en-attente-get-json';
import { DemandesMesDemandesEnAttenteGet$Json$Params } from '../fn/demandes/demandes-mes-demandes-en-attente-get-json';
import { demandesMesDemandesEnAttenteGet$Plain } from '../fn/demandes/demandes-mes-demandes-en-attente-get-plain';
import { DemandesMesDemandesEnAttenteGet$Plain$Params } from '../fn/demandes/demandes-mes-demandes-en-attente-get-plain';
import { demandesMesDemandesEnCoursGet$Any } from '../fn/demandes/demandes-mes-demandes-en-cours-get-any';
import { DemandesMesDemandesEnCoursGet$Any$Params } from '../fn/demandes/demandes-mes-demandes-en-cours-get-any';
import { demandesMesDemandesEnCoursGet$Json } from '../fn/demandes/demandes-mes-demandes-en-cours-get-json';
import { DemandesMesDemandesEnCoursGet$Json$Params } from '../fn/demandes/demandes-mes-demandes-en-cours-get-json';
import { demandesMesDemandesEnCoursGet$Plain } from '../fn/demandes/demandes-mes-demandes-en-cours-get-plain';
import { DemandesMesDemandesEnCoursGet$Plain$Params } from '../fn/demandes/demandes-mes-demandes-en-cours-get-plain';
import { demandesMesDemandesEnCoursIdDeclarerRecuPost } from '../fn/demandes/demandes-mes-demandes-en-cours-id-declarer-recu-post';
import { DemandesMesDemandesEnCoursIdDeclarerRecuPost$Params } from '../fn/demandes/demandes-mes-demandes-en-cours-id-declarer-recu-post';
import { demandesMesDemandesGet$Any } from '../fn/demandes/demandes-mes-demandes-get-any';
import { DemandesMesDemandesGet$Any$Params } from '../fn/demandes/demandes-mes-demandes-get-any';
import { demandesMesDemandesGet$Json } from '../fn/demandes/demandes-mes-demandes-get-json';
import { DemandesMesDemandesGet$Json$Params } from '../fn/demandes/demandes-mes-demandes-get-json';
import { demandesMesDemandesGet$Plain } from '../fn/demandes/demandes-mes-demandes-get-plain';
import { DemandesMesDemandesGet$Plain$Params } from '../fn/demandes/demandes-mes-demandes-get-plain';
import { demandesMesDemandesIdDelete } from '../fn/demandes/demandes-mes-demandes-id-delete';
import { DemandesMesDemandesIdDelete$Params } from '../fn/demandes/demandes-mes-demandes-id-delete';
import { demandesMesDemandesRelanceGet$Any } from '../fn/demandes/demandes-mes-demandes-relance-get-any';
import { DemandesMesDemandesRelanceGet$Any$Params } from '../fn/demandes/demandes-mes-demandes-relance-get-any';
import { demandesMesDemandesRelanceGet$Json } from '../fn/demandes/demandes-mes-demandes-relance-get-json';
import { DemandesMesDemandesRelanceGet$Json$Params } from '../fn/demandes/demandes-mes-demandes-relance-get-json';
import { demandesMesDemandesRelanceGet$Plain } from '../fn/demandes/demandes-mes-demandes-relance-get-plain';
import { DemandesMesDemandesRelanceGet$Plain$Params } from '../fn/demandes/demandes-mes-demandes-relance-get-plain';
import { demandesPost$Any } from '../fn/demandes/demandes-post-any';
import { DemandesPost$Any$Params } from '../fn/demandes/demandes-post-any';
import { demandesPost$Json } from '../fn/demandes/demandes-post-json';
import { DemandesPost$Json$Params } from '../fn/demandes/demandes-post-json';
import { demandesPost$Plain } from '../fn/demandes/demandes-post-plain';
import { DemandesPost$Plain$Params } from '../fn/demandes/demandes-post-plain';

@Injectable({ providedIn: 'root' })
export class DemandesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `demandesPost()` */
  static readonly DemandesPostPath = '/demandes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  demandesPost$Json$Response(params?: DemandesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return demandesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  demandesPost$Json(params?: DemandesPost$Json$Params, context?: HttpContext): Observable<string> {
    return this.demandesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  demandesPost$Plain$Response(params?: DemandesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return demandesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  demandesPost$Plain(params?: DemandesPost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.demandesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  demandesPost$Any$Response(params?: DemandesPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return demandesPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  demandesPost$Any(params?: DemandesPost$Any$Params, context?: HttpContext): Observable<string> {
    return this.demandesPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `demandesMesDemandesGet()` */
  static readonly DemandesMesDemandesGetPath = '/demandes/mes-demandes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesGet$Json$Response(params?: DemandesMesDemandesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesGet$Json(params?: DemandesMesDemandesGet$Json$Params, context?: HttpContext): Observable<DemandeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeDtoOutputForListOdataResponse>): DemandeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesGet$Plain$Response(params?: DemandesMesDemandesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesGet$Plain(params?: DemandesMesDemandesGet$Plain$Params, context?: HttpContext): Observable<DemandeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeDtoOutputForListOdataResponse>): DemandeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesGet$Any$Response(params?: DemandesMesDemandesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesGet$Any(params?: DemandesMesDemandesGet$Any$Params, context?: HttpContext): Observable<DemandeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeDtoOutputForListOdataResponse>): DemandeDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `demandesMesDemandesEnAttenteGet()` */
  static readonly DemandesMesDemandesEnAttenteGetPath = '/demandes/mes-demandes/en-attente';

  /**
   * Liste de mes demandes en attentes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesEnAttenteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnAttenteGet$Json$Response(params?: DemandesMesDemandesEnAttenteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesEnAttenteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste de mes demandes en attentes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesEnAttenteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnAttenteGet$Json(params?: DemandesMesDemandesEnAttenteGet$Json$Params, context?: HttpContext): Observable<DemandeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesEnAttenteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeDtoOutputForListOdataResponse>): DemandeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Liste de mes demandes en attentes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesEnAttenteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnAttenteGet$Plain$Response(params?: DemandesMesDemandesEnAttenteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesEnAttenteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste de mes demandes en attentes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesEnAttenteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnAttenteGet$Plain(params?: DemandesMesDemandesEnAttenteGet$Plain$Params, context?: HttpContext): Observable<DemandeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesEnAttenteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeDtoOutputForListOdataResponse>): DemandeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Liste de mes demandes en attentes.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesEnAttenteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnAttenteGet$Any$Response(params?: DemandesMesDemandesEnAttenteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesEnAttenteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste de mes demandes en attentes.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesEnAttenteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnAttenteGet$Any(params?: DemandesMesDemandesEnAttenteGet$Any$Params, context?: HttpContext): Observable<DemandeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesEnAttenteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeDtoOutputForListOdataResponse>): DemandeDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `demandesMesDemandesEnCoursGet()` */
  static readonly DemandesMesDemandesEnCoursGetPath = '/demandes/mes-demandes/en-cours';

  /**
   * Liste des demandes en cours.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesEnCoursGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursGet$Json$Response(params?: DemandesMesDemandesEnCoursGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeEnCoursDtoOutputForListOdataResponse>> {
    return demandesMesDemandesEnCoursGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste des demandes en cours.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesEnCoursGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursGet$Json(params?: DemandesMesDemandesEnCoursGet$Json$Params, context?: HttpContext): Observable<DemandeEnCoursDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesEnCoursGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeEnCoursDtoOutputForListOdataResponse>): DemandeEnCoursDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Liste des demandes en cours.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesEnCoursGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursGet$Plain$Response(params?: DemandesMesDemandesEnCoursGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeEnCoursDtoOutputForListOdataResponse>> {
    return demandesMesDemandesEnCoursGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste des demandes en cours.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesEnCoursGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursGet$Plain(params?: DemandesMesDemandesEnCoursGet$Plain$Params, context?: HttpContext): Observable<DemandeEnCoursDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesEnCoursGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeEnCoursDtoOutputForListOdataResponse>): DemandeEnCoursDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Liste des demandes en cours.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesEnCoursGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursGet$Any$Response(params?: DemandesMesDemandesEnCoursGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeEnCoursDtoOutputForListOdataResponse>> {
    return demandesMesDemandesEnCoursGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste des demandes en cours.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesEnCoursGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursGet$Any(params?: DemandesMesDemandesEnCoursGet$Any$Params, context?: HttpContext): Observable<DemandeEnCoursDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesEnCoursGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeEnCoursDtoOutputForListOdataResponse>): DemandeEnCoursDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `demandesMesDemandesEnCoursIdDeclarerRecuPost()` */
  static readonly DemandesMesDemandesEnCoursIdDeclarerRecuPostPath = '/demandes/mes-demandes/en-cours/{id}/declarer-recu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesEnCoursIdDeclarerRecuPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursIdDeclarerRecuPost$Response(params: DemandesMesDemandesEnCoursIdDeclarerRecuPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return demandesMesDemandesEnCoursIdDeclarerRecuPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesEnCoursIdDeclarerRecuPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesEnCoursIdDeclarerRecuPost(params: DemandesMesDemandesEnCoursIdDeclarerRecuPost$Params, context?: HttpContext): Observable<void> {
    return this.demandesMesDemandesEnCoursIdDeclarerRecuPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `demandesMesDemandesArchiveesGet()` */
  static readonly DemandesMesDemandesArchiveesGetPath = '/demandes/mes-demandes/archivees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesArchiveesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesArchiveesGet$Json$Response(params?: DemandesMesDemandesArchiveesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesArchiveesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesArchiveesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesArchiveesGet$Json(params?: DemandesMesDemandesArchiveesGet$Json$Params, context?: HttpContext): Observable<DemandeArchiveeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesArchiveesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>): DemandeArchiveeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesArchiveesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesArchiveesGet$Plain$Response(params?: DemandesMesDemandesArchiveesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesArchiveesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesArchiveesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesArchiveesGet$Plain(params?: DemandesMesDemandesArchiveesGet$Plain$Params, context?: HttpContext): Observable<DemandeArchiveeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesArchiveesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>): DemandeArchiveeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesArchiveesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesArchiveesGet$Any$Response(params?: DemandesMesDemandesArchiveesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesArchiveesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesArchiveesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesArchiveesGet$Any(params?: DemandesMesDemandesArchiveesGet$Any$Params, context?: HttpContext): Observable<DemandeArchiveeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesArchiveesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>): DemandeArchiveeDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `demandesMesDemandesRelanceGet()` */
  static readonly DemandesMesDemandesRelanceGetPath = '/demandes/mes-demandes/relance';

  /**
   * Si une demande est annulé en cors de transaction, les demandes des autres utilisateurs peuvent être recréés en un clic.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesRelanceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesRelanceGet$Json$Response(params?: DemandesMesDemandesRelanceGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesRelanceGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Si une demande est annulé en cors de transaction, les demandes des autres utilisateurs peuvent être recréés en un clic.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesRelanceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesRelanceGet$Json(params?: DemandesMesDemandesRelanceGet$Json$Params, context?: HttpContext): Observable<DemandeArchiveeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesRelanceGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>): DemandeArchiveeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Si une demande est annulé en cors de transaction, les demandes des autres utilisateurs peuvent être recréés en un clic.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesRelanceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesRelanceGet$Plain$Response(params?: DemandesMesDemandesRelanceGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesRelanceGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Si une demande est annulé en cors de transaction, les demandes des autres utilisateurs peuvent être recréés en un clic.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesRelanceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesRelanceGet$Plain(params?: DemandesMesDemandesRelanceGet$Plain$Params, context?: HttpContext): Observable<DemandeArchiveeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesRelanceGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>): DemandeArchiveeDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Si une demande est annulé en cors de transaction, les demandes des autres utilisateurs peuvent être recréés en un clic.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesRelanceGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesRelanceGet$Any$Response(params?: DemandesMesDemandesRelanceGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>> {
    return demandesMesDemandesRelanceGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Si une demande est annulé en cors de transaction, les demandes des autres utilisateurs peuvent être recréés en un clic.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesRelanceGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesRelanceGet$Any(params?: DemandesMesDemandesRelanceGet$Any$Params, context?: HttpContext): Observable<DemandeArchiveeDtoOutputForListOdataResponse> {
    return this.demandesMesDemandesRelanceGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<DemandeArchiveeDtoOutputForListOdataResponse>): DemandeArchiveeDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `demandesMesDemandesIdDelete()` */
  static readonly DemandesMesDemandesIdDeletePath = '/demandes/mes-demandes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `demandesMesDemandesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesIdDelete$Response(params: DemandesMesDemandesIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return demandesMesDemandesIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `demandesMesDemandesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  demandesMesDemandesIdDelete(params: DemandesMesDemandesIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.demandesMesDemandesIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
