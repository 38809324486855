/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CommuneDtoOutputForListOdataResponse } from '../models/commune-dto-output-for-list-odata-response';
import { departementCommuneRegionDepartementCommunesGet$Any } from '../fn/departement-commune-region/departement-commune-region-departement-communes-get-any';
import { DepartementCommuneRegionDepartementCommunesGet$Any$Params } from '../fn/departement-commune-region/departement-commune-region-departement-communes-get-any';
import { departementCommuneRegionDepartementCommunesGet$Json } from '../fn/departement-commune-region/departement-commune-region-departement-communes-get-json';
import { DepartementCommuneRegionDepartementCommunesGet$Json$Params } from '../fn/departement-commune-region/departement-commune-region-departement-communes-get-json';
import { departementCommuneRegionDepartementCommunesGet$Plain } from '../fn/departement-commune-region/departement-commune-region-departement-communes-get-plain';
import { DepartementCommuneRegionDepartementCommunesGet$Plain$Params } from '../fn/departement-commune-region/departement-commune-region-departement-communes-get-plain';
import { departementCommuneRegionRegionsDepartementsGet$Any } from '../fn/departement-commune-region/departement-commune-region-regions-departements-get-any';
import { DepartementCommuneRegionRegionsDepartementsGet$Any$Params } from '../fn/departement-commune-region/departement-commune-region-regions-departements-get-any';
import { departementCommuneRegionRegionsDepartementsGet$Json } from '../fn/departement-commune-region/departement-commune-region-regions-departements-get-json';
import { DepartementCommuneRegionRegionsDepartementsGet$Json$Params } from '../fn/departement-commune-region/departement-commune-region-regions-departements-get-json';
import { departementCommuneRegionRegionsDepartementsGet$Plain } from '../fn/departement-commune-region/departement-commune-region-regions-departements-get-plain';
import { DepartementCommuneRegionRegionsDepartementsGet$Plain$Params } from '../fn/departement-commune-region/departement-commune-region-regions-departements-get-plain';
import { RegionDtoOutputWithDepartements } from '../models/region-dto-output-with-departements';

@Injectable({ providedIn: 'root' })
export class DepartementCommuneRegionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `departementCommuneRegionRegionsDepartementsGet()` */
  static readonly DepartementCommuneRegionRegionsDepartementsGetPath = '/departement-commune-region/regions-departements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departementCommuneRegionRegionsDepartementsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionRegionsDepartementsGet$Json$Response(params?: DepartementCommuneRegionRegionsDepartementsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegionDtoOutputWithDepartements>>> {
    return departementCommuneRegionRegionsDepartementsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `departementCommuneRegionRegionsDepartementsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionRegionsDepartementsGet$Json(params?: DepartementCommuneRegionRegionsDepartementsGet$Json$Params, context?: HttpContext): Observable<Array<RegionDtoOutputWithDepartements>> {
    return this.departementCommuneRegionRegionsDepartementsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegionDtoOutputWithDepartements>>): Array<RegionDtoOutputWithDepartements> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departementCommuneRegionRegionsDepartementsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionRegionsDepartementsGet$Plain$Response(params?: DepartementCommuneRegionRegionsDepartementsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegionDtoOutputWithDepartements>>> {
    return departementCommuneRegionRegionsDepartementsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `departementCommuneRegionRegionsDepartementsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionRegionsDepartementsGet$Plain(params?: DepartementCommuneRegionRegionsDepartementsGet$Plain$Params, context?: HttpContext): Observable<Array<RegionDtoOutputWithDepartements>> {
    return this.departementCommuneRegionRegionsDepartementsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegionDtoOutputWithDepartements>>): Array<RegionDtoOutputWithDepartements> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departementCommuneRegionRegionsDepartementsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionRegionsDepartementsGet$Any$Response(params?: DepartementCommuneRegionRegionsDepartementsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegionDtoOutputWithDepartements>>> {
    return departementCommuneRegionRegionsDepartementsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `departementCommuneRegionRegionsDepartementsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionRegionsDepartementsGet$Any(params?: DepartementCommuneRegionRegionsDepartementsGet$Any$Params, context?: HttpContext): Observable<Array<RegionDtoOutputWithDepartements>> {
    return this.departementCommuneRegionRegionsDepartementsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegionDtoOutputWithDepartements>>): Array<RegionDtoOutputWithDepartements> => r.body)
    );
  }

  /** Path part for operation `departementCommuneRegionDepartementCommunesGet()` */
  static readonly DepartementCommuneRegionDepartementCommunesGetPath = '/departement-commune-region/departement-communes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departementCommuneRegionDepartementCommunesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionDepartementCommunesGet$Json$Response(params?: DepartementCommuneRegionDepartementCommunesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CommuneDtoOutputForListOdataResponse>> {
    return departementCommuneRegionDepartementCommunesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `departementCommuneRegionDepartementCommunesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionDepartementCommunesGet$Json(params?: DepartementCommuneRegionDepartementCommunesGet$Json$Params, context?: HttpContext): Observable<CommuneDtoOutputForListOdataResponse> {
    return this.departementCommuneRegionDepartementCommunesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommuneDtoOutputForListOdataResponse>): CommuneDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departementCommuneRegionDepartementCommunesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionDepartementCommunesGet$Plain$Response(params?: DepartementCommuneRegionDepartementCommunesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CommuneDtoOutputForListOdataResponse>> {
    return departementCommuneRegionDepartementCommunesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `departementCommuneRegionDepartementCommunesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionDepartementCommunesGet$Plain(params?: DepartementCommuneRegionDepartementCommunesGet$Plain$Params, context?: HttpContext): Observable<CommuneDtoOutputForListOdataResponse> {
    return this.departementCommuneRegionDepartementCommunesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommuneDtoOutputForListOdataResponse>): CommuneDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `departementCommuneRegionDepartementCommunesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionDepartementCommunesGet$Any$Response(params?: DepartementCommuneRegionDepartementCommunesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<CommuneDtoOutputForListOdataResponse>> {
    return departementCommuneRegionDepartementCommunesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `departementCommuneRegionDepartementCommunesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  departementCommuneRegionDepartementCommunesGet$Any(params?: DepartementCommuneRegionDepartementCommunesGet$Any$Params, context?: HttpContext): Observable<CommuneDtoOutputForListOdataResponse> {
    return this.departementCommuneRegionDepartementCommunesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<CommuneDtoOutputForListOdataResponse>): CommuneDtoOutputForListOdataResponse => r.body)
    );
  }

}
