<div class="flex flex-col" [ngClass]="label ? 'gap-2' : ''">
    <mat-label class="text-primary font-bold whitespace-nowrap">{{label}}</mat-label>
    @if(editMode){
    <mat-form-field appearance="fill">
        <input matInput [matDatepicker]="picker" [formControl]="formControl" (dateChange)="onDateChange($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    @if(datetime){
    <div class="flex gap-2">
        <app-edit-select-field label="Heure"  [IsEditMode]="editMode" [formControl]="hoursControl"
            [options]="hoursOptions"></app-edit-select-field>
        <app-edit-select-field label="Minutes" [IsEditMode]="editMode" [formControl]="minutesControl"
            [options]="minutesOptions"></app-edit-select-field>
    </div>
    }
    }
    @else {
    <p [ngClass]="nowrap ? 'whitespace-nowrap' : ''" class="text-sm">{{ momentToDate(formControl.value) | date: "dd/MM/YY 'à' HH'h'mm"
        }}</p>
    }
</div>