<div class="flex flex-col relative items-center justify-center p-4 rounded-xl border-dashed "
[ngClass]="{'cursor-pointer': IsEditMode}">
  <div>
    @if(imagePreview()){
      <div>
        <img [src]="imagePreview()" alt="Image Preview" class="image-preview" />
        @if(IsEditMode){
          <mat-icon class="delete-icon absolute top-1 right-1" (click)="removeImage($event)">delete</mat-icon>
        }
      </div>
    }
    @else {
      @if(IsEditMode){
        <div class="flex flex-col items-center"  role="presentation" (click)="fileInput.click()">
          <mat-icon>upload</mat-icon>
          <p>Ajouter une image</p>
        </div>
      }
      @else {
        <p>Aucune image</p>
      }
    }
  </div>
  <input type="file" accept="image/*" (change)="onFileChange($event)" hidden #fileInput />
</div>