<mat-label class="text-primary font-bold whitespace-nowrap">{{label}}</mat-label>

<div class="details-offre flex bg-white p-6 max-w-[60%] justify-between">
    <div class="flex w-full flex-col gap-3">
        @if (!!offreDetails && !!offreDetails.nom){
        <h2 class="custom-color-text text-lg font-bold">{{offreDetails.nom}}</h2>
        }

        @if (!!offreDetails && !!offreDetails.reference){
        <p class="text-primary"><em>Référence : </em>{{offreDetails.reference}}</p>
        }

        @if (!!offreDetails && !!offreDetails.etatArticle){
        <p><em>Etat : </em>{{offreDetails.etatArticle.nom}}</p>
        }

        @if (!!offreDetails && !!offreDetails.taille){
        <p><em>Taille : </em>{{offreDetails.taille}}</p>
        }

        @if (!!offreDetails && !!offreDetails.conformation){
        <p><em>Conformation : </em>{{offreDetails.conformation}}</p>
        }

        @if (!!offreDetails && !!offreDetails.description){
        <p><em>Description : </em>{{offreDetails.description}}</p>
        }

        @if (!!offreDetails && !!offreDetails.creeeLe){
        <p><em>Offre postée à {{offreDetails.creeeLe | date: 'HH:mm le dd/MM/YY'}}</em></p>
        }

        @if (!!offreDetails && !!offreDetails.estArchivee){
        <p><em>L'offre est archivée.</em></p>
        }

    </div>

    @if (!!offreDetails && !!offreDetails.imagesURls && offreDetails.imagesURls.length > 0){
    <div class="flex flex-col h-full w-full md:w-1/2 lg:w-2/3 xl:w-3/4 gap-6">
        <dynamic-photo-grid-list [offre]="offreDetails"></dynamic-photo-grid-list>
    </div>
    }
</div>