<div class="flex flex-col gap-2">
    <mat-label class="text-primary font-bold whitespace-nowrap">{{label}}</mat-label>

    @if(IsEditMode && !IsReadOnly){
    <mat-form-field class="w-full">
        <input type="text" matInput [formControl]="autocompleteFormControl" placeholder="Sélectionner {{errorName}}"
            [readonly]="IsDisabled" [matAutocomplete]="auto" (keydown.enter)="onEnterKey($event)">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            @for(option of filteredOptions(); track $index){
            <mat-option [value]="option.id" [disabled]="IsDisabled">{{option.id}}</mat-option>
            }
        </mat-autocomplete>
    </mat-form-field>

    <mat-chip-set aria-label="selection">
        @for (chip of formControl.value; track $index) {
        <mat-chip (removed)="remove(chip)">
            <span class="text-lg">
                {{chip.id}}
            </span>
            <button matChipRemove [attr.aria-label]="'remove ' + chip.id">
                <mat-icon>close</mat-icon>
            </button>
        </mat-chip>
        }
    </mat-chip-set>

    @if(!formControl.valid && formControl.touched){
    <mat-error>
        Veuillez sélectionner au moins {{errorName}}.
    </mat-error>
    }
    } @else {
    <p class="text-sm whitespace-nowrap">
        {{ getFormattedValue() }}
    </p>
    }

</div>