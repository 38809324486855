import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DetailsArticleCarouselComponent } from '../details-article-carousel/details-article-carousel.component';

@Component({
    selector: 'app-article-carrousel-popup',
    standalone: true,
    templateUrl: './article-carrousel-popup.component.html',
    styleUrl: './article-carrousel-popup.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DetailsArticleCarouselComponent],
})
export class ArticleCarrouselPopupComponent {
    currentIndex = 0;
    imageList: string[] = [];

    constructor(
        public dialogRef: MatDialogRef<ArticleCarrouselPopupComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { images: string[]; index: number },
    ) {
        this.imageList = data.images;
        this.currentIndex = data.index;
    }

    close() {
        this.dialogRef.close();
    }
}
