<div class="w-full h-full">
  <div class="flex flex-col bg-white h-full rounded-xl overflow-y-scroll divide-y scrollbar-visible">
    @for (conv of conversations; track $index) {
    <div role="presentation" (click)="conversationSelected(conv)"
      class="flex gap-8 py-6 px-8 items-center divide-x-0 cursor-pointer hover:bg-tertiary hover:text-white"
      [ngClass]="isSelected(conv) ? 'bg-tertiary':''">
      <mat-icon class="bg-[#F2F2F2] flex items-center rounded-full text-[#AEAEAE] custom-icon">person</mat-icon>
      <div class="flex gap-2 flex-col">
        <p class="text-[14px] flex items-center gap-4"
          [ngClass]="[isSelected(conv) ? 'text-white' : '', isRead(conv) ? 'font-bold' : 'font-medium']">
          {{ getContactName(conv.contact) }}
          @if(conv.messages && isRead(conv)) {
          <span class="rounded-full text-[10px] bg-tertiary w-2 h-2"
            [ngClass]="isSelected(conv) ? 'bg-white':'bg-tertiary'"></span>
          }
        </p>
        @if(conv.messages){
        <p class="font-light text-[14px] flex items-center gap-4 max-content max-w-2/4"
          [ngClass]="[isSelected(conv) ? 'text-white' : '', isRead(conv)  ? 'font-medium' : 'font-light']">
          @if (conv.messages && conv.messages[0] && conv.messages[0].envoyeParMoi) {
          <mat-icon [ngClass]="isSelected(conv) ? 'text-white':''">reply</mat-icon>
          }
          {{ getTrimedMessage(conv.messages[0].message) }}
        </p>
        }
        @else {
        <p class="font-light text-[14px] flex items-center gap-4">Nouvelle conversation</p>
        }
      </div>
    </div>
    }

    @if (!conversations.length) {
    <div class="flex items-center justify-center h-40">
      <p class="text-[14px]">Aucune conversation</p>
    </div>
    }
  </div>