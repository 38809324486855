/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ConversationAvecAdminDtoOutputForDetail } from '../models/conversation-avec-admin-dto-output-for-detail';
import { ConversationAvecAdminDtoOutputWithLastMessageForList } from '../models/conversation-avec-admin-dto-output-with-last-message-for-list';
import { ConversationDtoOutputForDetail } from '../models/conversation-dto-output-for-detail';
import { ConversationDtoOutputWithLastMessageForList } from '../models/conversation-dto-output-with-last-message-for-list';
import { messagerieMesMessagesAdminContacterPost } from '../fn/messagerie/messagerie-mes-messages-admin-contacter-post';
import { MessagerieMesMessagesAdminContacterPost$Params } from '../fn/messagerie/messagerie-mes-messages-admin-contacter-post';
import { messagerieMesMessagesAdminConversationGet$Any } from '../fn/messagerie/messagerie-mes-messages-admin-conversation-get-any';
import { MessagerieMesMessagesAdminConversationGet$Any$Params } from '../fn/messagerie/messagerie-mes-messages-admin-conversation-get-any';
import { messagerieMesMessagesAdminConversationGet$Json } from '../fn/messagerie/messagerie-mes-messages-admin-conversation-get-json';
import { MessagerieMesMessagesAdminConversationGet$Json$Params } from '../fn/messagerie/messagerie-mes-messages-admin-conversation-get-json';
import { messagerieMesMessagesAdminConversationGet$Plain } from '../fn/messagerie/messagerie-mes-messages-admin-conversation-get-plain';
import { MessagerieMesMessagesAdminConversationGet$Plain$Params } from '../fn/messagerie/messagerie-mes-messages-admin-conversation-get-plain';
import { messagerieMesMessagesAdminMurDesConversationsGet$Any } from '../fn/messagerie/messagerie-mes-messages-admin-mur-des-conversations-get-any';
import { MessagerieMesMessagesAdminMurDesConversationsGet$Any$Params } from '../fn/messagerie/messagerie-mes-messages-admin-mur-des-conversations-get-any';
import { messagerieMesMessagesAdminMurDesConversationsGet$Json } from '../fn/messagerie/messagerie-mes-messages-admin-mur-des-conversations-get-json';
import { MessagerieMesMessagesAdminMurDesConversationsGet$Json$Params } from '../fn/messagerie/messagerie-mes-messages-admin-mur-des-conversations-get-json';
import { messagerieMesMessagesAdminMurDesConversationsGet$Plain } from '../fn/messagerie/messagerie-mes-messages-admin-mur-des-conversations-get-plain';
import { MessagerieMesMessagesAdminMurDesConversationsGet$Plain$Params } from '../fn/messagerie/messagerie-mes-messages-admin-mur-des-conversations-get-plain';
import { messagerieMesMessagesConversationGet$Any } from '../fn/messagerie/messagerie-mes-messages-conversation-get-any';
import { MessagerieMesMessagesConversationGet$Any$Params } from '../fn/messagerie/messagerie-mes-messages-conversation-get-any';
import { messagerieMesMessagesConversationGet$Json } from '../fn/messagerie/messagerie-mes-messages-conversation-get-json';
import { MessagerieMesMessagesConversationGet$Json$Params } from '../fn/messagerie/messagerie-mes-messages-conversation-get-json';
import { messagerieMesMessagesConversationGet$Plain } from '../fn/messagerie/messagerie-mes-messages-conversation-get-plain';
import { MessagerieMesMessagesConversationGet$Plain$Params } from '../fn/messagerie/messagerie-mes-messages-conversation-get-plain';
import { messagerieMesMessagesMurDesConversationsGet$Any } from '../fn/messagerie/messagerie-mes-messages-mur-des-conversations-get-any';
import { MessagerieMesMessagesMurDesConversationsGet$Any$Params } from '../fn/messagerie/messagerie-mes-messages-mur-des-conversations-get-any';
import { messagerieMesMessagesMurDesConversationsGet$Json } from '../fn/messagerie/messagerie-mes-messages-mur-des-conversations-get-json';
import { MessagerieMesMessagesMurDesConversationsGet$Json$Params } from '../fn/messagerie/messagerie-mes-messages-mur-des-conversations-get-json';
import { messagerieMesMessagesMurDesConversationsGet$Plain } from '../fn/messagerie/messagerie-mes-messages-mur-des-conversations-get-plain';
import { MessagerieMesMessagesMurDesConversationsGet$Plain$Params } from '../fn/messagerie/messagerie-mes-messages-mur-des-conversations-get-plain';
import { messagerieMesMessagesNonLuesCompteGet$Any } from '../fn/messagerie/messagerie-mes-messages-non-lues-compte-get-any';
import { MessagerieMesMessagesNonLuesCompteGet$Any$Params } from '../fn/messagerie/messagerie-mes-messages-non-lues-compte-get-any';
import { messagerieMesMessagesNonLuesCompteGet$Json } from '../fn/messagerie/messagerie-mes-messages-non-lues-compte-get-json';
import { MessagerieMesMessagesNonLuesCompteGet$Json$Params } from '../fn/messagerie/messagerie-mes-messages-non-lues-compte-get-json';
import { messagerieMesMessagesNonLuesCompteGet$Plain } from '../fn/messagerie/messagerie-mes-messages-non-lues-compte-get-plain';
import { MessagerieMesMessagesNonLuesCompteGet$Plain$Params } from '../fn/messagerie/messagerie-mes-messages-non-lues-compte-get-plain';
import { messagerieOffresIdContacterPost } from '../fn/messagerie/messagerie-offres-id-contacter-post';
import { MessagerieOffresIdContacterPost$Params } from '../fn/messagerie/messagerie-offres-id-contacter-post';
import { messagerieSignalerPost } from '../fn/messagerie/messagerie-signaler-post';
import { MessagerieSignalerPost$Params } from '../fn/messagerie/messagerie-signaler-post';
import { messagerieTypeDeSignalementsGet$Any } from '../fn/messagerie/messagerie-type-de-signalements-get-any';
import { MessagerieTypeDeSignalementsGet$Any$Params } from '../fn/messagerie/messagerie-type-de-signalements-get-any';
import { messagerieTypeDeSignalementsGet$Json } from '../fn/messagerie/messagerie-type-de-signalements-get-json';
import { MessagerieTypeDeSignalementsGet$Json$Params } from '../fn/messagerie/messagerie-type-de-signalements-get-json';
import { messagerieTypeDeSignalementsGet$Plain } from '../fn/messagerie/messagerie-type-de-signalements-get-plain';
import { MessagerieTypeDeSignalementsGet$Plain$Params } from '../fn/messagerie/messagerie-type-de-signalements-get-plain';
import { SignalementTypeDtoOuput } from '../models/signalement-type-dto-ouput';

@Injectable({ providedIn: 'root' })
export class MessagerieService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `messagerieOffresIdContacterPost()` */
  static readonly MessagerieOffresIdContacterPostPath = '/messagerie/offres/{id}/contacter';

  /**
   * On peux contacter une personne via son offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieOffresIdContacterPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieOffresIdContacterPost$Response(params: MessagerieOffresIdContacterPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return messagerieOffresIdContacterPost(this.http, this.rootUrl, params, context);
  }

  /**
   * On peux contacter une personne via son offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieOffresIdContacterPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieOffresIdContacterPost(params: MessagerieOffresIdContacterPost$Params, context?: HttpContext): Observable<void> {
    return this.messagerieOffresIdContacterPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `messagerieMesMessagesNonLuesCompteGet()` */
  static readonly MessagerieMesMessagesNonLuesCompteGetPath = '/messagerie/mes-messages/non-lues/compte';

  /**
   * Somme des messages non lue avec les autres utilisateur + avec les admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesNonLuesCompteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesNonLuesCompteGet$Json$Response(params?: MessagerieMesMessagesNonLuesCompteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return messagerieMesMessagesNonLuesCompteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Somme des messages non lue avec les autres utilisateur + avec les admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesNonLuesCompteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesNonLuesCompteGet$Json(params?: MessagerieMesMessagesNonLuesCompteGet$Json$Params, context?: HttpContext): Observable<number> {
    return this.messagerieMesMessagesNonLuesCompteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Somme des messages non lue avec les autres utilisateur + avec les admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesNonLuesCompteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesNonLuesCompteGet$Plain$Response(params?: MessagerieMesMessagesNonLuesCompteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return messagerieMesMessagesNonLuesCompteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Somme des messages non lue avec les autres utilisateur + avec les admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesNonLuesCompteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesNonLuesCompteGet$Plain(params?: MessagerieMesMessagesNonLuesCompteGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.messagerieMesMessagesNonLuesCompteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * Somme des messages non lue avec les autres utilisateur + avec les admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesNonLuesCompteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesNonLuesCompteGet$Any$Response(params?: MessagerieMesMessagesNonLuesCompteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return messagerieMesMessagesNonLuesCompteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Somme des messages non lue avec les autres utilisateur + avec les admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesNonLuesCompteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesNonLuesCompteGet$Any(params?: MessagerieMesMessagesNonLuesCompteGet$Any$Params, context?: HttpContext): Observable<number> {
    return this.messagerieMesMessagesNonLuesCompteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `messagerieMesMessagesMurDesConversationsGet()` */
  static readonly MessagerieMesMessagesMurDesConversationsGetPath = '/messagerie/mes-messages/mur-des-conversations';

  /**
   * Obtenir le mur des conversations. Toute les conversations groupées par offre + duo (auteur-destinataire) avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesMurDesConversationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesMurDesConversationsGet$Json$Response(params?: MessagerieMesMessagesMurDesConversationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationDtoOutputWithLastMessageForList>>> {
    return messagerieMesMessagesMurDesConversationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le mur des conversations. Toute les conversations groupées par offre + duo (auteur-destinataire) avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesMurDesConversationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesMurDesConversationsGet$Json(params?: MessagerieMesMessagesMurDesConversationsGet$Json$Params, context?: HttpContext): Observable<Array<ConversationDtoOutputWithLastMessageForList>> {
    return this.messagerieMesMessagesMurDesConversationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationDtoOutputWithLastMessageForList>>): Array<ConversationDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /**
   * Obtenir le mur des conversations. Toute les conversations groupées par offre + duo (auteur-destinataire) avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesMurDesConversationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesMurDesConversationsGet$Plain$Response(params?: MessagerieMesMessagesMurDesConversationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationDtoOutputWithLastMessageForList>>> {
    return messagerieMesMessagesMurDesConversationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le mur des conversations. Toute les conversations groupées par offre + duo (auteur-destinataire) avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesMurDesConversationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesMurDesConversationsGet$Plain(params?: MessagerieMesMessagesMurDesConversationsGet$Plain$Params, context?: HttpContext): Observable<Array<ConversationDtoOutputWithLastMessageForList>> {
    return this.messagerieMesMessagesMurDesConversationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationDtoOutputWithLastMessageForList>>): Array<ConversationDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /**
   * Obtenir le mur des conversations. Toute les conversations groupées par offre + duo (auteur-destinataire) avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesMurDesConversationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesMurDesConversationsGet$Any$Response(params?: MessagerieMesMessagesMurDesConversationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationDtoOutputWithLastMessageForList>>> {
    return messagerieMesMessagesMurDesConversationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le mur des conversations. Toute les conversations groupées par offre + duo (auteur-destinataire) avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesMurDesConversationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesMurDesConversationsGet$Any(params?: MessagerieMesMessagesMurDesConversationsGet$Any$Params, context?: HttpContext): Observable<Array<ConversationDtoOutputWithLastMessageForList>> {
    return this.messagerieMesMessagesMurDesConversationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationDtoOutputWithLastMessageForList>>): Array<ConversationDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /** Path part for operation `messagerieMesMessagesAdminMurDesConversationsGet()` */
  static readonly MessagerieMesMessagesAdminMurDesConversationsGetPath = '/messagerie/mes-messages/admin/mur-des-conversations';

  /**
   * Obtenir le mur des conversations avec les admin avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesAdminMurDesConversationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminMurDesConversationsGet$Json$Response(params?: MessagerieMesMessagesAdminMurDesConversationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>> {
    return messagerieMesMessagesAdminMurDesConversationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le mur des conversations avec les admin avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesAdminMurDesConversationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminMurDesConversationsGet$Json(params?: MessagerieMesMessagesAdminMurDesConversationsGet$Json$Params, context?: HttpContext): Observable<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>> {
    return this.messagerieMesMessagesAdminMurDesConversationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>): Array<ConversationAvecAdminDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /**
   * Obtenir le mur des conversations avec les admin avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesAdminMurDesConversationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminMurDesConversationsGet$Plain$Response(params?: MessagerieMesMessagesAdminMurDesConversationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>> {
    return messagerieMesMessagesAdminMurDesConversationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le mur des conversations avec les admin avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesAdminMurDesConversationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminMurDesConversationsGet$Plain(params?: MessagerieMesMessagesAdminMurDesConversationsGet$Plain$Params, context?: HttpContext): Observable<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>> {
    return this.messagerieMesMessagesAdminMurDesConversationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>): Array<ConversationAvecAdminDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /**
   * Obtenir le mur des conversations avec les admin avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesAdminMurDesConversationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminMurDesConversationsGet$Any$Response(params?: MessagerieMesMessagesAdminMurDesConversationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>> {
    return messagerieMesMessagesAdminMurDesConversationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le mur des conversations avec les admin avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesAdminMurDesConversationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminMurDesConversationsGet$Any(params?: MessagerieMesMessagesAdminMurDesConversationsGet$Any$Params, context?: HttpContext): Observable<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>> {
    return this.messagerieMesMessagesAdminMurDesConversationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>): Array<ConversationAvecAdminDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /** Path part for operation `messagerieMesMessagesConversationGet()` */
  static readonly MessagerieMesMessagesConversationGetPath = '/messagerie/mes-messages/conversation';

  /**
   * Obtenir le détail d'une conversation avec un utilisateur au sein d'une offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesConversationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesConversationGet$Json$Response(params?: MessagerieMesMessagesConversationGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationDtoOutputForDetail>> {
    return messagerieMesMessagesConversationGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le détail d'une conversation avec un utilisateur au sein d'une offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesConversationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesConversationGet$Json(params?: MessagerieMesMessagesConversationGet$Json$Params, context?: HttpContext): Observable<ConversationDtoOutputForDetail> {
    return this.messagerieMesMessagesConversationGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationDtoOutputForDetail>): ConversationDtoOutputForDetail => r.body)
    );
  }

  /**
   * Obtenir le détail d'une conversation avec un utilisateur au sein d'une offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesConversationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesConversationGet$Plain$Response(params?: MessagerieMesMessagesConversationGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationDtoOutputForDetail>> {
    return messagerieMesMessagesConversationGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le détail d'une conversation avec un utilisateur au sein d'une offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesConversationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesConversationGet$Plain(params?: MessagerieMesMessagesConversationGet$Plain$Params, context?: HttpContext): Observable<ConversationDtoOutputForDetail> {
    return this.messagerieMesMessagesConversationGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationDtoOutputForDetail>): ConversationDtoOutputForDetail => r.body)
    );
  }

  /**
   * Obtenir le détail d'une conversation avec un utilisateur au sein d'une offre.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesConversationGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesConversationGet$Any$Response(params?: MessagerieMesMessagesConversationGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationDtoOutputForDetail>> {
    return messagerieMesMessagesConversationGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le détail d'une conversation avec un utilisateur au sein d'une offre.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesConversationGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesConversationGet$Any(params?: MessagerieMesMessagesConversationGet$Any$Params, context?: HttpContext): Observable<ConversationDtoOutputForDetail> {
    return this.messagerieMesMessagesConversationGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationDtoOutputForDetail>): ConversationDtoOutputForDetail => r.body)
    );
  }

  /** Path part for operation `messagerieMesMessagesAdminConversationGet()` */
  static readonly MessagerieMesMessagesAdminConversationGetPath = '/messagerie/mes-messages/admin/conversation';

  /**
   * Obtenir le détail de la conversation avec les admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesAdminConversationGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminConversationGet$Json$Response(params?: MessagerieMesMessagesAdminConversationGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>> {
    return messagerieMesMessagesAdminConversationGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le détail de la conversation avec les admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesAdminConversationGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminConversationGet$Json(params?: MessagerieMesMessagesAdminConversationGet$Json$Params, context?: HttpContext): Observable<ConversationAvecAdminDtoOutputForDetail> {
    return this.messagerieMesMessagesAdminConversationGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>): ConversationAvecAdminDtoOutputForDetail => r.body)
    );
  }

  /**
   * Obtenir le détail de la conversation avec les admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesAdminConversationGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminConversationGet$Plain$Response(params?: MessagerieMesMessagesAdminConversationGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>> {
    return messagerieMesMessagesAdminConversationGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le détail de la conversation avec les admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesAdminConversationGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminConversationGet$Plain(params?: MessagerieMesMessagesAdminConversationGet$Plain$Params, context?: HttpContext): Observable<ConversationAvecAdminDtoOutputForDetail> {
    return this.messagerieMesMessagesAdminConversationGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>): ConversationAvecAdminDtoOutputForDetail => r.body)
    );
  }

  /**
   * Obtenir le détail de la conversation avec les admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesAdminConversationGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminConversationGet$Any$Response(params?: MessagerieMesMessagesAdminConversationGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>> {
    return messagerieMesMessagesAdminConversationGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Obtenir le détail de la conversation avec les admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesAdminConversationGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieMesMessagesAdminConversationGet$Any(params?: MessagerieMesMessagesAdminConversationGet$Any$Params, context?: HttpContext): Observable<ConversationAvecAdminDtoOutputForDetail> {
    return this.messagerieMesMessagesAdminConversationGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>): ConversationAvecAdminDtoOutputForDetail => r.body)
    );
  }

  /** Path part for operation `messagerieMesMessagesAdminContacterPost()` */
  static readonly MessagerieMesMessagesAdminContacterPostPath = '/messagerie/mes-messages/admin/contacter';

  /**
   * Contact les admins.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieMesMessagesAdminContacterPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagerieMesMessagesAdminContacterPost$Response(params?: MessagerieMesMessagesAdminContacterPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return messagerieMesMessagesAdminContacterPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Contact les admins.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieMesMessagesAdminContacterPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagerieMesMessagesAdminContacterPost(params?: MessagerieMesMessagesAdminContacterPost$Params, context?: HttpContext): Observable<void> {
    return this.messagerieMesMessagesAdminContacterPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `messagerieSignalerPost()` */
  static readonly MessagerieSignalerPostPath = '/messagerie/signaler';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieSignalerPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagerieSignalerPost$Response(params?: MessagerieSignalerPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return messagerieSignalerPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieSignalerPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  messagerieSignalerPost(params?: MessagerieSignalerPost$Params, context?: HttpContext): Observable<void> {
    return this.messagerieSignalerPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `messagerieTypeDeSignalementsGet()` */
  static readonly MessagerieTypeDeSignalementsGetPath = '/messagerie/type-de-signalements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieTypeDeSignalementsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieTypeDeSignalementsGet$Json$Response(params?: MessagerieTypeDeSignalementsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SignalementTypeDtoOuput>>> {
    return messagerieTypeDeSignalementsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieTypeDeSignalementsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieTypeDeSignalementsGet$Json(params?: MessagerieTypeDeSignalementsGet$Json$Params, context?: HttpContext): Observable<Array<SignalementTypeDtoOuput>> {
    return this.messagerieTypeDeSignalementsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SignalementTypeDtoOuput>>): Array<SignalementTypeDtoOuput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieTypeDeSignalementsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieTypeDeSignalementsGet$Plain$Response(params?: MessagerieTypeDeSignalementsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SignalementTypeDtoOuput>>> {
    return messagerieTypeDeSignalementsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieTypeDeSignalementsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieTypeDeSignalementsGet$Plain(params?: MessagerieTypeDeSignalementsGet$Plain$Params, context?: HttpContext): Observable<Array<SignalementTypeDtoOuput>> {
    return this.messagerieTypeDeSignalementsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SignalementTypeDtoOuput>>): Array<SignalementTypeDtoOuput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `messagerieTypeDeSignalementsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieTypeDeSignalementsGet$Any$Response(params?: MessagerieTypeDeSignalementsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SignalementTypeDtoOuput>>> {
    return messagerieTypeDeSignalementsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `messagerieTypeDeSignalementsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  messagerieTypeDeSignalementsGet$Any(params?: MessagerieTypeDeSignalementsGet$Any$Params, context?: HttpContext): Observable<Array<SignalementTypeDtoOuput>> {
    return this.messagerieTypeDeSignalementsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SignalementTypeDtoOuput>>): Array<SignalementTypeDtoOuput> => r.body)
    );
  }

}
