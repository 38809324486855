<div class="relative flex w-screen h-[calc(100vh-80px)] gap-4 flex-col justify-center ">
    @if (convLoading) {
        <div class="absolute flex h-40 items-center justify-center lg:hidden">
            <mat-spinner diameter="32"></mat-spinner>
        </div>
    }
    @if (loading) {
        <div class="flex h-40 items-center justify-center">
            <mat-spinner diameter="32"></mat-spinner>
        </div>
    } @else {
        <div class="flex h-[90%] items-start justify-center gap-4">
        <app-messagerie-wall
            class="max-w-[20%] bg-white h-full rounded-xl flex-grow shadow-md"
            [conversations]="conversations"
            [selected]="selectedConversation"
            (selectedConversation)="openConversation($event)"
        />
        <app-messagerie-chat
            (typingEvent)="sendTypingInfo($event)"
            [userTyping]="currentUserTyping"
            (sendMessageEvent)="sendMessage($event)"
            (closeConversation)="closeConversation($event)"
            [loading]="convLoading"
            class="max-w-[70%] w-[70%] h-full bg-white rounded-xl flex-grow shadow-md"
            [conversation]="conversationContent"
            (return)="backToList()"
        />
    </div>
    }
</div>
