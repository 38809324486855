<div class="flex gap-4">
  <div>
    <mat-form-field class="w-40 mat-datepicker">
      <mat-icon matSuffix>expand_more</mat-icon>
      <mat-select [disabled]="disabled" [(ngModel)]="selectedRange" (valueChange)="updateRange($event, range)" name="food">
        @for (option of rangeOptions; track option) {
        <mat-option [value]="option.value">{{option.name}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <mat-date-range-input [disabled]="disabled" [formGroup]="range" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Début">
        <input matEndDate formControlName="end" (dateChange)="customRangeChange()" placeholder="Fin">
      </mat-date-range-input>
      <mat-datepicker-toggle [disabled]="disabled" matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker [disabled]="disabled" #picker></mat-date-range-picker>

    </mat-form-field>
  </div>
</div>