/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OffreDtoOutputForDetailAsUserPointOfView } from '../../models/offre-dto-output-for-detail-as-user-point-of-view';

export interface OffresOffreIdGet$Json$Params {
  offreId: string;
}

export function offresOffreIdGet$Json(http: HttpClient, rootUrl: string, params: OffresOffreIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>> {
  const rb = new RequestBuilder(rootUrl, offresOffreIdGet$Json.PATH, 'get');
  if (params) {
    rb.path('offreId', params.offreId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'text/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OffreDtoOutputForDetailAsUserPointOfView>;
    })
  );
}

offresOffreIdGet$Json.PATH = '/offres/{offreId}';
