<div class="flex flex-col gap-2">
    <mat-label class="text-primary font-bold whitespace-nowrap">{{label}}</mat-label>

    @if(IsEditMode && !IsReadOnly){
    <mat-form-field class="w-full">
        <input matInput [formControl]="formControl" placeholder="-">
    </mat-form-field>
    @if(!formControl.valid && formControl.touched){
    <mat-error>
        La saisie du champ n'est pas valide.
    </mat-error>
    }
    } @else {
    <p [ngClass]="nowrap ? 'whitespace-nowrap' : ''" class="text-sm">{{ formControl.value }}</p>
    }

</div>