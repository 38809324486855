import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { UserOutputForAdminDataGrid } from '@app/api/models';
import { GridApi, SortChangedEvent, FilterChangedEvent, GridReadyEvent } from 'ag-grid-community';
import { mapAgGridFiltersToOData } from '../aggrid-to-odatafilter';

type SortDirection = 'asc' | 'desc' | null;

export class FetchParamsDto {
    $top: number = 5; // Nombre d'enregistrements à récupérer
    $skip: number = 0; // Nombre d'enregistrements à sauter
    $filter?: string = undefined; // Chaîne de filtre OData
    $orderby?: string; // Chaîne de tri OData

    constructor(init?: Partial<FetchParamsDto>) {
        Object.assign(this, init);
    }
}

@Injectable({
    providedIn: 'root',
})
export class DataGridService {
    activeSort: { field: string; sort: SortDirection | undefined }[] = [];

    constructor() {}

    onSortChanged(event: SortChangedEvent<any, any>, fetchData: (fetchParams: FetchParamsDto) => void, fetchParams: FetchParamsDto, gridApi: GridApi<UserOutputForAdminDataGrid> | undefined) {
        this.activeSort =
            event.columns?.map((col) => ({
                field: col.getColId(),
                sort: col.getSort(),
            })) ?? [];

        // Mettre à jour les paramètres de recherche en fonction du tri actif
        fetchParams = this.updateFetchParams(fetchParams, gridApi);

        // Appel de la fonction fetchData et gestion des données asynchrones
        fetchData(fetchParams);
    }

    onFilterChanged(event: FilterChangedEvent<any, any>, fetchData: (fetchParams: FetchParamsDto) => void, fetchParams: FetchParamsDto, gridApi: GridApi<UserOutputForAdminDataGrid> | undefined) {
        if (event.columns) {
            const filters = event.api.getFilterModel();
            const oDataFilters = Object.entries(filters)
                .map(([key, filter]) => mapAgGridFiltersToOData(filter.type, filter.filter ?? new Date(filter.dateFrom).toISOString(), key, filter.filterType))
                .join(' and ');
            fetchParams.$filter = oDataFilters.length > 0 ? oDataFilters : undefined;
        } else {
            console.error('no columns found');
        }

        fetchParams = this.resetSort(fetchParams, gridApi);
        fetchData(fetchParams);
    }

    onPageSizeChange(event: PageEvent, rawData: any, fetchData: (fetchParams: FetchParamsDto) => void, fetchParams: FetchParamsDto) {
        if (fetchParams.$top !== event.pageSize) {
            fetchParams.$top = event.pageSize;
            fetchData(fetchParams);
        }

        if (rawData) {
            this.fetchPage(event.pageIndex > (event.previousPageIndex ?? -1) ? rawData.nextLink : rawData.previousLink, fetchData, fetchParams);
        } else {
            console.error('rowData is null');
        }
    }

    fetchPage(link: string | null, fetchData: (fetchParams: FetchParamsDto) => void, fetchParams: FetchParamsDto) {
        if (link) {
            const { searchParams } = new URL(link);
            const top = searchParams.get('top');
            const skip = searchParams.get('skip');

            if (top !== null && skip !== null) {
                fetchParams.$top = +top;
                fetchParams.$skip = +skip;
                fetchData(fetchParams);
            }
        } else {
            console.error('No link found');
        }
    }

    private updateFetchParams(fetchParams: FetchParamsDto, gridApi: GridApi<UserOutputForAdminDataGrid> | undefined): FetchParamsDto {
        if (this.activeSort.length) {
            fetchParams.$orderby = this.activeSort.map((s) => `${s.field} ${s.sort}`).join(',');
            return fetchParams;
        } else {
            return this.resetSort(fetchParams, gridApi);
        }
    }

    resetSort(fetchParams: FetchParamsDto, gridApi: GridApi<UserOutputForAdminDataGrid> | undefined): FetchParamsDto {
        fetchParams.$skip = 0;
        if (gridApi) {
            gridApi.paginationGoToFirstPage();
        }
        return fetchParams;
    }
}
