import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { NotificationDtoOutputForList, UserOutputForDetail } from '@app/api/models';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { NotificationsMesNotificationsGet$Json$Params } from '@app/api/fn/notifications/notifications-mes-notifications-get-json';
import { AccountsService, NotificationsService } from '@app/api/services';
import { NotifCardComponent } from '../notif-card/notif-card.component';
import { NotifsCron } from '@app/utils/notif-cron';
import { Subscription } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-header',
    standalone: true,
    imports: [MatToolbarModule, MatButtonModule, MatMenuModule, MatIconModule, MatFormFieldModule, MatInputModule, RouterLink, RouterLinkActive, NotifCardComponent],
    templateUrl: './header.component.html',
    styleUrl: './header.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
    user: UserOutputForDetail | null = this.getUser();
    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger | undefined;
    unreadNotificationsCount = 0;

    notifications: NotificationDtoOutputForList[] = [];
    private subscriptions: Subscription = new Subscription();

    constructor(
        private notifService: NotificationsService,
        private cdr: ChangeDetectorRef,
        private notifCron: NotifsCron,
        private accountsService: AccountsService,
        private router: Router,
        private _snackBar: MatSnackBar,
    ) {}

    ngOnInit() {
        this.notifCron.notifValue$.subscribe((count) => {
            this.subscriptions.add(
                this.notifCron.notifValue$.subscribe((count) => {
                    this.unreadNotificationsCount = count;
                    this.cdr.detectChanges();
                }),
            );
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    getUser(): UserOutputForDetail | null {
        const user = localStorage.getItem('user');
        if (user) {
            return JSON.parse(user) as UserOutputForDetail;
        }
        return null;
    }
    toggleMenu() {
        this.menuTrigger?.openMenu();
    }

    deleteNotification(id: string) {
        this.getNotifications();
        this.cdr.detectChanges();
    }

    getNotifications() {
        const param: NotificationsMesNotificationsGet$Json$Params = { non_lues: true };
        this.notifService.notificationsMesNotificationsGet$Json(param).subscribe((result) => {
            this.notifications = result;
            this.cdr.detectChanges();
        });
    }

    numberCountToString(count: number): string {
        if (count > 9) {
            return '9+';
        }
        return count.toString();
    }

    formatUserName() {
        if (!this.user) return '';

        const firstName = this.user.firstName || '';
        const lastNameInitial = this.user.lastName && this.user.lastName.trim() !== '' ? this.user.lastName.charAt(0).toUpperCase() + '.' : '';

        return `${firstName} ${lastNameInitial}`.trim();
    }

    logout() {
        this.accountsService.accountsLogoutPost$Response().subscribe({
            next: (response) => {
                if (response.ok) {
                    this.router.navigate(['/login']);
                }
            },
            error: (error) => {
                console.log(error);
                let errorMessage = error.message;

                this._snackBar.open(errorMessage, 'Fermer', {
                    duration: 3000,
                    panelClass: ['red-snackbar', 'snackbar'],
                });
            },
        });
    }
}
