import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { StatisticsCompleteDataDtoOutput } from '@app/api/models';
import { AdminService } from '@app/api/services';
import { NumberCardComponent } from '@app/components/number-card/number-card.component';
import { StatsHeaderComponent } from '@app/components/stats-header/stats-header.component';
import moment from 'moment';
import { StatRankComponent } from '../../components/stat-rank/stat-rank.component';
import { GraphCardComponent } from '../../components/graph-card/graph-card.component';
import { AdminStatistiquesGet$Json$Params } from '@app/api/fn/admin/admin-statistiques-get-json';
import { StatReportComponent } from '../../components/stat-report/stat-report.component';
import { ExportButtonComponent } from '../../export-button/export-button.component';
import DownloadFile from '@app/utils/http-headers-utils';

@Component({
    selector: 'app-statistiques',
    standalone: true,
    templateUrl: './statistiques.component.html',
    styleUrl: './statistiques.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [StatsHeaderComponent, NumberCardComponent, StatRankComponent, GraphCardComponent, StatReportComponent, ExportButtonComponent],
})
export class StatistiquesComponent implements OnInit {
    constructor(
        private adminService: AdminService,
        private cdr: ChangeDetectorRef,
    ) {}
    period1 = new FormGroup({
        start: new FormControl<moment.Moment | null>(moment().subtract(1, 'year')),
        end: new FormControl<moment.Moment | null>(moment()),
    });
    period2 = new FormGroup({
        start: new FormControl<moment.Moment | null>(moment().subtract(1, 'year')),
        end: new FormControl<moment.Moment | null>(moment()),
    });
    stats: StatisticsCompleteDataDtoOutput | null = null;

    diff = 366;
    diff2 = 366;

    comparaison = false;

    ngOnInit(): void {
        this.onDateChange(this.period1.value);
    }

    export() {
        const dateDebut = this.period1.value.start;
        const dateDebut2 = this.period2.value.start;
        if (!dateDebut || !dateDebut2) return;
        const params: AdminStatistiquesGet$Json$Params = {
            DureeEnJour1: this.diff,
            date1: dateDebut.toISOString(),
            DureeEnJour2: this.comparaison ? this.diff2 : undefined,
            date2: this.comparaison ? dateDebut2.toISOString() : undefined,
        };
        this.adminService.adminExportStatistiquesGet$Response(params).subscribe((res) => {
            DownloadFile(res);
        });
    }

    onDateChange(event: any) {
        this.period1.patchValue({
            start: event.start,
            end: event.end,
        });
        const start = this.period1.value.start;
        const end = this.period1.value.end;
        this.diff = end ? end.diff(start, 'days') : 0;
        if (this.diff) {
            this.fetchStats();
        }
    }

    onDateChange2(event: any) {
        this.period2.patchValue({
            start: event.start,
            end: event.end,
        });
        const start = this.period2.value.start;
        const end = this.period2.value.end;
        this.diff2 = end ? end.diff(start, 'days') : 0;
        if (this.diff2) {
            this.fetchStats();
        }
    }

    fetchStats() {
        const dateDebut = this.period1.value.start;
        const dateDebut2 = this.period2.value.start;
        if (!dateDebut || !dateDebut2) return;
        const params: AdminStatistiquesGet$Json$Params = {
            DureeEnJour1: this.diff,
            date1: dateDebut.toISOString(),
            DureeEnJour2: this.comparaison ? this.diff2 : undefined,
            date2: this.comparaison ? dateDebut2.toISOString() : undefined,
        };
        this.adminService.adminStatistiquesGet$Json(params).subscribe({
            next: (res) => {
                this.stats = res;
            },
            error: (err) => {
                console.error(err);
            },
            complete: () => {
                this.cdr.detectChanges();
            },
        });
    }

    toggleComparaison(event: boolean) {
        this.comparaison = event;
        this.fetchStats();
    }
}
