/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { accountsAdminGet } from '../fn/accounts/accounts-admin-get';
import { AccountsAdminGet$Params } from '../fn/accounts/accounts-admin-get';
import { accountsLoginPost$Any } from '../fn/accounts/accounts-login-post-any';
import { AccountsLoginPost$Any$Params } from '../fn/accounts/accounts-login-post-any';
import { accountsLoginPost$Json } from '../fn/accounts/accounts-login-post-json';
import { AccountsLoginPost$Json$Params } from '../fn/accounts/accounts-login-post-json';
import { accountsLoginPost$Plain } from '../fn/accounts/accounts-login-post-plain';
import { AccountsLoginPost$Plain$Params } from '../fn/accounts/accounts-login-post-plain';
import { accountsLogoutPost } from '../fn/accounts/accounts-logout-post';
import { AccountsLogoutPost$Params } from '../fn/accounts/accounts-logout-post';
import { accountsMotDePasseOubliPost } from '../fn/accounts/accounts-mot-de-passe-oubli-post';
import { AccountsMotDePasseOubliPost$Params } from '../fn/accounts/accounts-mot-de-passe-oubli-post';
import { accountsMotDePasseReinitialiserPost } from '../fn/accounts/accounts-mot-de-passe-reinitialiser-post';
import { AccountsMotDePasseReinitialiserPost$Params } from '../fn/accounts/accounts-mot-de-passe-reinitialiser-post';
import { accountsRegisterPost } from '../fn/accounts/accounts-register-post';
import { AccountsRegisterPost$Params } from '../fn/accounts/accounts-register-post';
import { UserOutputForDetail } from '../models/user-output-for-detail';

@Injectable({ providedIn: 'root' })
export class AccountsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `accountsAdminGet()` */
  static readonly AccountsAdminGetPath = '/accounts/admin';

  /**
   * Create the admin role.
   *
   * This operation is idempotent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsAdminGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsAdminGet$Response(params?: AccountsAdminGet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountsAdminGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Create the admin role.
   *
   * This operation is idempotent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsAdminGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsAdminGet(params?: AccountsAdminGet$Params, context?: HttpContext): Observable<void> {
    return this.accountsAdminGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountsRegisterPost()` */
  static readonly AccountsRegisterPostPath = '/accounts/register';

  /**
   * Register a new user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsRegisterPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsRegisterPost$Response(params?: AccountsRegisterPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountsRegisterPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Register a new user.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsRegisterPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsRegisterPost(params?: AccountsRegisterPost$Params, context?: HttpContext): Observable<void> {
    return this.accountsRegisterPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountsLoginPost()` */
  static readonly AccountsLoginPostPath = '/accounts/login';

  /**
   * Log the user in.
   *
   * Create an HttpOnly Cookie with the user roles as its claims
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsLoginPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsLoginPost$Json$Response(params?: AccountsLoginPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForDetail>> {
    return accountsLoginPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Log the user in.
   *
   * Create an HttpOnly Cookie with the user roles as its claims
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsLoginPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsLoginPost$Json(params?: AccountsLoginPost$Json$Params, context?: HttpContext): Observable<UserOutputForDetail> {
    return this.accountsLoginPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForDetail>): UserOutputForDetail => r.body)
    );
  }

  /**
   * Log the user in.
   *
   * Create an HttpOnly Cookie with the user roles as its claims
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsLoginPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsLoginPost$Plain$Response(params?: AccountsLoginPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForDetail>> {
    return accountsLoginPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Log the user in.
   *
   * Create an HttpOnly Cookie with the user roles as its claims
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsLoginPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsLoginPost$Plain(params?: AccountsLoginPost$Plain$Params, context?: HttpContext): Observable<UserOutputForDetail> {
    return this.accountsLoginPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForDetail>): UserOutputForDetail => r.body)
    );
  }

  /**
   * Log the user in.
   *
   * Create an HttpOnly Cookie with the user roles as its claims
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsLoginPost$Any()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsLoginPost$Any$Response(params?: AccountsLoginPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForDetail>> {
    return accountsLoginPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Log the user in.
   *
   * Create an HttpOnly Cookie with the user roles as its claims
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsLoginPost$Any$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsLoginPost$Any(params?: AccountsLoginPost$Any$Params, context?: HttpContext): Observable<UserOutputForDetail> {
    return this.accountsLoginPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForDetail>): UserOutputForDetail => r.body)
    );
  }

  /** Path part for operation `accountsLogoutPost()` */
  static readonly AccountsLogoutPostPath = '/accounts/logout';

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsLogoutPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsLogoutPost$Response(params?: AccountsLogoutPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountsLogoutPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsLogoutPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  accountsLogoutPost(params?: AccountsLogoutPost$Params, context?: HttpContext): Observable<void> {
    return this.accountsLogoutPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountsMotDePasseOubliPost()` */
  static readonly AccountsMotDePasseOubliPostPath = '/accounts/mot-de-passe/oubli';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsMotDePasseOubliPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsMotDePasseOubliPost$Response(params?: AccountsMotDePasseOubliPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountsMotDePasseOubliPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsMotDePasseOubliPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsMotDePasseOubliPost(params?: AccountsMotDePasseOubliPost$Params, context?: HttpContext): Observable<void> {
    return this.accountsMotDePasseOubliPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `accountsMotDePasseReinitialiserPost()` */
  static readonly AccountsMotDePasseReinitialiserPostPath = '/accounts/mot-de-passe/reinitialiser';

  /**
   * Change the password.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `accountsMotDePasseReinitialiserPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsMotDePasseReinitialiserPost$Response(params?: AccountsMotDePasseReinitialiserPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return accountsMotDePasseReinitialiserPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Change the password.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `accountsMotDePasseReinitialiserPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  accountsMotDePasseReinitialiserPost(params?: AccountsMotDePasseReinitialiserPost$Params, context?: HttpContext): Observable<void> {
    return this.accountsMotDePasseReinitialiserPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
