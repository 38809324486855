/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LieuEchangeXOffreDtoOutputForMap } from '../../models/lieu-echange-x-offre-dto-output-for-map';

export interface OffresVueCarteGet$Plain$Params {
  latMin?: number;
  latMax?: number;
  lngMin?: number;
  lngMax?: number;
  categories?: Array<string>;
  familles?: Array<string>;
  sousfamilles?: Array<string>;
  tailles?: Array<string>;
  conformations?: Array<string>;
  recherche?: string;
  reference?: string;
  libelle?: string;
  force?: string;
  groupe?: string;
  genre?: string;
  sousgroupe?: string;
}

export function offresVueCarteGet$Plain(http: HttpClient, rootUrl: string, params?: OffresVueCarteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>> {
  const rb = new RequestBuilder(rootUrl, offresVueCarteGet$Plain.PATH, 'get');
  if (params) {
    rb.query('latMin', params.latMin, {});
    rb.query('latMax', params.latMax, {});
    rb.query('lngMin', params.lngMin, {});
    rb.query('lngMax', params.lngMax, {});
    rb.query('categories', params.categories, {});
    rb.query('familles', params.familles, {});
    rb.query('sousfamilles', params.sousfamilles, {});
    rb.query('tailles', params.tailles, {});
    rb.query('conformations', params.conformations, {});
    rb.query('recherche', params.recherche, {});
    rb.query('reference', params.reference, {});
    rb.query('libelle', params.libelle, {});
    rb.query('force', params.force, {});
    rb.query('groupe', params.groupe, {});
    rb.query('genre', params.genre, {});
    rb.query('sousgroupe', params.sousgroupe, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<LieuEchangeXOffreDtoOutputForMap>>;
    })
  );
}

offresVueCarteGet$Plain.PATH = '/offres/vue-carte';
