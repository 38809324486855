// Import necessary Angular modules
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root', // This makes the service globally available
})
export class FormService {
    constructor() {}

    // Recursive method to mark all form controls as touched
    markAllAsTouched(group: FormGroup | FormControl) {
        if (group instanceof FormControl) {
            group.markAsTouched();
        } else {
            Object.keys(group.controls).forEach((key) => {
                const control = group.get(key);
                if (control instanceof FormControl) {
                    control.markAsTouched();
                    control.updateValueAndValidity();
                } else if (control instanceof FormGroup) {
                    this.markAllAsTouched(control);
                }
            });
        }
    }
}
