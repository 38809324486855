import { CommonModule } from '@angular/common';
import { RichtextComponent } from '@app/components/richtext/richtext.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxEditorModule } from 'ngx-editor';
import { MaterialModule } from './modules/material.module';
import { HttpClient } from '@angular/common/http';

@NgModule({
    imports: [FormsModule, CommonModule, ReactiveFormsModule, MaterialModule, NgxEditorModule.forChild({
        locals: {
            // menu
            bold: 'Gras',
            italic: 'Italique',
            code: 'Code',
            underline: 'Souligner',
            strike: 'Barrer',
            blockquote: 'Citation',
            bullet_list: 'Liste à puces',
            ordered_list: 'Liste numérotée',
            heading: 'Titre',
            h1: 'Titre 1',
            h2: 'Titre 2',
            h3: 'Titre 3',
            h4: 'Titre 4',
            h5: 'Titre 5',
            h6: 'Titre 6',
            align_left: 'Aligner à gauche',
            align_center: 'Aligner au centre',
            align_right: 'Aligner à droite',
            align_justify: 'Justifier',
            text_color: 'Couleur du texte',
            background_color: 'Couleur de fond',
            horizontal_rule: 'Règle horizontale',
            format_clear: 'Effacer le formatage',
            insertLink: 'Insérer un lien',
            removeLink: 'Supprimer le lien',
            insertImage: 'Insérer une image',
            indent: 'Augmenter le retrait',
            outdent: 'Diminuer le retrait',
            superscript: 'Exposant',
            subscript: 'Indice',
            undo: 'Annuler',
            redo: 'Rétablir',
            // pupups, forms, others...
            url: 'URL',
            text: 'Texte',
            openInNewTab: 'Ouvrir dans un nouvel onglet',
            insert: 'Insérer',
            altText: 'Texte alternatif',
            title: 'Titre',
            remove: 'Supprimer',
            enterValidUrl: 'Veuillez entrer une URL valide',
        }
    })
    ],
    declarations: [RichtextComponent],
    bootstrap: [RichtextComponent],
    exports: [RichtextComponent],
})
export class AppModule { }
