<div class="gap-2 flex flex-col">
    <!-- Top section with search bar and label -->
        <mat-label class="text-primary font-bold whitespace-nowrap h-full">{{ label }}</mat-label>
    <!-- Main content section with two side-by-side components -->
    <div class="h-[calc(100%-82px)] flex">
        <div class="flex flex-row w-full h-full gap-6">
            <!-- First recursive tree component -->
            <div class="h-full flex flex-col w-1/2">
                
                <app-recursive-tree class="h-full" title="Sélection" [editMode]="editMode" [selectedNodes]="selectedNodes" [nodesData]="nodesData" (selectedNodesChange)="changement($event)"></app-recursive-tree>
            </div>
            <!-- Second recursive tree component -->
            <div class="h-full flex flex-col w-1/2">
                <app-recursive-tree-resume class="h-full" title="Résumé" [date]="date" [nodesData]="selectedNodes()" [allNodes]="nodesData"></app-recursive-tree-resume>
            </div>
        </div>
    </div>
</div>