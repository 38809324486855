import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatLabel } from '@angular/material/form-field';
import { SignalementOutputForDetail } from '@app/api/models';

@Component({
    selector: 'app-confirm-dialog',
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, MatLabel],
    templateUrl: './signalement-action-dialog.component.html',
    styleUrl: './signalement-action-dialog.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalementActionComponent {
    constructor(
        public dialogRef: MatDialogRef<SignalementActionComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { 
            signalement: SignalementOutputForDetail,
            nomDuBouton: string; }
    ) {}

    onDismiss(): void {
        this.dialogRef.close(false);
    }
    onConfirm(): void {

        this.dialogRef.close(true);
    }
}
