/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ReferencesTaillesGet$Plain$Params {
  reference?: string;
  libelle?: string;
  force?: string;
  categorieFiltre?: Array<string>;
  familleFiltre?: Array<string>;
  sousfamilleFiltre?: Array<string>;
  groupe?: string;
  genre?: string;
  sousgroupe?: string;
  taille?: string;
  conformation?: string;
}

export function referencesTaillesGet$Plain(http: HttpClient, rootUrl: string, params?: ReferencesTaillesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
  const rb = new RequestBuilder(rootUrl, referencesTaillesGet$Plain.PATH, 'get');
  if (params) {
    rb.query('reference', params.reference, {});
    rb.query('libelle', params.libelle, {});
    rb.query('force', params.force, {});
    rb.query('categorieFiltre', params.categorieFiltre, {});
    rb.query('familleFiltre', params.familleFiltre, {});
    rb.query('sousfamilleFiltre', params.sousfamilleFiltre, {});
    rb.query('groupe', params.groupe, {});
    rb.query('genre', params.genre, {});
    rb.query('sousgroupe', params.sousgroupe, {});
    rb.query('taille', params.taille, {});
    rb.query('conformation', params.conformation, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<string>>;
    })
  );
}

referencesTaillesGet$Plain.PATH = '/references/tailles';
