import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule, FormsModule, FormGroup, FormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { ExportButtonComponent } from '@app/export-button/export-button.component';
import { RangeType } from '../stats-header/stats-header.component';
import moment from 'moment';

@Component({
    selector: 'app-stat-range',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatIconModule,
        RouterLink,
        RouterLinkActive,
        RouterOutlet,
        MatOptionModule,
        MatDatepickerModule,
        FormsModule,
        MatSelectModule,
        ExportButtonComponent,
        StatRangeComponent,
    ],
    templateUrl: './stat-range.component.html',
    styleUrls: ['./stat-range.component.css'],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatRangeComponent {
    @Input() range: FormGroup = new FormGroup({
        start: new FormControl<moment.Moment | null>(moment().subtract(1, 'year')),
        end: new FormControl<moment.Moment | null>(moment()),
    });
    @Input() disabled = false;

    @Output() dateChange = new EventEmitter();
    rangeOptions: RangeType[] = [
        { name: '1 semaine', value: 'week' },
        { name: '1 mois', value: 'month' },
        { name: '1 an', value: 'year' },
        { name: 'Perso.', value: 'custom' },
    ];
    selectedRange: moment.DurationInputArg2 | string = 'year';
    constructor(private cdr: ChangeDetectorRef) {}
    updateRange(event: moment.DurationInputArg2, range: FormGroup) {
        const today = moment();
        const lastMoment = moment().subtract(1, event);
        range.patchValue({
            start: lastMoment,
            end: today,
        });
        this.selectedRange = event;
        this.dateChangeEmit();
    }

    customRangeChange() {
        this.selectedRange = 'custom';
        this.dateChangeEmit();
    }

    dateChangeEmit() {
        const rangeToSend = this.range.value as Range;
        this.cdr.detectChanges();
        this.dateChange.emit(rangeToSend);
    }
}
