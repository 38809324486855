<form id="form-password-recovery"  [formGroup]="passwordRecoveryForm" (ngSubmit)="onSubmit()" id="form_password_recovery" class="flex w-full flex-col">
  <mat-label class="text-primary">Nouveau mot de passe</mat-label>
  <mat-form-field>
      <input matInput placeholder="*****" formControlName="password" [type]="hide() ? 'password' : 'text'" autocomplete="password" />
      <button type="button" mat-icon-button matSuffix (click)="clickEvent($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide()">
        <mat-icon class="text-primary h-full">{{ hide() ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </mat-form-field>
  @if (passwordRecoveryForm.get('password')?.hasError('server')) {
    <p class="text-red-600 italic">{{ passwordRecoveryForm.get('password')?.getError('server') }}</p>
  }

  <mat-label class="text-primary">Confirmer le mot de passe</mat-label>
  <mat-form-field class="w-full flex items-center">
      <input matInput placeholder="*****" formControlName="confirmPassword" [type]="hide() ? 'password' : 'text'" autocomplete="password" />
      <button type="button" mat-icon-button matSuffix (click)="clickEvent($event)" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide()">
          <mat-icon class="text-primary h-full">{{ hide() ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
  </mat-form-field>
  @if (passwordRecoveryForm.get('confirmPassword')?.hasError('server')) {
    <p class="text-red-600 italic">{{ passwordRecoveryForm.get('confirmPassword')?.getError('server') }}</p>
  }

  <div class="mt-7 md:flex md:justify-center">
      <button mat-flat-button class="w-full !bg-primary md:w-52" type="submit">Enregistrer</button>
  </div>
</form>
