/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminAdressesPubliquesGet$Any } from '../fn/admin/admin-adresses-publiques-get-any';
import { AdminAdressesPubliquesGet$Any$Params } from '../fn/admin/admin-adresses-publiques-get-any';
import { adminAdressesPubliquesGet$Json } from '../fn/admin/admin-adresses-publiques-get-json';
import { AdminAdressesPubliquesGet$Json$Params } from '../fn/admin/admin-adresses-publiques-get-json';
import { adminAdressesPubliquesGet$Plain } from '../fn/admin/admin-adresses-publiques-get-plain';
import { AdminAdressesPubliquesGet$Plain$Params } from '../fn/admin/admin-adresses-publiques-get-plain';
import { adminAdressesPubliquesIdDelete } from '../fn/admin/admin-adresses-publiques-id-delete';
import { AdminAdressesPubliquesIdDelete$Params } from '../fn/admin/admin-adresses-publiques-id-delete';
import { adminAdressesPubliquesIdGet$Any } from '../fn/admin/admin-adresses-publiques-id-get-any';
import { AdminAdressesPubliquesIdGet$Any$Params } from '../fn/admin/admin-adresses-publiques-id-get-any';
import { adminAdressesPubliquesIdGet$Json } from '../fn/admin/admin-adresses-publiques-id-get-json';
import { AdminAdressesPubliquesIdGet$Json$Params } from '../fn/admin/admin-adresses-publiques-id-get-json';
import { adminAdressesPubliquesIdGet$Plain } from '../fn/admin/admin-adresses-publiques-id-get-plain';
import { AdminAdressesPubliquesIdGet$Plain$Params } from '../fn/admin/admin-adresses-publiques-id-get-plain';
import { adminAdressesPubliquesIdPut } from '../fn/admin/admin-adresses-publiques-id-put';
import { AdminAdressesPubliquesIdPut$Params } from '../fn/admin/admin-adresses-publiques-id-put';
import { adminAdressesPubliquesPost } from '../fn/admin/admin-adresses-publiques-post';
import { AdminAdressesPubliquesPost$Params } from '../fn/admin/admin-adresses-publiques-post';
import { adminCategoriesGet$Any } from '../fn/admin/admin-categories-get-any';
import { AdminCategoriesGet$Any$Params } from '../fn/admin/admin-categories-get-any';
import { adminCategoriesGet$Json } from '../fn/admin/admin-categories-get-json';
import { AdminCategoriesGet$Json$Params } from '../fn/admin/admin-categories-get-json';
import { adminCategoriesGet$Plain } from '../fn/admin/admin-categories-get-plain';
import { AdminCategoriesGet$Plain$Params } from '../fn/admin/admin-categories-get-plain';
import { adminCategoriesIdPut } from '../fn/admin/admin-categories-id-put';
import { AdminCategoriesIdPut$Params } from '../fn/admin/admin-categories-id-put';
import { adminCategoriesPost } from '../fn/admin/admin-categories-post';
import { AdminCategoriesPost$Params } from '../fn/admin/admin-categories-post';
import { adminExportCommunesGet } from '../fn/admin/admin-export-communes-get';
import { AdminExportCommunesGet$Params } from '../fn/admin/admin-export-communes-get';
import { adminExportDerniereListeAdressesGet } from '../fn/admin/admin-export-derniere-liste-adresses-get';
import { AdminExportDerniereListeAdressesGet$Params } from '../fn/admin/admin-export-derniere-liste-adresses-get';
import { adminExportDerniereListeUtilisateursGet } from '../fn/admin/admin-export-derniere-liste-utilisateurs-get';
import { AdminExportDerniereListeUtilisateursGet$Params } from '../fn/admin/admin-export-derniere-liste-utilisateurs-get';
import { adminExportDernieresReferencesGet } from '../fn/admin/admin-export-dernieres-references-get';
import { AdminExportDernieresReferencesGet$Params } from '../fn/admin/admin-export-dernieres-references-get';
import { adminExportDetailsUtilisateurIdGet } from '../fn/admin/admin-export-details-utilisateur-id-get';
import { AdminExportDetailsUtilisateurIdGet$Params } from '../fn/admin/admin-export-details-utilisateur-id-get';
import { adminExportListeUtilisateursGet } from '../fn/admin/admin-export-liste-utilisateurs-get';
import { AdminExportListeUtilisateursGet$Params } from '../fn/admin/admin-export-liste-utilisateurs-get';
import { adminExportNotificationsGet } from '../fn/admin/admin-export-notifications-get';
import { AdminExportNotificationsGet$Params } from '../fn/admin/admin-export-notifications-get';
import { adminExportReferencesGet } from '../fn/admin/admin-export-references-get';
import { AdminExportReferencesGet$Params } from '../fn/admin/admin-export-references-get';
import { adminExportSignalementsGet } from '../fn/admin/admin-export-signalements-get';
import { AdminExportSignalementsGet$Params } from '../fn/admin/admin-export-signalements-get';
import { adminExportStatistiquesGet } from '../fn/admin/admin-export-statistiques-get';
import { AdminExportStatistiquesGet$Params } from '../fn/admin/admin-export-statistiques-get';
import { adminGuideDesTaillesGet$Any } from '../fn/admin/admin-guide-des-tailles-get-any';
import { AdminGuideDesTaillesGet$Any$Params } from '../fn/admin/admin-guide-des-tailles-get-any';
import { adminGuideDesTaillesGet$Json } from '../fn/admin/admin-guide-des-tailles-get-json';
import { AdminGuideDesTaillesGet$Json$Params } from '../fn/admin/admin-guide-des-tailles-get-json';
import { adminGuideDesTaillesGet$Plain } from '../fn/admin/admin-guide-des-tailles-get-plain';
import { AdminGuideDesTaillesGet$Plain$Params } from '../fn/admin/admin-guide-des-tailles-get-plain';
import { adminGuideDesTaillesPost } from '../fn/admin/admin-guide-des-tailles-post';
import { AdminGuideDesTaillesPost$Params } from '../fn/admin/admin-guide-des-tailles-post';
import { adminImportAdressesPost$Any } from '../fn/admin/admin-import-adresses-post-any';
import { AdminImportAdressesPost$Any$Params } from '../fn/admin/admin-import-adresses-post-any';
import { adminImportAdressesPost$Json } from '../fn/admin/admin-import-adresses-post-json';
import { AdminImportAdressesPost$Json$Params } from '../fn/admin/admin-import-adresses-post-json';
import { adminImportAdressesPost$Plain } from '../fn/admin/admin-import-adresses-post-plain';
import { AdminImportAdressesPost$Plain$Params } from '../fn/admin/admin-import-adresses-post-plain';
import { adminImportReferencesPost$Any } from '../fn/admin/admin-import-references-post-any';
import { AdminImportReferencesPost$Any$Params } from '../fn/admin/admin-import-references-post-any';
import { adminImportReferencesPost$Json } from '../fn/admin/admin-import-references-post-json';
import { AdminImportReferencesPost$Json$Params } from '../fn/admin/admin-import-references-post-json';
import { adminImportReferencesPost$Plain } from '../fn/admin/admin-import-references-post-plain';
import { AdminImportReferencesPost$Plain$Params } from '../fn/admin/admin-import-references-post-plain';
import { adminImportUtilisateursPost$Any } from '../fn/admin/admin-import-utilisateurs-post-any';
import { AdminImportUtilisateursPost$Any$Params } from '../fn/admin/admin-import-utilisateurs-post-any';
import { adminImportUtilisateursPost$Json } from '../fn/admin/admin-import-utilisateurs-post-json';
import { AdminImportUtilisateursPost$Json$Params } from '../fn/admin/admin-import-utilisateurs-post-json';
import { adminImportUtilisateursPost$Plain } from '../fn/admin/admin-import-utilisateurs-post-plain';
import { AdminImportUtilisateursPost$Plain$Params } from '../fn/admin/admin-import-utilisateurs-post-plain';
import { adminInformationsCguGet$Any } from '../fn/admin/admin-informations-cgu-get-any';
import { AdminInformationsCguGet$Any$Params } from '../fn/admin/admin-informations-cgu-get-any';
import { adminInformationsCguGet$Json } from '../fn/admin/admin-informations-cgu-get-json';
import { AdminInformationsCguGet$Json$Params } from '../fn/admin/admin-informations-cgu-get-json';
import { adminInformationsCguGet$Plain } from '../fn/admin/admin-informations-cgu-get-plain';
import { AdminInformationsCguGet$Plain$Params } from '../fn/admin/admin-informations-cgu-get-plain';
import { adminInformationsCguPost$Any } from '../fn/admin/admin-informations-cgu-post-any';
import { AdminInformationsCguPost$Any$Params } from '../fn/admin/admin-informations-cgu-post-any';
import { adminInformationsCguPost$Json } from '../fn/admin/admin-informations-cgu-post-json';
import { AdminInformationsCguPost$Json$Params } from '../fn/admin/admin-informations-cgu-post-json';
import { adminInformationsCguPost$Plain } from '../fn/admin/admin-informations-cgu-post-plain';
import { AdminInformationsCguPost$Plain$Params } from '../fn/admin/admin-informations-cgu-post-plain';
import { adminInformationsRecyclageGet$Any } from '../fn/admin/admin-informations-recyclage-get-any';
import { AdminInformationsRecyclageGet$Any$Params } from '../fn/admin/admin-informations-recyclage-get-any';
import { adminInformationsRecyclageGet$Json } from '../fn/admin/admin-informations-recyclage-get-json';
import { AdminInformationsRecyclageGet$Json$Params } from '../fn/admin/admin-informations-recyclage-get-json';
import { adminInformationsRecyclageGet$Plain } from '../fn/admin/admin-informations-recyclage-get-plain';
import { AdminInformationsRecyclageGet$Plain$Params } from '../fn/admin/admin-informations-recyclage-get-plain';
import { adminInformationsRecyclagePost$Any } from '../fn/admin/admin-informations-recyclage-post-any';
import { AdminInformationsRecyclagePost$Any$Params } from '../fn/admin/admin-informations-recyclage-post-any';
import { adminInformationsRecyclagePost$Json } from '../fn/admin/admin-informations-recyclage-post-json';
import { AdminInformationsRecyclagePost$Json$Params } from '../fn/admin/admin-informations-recyclage-post-json';
import { adminInformationsRecyclagePost$Plain } from '../fn/admin/admin-informations-recyclage-post-plain';
import { AdminInformationsRecyclagePost$Plain$Params } from '../fn/admin/admin-informations-recyclage-post-plain';
import { adminInformationsSymbioseGet$Any } from '../fn/admin/admin-informations-symbiose-get-any';
import { AdminInformationsSymbioseGet$Any$Params } from '../fn/admin/admin-informations-symbiose-get-any';
import { adminInformationsSymbioseGet$Json } from '../fn/admin/admin-informations-symbiose-get-json';
import { AdminInformationsSymbioseGet$Json$Params } from '../fn/admin/admin-informations-symbiose-get-json';
import { adminInformationsSymbioseGet$Plain } from '../fn/admin/admin-informations-symbiose-get-plain';
import { AdminInformationsSymbioseGet$Plain$Params } from '../fn/admin/admin-informations-symbiose-get-plain';
import { adminInformationsSymbiosePost$Any } from '../fn/admin/admin-informations-symbiose-post-any';
import { AdminInformationsSymbiosePost$Any$Params } from '../fn/admin/admin-informations-symbiose-post-any';
import { adminInformationsSymbiosePost$Json } from '../fn/admin/admin-informations-symbiose-post-json';
import { AdminInformationsSymbiosePost$Json$Params } from '../fn/admin/admin-informations-symbiose-post-json';
import { adminInformationsSymbiosePost$Plain } from '../fn/admin/admin-informations-symbiose-post-plain';
import { AdminInformationsSymbiosePost$Plain$Params } from '../fn/admin/admin-informations-symbiose-post-plain';
import { adminMessagesMurDesConversationsGet$Any } from '../fn/admin/admin-messages-mur-des-conversations-get-any';
import { AdminMessagesMurDesConversationsGet$Any$Params } from '../fn/admin/admin-messages-mur-des-conversations-get-any';
import { adminMessagesMurDesConversationsGet$Json } from '../fn/admin/admin-messages-mur-des-conversations-get-json';
import { AdminMessagesMurDesConversationsGet$Json$Params } from '../fn/admin/admin-messages-mur-des-conversations-get-json';
import { adminMessagesMurDesConversationsGet$Plain } from '../fn/admin/admin-messages-mur-des-conversations-get-plain';
import { AdminMessagesMurDesConversationsGet$Plain$Params } from '../fn/admin/admin-messages-mur-des-conversations-get-plain';
import { adminMessagesUtilisateursUtilisateurIdDelete } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-delete';
import { AdminMessagesUtilisateursUtilisateurIdDelete$Params } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-delete';
import { adminMessagesUtilisateursUtilisateurIdGet$Any } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-get-any';
import { AdminMessagesUtilisateursUtilisateurIdGet$Any$Params } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-get-any';
import { adminMessagesUtilisateursUtilisateurIdGet$Json } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-get-json';
import { AdminMessagesUtilisateursUtilisateurIdGet$Json$Params } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-get-json';
import { adminMessagesUtilisateursUtilisateurIdGet$Plain } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-get-plain';
import { AdminMessagesUtilisateursUtilisateurIdGet$Plain$Params } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-get-plain';
import { adminMessagesUtilisateursUtilisateurIdPost } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-post';
import { AdminMessagesUtilisateursUtilisateurIdPost$Params } from '../fn/admin/admin-messages-utilisateurs-utilisateur-id-post';
import { adminNotificationsProgrammeesGet$Any } from '../fn/admin/admin-notifications-programmees-get-any';
import { AdminNotificationsProgrammeesGet$Any$Params } from '../fn/admin/admin-notifications-programmees-get-any';
import { adminNotificationsProgrammeesGet$Json } from '../fn/admin/admin-notifications-programmees-get-json';
import { AdminNotificationsProgrammeesGet$Json$Params } from '../fn/admin/admin-notifications-programmees-get-json';
import { adminNotificationsProgrammeesGet$Plain } from '../fn/admin/admin-notifications-programmees-get-plain';
import { AdminNotificationsProgrammeesGet$Plain$Params } from '../fn/admin/admin-notifications-programmees-get-plain';
import { adminNotificationsProgrammeesIdGet$Any } from '../fn/admin/admin-notifications-programmees-id-get-any';
import { AdminNotificationsProgrammeesIdGet$Any$Params } from '../fn/admin/admin-notifications-programmees-id-get-any';
import { adminNotificationsProgrammeesIdGet$Json } from '../fn/admin/admin-notifications-programmees-id-get-json';
import { AdminNotificationsProgrammeesIdGet$Json$Params } from '../fn/admin/admin-notifications-programmees-id-get-json';
import { adminNotificationsProgrammeesIdGet$Plain } from '../fn/admin/admin-notifications-programmees-id-get-plain';
import { AdminNotificationsProgrammeesIdGet$Plain$Params } from '../fn/admin/admin-notifications-programmees-id-get-plain';
import { adminNotificationsProgrammeesIdPut } from '../fn/admin/admin-notifications-programmees-id-put';
import { AdminNotificationsProgrammeesIdPut$Params } from '../fn/admin/admin-notifications-programmees-id-put';
import { adminNotificationsProgrammeesPost } from '../fn/admin/admin-notifications-programmees-post';
import { AdminNotificationsProgrammeesPost$Params } from '../fn/admin/admin-notifications-programmees-post';
import { adminReferencesGet$Any } from '../fn/admin/admin-references-get-any';
import { AdminReferencesGet$Any$Params } from '../fn/admin/admin-references-get-any';
import { adminReferencesGet$Json } from '../fn/admin/admin-references-get-json';
import { AdminReferencesGet$Json$Params } from '../fn/admin/admin-references-get-json';
import { adminReferencesGet$Plain } from '../fn/admin/admin-references-get-plain';
import { AdminReferencesGet$Plain$Params } from '../fn/admin/admin-references-get-plain';
import { adminReferencesPost } from '../fn/admin/admin-references-post';
import { AdminReferencesPost$Params } from '../fn/admin/admin-references-post';
import { adminReferencesPut } from '../fn/admin/admin-references-put';
import { AdminReferencesPut$Params } from '../fn/admin/admin-references-put';
import { adminReferencesReferenceGet$Any } from '../fn/admin/admin-references-reference-get-any';
import { AdminReferencesReferenceGet$Any$Params } from '../fn/admin/admin-references-reference-get-any';
import { adminReferencesReferenceGet$Json } from '../fn/admin/admin-references-reference-get-json';
import { AdminReferencesReferenceGet$Json$Params } from '../fn/admin/admin-references-reference-get-json';
import { adminReferencesReferenceGet$Plain } from '../fn/admin/admin-references-reference-get-plain';
import { AdminReferencesReferenceGet$Plain$Params } from '../fn/admin/admin-references-reference-get-plain';
import { adminSignalementsGet$Any } from '../fn/admin/admin-signalements-get-any';
import { AdminSignalementsGet$Any$Params } from '../fn/admin/admin-signalements-get-any';
import { adminSignalementsGet$Json } from '../fn/admin/admin-signalements-get-json';
import { AdminSignalementsGet$Json$Params } from '../fn/admin/admin-signalements-get-json';
import { adminSignalementsGet$Plain } from '../fn/admin/admin-signalements-get-plain';
import { AdminSignalementsGet$Plain$Params } from '../fn/admin/admin-signalements-get-plain';
import { adminSignalementsIdDelete } from '../fn/admin/admin-signalements-id-delete';
import { AdminSignalementsIdDelete$Params } from '../fn/admin/admin-signalements-id-delete';
import { adminSignalementsIdGet$Any } from '../fn/admin/admin-signalements-id-get-any';
import { AdminSignalementsIdGet$Any$Params } from '../fn/admin/admin-signalements-id-get-any';
import { adminSignalementsIdGet$Json } from '../fn/admin/admin-signalements-id-get-json';
import { AdminSignalementsIdGet$Json$Params } from '../fn/admin/admin-signalements-id-get-json';
import { adminSignalementsIdGet$Plain } from '../fn/admin/admin-signalements-id-get-plain';
import { AdminSignalementsIdGet$Plain$Params } from '../fn/admin/admin-signalements-id-get-plain';
import { adminSignalementsIdPut$Any } from '../fn/admin/admin-signalements-id-put-any';
import { AdminSignalementsIdPut$Any$Params } from '../fn/admin/admin-signalements-id-put-any';
import { adminSignalementsIdPut$Json } from '../fn/admin/admin-signalements-id-put-json';
import { AdminSignalementsIdPut$Json$Params } from '../fn/admin/admin-signalements-id-put-json';
import { adminSignalementsIdPut$Plain } from '../fn/admin/admin-signalements-id-put-plain';
import { AdminSignalementsIdPut$Plain$Params } from '../fn/admin/admin-signalements-id-put-plain';
import { adminStatistiquesArticlesClotureesCompteGet$Any } from '../fn/admin/admin-statistiques-articles-cloturees-compte-get-any';
import { AdminStatistiquesArticlesClotureesCompteGet$Any$Params } from '../fn/admin/admin-statistiques-articles-cloturees-compte-get-any';
import { adminStatistiquesArticlesClotureesCompteGet$Json } from '../fn/admin/admin-statistiques-articles-cloturees-compte-get-json';
import { AdminStatistiquesArticlesClotureesCompteGet$Json$Params } from '../fn/admin/admin-statistiques-articles-cloturees-compte-get-json';
import { adminStatistiquesArticlesClotureesCompteGet$Plain } from '../fn/admin/admin-statistiques-articles-cloturees-compte-get-plain';
import { AdminStatistiquesArticlesClotureesCompteGet$Plain$Params } from '../fn/admin/admin-statistiques-articles-cloturees-compte-get-plain';
import { adminStatistiquesArticlesEnCoursCompteGet$Any } from '../fn/admin/admin-statistiques-articles-en-cours-compte-get-any';
import { AdminStatistiquesArticlesEnCoursCompteGet$Any$Params } from '../fn/admin/admin-statistiques-articles-en-cours-compte-get-any';
import { adminStatistiquesArticlesEnCoursCompteGet$Json } from '../fn/admin/admin-statistiques-articles-en-cours-compte-get-json';
import { AdminStatistiquesArticlesEnCoursCompteGet$Json$Params } from '../fn/admin/admin-statistiques-articles-en-cours-compte-get-json';
import { adminStatistiquesArticlesEnCoursCompteGet$Plain } from '../fn/admin/admin-statistiques-articles-en-cours-compte-get-plain';
import { AdminStatistiquesArticlesEnCoursCompteGet$Plain$Params } from '../fn/admin/admin-statistiques-articles-en-cours-compte-get-plain';
import { adminStatistiquesGet$Any } from '../fn/admin/admin-statistiques-get-any';
import { AdminStatistiquesGet$Any$Params } from '../fn/admin/admin-statistiques-get-any';
import { adminStatistiquesGet$Json } from '../fn/admin/admin-statistiques-get-json';
import { AdminStatistiquesGet$Json$Params } from '../fn/admin/admin-statistiques-get-json';
import { adminStatistiquesGet$Plain } from '../fn/admin/admin-statistiques-get-plain';
import { AdminStatistiquesGet$Plain$Params } from '../fn/admin/admin-statistiques-get-plain';
import { adminStatistiquesImpactCarboneEquivalentArbresGet$Any } from '../fn/admin/admin-statistiques-impact-carbone-equivalent-arbres-get-any';
import { AdminStatistiquesImpactCarboneEquivalentArbresGet$Any$Params } from '../fn/admin/admin-statistiques-impact-carbone-equivalent-arbres-get-any';
import { adminStatistiquesImpactCarboneEquivalentArbresGet$Json } from '../fn/admin/admin-statistiques-impact-carbone-equivalent-arbres-get-json';
import { AdminStatistiquesImpactCarboneEquivalentArbresGet$Json$Params } from '../fn/admin/admin-statistiques-impact-carbone-equivalent-arbres-get-json';
import { adminStatistiquesImpactCarboneEquivalentArbresGet$Plain } from '../fn/admin/admin-statistiques-impact-carbone-equivalent-arbres-get-plain';
import { AdminStatistiquesImpactCarboneEquivalentArbresGet$Plain$Params } from '../fn/admin/admin-statistiques-impact-carbone-equivalent-arbres-get-plain';
import { adminStatistiquesImpactCarboneGet$Any } from '../fn/admin/admin-statistiques-impact-carbone-get-any';
import { AdminStatistiquesImpactCarboneGet$Any$Params } from '../fn/admin/admin-statistiques-impact-carbone-get-any';
import { adminStatistiquesImpactCarboneGet$Json } from '../fn/admin/admin-statistiques-impact-carbone-get-json';
import { AdminStatistiquesImpactCarboneGet$Json$Params } from '../fn/admin/admin-statistiques-impact-carbone-get-json';
import { adminStatistiquesImpactCarboneGet$Plain } from '../fn/admin/admin-statistiques-impact-carbone-get-plain';
import { AdminStatistiquesImpactCarboneGet$Plain$Params } from '../fn/admin/admin-statistiques-impact-carbone-get-plain';
import { adminUtilisateursGet$Any } from '../fn/admin/admin-utilisateurs-get-any';
import { AdminUtilisateursGet$Any$Params } from '../fn/admin/admin-utilisateurs-get-any';
import { adminUtilisateursGet$Json } from '../fn/admin/admin-utilisateurs-get-json';
import { AdminUtilisateursGet$Json$Params } from '../fn/admin/admin-utilisateurs-get-json';
import { adminUtilisateursGet$Plain } from '../fn/admin/admin-utilisateurs-get-plain';
import { AdminUtilisateursGet$Plain$Params } from '../fn/admin/admin-utilisateurs-get-plain';
import { adminUtilisateursIdBannirPut$Any } from '../fn/admin/admin-utilisateurs-id-bannir-put-any';
import { AdminUtilisateursIdBannirPut$Any$Params } from '../fn/admin/admin-utilisateurs-id-bannir-put-any';
import { adminUtilisateursIdBannirPut$Json } from '../fn/admin/admin-utilisateurs-id-bannir-put-json';
import { AdminUtilisateursIdBannirPut$Json$Params } from '../fn/admin/admin-utilisateurs-id-bannir-put-json';
import { adminUtilisateursIdBannirPut$Plain } from '../fn/admin/admin-utilisateurs-id-bannir-put-plain';
import { AdminUtilisateursIdBannirPut$Plain$Params } from '../fn/admin/admin-utilisateurs-id-bannir-put-plain';
import { adminUtilisateursIdCommentaireAdminPost } from '../fn/admin/admin-utilisateurs-id-commentaire-admin-post';
import { AdminUtilisateursIdCommentaireAdminPost$Params } from '../fn/admin/admin-utilisateurs-id-commentaire-admin-post';
import { adminUtilisateursIdDemandesGet$Any } from '../fn/admin/admin-utilisateurs-id-demandes-get-any';
import { AdminUtilisateursIdDemandesGet$Any$Params } from '../fn/admin/admin-utilisateurs-id-demandes-get-any';
import { adminUtilisateursIdDemandesGet$Json } from '../fn/admin/admin-utilisateurs-id-demandes-get-json';
import { AdminUtilisateursIdDemandesGet$Json$Params } from '../fn/admin/admin-utilisateurs-id-demandes-get-json';
import { adminUtilisateursIdDemandesGet$Plain } from '../fn/admin/admin-utilisateurs-id-demandes-get-plain';
import { AdminUtilisateursIdDemandesGet$Plain$Params } from '../fn/admin/admin-utilisateurs-id-demandes-get-plain';
import { adminUtilisateursIdDonationsGet$Any } from '../fn/admin/admin-utilisateurs-id-donations-get-any';
import { AdminUtilisateursIdDonationsGet$Any$Params } from '../fn/admin/admin-utilisateurs-id-donations-get-any';
import { adminUtilisateursIdDonationsGet$Json } from '../fn/admin/admin-utilisateurs-id-donations-get-json';
import { AdminUtilisateursIdDonationsGet$Json$Params } from '../fn/admin/admin-utilisateurs-id-donations-get-json';
import { adminUtilisateursIdDonationsGet$Plain } from '../fn/admin/admin-utilisateurs-id-donations-get-plain';
import { AdminUtilisateursIdDonationsGet$Plain$Params } from '../fn/admin/admin-utilisateurs-id-donations-get-plain';
import { adminUtilisateursIdGet$Any } from '../fn/admin/admin-utilisateurs-id-get-any';
import { AdminUtilisateursIdGet$Any$Params } from '../fn/admin/admin-utilisateurs-id-get-any';
import { adminUtilisateursIdGet$Json } from '../fn/admin/admin-utilisateurs-id-get-json';
import { AdminUtilisateursIdGet$Json$Params } from '../fn/admin/admin-utilisateurs-id-get-json';
import { adminUtilisateursIdGet$Plain } from '../fn/admin/admin-utilisateurs-id-get-plain';
import { AdminUtilisateursIdGet$Plain$Params } from '../fn/admin/admin-utilisateurs-id-get-plain';
import { adminUtilisateursIdSignalementsGet$Any } from '../fn/admin/admin-utilisateurs-id-signalements-get-any';
import { AdminUtilisateursIdSignalementsGet$Any$Params } from '../fn/admin/admin-utilisateurs-id-signalements-get-any';
import { adminUtilisateursIdSignalementsGet$Json } from '../fn/admin/admin-utilisateurs-id-signalements-get-json';
import { AdminUtilisateursIdSignalementsGet$Json$Params } from '../fn/admin/admin-utilisateurs-id-signalements-get-json';
import { adminUtilisateursIdSignalementsGet$Plain } from '../fn/admin/admin-utilisateurs-id-signalements-get-plain';
import { AdminUtilisateursIdSignalementsGet$Plain$Params } from '../fn/admin/admin-utilisateurs-id-signalements-get-plain';
import { CategorieDtoOutputForDatagridOdataResponse } from '../models/categorie-dto-output-for-datagrid-odata-response';
import { ConversationAvecAdminDtoOutputForDetail } from '../models/conversation-avec-admin-dto-output-for-detail';
import { ConversationAvecAdminDtoOutputWithLastMessageForList } from '../models/conversation-avec-admin-dto-output-with-last-message-for-list';
import { LieuEchangeDtoOuputAvecGeolocalisation } from '../models/lieu-echange-dto-ouput-avec-geolocalisation';
import { LieuEchangeDtoOuputAvecGeolocalisationOdataResponse } from '../models/lieu-echange-dto-ouput-avec-geolocalisation-odata-response';
import { OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse } from '../models/offre-dto-output-with-buyer-for-utilisateur-detail-odata-response';
import { OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse } from '../models/offre-dto-output-with-seller-for-utilisateur-detail-odata-response';
import { ProgrammationNotificationDtoOutputForDetail } from '../models/programmation-notification-dto-output-for-detail';
import { ProgrammationNotificationDtoOutputForListOdataResponse } from '../models/programmation-notification-dto-output-for-list-odata-response';
import { ReferenceDtoOutputForAdminDatagridForDetail } from '../models/reference-dto-output-for-admin-datagrid-for-detail';
import { ReferenceDtoOutputForAdminDatagridForListOdataResponse } from '../models/reference-dto-output-for-admin-datagrid-for-list-odata-response';
import { SignalementOutputForDetailWithConversation } from '../models/signalement-output-for-detail-with-conversation';
import { SignalementOutputForListOdataResponse } from '../models/signalement-output-for-list-odata-response';
import { StatisticsCompleteDataDtoOutput } from '../models/statistics-complete-data-dto-output';
import { UserOutputForAdminDataGrid } from '../models/user-output-for-admin-data-grid';
import { UserOutputForAdminDataGridOdataResponse } from '../models/user-output-for-admin-data-grid-odata-response';

@Injectable({ providedIn: 'root' })
export class AdminService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminStatistiquesGet()` */
  static readonly AdminStatistiquesGetPath = '/admin/statistiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesGet$Json$Response(params?: AdminStatistiquesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<StatisticsCompleteDataDtoOutput>> {
    return adminStatistiquesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesGet$Json(params?: AdminStatistiquesGet$Json$Params, context?: HttpContext): Observable<StatisticsCompleteDataDtoOutput> {
    return this.adminStatistiquesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatisticsCompleteDataDtoOutput>): StatisticsCompleteDataDtoOutput => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesGet$Plain$Response(params?: AdminStatistiquesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<StatisticsCompleteDataDtoOutput>> {
    return adminStatistiquesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesGet$Plain(params?: AdminStatistiquesGet$Plain$Params, context?: HttpContext): Observable<StatisticsCompleteDataDtoOutput> {
    return this.adminStatistiquesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatisticsCompleteDataDtoOutput>): StatisticsCompleteDataDtoOutput => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesGet$Any$Response(params?: AdminStatistiquesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<StatisticsCompleteDataDtoOutput>> {
    return adminStatistiquesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesGet$Any(params?: AdminStatistiquesGet$Any$Params, context?: HttpContext): Observable<StatisticsCompleteDataDtoOutput> {
    return this.adminStatistiquesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatisticsCompleteDataDtoOutput>): StatisticsCompleteDataDtoOutput => r.body)
    );
  }

  /** Path part for operation `adminSignalementsGet()` */
  static readonly AdminSignalementsGetPath = '/admin/signalements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsGet$Json$Response(params?: AdminSignalementsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForListOdataResponse>> {
    return adminSignalementsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsGet$Json(params?: AdminSignalementsGet$Json$Params, context?: HttpContext): Observable<SignalementOutputForListOdataResponse> {
    return this.adminSignalementsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForListOdataResponse>): SignalementOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsGet$Plain$Response(params?: AdminSignalementsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForListOdataResponse>> {
    return adminSignalementsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsGet$Plain(params?: AdminSignalementsGet$Plain$Params, context?: HttpContext): Observable<SignalementOutputForListOdataResponse> {
    return this.adminSignalementsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForListOdataResponse>): SignalementOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsGet$Any$Response(params?: AdminSignalementsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForListOdataResponse>> {
    return adminSignalementsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsGet$Any(params?: AdminSignalementsGet$Any$Params, context?: HttpContext): Observable<SignalementOutputForListOdataResponse> {
    return this.adminSignalementsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForListOdataResponse>): SignalementOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminSignalementsIdGet()` */
  static readonly AdminSignalementsIdGetPath = '/admin/signalements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdGet$Json$Response(params: AdminSignalementsIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForDetailWithConversation>> {
    return adminSignalementsIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdGet$Json(params: AdminSignalementsIdGet$Json$Params, context?: HttpContext): Observable<SignalementOutputForDetailWithConversation> {
    return this.adminSignalementsIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForDetailWithConversation>): SignalementOutputForDetailWithConversation => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdGet$Plain$Response(params: AdminSignalementsIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForDetailWithConversation>> {
    return adminSignalementsIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdGet$Plain(params: AdminSignalementsIdGet$Plain$Params, context?: HttpContext): Observable<SignalementOutputForDetailWithConversation> {
    return this.adminSignalementsIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForDetailWithConversation>): SignalementOutputForDetailWithConversation => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdGet$Any$Response(params: AdminSignalementsIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForDetailWithConversation>> {
    return adminSignalementsIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdGet$Any(params: AdminSignalementsIdGet$Any$Params, context?: HttpContext): Observable<SignalementOutputForDetailWithConversation> {
    return this.adminSignalementsIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForDetailWithConversation>): SignalementOutputForDetailWithConversation => r.body)
    );
  }

  /** Path part for operation `adminSignalementsIdPut()` */
  static readonly AdminSignalementsIdPutPath = '/admin/signalements/{id}';

  /**
   * Permet de prendre action ou non.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsIdPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdPut$Json$Response(params: AdminSignalementsIdPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminSignalementsIdPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Permet de prendre action ou non.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsIdPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdPut$Json(params: AdminSignalementsIdPut$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminSignalementsIdPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Permet de prendre action ou non.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsIdPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdPut$Plain$Response(params: AdminSignalementsIdPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminSignalementsIdPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Permet de prendre action ou non.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsIdPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdPut$Plain(params: AdminSignalementsIdPut$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminSignalementsIdPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * Permet de prendre action ou non.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsIdPut$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdPut$Any$Response(params: AdminSignalementsIdPut$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminSignalementsIdPut$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Permet de prendre action ou non.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsIdPut$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdPut$Any(params: AdminSignalementsIdPut$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminSignalementsIdPut$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminSignalementsIdDelete()` */
  static readonly AdminSignalementsIdDeletePath = '/admin/signalements/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminSignalementsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdDelete$Response(params: AdminSignalementsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminSignalementsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminSignalementsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminSignalementsIdDelete(params: AdminSignalementsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminSignalementsIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminNotificationsProgrammeesGet()` */
  static readonly AdminNotificationsProgrammeesGetPath = '/admin/notifications-programmees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesGet$Json$Response(params?: AdminNotificationsProgrammeesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForListOdataResponse>> {
    return adminNotificationsProgrammeesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesGet$Json(params?: AdminNotificationsProgrammeesGet$Json$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForListOdataResponse> {
    return this.adminNotificationsProgrammeesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForListOdataResponse>): ProgrammationNotificationDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesGet$Plain$Response(params?: AdminNotificationsProgrammeesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForListOdataResponse>> {
    return adminNotificationsProgrammeesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesGet$Plain(params?: AdminNotificationsProgrammeesGet$Plain$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForListOdataResponse> {
    return this.adminNotificationsProgrammeesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForListOdataResponse>): ProgrammationNotificationDtoOutputForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesGet$Any$Response(params?: AdminNotificationsProgrammeesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForListOdataResponse>> {
    return adminNotificationsProgrammeesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesGet$Any(params?: AdminNotificationsProgrammeesGet$Any$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForListOdataResponse> {
    return this.adminNotificationsProgrammeesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForListOdataResponse>): ProgrammationNotificationDtoOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminNotificationsProgrammeesPost()` */
  static readonly AdminNotificationsProgrammeesPostPath = '/admin/notifications-programmees';

  /**
   * Créer une notification programmées.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminNotificationsProgrammeesPost$Response(params?: AdminNotificationsProgrammeesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminNotificationsProgrammeesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Créer une notification programmées.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminNotificationsProgrammeesPost(params?: AdminNotificationsProgrammeesPost$Params, context?: HttpContext): Observable<void> {
    return this.adminNotificationsProgrammeesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminNotificationsProgrammeesIdGet()` */
  static readonly AdminNotificationsProgrammeesIdGetPath = '/admin/notifications-programmees/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesIdGet$Json$Response(params: AdminNotificationsProgrammeesIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>> {
    return adminNotificationsProgrammeesIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesIdGet$Json(params: AdminNotificationsProgrammeesIdGet$Json$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForDetail> {
    return this.adminNotificationsProgrammeesIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>): ProgrammationNotificationDtoOutputForDetail => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesIdGet$Plain$Response(params: AdminNotificationsProgrammeesIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>> {
    return adminNotificationsProgrammeesIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesIdGet$Plain(params: AdminNotificationsProgrammeesIdGet$Plain$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForDetail> {
    return this.adminNotificationsProgrammeesIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>): ProgrammationNotificationDtoOutputForDetail => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesIdGet$Any$Response(params: AdminNotificationsProgrammeesIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>> {
    return adminNotificationsProgrammeesIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminNotificationsProgrammeesIdGet$Any(params: AdminNotificationsProgrammeesIdGet$Any$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForDetail> {
    return this.adminNotificationsProgrammeesIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>): ProgrammationNotificationDtoOutputForDetail => r.body)
    );
  }

  /** Path part for operation `adminNotificationsProgrammeesIdPut()` */
  static readonly AdminNotificationsProgrammeesIdPutPath = '/admin/notifications-programmees/{id}';

  /**
   * Edition.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminNotificationsProgrammeesIdPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminNotificationsProgrammeesIdPut$Response(params: AdminNotificationsProgrammeesIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminNotificationsProgrammeesIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Edition.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminNotificationsProgrammeesIdPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminNotificationsProgrammeesIdPut(params: AdminNotificationsProgrammeesIdPut$Params, context?: HttpContext): Observable<void> {
    return this.adminNotificationsProgrammeesIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminCategoriesGet()` */
  static readonly AdminCategoriesGetPath = '/admin/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCategoriesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCategoriesGet$Json$Response(params?: AdminCategoriesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<CategorieDtoOutputForDatagridOdataResponse>> {
    return adminCategoriesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCategoriesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCategoriesGet$Json(params?: AdminCategoriesGet$Json$Params, context?: HttpContext): Observable<CategorieDtoOutputForDatagridOdataResponse> {
    return this.adminCategoriesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategorieDtoOutputForDatagridOdataResponse>): CategorieDtoOutputForDatagridOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCategoriesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCategoriesGet$Plain$Response(params?: AdminCategoriesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<CategorieDtoOutputForDatagridOdataResponse>> {
    return adminCategoriesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCategoriesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCategoriesGet$Plain(params?: AdminCategoriesGet$Plain$Params, context?: HttpContext): Observable<CategorieDtoOutputForDatagridOdataResponse> {
    return this.adminCategoriesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategorieDtoOutputForDatagridOdataResponse>): CategorieDtoOutputForDatagridOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCategoriesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCategoriesGet$Any$Response(params?: AdminCategoriesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<CategorieDtoOutputForDatagridOdataResponse>> {
    return adminCategoriesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCategoriesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminCategoriesGet$Any(params?: AdminCategoriesGet$Any$Params, context?: HttpContext): Observable<CategorieDtoOutputForDatagridOdataResponse> {
    return this.adminCategoriesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<CategorieDtoOutputForDatagridOdataResponse>): CategorieDtoOutputForDatagridOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminCategoriesPost()` */
  static readonly AdminCategoriesPostPath = '/admin/categories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCategoriesPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminCategoriesPost$Response(params?: AdminCategoriesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminCategoriesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCategoriesPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminCategoriesPost(params?: AdminCategoriesPost$Params, context?: HttpContext): Observable<void> {
    return this.adminCategoriesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminCategoriesIdPut()` */
  static readonly AdminCategoriesIdPutPath = '/admin/categories/{id}';

  /**
   * Permet d'associer des familles à une catégorie.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminCategoriesIdPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminCategoriesIdPut$Response(params: AdminCategoriesIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminCategoriesIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Permet d'associer des familles à une catégorie.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminCategoriesIdPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminCategoriesIdPut(params: AdminCategoriesIdPut$Params, context?: HttpContext): Observable<void> {
    return this.adminCategoriesIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminReferencesGet()` */
  static readonly AdminReferencesGetPath = '/admin/references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesGet$Json$Response(params?: AdminReferencesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForListOdataResponse>> {
    return adminReferencesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesGet$Json(params?: AdminReferencesGet$Json$Params, context?: HttpContext): Observable<ReferenceDtoOutputForAdminDatagridForListOdataResponse> {
    return this.adminReferencesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForListOdataResponse>): ReferenceDtoOutputForAdminDatagridForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesGet$Plain$Response(params?: AdminReferencesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForListOdataResponse>> {
    return adminReferencesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesGet$Plain(params?: AdminReferencesGet$Plain$Params, context?: HttpContext): Observable<ReferenceDtoOutputForAdminDatagridForListOdataResponse> {
    return this.adminReferencesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForListOdataResponse>): ReferenceDtoOutputForAdminDatagridForListOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesGet$Any$Response(params?: AdminReferencesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForListOdataResponse>> {
    return adminReferencesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesGet$Any(params?: AdminReferencesGet$Any$Params, context?: HttpContext): Observable<ReferenceDtoOutputForAdminDatagridForListOdataResponse> {
    return this.adminReferencesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForListOdataResponse>): ReferenceDtoOutputForAdminDatagridForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminReferencesPut()` */
  static readonly AdminReferencesPutPath = '/admin/references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesPut()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminReferencesPut$Response(params?: AdminReferencesPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminReferencesPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesPut$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminReferencesPut(params?: AdminReferencesPut$Params, context?: HttpContext): Observable<void> {
    return this.adminReferencesPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminReferencesPost()` */
  static readonly AdminReferencesPostPath = '/admin/references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminReferencesPost$Response(params?: AdminReferencesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminReferencesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminReferencesPost(params?: AdminReferencesPost$Params, context?: HttpContext): Observable<void> {
    return this.adminReferencesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminReferencesReferenceGet()` */
  static readonly AdminReferencesReferenceGetPath = '/admin/references/{reference}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesReferenceGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesReferenceGet$Json$Response(params: AdminReferencesReferenceGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForDetail>> {
    return adminReferencesReferenceGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesReferenceGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesReferenceGet$Json(params: AdminReferencesReferenceGet$Json$Params, context?: HttpContext): Observable<ReferenceDtoOutputForAdminDatagridForDetail> {
    return this.adminReferencesReferenceGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForDetail>): ReferenceDtoOutputForAdminDatagridForDetail => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesReferenceGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesReferenceGet$Plain$Response(params: AdminReferencesReferenceGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForDetail>> {
    return adminReferencesReferenceGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesReferenceGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesReferenceGet$Plain(params: AdminReferencesReferenceGet$Plain$Params, context?: HttpContext): Observable<ReferenceDtoOutputForAdminDatagridForDetail> {
    return this.adminReferencesReferenceGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForDetail>): ReferenceDtoOutputForAdminDatagridForDetail => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminReferencesReferenceGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesReferenceGet$Any$Response(params: AdminReferencesReferenceGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForDetail>> {
    return adminReferencesReferenceGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminReferencesReferenceGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminReferencesReferenceGet$Any(params: AdminReferencesReferenceGet$Any$Params, context?: HttpContext): Observable<ReferenceDtoOutputForAdminDatagridForDetail> {
    return this.adminReferencesReferenceGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReferenceDtoOutputForAdminDatagridForDetail>): ReferenceDtoOutputForAdminDatagridForDetail => r.body)
    );
  }

  /** Path part for operation `adminExportDernieresReferencesGet()` */
  static readonly AdminExportDernieresReferencesGetPath = '/admin/export-dernieres-references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportDernieresReferencesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDernieresReferencesGet$Response(params?: AdminExportDernieresReferencesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportDernieresReferencesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportDernieresReferencesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDernieresReferencesGet(params?: AdminExportDernieresReferencesGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportDernieresReferencesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminImportReferencesPost()` */
  static readonly AdminImportReferencesPostPath = '/admin/import-references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportReferencesPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportReferencesPost$Json$Response(params?: AdminImportReferencesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportReferencesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportReferencesPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportReferencesPost$Json(params?: AdminImportReferencesPost$Json$Params, context?: HttpContext): Observable<number> {
    return this.adminImportReferencesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportReferencesPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportReferencesPost$Plain$Response(params?: AdminImportReferencesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportReferencesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportReferencesPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportReferencesPost$Plain(params?: AdminImportReferencesPost$Plain$Params, context?: HttpContext): Observable<number> {
    return this.adminImportReferencesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportReferencesPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportReferencesPost$Any$Response(params?: AdminImportReferencesPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportReferencesPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportReferencesPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportReferencesPost$Any(params?: AdminImportReferencesPost$Any$Params, context?: HttpContext): Observable<number> {
    return this.adminImportReferencesPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `adminUtilisateursGet()` */
  static readonly AdminUtilisateursGetPath = '/admin/utilisateurs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursGet$Json$Response(params?: AdminUtilisateursGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGridOdataResponse>> {
    return adminUtilisateursGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursGet$Json(params?: AdminUtilisateursGet$Json$Params, context?: HttpContext): Observable<UserOutputForAdminDataGridOdataResponse> {
    return this.adminUtilisateursGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGridOdataResponse>): UserOutputForAdminDataGridOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursGet$Plain$Response(params?: AdminUtilisateursGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGridOdataResponse>> {
    return adminUtilisateursGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursGet$Plain(params?: AdminUtilisateursGet$Plain$Params, context?: HttpContext): Observable<UserOutputForAdminDataGridOdataResponse> {
    return this.adminUtilisateursGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGridOdataResponse>): UserOutputForAdminDataGridOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursGet$Any$Response(params?: AdminUtilisateursGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGridOdataResponse>> {
    return adminUtilisateursGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursGet$Any(params?: AdminUtilisateursGet$Any$Params, context?: HttpContext): Observable<UserOutputForAdminDataGridOdataResponse> {
    return this.adminUtilisateursGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGridOdataResponse>): UserOutputForAdminDataGridOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminUtilisateursIdBannirPut()` */
  static readonly AdminUtilisateursIdBannirPutPath = '/admin/utilisateurs/{id}/bannir';

  /**
   * Permet de bannir ou de retirer le ban d'un utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdBannirPut$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdBannirPut$Json$Response(params: AdminUtilisateursIdBannirPut$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGrid>> {
    return adminUtilisateursIdBannirPut$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Permet de bannir ou de retirer le ban d'un utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdBannirPut$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdBannirPut$Json(params: AdminUtilisateursIdBannirPut$Json$Params, context?: HttpContext): Observable<UserOutputForAdminDataGrid> {
    return this.adminUtilisateursIdBannirPut$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGrid>): UserOutputForAdminDataGrid => r.body)
    );
  }

  /**
   * Permet de bannir ou de retirer le ban d'un utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdBannirPut$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdBannirPut$Plain$Response(params: AdminUtilisateursIdBannirPut$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGrid>> {
    return adminUtilisateursIdBannirPut$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Permet de bannir ou de retirer le ban d'un utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdBannirPut$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdBannirPut$Plain(params: AdminUtilisateursIdBannirPut$Plain$Params, context?: HttpContext): Observable<UserOutputForAdminDataGrid> {
    return this.adminUtilisateursIdBannirPut$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGrid>): UserOutputForAdminDataGrid => r.body)
    );
  }

  /**
   * Permet de bannir ou de retirer le ban d'un utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdBannirPut$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdBannirPut$Any$Response(params: AdminUtilisateursIdBannirPut$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGrid>> {
    return adminUtilisateursIdBannirPut$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Permet de bannir ou de retirer le ban d'un utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdBannirPut$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdBannirPut$Any(params: AdminUtilisateursIdBannirPut$Any$Params, context?: HttpContext): Observable<UserOutputForAdminDataGrid> {
    return this.adminUtilisateursIdBannirPut$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGrid>): UserOutputForAdminDataGrid => r.body)
    );
  }

  /** Path part for operation `adminUtilisateursIdGet()` */
  static readonly AdminUtilisateursIdGetPath = '/admin/utilisateurs/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdGet$Json$Response(params: AdminUtilisateursIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGrid>> {
    return adminUtilisateursIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdGet$Json(params: AdminUtilisateursIdGet$Json$Params, context?: HttpContext): Observable<UserOutputForAdminDataGrid> {
    return this.adminUtilisateursIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGrid>): UserOutputForAdminDataGrid => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdGet$Plain$Response(params: AdminUtilisateursIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGrid>> {
    return adminUtilisateursIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdGet$Plain(params: AdminUtilisateursIdGet$Plain$Params, context?: HttpContext): Observable<UserOutputForAdminDataGrid> {
    return this.adminUtilisateursIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGrid>): UserOutputForAdminDataGrid => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdGet$Any$Response(params: AdminUtilisateursIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<UserOutputForAdminDataGrid>> {
    return adminUtilisateursIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdGet$Any(params: AdminUtilisateursIdGet$Any$Params, context?: HttpContext): Observable<UserOutputForAdminDataGrid> {
    return this.adminUtilisateursIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserOutputForAdminDataGrid>): UserOutputForAdminDataGrid => r.body)
    );
  }

  /** Path part for operation `adminUtilisateursIdCommentaireAdminPost()` */
  static readonly AdminUtilisateursIdCommentaireAdminPostPath = '/admin/utilisateurs/{id}/commentaire-admin';

  /**
   * Mettre a jour le commentaire admin associé à un utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdCommentaireAdminPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminUtilisateursIdCommentaireAdminPost$Response(params: AdminUtilisateursIdCommentaireAdminPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminUtilisateursIdCommentaireAdminPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Mettre a jour le commentaire admin associé à un utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdCommentaireAdminPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminUtilisateursIdCommentaireAdminPost(params: AdminUtilisateursIdCommentaireAdminPost$Params, context?: HttpContext): Observable<void> {
    return this.adminUtilisateursIdCommentaireAdminPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminUtilisateursIdDonationsGet()` */
  static readonly AdminUtilisateursIdDonationsGetPath = '/admin/utilisateurs/{id}/donations';

  /**
   * Les dons de l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdDonationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDonationsGet$Json$Response(params: AdminUtilisateursIdDonationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse>> {
    return adminUtilisateursIdDonationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Les dons de l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdDonationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDonationsGet$Json(params: AdminUtilisateursIdDonationsGet$Json$Params, context?: HttpContext): Observable<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse> {
    return this.adminUtilisateursIdDonationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse>): OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse => r.body)
    );
  }

  /**
   * Les dons de l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdDonationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDonationsGet$Plain$Response(params: AdminUtilisateursIdDonationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse>> {
    return adminUtilisateursIdDonationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Les dons de l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdDonationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDonationsGet$Plain(params: AdminUtilisateursIdDonationsGet$Plain$Params, context?: HttpContext): Observable<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse> {
    return this.adminUtilisateursIdDonationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse>): OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse => r.body)
    );
  }

  /**
   * Les dons de l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdDonationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDonationsGet$Any$Response(params: AdminUtilisateursIdDonationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse>> {
    return adminUtilisateursIdDonationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Les dons de l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdDonationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDonationsGet$Any(params: AdminUtilisateursIdDonationsGet$Any$Params, context?: HttpContext): Observable<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse> {
    return this.adminUtilisateursIdDonationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse>): OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminUtilisateursIdDemandesGet()` */
  static readonly AdminUtilisateursIdDemandesGetPath = '/admin/utilisateurs/{id}/demandes';

  /**
   * Les demandes de l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdDemandesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDemandesGet$Json$Response(params: AdminUtilisateursIdDemandesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse>> {
    return adminUtilisateursIdDemandesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Les demandes de l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdDemandesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDemandesGet$Json(params: AdminUtilisateursIdDemandesGet$Json$Params, context?: HttpContext): Observable<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse> {
    return this.adminUtilisateursIdDemandesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse>): OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse => r.body)
    );
  }

  /**
   * Les demandes de l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdDemandesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDemandesGet$Plain$Response(params: AdminUtilisateursIdDemandesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse>> {
    return adminUtilisateursIdDemandesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Les demandes de l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdDemandesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDemandesGet$Plain(params: AdminUtilisateursIdDemandesGet$Plain$Params, context?: HttpContext): Observable<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse> {
    return this.adminUtilisateursIdDemandesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse>): OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse => r.body)
    );
  }

  /**
   * Les demandes de l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdDemandesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDemandesGet$Any$Response(params: AdminUtilisateursIdDemandesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse>> {
    return adminUtilisateursIdDemandesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Les demandes de l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdDemandesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdDemandesGet$Any(params: AdminUtilisateursIdDemandesGet$Any$Params, context?: HttpContext): Observable<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse> {
    return this.adminUtilisateursIdDemandesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse>): OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminUtilisateursIdSignalementsGet()` */
  static readonly AdminUtilisateursIdSignalementsGetPath = '/admin/utilisateurs/{id}/signalements';

  /**
   * Liste des signalements ciblant l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdSignalementsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdSignalementsGet$Json$Response(params: AdminUtilisateursIdSignalementsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForListOdataResponse>> {
    return adminUtilisateursIdSignalementsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste des signalements ciblant l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdSignalementsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdSignalementsGet$Json(params: AdminUtilisateursIdSignalementsGet$Json$Params, context?: HttpContext): Observable<SignalementOutputForListOdataResponse> {
    return this.adminUtilisateursIdSignalementsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForListOdataResponse>): SignalementOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Liste des signalements ciblant l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdSignalementsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdSignalementsGet$Plain$Response(params: AdminUtilisateursIdSignalementsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForListOdataResponse>> {
    return adminUtilisateursIdSignalementsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste des signalements ciblant l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdSignalementsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdSignalementsGet$Plain(params: AdminUtilisateursIdSignalementsGet$Plain$Params, context?: HttpContext): Observable<SignalementOutputForListOdataResponse> {
    return this.adminUtilisateursIdSignalementsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForListOdataResponse>): SignalementOutputForListOdataResponse => r.body)
    );
  }

  /**
   * Liste des signalements ciblant l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUtilisateursIdSignalementsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdSignalementsGet$Any$Response(params: AdminUtilisateursIdSignalementsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<SignalementOutputForListOdataResponse>> {
    return adminUtilisateursIdSignalementsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Liste des signalements ciblant l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUtilisateursIdSignalementsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminUtilisateursIdSignalementsGet$Any(params: AdminUtilisateursIdSignalementsGet$Any$Params, context?: HttpContext): Observable<SignalementOutputForListOdataResponse> {
    return this.adminUtilisateursIdSignalementsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignalementOutputForListOdataResponse>): SignalementOutputForListOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminExportDerniereListeUtilisateursGet()` */
  static readonly AdminExportDerniereListeUtilisateursGetPath = '/admin/export-derniere-liste-utilisateurs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportDerniereListeUtilisateursGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDerniereListeUtilisateursGet$Response(params?: AdminExportDerniereListeUtilisateursGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportDerniereListeUtilisateursGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportDerniereListeUtilisateursGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDerniereListeUtilisateursGet(params?: AdminExportDerniereListeUtilisateursGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportDerniereListeUtilisateursGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminImportUtilisateursPost()` */
  static readonly AdminImportUtilisateursPostPath = '/admin/import-utilisateurs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportUtilisateursPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportUtilisateursPost$Json$Response(params?: AdminImportUtilisateursPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportUtilisateursPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportUtilisateursPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportUtilisateursPost$Json(params?: AdminImportUtilisateursPost$Json$Params, context?: HttpContext): Observable<number> {
    return this.adminImportUtilisateursPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportUtilisateursPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportUtilisateursPost$Plain$Response(params?: AdminImportUtilisateursPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportUtilisateursPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportUtilisateursPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportUtilisateursPost$Plain(params?: AdminImportUtilisateursPost$Plain$Params, context?: HttpContext): Observable<number> {
    return this.adminImportUtilisateursPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportUtilisateursPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportUtilisateursPost$Any$Response(params?: AdminImportUtilisateursPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportUtilisateursPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportUtilisateursPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportUtilisateursPost$Any(params?: AdminImportUtilisateursPost$Any$Params, context?: HttpContext): Observable<number> {
    return this.adminImportUtilisateursPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `adminStatistiquesArticlesEnCoursCompteGet()` */
  static readonly AdminStatistiquesArticlesEnCoursCompteGetPath = '/admin/statistiques/articles/en-cours/compte';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesArticlesEnCoursCompteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesEnCoursCompteGet$Json$Response(params?: AdminStatistiquesArticlesEnCoursCompteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesArticlesEnCoursCompteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesArticlesEnCoursCompteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesEnCoursCompteGet$Json(params?: AdminStatistiquesArticlesEnCoursCompteGet$Json$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesArticlesEnCoursCompteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesArticlesEnCoursCompteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesEnCoursCompteGet$Plain$Response(params?: AdminStatistiquesArticlesEnCoursCompteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesArticlesEnCoursCompteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesArticlesEnCoursCompteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesEnCoursCompteGet$Plain(params?: AdminStatistiquesArticlesEnCoursCompteGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesArticlesEnCoursCompteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesArticlesEnCoursCompteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesEnCoursCompteGet$Any$Response(params?: AdminStatistiquesArticlesEnCoursCompteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesArticlesEnCoursCompteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesArticlesEnCoursCompteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesEnCoursCompteGet$Any(params?: AdminStatistiquesArticlesEnCoursCompteGet$Any$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesArticlesEnCoursCompteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `adminStatistiquesArticlesClotureesCompteGet()` */
  static readonly AdminStatistiquesArticlesClotureesCompteGetPath = '/admin/statistiques/articles/cloturees/compte';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesArticlesClotureesCompteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesClotureesCompteGet$Json$Response(params?: AdminStatistiquesArticlesClotureesCompteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesArticlesClotureesCompteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesArticlesClotureesCompteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesClotureesCompteGet$Json(params?: AdminStatistiquesArticlesClotureesCompteGet$Json$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesArticlesClotureesCompteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesArticlesClotureesCompteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesClotureesCompteGet$Plain$Response(params?: AdminStatistiquesArticlesClotureesCompteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesArticlesClotureesCompteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesArticlesClotureesCompteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesClotureesCompteGet$Plain(params?: AdminStatistiquesArticlesClotureesCompteGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesArticlesClotureesCompteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesArticlesClotureesCompteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesClotureesCompteGet$Any$Response(params?: AdminStatistiquesArticlesClotureesCompteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesArticlesClotureesCompteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesArticlesClotureesCompteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesArticlesClotureesCompteGet$Any(params?: AdminStatistiquesArticlesClotureesCompteGet$Any$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesArticlesClotureesCompteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `adminStatistiquesImpactCarboneGet()` */
  static readonly AdminStatistiquesImpactCarboneGetPath = '/admin/statistiques/impact-carbone';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesImpactCarboneGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneGet$Json$Response(params?: AdminStatistiquesImpactCarboneGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesImpactCarboneGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesImpactCarboneGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneGet$Json(params?: AdminStatistiquesImpactCarboneGet$Json$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesImpactCarboneGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesImpactCarboneGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneGet$Plain$Response(params?: AdminStatistiquesImpactCarboneGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesImpactCarboneGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesImpactCarboneGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneGet$Plain(params?: AdminStatistiquesImpactCarboneGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesImpactCarboneGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesImpactCarboneGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneGet$Any$Response(params?: AdminStatistiquesImpactCarboneGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesImpactCarboneGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesImpactCarboneGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneGet$Any(params?: AdminStatistiquesImpactCarboneGet$Any$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesImpactCarboneGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `adminStatistiquesImpactCarboneEquivalentArbresGet()` */
  static readonly AdminStatistiquesImpactCarboneEquivalentArbresGetPath = '/admin/statistiques/impact-carbone/equivalent-arbres';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesImpactCarboneEquivalentArbresGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneEquivalentArbresGet$Json$Response(params?: AdminStatistiquesImpactCarboneEquivalentArbresGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesImpactCarboneEquivalentArbresGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesImpactCarboneEquivalentArbresGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneEquivalentArbresGet$Json(params?: AdminStatistiquesImpactCarboneEquivalentArbresGet$Json$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesImpactCarboneEquivalentArbresGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesImpactCarboneEquivalentArbresGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneEquivalentArbresGet$Plain$Response(params?: AdminStatistiquesImpactCarboneEquivalentArbresGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesImpactCarboneEquivalentArbresGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesImpactCarboneEquivalentArbresGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneEquivalentArbresGet$Plain(params?: AdminStatistiquesImpactCarboneEquivalentArbresGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesImpactCarboneEquivalentArbresGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminStatistiquesImpactCarboneEquivalentArbresGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneEquivalentArbresGet$Any$Response(params?: AdminStatistiquesImpactCarboneEquivalentArbresGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminStatistiquesImpactCarboneEquivalentArbresGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminStatistiquesImpactCarboneEquivalentArbresGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminStatistiquesImpactCarboneEquivalentArbresGet$Any(params?: AdminStatistiquesImpactCarboneEquivalentArbresGet$Any$Params, context?: HttpContext): Observable<number> {
    return this.adminStatistiquesImpactCarboneEquivalentArbresGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `adminInformationsRecyclageGet()` */
  static readonly AdminInformationsRecyclageGetPath = '/admin/informations/recyclage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsRecyclageGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsRecyclageGet$Json$Response(params?: AdminInformationsRecyclageGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsRecyclageGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsRecyclageGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsRecyclageGet$Json(params?: AdminInformationsRecyclageGet$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsRecyclageGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsRecyclageGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsRecyclageGet$Plain$Response(params?: AdminInformationsRecyclageGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsRecyclageGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsRecyclageGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsRecyclageGet$Plain(params?: AdminInformationsRecyclageGet$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsRecyclageGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsRecyclageGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsRecyclageGet$Any$Response(params?: AdminInformationsRecyclageGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsRecyclageGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsRecyclageGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsRecyclageGet$Any(params?: AdminInformationsRecyclageGet$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsRecyclageGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminInformationsRecyclagePost()` */
  static readonly AdminInformationsRecyclagePostPath = '/admin/informations/recyclage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsRecyclagePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsRecyclagePost$Json$Response(params?: AdminInformationsRecyclagePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsRecyclagePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsRecyclagePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsRecyclagePost$Json(params?: AdminInformationsRecyclagePost$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsRecyclagePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsRecyclagePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsRecyclagePost$Plain$Response(params?: AdminInformationsRecyclagePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsRecyclagePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsRecyclagePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsRecyclagePost$Plain(params?: AdminInformationsRecyclagePost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsRecyclagePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsRecyclagePost$Any()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsRecyclagePost$Any$Response(params?: AdminInformationsRecyclagePost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsRecyclagePost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsRecyclagePost$Any$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsRecyclagePost$Any(params?: AdminInformationsRecyclagePost$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsRecyclagePost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminInformationsSymbioseGet()` */
  static readonly AdminInformationsSymbioseGetPath = '/admin/informations/symbiose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsSymbioseGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsSymbioseGet$Json$Response(params?: AdminInformationsSymbioseGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsSymbioseGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsSymbioseGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsSymbioseGet$Json(params?: AdminInformationsSymbioseGet$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsSymbioseGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsSymbioseGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsSymbioseGet$Plain$Response(params?: AdminInformationsSymbioseGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsSymbioseGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsSymbioseGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsSymbioseGet$Plain(params?: AdminInformationsSymbioseGet$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsSymbioseGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsSymbioseGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsSymbioseGet$Any$Response(params?: AdminInformationsSymbioseGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsSymbioseGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsSymbioseGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsSymbioseGet$Any(params?: AdminInformationsSymbioseGet$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsSymbioseGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminInformationsSymbiosePost()` */
  static readonly AdminInformationsSymbiosePostPath = '/admin/informations/symbiose';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsSymbiosePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsSymbiosePost$Json$Response(params?: AdminInformationsSymbiosePost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsSymbiosePost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsSymbiosePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsSymbiosePost$Json(params?: AdminInformationsSymbiosePost$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsSymbiosePost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsSymbiosePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsSymbiosePost$Plain$Response(params?: AdminInformationsSymbiosePost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsSymbiosePost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsSymbiosePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsSymbiosePost$Plain(params?: AdminInformationsSymbiosePost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsSymbiosePost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsSymbiosePost$Any()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsSymbiosePost$Any$Response(params?: AdminInformationsSymbiosePost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsSymbiosePost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsSymbiosePost$Any$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsSymbiosePost$Any(params?: AdminInformationsSymbiosePost$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsSymbiosePost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminInformationsCguGet()` */
  static readonly AdminInformationsCguGetPath = '/admin/informations/cgu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsCguGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsCguGet$Json$Response(params?: AdminInformationsCguGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsCguGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsCguGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsCguGet$Json(params?: AdminInformationsCguGet$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsCguGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsCguGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsCguGet$Plain$Response(params?: AdminInformationsCguGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsCguGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsCguGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsCguGet$Plain(params?: AdminInformationsCguGet$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsCguGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsCguGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsCguGet$Any$Response(params?: AdminInformationsCguGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsCguGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsCguGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminInformationsCguGet$Any(params?: AdminInformationsCguGet$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsCguGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminInformationsCguPost()` */
  static readonly AdminInformationsCguPostPath = '/admin/informations/cgu';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsCguPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsCguPost$Json$Response(params?: AdminInformationsCguPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsCguPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsCguPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsCguPost$Json(params?: AdminInformationsCguPost$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsCguPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsCguPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsCguPost$Plain$Response(params?: AdminInformationsCguPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsCguPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsCguPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsCguPost$Plain(params?: AdminInformationsCguPost$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsCguPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminInformationsCguPost$Any()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsCguPost$Any$Response(params?: AdminInformationsCguPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminInformationsCguPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminInformationsCguPost$Any$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminInformationsCguPost$Any(params?: AdminInformationsCguPost$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminInformationsCguPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminMessagesMurDesConversationsGet()` */
  static readonly AdminMessagesMurDesConversationsGetPath = '/admin/messages/mur-des-conversations';

  /**
   * En tant qu'admin, Obtenir le mur des conversations avec les utilisateurs avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesMurDesConversationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesMurDesConversationsGet$Json$Response(params?: AdminMessagesMurDesConversationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>> {
    return adminMessagesMurDesConversationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * En tant qu'admin, Obtenir le mur des conversations avec les utilisateurs avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesMurDesConversationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesMurDesConversationsGet$Json(params?: AdminMessagesMurDesConversationsGet$Json$Params, context?: HttpContext): Observable<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>> {
    return this.adminMessagesMurDesConversationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>): Array<ConversationAvecAdminDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /**
   * En tant qu'admin, Obtenir le mur des conversations avec les utilisateurs avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesMurDesConversationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesMurDesConversationsGet$Plain$Response(params?: AdminMessagesMurDesConversationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>> {
    return adminMessagesMurDesConversationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * En tant qu'admin, Obtenir le mur des conversations avec les utilisateurs avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesMurDesConversationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesMurDesConversationsGet$Plain(params?: AdminMessagesMurDesConversationsGet$Plain$Params, context?: HttpContext): Observable<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>> {
    return this.adminMessagesMurDesConversationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>): Array<ConversationAvecAdminDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /**
   * En tant qu'admin, Obtenir le mur des conversations avec les utilisateurs avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesMurDesConversationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesMurDesConversationsGet$Any$Response(params?: AdminMessagesMurDesConversationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>> {
    return adminMessagesMurDesConversationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * En tant qu'admin, Obtenir le mur des conversations avec les utilisateurs avec le dernier mesage envoyé.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesMurDesConversationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesMurDesConversationsGet$Any(params?: AdminMessagesMurDesConversationsGet$Any$Params, context?: HttpContext): Observable<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>> {
    return this.adminMessagesMurDesConversationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversationAvecAdminDtoOutputWithLastMessageForList>>): Array<ConversationAvecAdminDtoOutputWithLastMessageForList> => r.body)
    );
  }

  /** Path part for operation `adminMessagesUtilisateursUtilisateurIdGet()` */
  static readonly AdminMessagesUtilisateursUtilisateurIdGetPath = '/admin/messages/utilisateurs/{utilisateurId}';

  /**
   * En tant qu'admin, voir le contenu d'une conversation avec un utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesUtilisateursUtilisateurIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdGet$Json$Response(params: AdminMessagesUtilisateursUtilisateurIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>> {
    return adminMessagesUtilisateursUtilisateurIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * En tant qu'admin, voir le contenu d'une conversation avec un utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesUtilisateursUtilisateurIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdGet$Json(params: AdminMessagesUtilisateursUtilisateurIdGet$Json$Params, context?: HttpContext): Observable<ConversationAvecAdminDtoOutputForDetail> {
    return this.adminMessagesUtilisateursUtilisateurIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>): ConversationAvecAdminDtoOutputForDetail => r.body)
    );
  }

  /**
   * En tant qu'admin, voir le contenu d'une conversation avec un utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesUtilisateursUtilisateurIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdGet$Plain$Response(params: AdminMessagesUtilisateursUtilisateurIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>> {
    return adminMessagesUtilisateursUtilisateurIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * En tant qu'admin, voir le contenu d'une conversation avec un utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesUtilisateursUtilisateurIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdGet$Plain(params: AdminMessagesUtilisateursUtilisateurIdGet$Plain$Params, context?: HttpContext): Observable<ConversationAvecAdminDtoOutputForDetail> {
    return this.adminMessagesUtilisateursUtilisateurIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>): ConversationAvecAdminDtoOutputForDetail => r.body)
    );
  }

  /**
   * En tant qu'admin, voir le contenu d'une conversation avec un utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesUtilisateursUtilisateurIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdGet$Any$Response(params: AdminMessagesUtilisateursUtilisateurIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>> {
    return adminMessagesUtilisateursUtilisateurIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * En tant qu'admin, voir le contenu d'une conversation avec un utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesUtilisateursUtilisateurIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdGet$Any(params: AdminMessagesUtilisateursUtilisateurIdGet$Any$Params, context?: HttpContext): Observable<ConversationAvecAdminDtoOutputForDetail> {
    return this.adminMessagesUtilisateursUtilisateurIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConversationAvecAdminDtoOutputForDetail>): ConversationAvecAdminDtoOutputForDetail => r.body)
    );
  }

  /** Path part for operation `adminMessagesUtilisateursUtilisateurIdPost()` */
  static readonly AdminMessagesUtilisateursUtilisateurIdPostPath = '/admin/messages/utilisateurs/{utilisateurId}';

  /**
   * Contact l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesUtilisateursUtilisateurIdPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminMessagesUtilisateursUtilisateurIdPost$Response(params: AdminMessagesUtilisateursUtilisateurIdPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminMessagesUtilisateursUtilisateurIdPost(this.http, this.rootUrl, params, context);
  }

  /**
   * Contact l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesUtilisateursUtilisateurIdPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminMessagesUtilisateursUtilisateurIdPost(params: AdminMessagesUtilisateursUtilisateurIdPost$Params, context?: HttpContext): Observable<void> {
    return this.adminMessagesUtilisateursUtilisateurIdPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminMessagesUtilisateursUtilisateurIdDelete()` */
  static readonly AdminMessagesUtilisateursUtilisateurIdDeletePath = '/admin/messages/utilisateurs/{utilisateurId}';

  /**
   * En tant qu'admin, Feremer la conversation avec l'utilisateur.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminMessagesUtilisateursUtilisateurIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdDelete$Response(params: AdminMessagesUtilisateursUtilisateurIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminMessagesUtilisateursUtilisateurIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * En tant qu'admin, Feremer la conversation avec l'utilisateur.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminMessagesUtilisateursUtilisateurIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminMessagesUtilisateursUtilisateurIdDelete(params: AdminMessagesUtilisateursUtilisateurIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminMessagesUtilisateursUtilisateurIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminAdressesPubliquesGet()` */
  static readonly AdminAdressesPubliquesGetPath = '/admin/adresses-publiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesGet$Json$Response(params?: AdminAdressesPubliquesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse>> {
    return adminAdressesPubliquesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesGet$Json(params?: AdminAdressesPubliquesGet$Json$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse> {
    return this.adminAdressesPubliquesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse>): LieuEchangeDtoOuputAvecGeolocalisationOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesGet$Plain$Response(params?: AdminAdressesPubliquesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse>> {
    return adminAdressesPubliquesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesGet$Plain(params?: AdminAdressesPubliquesGet$Plain$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse> {
    return this.adminAdressesPubliquesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse>): LieuEchangeDtoOuputAvecGeolocalisationOdataResponse => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesGet$Any$Response(params?: AdminAdressesPubliquesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse>> {
    return adminAdressesPubliquesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesGet$Any(params?: AdminAdressesPubliquesGet$Any$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse> {
    return this.adminAdressesPubliquesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisationOdataResponse>): LieuEchangeDtoOuputAvecGeolocalisationOdataResponse => r.body)
    );
  }

  /** Path part for operation `adminAdressesPubliquesPost()` */
  static readonly AdminAdressesPubliquesPostPath = '/admin/adresses-publiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminAdressesPubliquesPost$Response(params?: AdminAdressesPubliquesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminAdressesPubliquesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminAdressesPubliquesPost(params?: AdminAdressesPubliquesPost$Params, context?: HttpContext): Observable<void> {
    return this.adminAdressesPubliquesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminAdressesPubliquesIdGet()` */
  static readonly AdminAdressesPubliquesIdGetPath = '/admin/adresses-publiques/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdGet$Json$Response(params: AdminAdressesPubliquesIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>> {
    return adminAdressesPubliquesIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdGet$Json(params: AdminAdressesPubliquesIdGet$Json$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisation> {
    return this.adminAdressesPubliquesIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>): LieuEchangeDtoOuputAvecGeolocalisation => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdGet$Plain$Response(params: AdminAdressesPubliquesIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>> {
    return adminAdressesPubliquesIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdGet$Plain(params: AdminAdressesPubliquesIdGet$Plain$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisation> {
    return this.adminAdressesPubliquesIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>): LieuEchangeDtoOuputAvecGeolocalisation => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdGet$Any$Response(params: AdminAdressesPubliquesIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>> {
    return adminAdressesPubliquesIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdGet$Any(params: AdminAdressesPubliquesIdGet$Any$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisation> {
    return this.adminAdressesPubliquesIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>): LieuEchangeDtoOuputAvecGeolocalisation => r.body)
    );
  }

  /** Path part for operation `adminAdressesPubliquesIdPut()` */
  static readonly AdminAdressesPubliquesIdPutPath = '/admin/adresses-publiques/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesIdPut()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminAdressesPubliquesIdPut$Response(params: AdminAdressesPubliquesIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminAdressesPubliquesIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesIdPut$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  adminAdressesPubliquesIdPut(params: AdminAdressesPubliquesIdPut$Params, context?: HttpContext): Observable<void> {
    return this.adminAdressesPubliquesIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminAdressesPubliquesIdDelete()` */
  static readonly AdminAdressesPubliquesIdDeletePath = '/admin/adresses-publiques/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminAdressesPubliquesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdDelete$Response(params: AdminAdressesPubliquesIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminAdressesPubliquesIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminAdressesPubliquesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminAdressesPubliquesIdDelete(params: AdminAdressesPubliquesIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.adminAdressesPubliquesIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminExportCommunesGet()` */
  static readonly AdminExportCommunesGetPath = '/admin/export-communes';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportCommunesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportCommunesGet$Response(params?: AdminExportCommunesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportCommunesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportCommunesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportCommunesGet(params?: AdminExportCommunesGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportCommunesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminExportDerniereListeAdressesGet()` */
  static readonly AdminExportDerniereListeAdressesGetPath = '/admin/export-derniere-liste-adresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportDerniereListeAdressesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDerniereListeAdressesGet$Response(params?: AdminExportDerniereListeAdressesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportDerniereListeAdressesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportDerniereListeAdressesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDerniereListeAdressesGet(params?: AdminExportDerniereListeAdressesGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportDerniereListeAdressesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminImportAdressesPost()` */
  static readonly AdminImportAdressesPostPath = '/admin/import-adresses';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportAdressesPost$Json()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportAdressesPost$Json$Response(params?: AdminImportAdressesPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportAdressesPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportAdressesPost$Json$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportAdressesPost$Json(params?: AdminImportAdressesPost$Json$Params, context?: HttpContext): Observable<number> {
    return this.adminImportAdressesPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportAdressesPost$Plain()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportAdressesPost$Plain$Response(params?: AdminImportAdressesPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportAdressesPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportAdressesPost$Plain$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportAdressesPost$Plain(params?: AdminImportAdressesPost$Plain$Params, context?: HttpContext): Observable<number> {
    return this.adminImportAdressesPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminImportAdressesPost$Any()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportAdressesPost$Any$Response(params?: AdminImportAdressesPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return adminImportAdressesPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminImportAdressesPost$Any$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminImportAdressesPost$Any(params?: AdminImportAdressesPost$Any$Params, context?: HttpContext): Observable<number> {
    return this.adminImportAdressesPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `adminGuideDesTaillesGet()` */
  static readonly AdminGuideDesTaillesGetPath = '/admin/guide-des-tailles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuideDesTaillesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuideDesTaillesGet$Json$Response(params?: AdminGuideDesTaillesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminGuideDesTaillesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuideDesTaillesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuideDesTaillesGet$Json(params?: AdminGuideDesTaillesGet$Json$Params, context?: HttpContext): Observable<string> {
    return this.adminGuideDesTaillesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuideDesTaillesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuideDesTaillesGet$Plain$Response(params?: AdminGuideDesTaillesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminGuideDesTaillesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuideDesTaillesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuideDesTaillesGet$Plain(params?: AdminGuideDesTaillesGet$Plain$Params, context?: HttpContext): Observable<string> {
    return this.adminGuideDesTaillesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuideDesTaillesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuideDesTaillesGet$Any$Response(params?: AdminGuideDesTaillesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return adminGuideDesTaillesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuideDesTaillesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGuideDesTaillesGet$Any(params?: AdminGuideDesTaillesGet$Any$Params, context?: HttpContext): Observable<string> {
    return this.adminGuideDesTaillesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `adminGuideDesTaillesPost()` */
  static readonly AdminGuideDesTaillesPostPath = '/admin/guide-des-tailles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGuideDesTaillesPost()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminGuideDesTaillesPost$Response(params?: AdminGuideDesTaillesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminGuideDesTaillesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGuideDesTaillesPost$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminGuideDesTaillesPost(params?: AdminGuideDesTaillesPost$Params, context?: HttpContext): Observable<void> {
    return this.adminGuideDesTaillesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminExportStatistiquesGet()` */
  static readonly AdminExportStatistiquesGetPath = '/admin/export-statistiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportStatistiquesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportStatistiquesGet$Response(params?: AdminExportStatistiquesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportStatistiquesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportStatistiquesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportStatistiquesGet(params?: AdminExportStatistiquesGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportStatistiquesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminExportListeUtilisateursGet()` */
  static readonly AdminExportListeUtilisateursGetPath = '/admin/export-liste-utilisateurs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportListeUtilisateursGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportListeUtilisateursGet$Response(params?: AdminExportListeUtilisateursGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportListeUtilisateursGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportListeUtilisateursGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportListeUtilisateursGet(params?: AdminExportListeUtilisateursGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportListeUtilisateursGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminExportDetailsUtilisateurIdGet()` */
  static readonly AdminExportDetailsUtilisateurIdGetPath = '/admin/export-details-utilisateur/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportDetailsUtilisateurIdGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDetailsUtilisateurIdGet$Response(params: AdminExportDetailsUtilisateurIdGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportDetailsUtilisateurIdGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportDetailsUtilisateurIdGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportDetailsUtilisateurIdGet(params: AdminExportDetailsUtilisateurIdGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportDetailsUtilisateurIdGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminExportNotificationsGet()` */
  static readonly AdminExportNotificationsGetPath = '/admin/export-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportNotificationsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportNotificationsGet$Response(params?: AdminExportNotificationsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportNotificationsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportNotificationsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportNotificationsGet(params?: AdminExportNotificationsGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportNotificationsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminExportReferencesGet()` */
  static readonly AdminExportReferencesGetPath = '/admin/export-references';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportReferencesGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportReferencesGet$Response(params?: AdminExportReferencesGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportReferencesGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportReferencesGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportReferencesGet(params?: AdminExportReferencesGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportReferencesGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

  /** Path part for operation `adminExportSignalementsGet()` */
  static readonly AdminExportSignalementsGetPath = '/admin/export-signalements';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminExportSignalementsGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportSignalementsGet$Response(params?: AdminExportSignalementsGet$Params, context?: HttpContext): Observable<StrictHttpResponse<Blob>> {
    return adminExportSignalementsGet(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminExportSignalementsGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminExportSignalementsGet(params?: AdminExportSignalementsGet$Params, context?: HttpContext): Observable<Blob> {
    return this.adminExportSignalementsGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<Blob>): Blob => r.body)
    );
  }

}
