/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse } from '../../models/offre-dto-output-with-favorites-and-modality-icons-for-list-odata-response';

export interface OffresFavorisGet$Any$Params {

/**
 * The max number of records.
 */
  '$top'?: Number;

/**
 * The number of records to skip.
 */
  '$skip'?: Number;

/**
 * A function that must evaluate to true for a record to be returned.
 */
  '$filter'?: String;

/**
 * Determines what values are used to order a collection of records.
 */
  '$orderby'?: String;
}

export function offresFavorisGet$Any(http: HttpClient, rootUrl: string, params?: OffresFavorisGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>> {
  const rb = new RequestBuilder(rootUrl, offresFavorisGet$Any.PATH, 'get');
  if (params) {
    rb.query('$top', params['$top'], {});
    rb.query('$skip', params['$skip'], {});
    rb.query('$filter', params['$filter'], {});
    rb.query('$orderby', params['$orderby'], {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OffreDtoOutputWithFavoritesAndModalityIconsForListOdataResponse>;
    })
  );
}

offresFavorisGet$Any.PATH = '/offres/favoris';
