import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { AdminNotificationsProgrammeesGet$Json$Params } from '@app/api/fn/admin/admin-notifications-programmees-get-json';
import { ProgrammationNotificationDtoOutputForList, ProgrammationNotificationDtoOutputForListOdataResponse } from '@app/api/models';
import { AdminService } from '@app/api/services';
import { GridApi, ColDef, GridReadyEvent, SortChangedEvent, ValueGetterParams, SortDirection, FilterChangedEvent } from 'ag-grid-community';
import { DatagridComponent } from '../../components/datagrid/datagrid.component';
import { TypeColorComponent } from '@app/components/datagrid/type-color/type-color.component';
import { mapAgGridFiltersToOData } from '@app/utils/aggrid-to-odatafilter';
import { MatButtonModule } from '@angular/material/button';
import { ExportButtonComponent } from '../../export-button/export-button.component';
import '@app/utils/http-headers-utils';
import DownloadFile from '@app/utils/http-headers-utils';

@Component({
    selector: 'app-notifications',
    standalone: true,
    templateUrl: './notifications.component.html',
    styleUrl: './notifications.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [DatagridComponent, MatButtonModule, ExportButtonComponent],
})
export class NotificationsComponent implements OnInit {
    private gridApi!: GridApi<ProgrammationNotificationDtoOutputForList>;

    // Column Definitions
    colDefs: ColDef[] = [
        { headerName: 'Titre', field: 'titre', filter: true, sortable: true, onCellClicked: this.rowClick.bind(this) },
        {
            headerName: 'Type',
            field: 'type/type',
            type: 'string',
            cellRenderer: TypeColorComponent,
            onCellClicked: this.rowClick.bind(this),
            filter: true,
            sortable: true,
            filterValueGetter: (p) => p.data.type?.nom,
            valueGetter: (p: ValueGetterParams<ProgrammationNotificationDtoOutputForList, any>) => p.data?.type?.type,
        },
        {
            headerName: 'Date de publication',
            field: 'datePublication',
            valueGetter: (p: ValueGetterParams<ProgrammationNotificationDtoOutputForList, any>) => p.data?.datePublication,
            filter: true,
            type: 'date',
            sortable: true,
            onCellClicked: this.rowClick.bind(this),
            valueFormatter: (params) => {
                const dateValue = params.value;
                return dateValue ? this.getDatePublicaiton(dateValue) : '';
            },
        },
        {
            headerName: 'Récurrence',
            field: 'recurrence',
            valueGetter: (p: ValueGetterParams<ProgrammationNotificationDtoOutputForList, any>) => this.getTextRecurrence(p.data),
            filter: false,
            sortable: false,
            onCellClicked: this.rowClick.bind(this),
        },
        {
            headerName: 'Cible (N° département)',
            field: 'cible',
            valueGetter: (p: ValueGetterParams<ProgrammationNotificationDtoOutputForList, any>) => this.getTextDepartement(p.data),
            filter: false,
            sortable: false,
            onCellClicked: this.rowClick.bind(this),
        },
    ];
    nbRecords = 50;
    fetchParams: AdminNotificationsProgrammeesGet$Json$Params = {
        $top: this.nbRecords,
    };

    rawData: ProgrammationNotificationDtoOutputForListOdataResponse | null = null;

    data: ProgrammationNotificationDtoOutputForList[] | null = null;
    activeSort: { field: string; sort: SortDirection | undefined }[] = [];
    oDataFilters: string | undefined;
    showCategorie: any;

    constructor(
        private router: Router,
        private adminService: AdminService,
        private cdr: ChangeDetectorRef,
    ) {}

    ngOnInit(): void {
        this.fetchNotifications();
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
    }

    fetchNotifications(params = this.fetchParams) {
        this.adminService.adminNotificationsProgrammeesGet$Json(params).subscribe((res: ProgrammationNotificationDtoOutputForListOdataResponse) => {
            this.rawData = res;
            this.cdr.detectChanges();
        });
    }

    rowClick(event: any) {
        this.router.navigate(['/notification', event.data.id]);
    }

    addNotif() {
        this.router.navigate(['/notification']);
    }

    export() {
        this.adminService.adminExportNotificationsGet$Response().subscribe((res) => {
            DownloadFile(res);
        });
    }

    onFilterChanged(event: FilterChangedEvent<any, any>) {
        if (event.columns) {
            const filtersSelected = event.api.getFilterModel();
            if (Object.keys(filtersSelected).length === 0) {
                this.resetSort(false, true);
                this.fetchNotifications();
                return;
            }
            this.oDataFilters = Object.entries(filtersSelected)
                .map(([key, filter]) => {
                    return mapAgGridFiltersToOData(filter.type, filter.filter ?? new Date(filter.dateFrom).toISOString(), key, filter.filterType);
                })
                .join(' and ');
            this.fetchParams = {
                ...this.fetchParams,
                $skip: 0,
                $filter: this.oDataFilters,
            };
            this.fetchNotifications();
        }
    }

    onSortChanged(event: SortChangedEvent<any, any>) {
        if (event.columns?.length) {
            const columnsChanged = event.columns.map((col) => {
                return {
                    field: col.getColId(),
                    sort: col.getSort(),
                };
            });
            if (this.activeSort.find((s) => s.field === columnsChanged[0].field && s.sort === columnsChanged[0].sort)) {
                this.activeSort = this.activeSort.filter((s) => s.field !== columnsChanged[0].field);
            } else {
                // filter out the column if it already exists and sort is different
                this.activeSort = this.activeSort.filter((s) => s.field !== columnsChanged[0].field);
                if (columnsChanged[0].sort) {
                    this.activeSort.push(...columnsChanged);
                }
            }
        }
        if (this.activeSort.length) {
            this.fetchParams = {
                ...this.fetchParams,
                $orderby: this.activeSort.map((s) => `${s.field} ${s.sort}`).join(','),
            };
        } else {
            this.resetSort(true, false);
        }
        this.fetchNotifications();
    }

    resetSort(keepFilters = false, keepSort = false) {
        if (this.activeSort.length !== 0 && this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $filter: keepFilters ? (this.oDataFilters ?? undefined) : undefined,
                $orderby: keepSort ? this.activeSort.map((s) => `${s.field} ${s.sort}`).join(',') : undefined,
            };
        } else if (this.activeSort.length === 0 && this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $filter: keepFilters ? (this.oDataFilters ?? undefined) : undefined,
            };
        } else if (this.activeSort.length !== 0 && !this.oDataFilters) {
            this.fetchParams = {
                $top: this.nbRecords,
                $orderby: keepSort ? this.activeSort.map((s) => `${s.field} ${s.sort}`).join(',') : undefined,
            };
        } else {
            this.fetchParams = {
                $top: this.nbRecords,
            };
        }
    }

    getTextRecurrence(data: ProgrammationNotificationDtoOutputForList | undefined) {
        if (!data) {
            return '';
        }
        if (data.recurrenceJour) {
            return `Tous les ${data.recurrenceJour > 1 ? data.recurrenceJour : ''} jours`;
        } else if (data.recurrenceMois) {
            return `Tous les ${data.recurrenceMois > 1 ? data.recurrenceMois : ''} mois`;
        }
        return 'Une seule fois';
    }

    getTextDepartement(data: ProgrammationNotificationDtoOutputForList | undefined) {
        if (!data) {
            return '';
        }
        if (data.departements) {
            return data.departements.map((d) => d.codeDepartement).join(', ');
        }
        return '';
    }

    onPageSizeChange(event: PageEvent) {
        if (this.nbRecords !== event.pageSize) {
            this.nbRecords = event.pageSize;
            this.fetchParams = {
                ...this.fetchParams,
                $top: event.pageSize,
            };
            this.fetchNotifications();
        }
        if (event.pageIndex > (event.previousPageIndex ?? -1)) {
            this.fetchPage(this.rawData?.nextLink ?? null);
        } else if (event.pageIndex < (event.previousPageIndex ?? -1)) {
            this.fetchPage(this.rawData?.previousLink ?? null);
        }
    }
    fetchPage(link: string | null) {
        if (link) {
            const url = new URL(link);
            const params = url.searchParams;
            const skip = params.get('skip');
            const top = params.get('top');
            if (skip && top) {
                this.fetchParams = {
                    ...this.fetchParams,
                    $top: +top,
                    $skip: +skip,
                };
                this.fetchNotifications(this.fetchParams);
            }
        }
    }

    getDatePublicaiton(date: string) {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const month = dateObj.getMonth() + 1;
        const year = dateObj.getFullYear();
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        return `${day < 10 ? '0' + day : day}/${month < 10 ? '0' + month : month}/${year} à ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;
    }
}
