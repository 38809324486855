/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface MessagerieOffresIdContacterPost$Params {
  id: string;

/**
 * Facultatif. Si aucun destinataire, ou si le destinataire est le vendeur, on communique avec le vendeur.  Sinon, c'est utilisé par le vendeur pour répondre à quelqu'un qui nous parle sur l'offre
 */
  destinataireId?: string;
  message?: string;
}

export function messagerieOffresIdContacterPost(http: HttpClient, rootUrl: string, params: MessagerieOffresIdContacterPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, messagerieOffresIdContacterPost.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('destinataireId', params.destinataireId, {});
    rb.query('message', params.message, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

messagerieOffresIdContacterPost.PATH = '/messagerie/offres/{id}/contacter';
