/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AdressesPubliquesRequete } from '../../models/adresses-publiques-requete';
import { LieuEchangeDtoOuputAvecFavorisOdataResponse } from '../../models/lieu-echange-dto-ouput-avec-favoris-odata-response';

export interface UsersAdressesAdressesPubliquesGet$Any$Params {
  optionRequete?: AdressesPubliquesRequete;
  filtreNom?: string;
  filtreVille?: string;
  filtreCodeCommune?: string;
  filtreRue?: string;

/**
 * The max number of records.
 */
  '$top'?: Number;

/**
 * The number of records to skip.
 */
  '$skip'?: Number;

/**
 * A function that must evaluate to true for a record to be returned.
 */
  '$filter'?: String;

/**
 * Determines what values are used to order a collection of records.
 */
  '$orderby'?: String;
}

export function usersAdressesAdressesPubliquesGet$Any(http: HttpClient, rootUrl: string, params?: UsersAdressesAdressesPubliquesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>> {
  const rb = new RequestBuilder(rootUrl, usersAdressesAdressesPubliquesGet$Any.PATH, 'get');
  if (params) {
    rb.query('optionRequete', params.optionRequete, {});
    rb.query('filtreNom', params.filtreNom, {});
    rb.query('filtreVille', params.filtreVille, {});
    rb.query('filtreCodeCommune', params.filtreCodeCommune, {});
    rb.query('filtreRue', params.filtreRue, {});
    rb.query('$top', params['$top'], {});
    rb.query('$skip', params['$skip'], {});
    rb.query('$filter', params['$filter'], {});
    rb.query('$orderby', params['$orderby'], {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>;
    })
  );
}

usersAdressesAdressesPubliquesGet$Any.PATH = '/users/adresses/adresses-publiques';
