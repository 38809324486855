/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { StatisticsCompleteDataDtoOutput } from '../../models/statistics-complete-data-dto-output';

export interface AdminStatistiquesGet$Any$Params {
  date1?: string;
  DureeEnJour1?: number;
  date2?: string;
  DureeEnJour2?: number;
}

export function adminStatistiquesGet$Any(http: HttpClient, rootUrl: string, params?: AdminStatistiquesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<StatisticsCompleteDataDtoOutput>> {
  const rb = new RequestBuilder(rootUrl, adminStatistiquesGet$Any.PATH, 'get');
  if (params) {
    rb.query('date1', params.date1, {});
    rb.query('DureeEnJour1', params.DureeEnJour1, {});
    rb.query('date2', params.date2, {});
    rb.query('DureeEnJour2', params.DureeEnJour2, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<StatisticsCompleteDataDtoOutput>;
    })
  );
}

adminStatistiquesGet$Any.PATH = '/admin/statistiques';
