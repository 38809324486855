/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { SousFamilleDtoOutputForDetail } from '../../models/sous-famille-dto-output-for-detail';

export interface ReferencesSousFamillesGet$Any$Params {
}

export function referencesSousFamillesGet$Any(http: HttpClient, rootUrl: string, params?: ReferencesSousFamillesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>> {
  const rb = new RequestBuilder(rootUrl, referencesSousFamillesGet$Any.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SousFamilleDtoOutputForDetail>>;
    })
  );
}

referencesSousFamillesGet$Any.PATH = '/references/sous-familles';
