<div class="flex flex-col w-fit gap-2">
    <mat-label class="text-primary w-fit font-bold whitespace-nowrap">{{label}}</mat-label>

    @if(IsEditMode && !IsReadOnly){
    <mat-form-field >
        <mat-icon matSuffix>expand_more</mat-icon>
        <mat-select [formControl]="formControl" (selectionChange)="onValueChange($event.value)">
            @for (option of options; track option) {
            <mat-option [value]="option" [disabled]="IsDisabled">{{option.id}}</mat-option>
            }
        </mat-select>
    </mat-form-field>
    @if(!formControl.valid && formControl.touched){
    <mat-error>
        La saisie du champ n'est pas valide.
    </mat-error>
    }
    } @else {
    <div [ngClass]="colorFormControl.value ? 'py-1 px-2 rounded w-fit': ''"
        [ngStyle]="{'color': colorFormControl.value , 'background-color': getColor(colorFormControl.value)}">
        <p class="text-sm whitespace-nowrap">{{ this.formControl.value?.value ?? this.formControl.value }}</p>
    </div>
    }

</div>