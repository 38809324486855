import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLabel } from '@angular/material/form-field';
import { ConversationDtoOutputForDetail, UserOutputForDetail } from '@app/api/models';

@Component({
  selector: 'app-conversation-signalee',
  standalone: true,
  imports: [MatLabel, CommonModule],
  templateUrl: './conversation-signalee.component.html',
  styleUrl: './conversation-signalee.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConversationSignaleeComponent {
  @Input() conversation! : ConversationDtoOutputForDetail;
  @Input() label: string | undefined;
  @Input() user! : UserOutputForDetail | undefined | null;
}
