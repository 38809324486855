import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, signal, ViewChild, WritableSignal } from '@angular/core';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TaskNode, FilterTreeComponent } from './recursive-tree/recursive-tree.component';
import { RecursiveTreeResumeComponent } from "./recursive-tree-resume/recursive-tree-resume.component";
import { debounceTime } from 'rxjs';

@Component({
    selector: 'app-recursive-tree-filtered-filed',
    standalone: true,
    imports: [MatLabel, MatFormField, MatInputModule, ReactiveFormsModule, FormsModule, FilterTreeComponent, RecursiveTreeResumeComponent],
    templateUrl: './recursive-tree-filtered-filed.component.html',
    styleUrl: './recursive-tree-filtered-filed.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecursiveTreeFilteredFiledComponent implements OnInit {
    @ViewChild(FilterTreeComponent) filterTreeComponent!: FilterTreeComponent;

    @Input() label: string | undefined;
    @Input() searchBarFormControl = new FormControl();
    @Input() formControl = new FormControl<TaskNode[]>([]);
    @Input() nodesData: TaskNode[] = [];
    @Input() editMode = false;
    @Input() selectedNodes: WritableSignal<TaskNode[]> = signal([]);
    date = new Date();
    //@Input() IsReadOnly = false;
    // @Input() IsEditMode = false;
    // @Input() nowrap = false;
    filteredNodes : TaskNode[] = this.nodesData;
    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.searchBarFormControl.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
            if (value) {
                this.filteredNodes = this.nodesData.filter((node) => node.value.value.nom.toLowerCase().includes(value.toLowerCase()));
            } else {
                this.formControl.setValue(this.nodesData);
            }
        });
        this.formControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    changement(event : TaskNode[]): void {
        this.date = new Date();
    }
}
