/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { LivreurDtoOuput } from '../models/livreur-dto-ouput';
import { MethodePayementDtoOuput } from '../models/methode-payement-dto-ouput';
import { modalitesLivreursGet$Any } from '../fn/modalites/modalites-livreurs-get-any';
import { ModalitesLivreursGet$Any$Params } from '../fn/modalites/modalites-livreurs-get-any';
import { modalitesLivreursGet$Json } from '../fn/modalites/modalites-livreurs-get-json';
import { ModalitesLivreursGet$Json$Params } from '../fn/modalites/modalites-livreurs-get-json';
import { modalitesLivreursGet$Plain } from '../fn/modalites/modalites-livreurs-get-plain';
import { ModalitesLivreursGet$Plain$Params } from '../fn/modalites/modalites-livreurs-get-plain';
import { modalitesMethodesPayementGet$Any } from '../fn/modalites/modalites-methodes-payement-get-any';
import { ModalitesMethodesPayementGet$Any$Params } from '../fn/modalites/modalites-methodes-payement-get-any';
import { modalitesMethodesPayementGet$Json } from '../fn/modalites/modalites-methodes-payement-get-json';
import { ModalitesMethodesPayementGet$Json$Params } from '../fn/modalites/modalites-methodes-payement-get-json';
import { modalitesMethodesPayementGet$Plain } from '../fn/modalites/modalites-methodes-payement-get-plain';
import { ModalitesMethodesPayementGet$Plain$Params } from '../fn/modalites/modalites-methodes-payement-get-plain';

@Injectable({ providedIn: 'root' })
export class ModalitesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `modalitesLivreursGet()` */
  static readonly ModalitesLivreursGetPath = '/modalites/livreurs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modalitesLivreursGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesLivreursGet$Json$Response(params?: ModalitesLivreursGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LivreurDtoOuput>>> {
    return modalitesLivreursGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `modalitesLivreursGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesLivreursGet$Json(params?: ModalitesLivreursGet$Json$Params, context?: HttpContext): Observable<Array<LivreurDtoOuput>> {
    return this.modalitesLivreursGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LivreurDtoOuput>>): Array<LivreurDtoOuput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modalitesLivreursGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesLivreursGet$Plain$Response(params?: ModalitesLivreursGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LivreurDtoOuput>>> {
    return modalitesLivreursGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `modalitesLivreursGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesLivreursGet$Plain(params?: ModalitesLivreursGet$Plain$Params, context?: HttpContext): Observable<Array<LivreurDtoOuput>> {
    return this.modalitesLivreursGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LivreurDtoOuput>>): Array<LivreurDtoOuput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modalitesLivreursGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesLivreursGet$Any$Response(params?: ModalitesLivreursGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LivreurDtoOuput>>> {
    return modalitesLivreursGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `modalitesLivreursGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesLivreursGet$Any(params?: ModalitesLivreursGet$Any$Params, context?: HttpContext): Observable<Array<LivreurDtoOuput>> {
    return this.modalitesLivreursGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LivreurDtoOuput>>): Array<LivreurDtoOuput> => r.body)
    );
  }

  /** Path part for operation `modalitesMethodesPayementGet()` */
  static readonly ModalitesMethodesPayementGetPath = '/modalites/methodes-payement';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modalitesMethodesPayementGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesMethodesPayementGet$Json$Response(params?: ModalitesMethodesPayementGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MethodePayementDtoOuput>>> {
    return modalitesMethodesPayementGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `modalitesMethodesPayementGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesMethodesPayementGet$Json(params?: ModalitesMethodesPayementGet$Json$Params, context?: HttpContext): Observable<Array<MethodePayementDtoOuput>> {
    return this.modalitesMethodesPayementGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MethodePayementDtoOuput>>): Array<MethodePayementDtoOuput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modalitesMethodesPayementGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesMethodesPayementGet$Plain$Response(params?: ModalitesMethodesPayementGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MethodePayementDtoOuput>>> {
    return modalitesMethodesPayementGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `modalitesMethodesPayementGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesMethodesPayementGet$Plain(params?: ModalitesMethodesPayementGet$Plain$Params, context?: HttpContext): Observable<Array<MethodePayementDtoOuput>> {
    return this.modalitesMethodesPayementGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MethodePayementDtoOuput>>): Array<MethodePayementDtoOuput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `modalitesMethodesPayementGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesMethodesPayementGet$Any$Response(params?: ModalitesMethodesPayementGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MethodePayementDtoOuput>>> {
    return modalitesMethodesPayementGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `modalitesMethodesPayementGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  modalitesMethodesPayementGet$Any(params?: ModalitesMethodesPayementGet$Any$Params, context?: HttpContext): Observable<Array<MethodePayementDtoOuput>> {
    return this.modalitesMethodesPayementGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MethodePayementDtoOuput>>): Array<MethodePayementDtoOuput> => r.body)
    );
  }

}
