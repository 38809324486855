import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { AnnoncesGroupedByDate, StatisticsCompleteDataDtoOutput } from '@app/api/models';
import { arrayEquals } from '@app/utils/array-equals';
import moment from 'moment';
import {
    ChartComponent,
    ApexAxisChartSeries,
    ApexChart,
    ApexXAxis,
    ApexTitleSubtitle,
    NgApexchartsModule,
    ApexPlotOptions,
    ApexFill,
    ApexDataLabels,
    ApexYAxis,
    ApexStroke,
    ApexMarkers,
} from 'ng-apexcharts';

export interface ChartOptions {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    title: ApexTitleSubtitle;
    plotOptions: ApexPlotOptions;
    fill: ApexFill;
    xaxis: ApexXAxis;
    stroke: ApexStroke;
    markers: ApexMarkers;
}

@Component({
    selector: 'app-graph-card',
    standalone: true,
    imports: [CommonModule, NgApexchartsModule],
    templateUrl: './graph-card.component.html',
    styleUrls: ['./graph-card.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphCardComponent implements AfterViewInit, OnChanges {
    @Input() stats: StatisticsCompleteDataDtoOutput | null | undefined;
    @ViewChild('chart') chart: ChartComponent | undefined;

    statAnnoncesPosteesGroupedByDatePrincipale: AnnoncesGroupedByDate[] | null = null;
    statAnnoncesTermineesGroupedByDatePrincipale: AnnoncesGroupedByDate[] | null = null;
    statAnnoncesPosteesGroupedByDateSecondaire: AnnoncesGroupedByDate[] | null = null;
    statAnnoncesTermineesGroupedByDateSecondaire: AnnoncesGroupedByDate[] | null = null;

    couleurAnnoncesPosteesPrincipale = '#236AB9';
    couleurAnnoncesTermineesPrincipale = '#33CCFF';
    couleurAnnoncesPosteesSecondaire = '#FC600A';
    couleurAnnoncesTermineesSecondaire = '#ffcc66';

    public chartOptions!: ChartOptions;

    constructor(private cdr: ChangeDetectorRef) {}

    ngAfterViewInit(): void {
        this.initializeChartOptions();
        this.updateSeries();
    }

    ngOnChanges() {
        this.updateSeries();
    }

    initializeChartOptions() {
        this.chartOptions = {
            title: {
                text: "Nombre d'annonces",
            },
            series: [],
            chart: {
                type: 'line',
                height: 350,
            },
            stroke: {
                curve: 'stepline',
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                },
            },
            xaxis: {
                categories: [],
            },
            fill: {
                opacity: 1,
            },
            markers: {
                size: 0,
            },
        };
    }

    updateSeries() {
        this.statAnnoncesPosteesGroupedByDatePrincipale = this.stats?.graphiques?.[0]?.annoncesPostees || null;
        this.statAnnoncesTermineesGroupedByDatePrincipale = this.stats?.graphiques?.[0]?.annoncesTerminees || null;
        this.statAnnoncesPosteesGroupedByDateSecondaire = this.stats?.graphiques?.[1]?.annoncesPostees || null;
        this.statAnnoncesTermineesGroupedByDateSecondaire = this.stats?.graphiques?.[1]?.annoncesTerminees || null;

        if (!!this.chartOptions) {
            if (this.hasValidDataForComparison()) {
                this.chartOptions.series = this.getSeriesForComparison();
            } else if (this.statAnnoncesPosteesGroupedByDatePrincipale && this.statAnnoncesTermineesGroupedByDatePrincipale) {
                this.chartOptions.series = this.getSeriesForSingleDataSet();
            }

            this.chartOptions.xaxis.categories = this.getCategoriesForSingleDataSet();

            // this.chart?.updateOptions(this.chartOptions);
        }

        // Forcer la détection des changements
        // this.cdr.detectChanges();
    }

    hasValidDataForComparison(): boolean {
        return (
            this.statAnnoncesPosteesGroupedByDatePrincipale?.length !== undefined &&
            this.statAnnoncesPosteesGroupedByDateSecondaire?.length !== undefined &&
            this.statAnnoncesTermineesGroupedByDatePrincipale?.length !== undefined &&
            this.statAnnoncesTermineesGroupedByDateSecondaire?.length !== undefined &&
            this.statAnnoncesPosteesGroupedByDatePrincipale.length > 0 &&
            this.statAnnoncesPosteesGroupedByDateSecondaire.length > 0 &&
            this.statAnnoncesTermineesGroupedByDatePrincipale.length > 0 &&
            this.statAnnoncesTermineesGroupedByDateSecondaire.length > 0 &&
            !arrayEquals(this.statAnnoncesPosteesGroupedByDatePrincipale, this.statAnnoncesPosteesGroupedByDateSecondaire) &&
            !arrayEquals(this.statAnnoncesTermineesGroupedByDatePrincipale, this.statAnnoncesTermineesGroupedByDateSecondaire)
        );
    }

    getSeriesForComparison(): ApexAxisChartSeries {
        return [
            {
                name: 'Offres postées date principale',
                data: this.mapDataToSeries(this.statAnnoncesPosteesGroupedByDatePrincipale),
                color: this.couleurAnnoncesPosteesPrincipale,
            },
            {
                name: 'Offres terminées date principale',
                data: this.mapDataToSeries(this.statAnnoncesTermineesGroupedByDatePrincipale),
                color: this.couleurAnnoncesTermineesPrincipale,
            },
            {
                name: 'Offres postées date secondaire',
                data: this.mapDataToSeries(this.statAnnoncesPosteesGroupedByDateSecondaire),
                color: this.couleurAnnoncesPosteesSecondaire,
            },
            {
                name: 'Offres terminées date secondaire',
                data: this.mapDataToSeries(this.statAnnoncesTermineesGroupedByDateSecondaire),
                color: this.couleurAnnoncesTermineesSecondaire,
            },
        ];
    }

    getSeriesForSingleDataSet(): ApexAxisChartSeries {
        return [
            {
                name: 'Offres postées',
                data: this.mapDataToSeries(this.statAnnoncesPosteesGroupedByDatePrincipale),
                color: this.couleurAnnoncesPosteesPrincipale,
            },
            {
                name: 'Offres terminées',
                data: this.mapDataToSeries(this.statAnnoncesTermineesGroupedByDatePrincipale),
                color: this.couleurAnnoncesTermineesPrincipale,
            },
        ];
    }

    getCategoriesForSingleDataSet(): string[] {
        return this.statAnnoncesPosteesGroupedByDatePrincipale?.map((d) => moment(d.date).format('DD MMM YYYY')) || [];
    }

    mapDataToSeries(data: AnnoncesGroupedByDate[] | null): { x: string | null | undefined; y: number | null | undefined }[] {
        return (
            data?.map((d) => ({
                x: moment(d.date).format('DD MMM YYYY'),
                y: d.nombreOffres,
            })) || []
        );
    }
}
