import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Validators, Editor, Toolbar } from 'ngx-editor';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-richtext',
    templateUrl: './richtext.component.html',
    styleUrl: './richtext.component.scss',
    encapsulation: ViewEncapsulation.None,
})
export class RichtextComponent implements OnDestroy {
    @Input() contenu = '';
    @Output() contenuChange = new EventEmitter();
    editor: Editor = new Editor();

    @ViewChild('fileInput') fileInput?: ElementRef;

    toolbar: Toolbar = [
        ['bold', 'italic'],
        ['underline', 'strike'],
        ['ordered_list', 'bullet_list'],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        ['link', 'image'],
        ['text_color', 'background_color'],
        ['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    updateContent(event: string) {
        this.contenu = event;
        this.contenuChange.emit(this.contenu);
    }

    ngOnDestroy(): void {
        this.editor.destroy();
    }

    selectFile(event: any) {
        const file = event.target.files[0];
        this.checkFileMimeType(file);
    }

    checkFileMimeType(file: any) {
        const fileReader = new FileReader();
        fileReader.onloadend = () => {
            const arr = new Uint8Array(fileReader.result as ArrayBuffer).subarray(0, 4);
            let header = '';
            let isValid = false;
            // Convert the header to hexadecimal string
            for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
            }
            // Check for common image headers
            const validHeaders = [
                '89504e47', // PNG
                'ffd8ffe0', // JPG
                'ffd8ffe1', // JPG
                'ffd8ffe2', // JPG
                'ffd8ffe3', // JPG
                'ffd8ffe8', // JPG
            ];

            isValid = validHeaders.some((validHeader) => header.startsWith(validHeader));

            if (isValid) {
                this.importFile(file);
            } else {
                alert('Fichier invalide');
            }
        };
        fileReader.readAsArrayBuffer(file);
    }

    importFile(file: any) {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64String = reader.result as string;

            this.editor.commands.insertHTML('<img src="' + base64String + '">').exec();
            if (this.fileInput) this.fileInput.nativeElement.value = '';
        };
        reader.readAsDataURL(file);
    }
}
