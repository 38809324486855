<div id="appbar" class="flex w-full items-center justify-between bg-primary shadow-md h-20 ">
    <div class="flex h-full items-center gap-5 bg-white px-5 py-3">
        <img src="assets/logo-ministere.svg" alt="logo" class="hidden h-12 md:block" />
        <img src="assets/logo-unichange-admin.svg" alt="logo" class="h-10 w-52" />
    </div>
    <div class="hidden xl:flex h-full items-center justify-center gap-6 pl-5 pr-3">
        <a routerLink="/statistiques" routerLinkActive="active-link"
            class="custom-hover relative flex h-full w-full cursor-pointer items-center justify-center p-1 text-white">Statistiques</a>
        <a routerLink="/notifications" routerLinkActive="active-link"
            class="custom-hover relative flex h-full w-full cursor-pointer items-center justify-center p-1 text-white">Notifications</a>
        <a routerLink="/references" routerLinkActive="active-link"
            class="custom-hover relative flex h-full w-full cursor-pointer items-center justify-center p-1 text-white">Références</a>
        <a routerLink="/utilisateurs" routerLinkActive="active-link"
            class="custom-hover relative flex h-full w-full cursor-pointer items-center justify-center p-1 text-white">Utilisateurs</a>
        <a routerLink="/adresses" routerLinkActive="active-link"
            class="custom-hover relative flex h-full w-full cursor-pointer items-center justify-center p-1 text-white">Adresses</a>
        <a routerLink="/signalements" routerLinkActive="active-link"
            class="custom-hover relative flex h-full w-full cursor-pointer items-center justify-center p-1 text-white">Signalements</a>
        <a routerLink="/contenu" routerLinkActive="active-link"
            class="custom-hover relative flex h-full w-full cursor-pointer items-center justify-center p-1 text-white">Contenus</a>
    </div>

    <div class="flex h-full items-center justify-between gap-3">
        <div class=" h-full divide-x px-3 py-4 flex items-center">
            <div class="flex h-full items-center justify-center p-2">
                <button mat-icon-button class="inline-block xl:!hidden relative h-full" [matMenuTriggerFor]="menu">
                    <mat-icon class="material-icons-outlined text-white">menu</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item routerLink="/statistiques">Statistiques</button>
                    <button mat-menu-item routerLink="/notifications">Notifications</button>
                    <button mat-menu-item routerLink="/references">Références</button>
                    <button mat-menu-item routerLink="/utilisateurs">Utilisateurs</button>
                    <button mat-menu-item routerLink="/adresses">Adresses</button>
                    <button mat-menu-item routerLink="/signalements">Signalements</button>
                    <button mat-menu-item routerLink="/contenu">Contenus</button>
                </mat-menu>
            </div>

            <div class="flex h-full items-center justify-center p-2">
                <button routerLink="/messagerie" class="relative h-full" mat-icon-button aria-label="menu">
                    <mat-icon class="material-icons-outlined text-white">email</mat-icon>
                </button>
            </div>

            <div class="flex h-full items-center justify-center p-2">
                <div>
                    <button (click)="getNotifications()" mat-icon-button [matMenuTriggerFor]="notif" aria-label="menu">
                        <mat-icon class="material-icons-outlined text-white">notifications</mat-icon>
                        @if (unreadNotificationsCount > 0) {
                        <div
                            class="absolute right-1 top-2 flex h-4 w-4 items-center justify-center rounded-full bg-white text-[10px] text-primary">
                            {{ numberCountToString(unreadNotificationsCount) }}
                        </div>
                        }
                    </button>
                    <mat-menu id="menuNotif" #notif="matMenu" (close)="(false)" xPosition="before" class="w-auto">
                        @if (!(notifications.length > 0)) {
                        <p aria-hidden="true" class="m-3 items-center justify-center px-2 py-0.5 opacity-80">
                            Aucune nouvelle notification.
                        </p>
                        } @else {
                        @for (notification of notifications; track notification) {
                        <div mat-menu-item class="flex w-full ">
                            <div (click)="$event.stopPropagation()" role="presentation" class="mb-2 mt-2 ">
                                <app-notif-card [notification]="notification" (notifDelete)="deleteNotification($event)"
                                    [isMenuCard]="true"></app-notif-card>
                            </div>
                        </div>
                        }
                        }
                    </mat-menu>
                </div>
            </div>

            <div class="flex h-full items-center justify-center p-2">
                <a class="flex items-center justify-center gap-3 px-2" [matMenuTriggerFor]="userMenu">
                    <div class="flex flex-col items-end text-white">
                        <span class="text-xs font-semibold">{{formatUserName()}}</span>
                    </div>
                    <img class="aspect-square text-white" [src]="'assets/icons/avatar_rond.svg'" alt="Image partage" />
                </a>
            </div>

            <!-- Material Dropdown Menu -->
            <mat-menu #userMenu="matMenu">
                <button mat-menu-item (click)="logout()">Déconnexion</button>
            </mat-menu>
        </div>
    </div>
</div>