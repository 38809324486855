import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AnnoncesGroupedByNomenclatureAvecVariation } from '@app/api/models';
import { StatVariationComponent } from "../stat-variation/stat-variation.component";

@Component({
    selector: 'app-stat-rank',
    standalone: true,
    imports: [
    CommonModule,
    StatVariationComponent
],
    templateUrl: './stat-rank.component.html',
    styleUrls: ['./stat-rank.component.css'],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatRankComponent implements OnInit {
    @Input() title = '';
    @Input() value = 0 ;
    @Input() data : AnnoncesGroupedByNomenclatureAvecVariation[] | null = null;
    @Input() greenValue = false;
    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {}
    ngOnChanges(): void {}

    getLibelle(libelle: string): string {
        if (libelle.length > 20) {
            return libelle.substring(0, 20) + '...';
        }
        return libelle;
    }

    isVariationPositive(variation: number): boolean {   
        return variation > 0;
    }


}
