import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { EditTextFieldComponent } from '../../../components/fields/edit-text-field/edit-text-field.component';
import { EditSelectFieldComponent } from '../../../components/fields/edit-select-field/edit-select-field.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLabel } from '@angular/material/form-field';
import { AdminService } from '@app/api/services';
import { AdminUtilisateursIdGet$Json$Params } from '@app/api/fn/admin/admin-utilisateurs-id-get-json';
import { OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse, OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse, SignalementOutputForDetail, SignalementOutputForList, SignalementOutputForListOdataResponse, UserOutputForAdminDataGrid } from '@app/api/models';
import { AdminUtilisateursIdDemandesGet$Json$Params } from '@app/api/fn/admin/admin-utilisateurs-id-demandes-get-json';
import { AdminUtilisateursIdDonationsGet$Json$Params } from '@app/api/fn/admin/admin-utilisateurs-id-donations-get-json';
import { DatagridComponent } from '../../../components/datagrid/datagrid.component';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { DataGridService, FetchParamsDto } from '@app/utils/services/data-grid-service';
import { SnackBarService } from '@app/utils/services/snackbar.service';
import { AdminUtilisateursIdBannirPut$Json$Params } from '@app/api/fn/admin/admin-utilisateurs-id-bannir-put-json';
import { STATUS_BLOQUE, STATUS_INDEFINI, STATUS_NON_BLOQUE } from '@app/utils/constantes';
import { DataSharingService } from '@app/utils/services/data-sharing.services';
import { FormService } from '@app/utils/services/form-service';
import { MatOption } from '@angular/material/core';
import DownloadFile from '@app/utils/http-headers-utils';
import { AdminUtilisateursIdSignalementsGet$Json$Params } from '@app/api/fn/admin/admin-utilisateurs-id-signalements-get-json';
import { AdminUtilisateursIdCommentaireAdminPost$Params } from '@app/api/fn/admin/admin-utilisateurs-id-commentaire-admin-post';
import { EditAreaFieldComponent } from "../../../components/fields/edit-area-field/edit-area-field.component";

@Component({
    selector: 'app-user-detail',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatDividerModule, EditTextFieldComponent, EditSelectFieldComponent, MatLabel, DatagridComponent, EditAreaFieldComponent],
    templateUrl: './user-detail.component.html',
    styleUrl: './user-detail.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailComponent implements OnInit {
    editForm: FormGroup | undefined;
    utilisateurId: string | undefined;
    title: string | undefined;

    editMode = false;
    NomFormControl: FormControl = new FormControl('', Validators.required);
    IdentifiantFormControl: FormControl = new FormControl('', Validators.required);
    StatutFormControl: FormControl = new FormControl('', Validators.required);
    EmailFormControl: FormControl = new FormControl('', Validators.required);
    CommFormControl: FormControl = new FormControl('', Validators.required);

    statuts: MatOption[] = [];

    user: UserOutputForAdminDataGrid | undefined;

    DonationsRawData: OffreDtoOutputWithBuyerForUtilisateurDetailOdataResponse | null = null;
    DonationsColDefs: ColDef[] = [
        { headerName: 'Libellés', field: 'libelle', filter: true, sortable: true },
        { headerName: 'Références', field: 'reference', filter: true, sortable: true },
        { headerName: 'Acquéreur', field: 'acquereur', valueGetter: (p) => `${p.data.acquereur?.firstName ?? ''} ${p.data.acquereur?.lastName ?? ''}`, filter: true, sortable: true },
        { headerName: 'Statut', field: 'status', filter: true, sortable: true },
    ];

    DemandesRawData: OffreDtoOutputWithSellerForUtilisateurDetailOdataResponse | null = null;
    DemandesColDefs: ColDef[] = [
        { headerName: 'Libellés', field: 'libelle', filter: true, sortable: true },
        { headerName: 'Références', field: 'reference', filter: true, sortable: true },
        { headerName: 'Donateur', field: 'donateur', valueGetter: (p) => `${p.data.donateur?.firstName ?? ''} ${p.data.donateur?.lastName ?? ''}`, filter: true, sortable: true },
        { headerName: 'Statut', field: 'status', filter: true, sortable: true },
    ];

    SignalementsRawData: SignalementOutputForListOdataResponse | null = null;
    SignalementsColDefs: ColDef[] = [
        { headerName: 'Id', field: 'id', filter: true, sortable: true },
        { headerName: 'Type', field: 'type.nom', filter: true, sortable: true },
        { headerName: 'Statut', field: 'statut', filter: true, sortable: true },
        { headerName: 'Auteur', field: 'auteur', valueGetter: (p) => `${p.data.auteur?.firstName ?? ''} ${p.data.auteur?.lastName ?? ''}`, filter: true, sortable: true },
    ];

    banBool: boolean | undefined;

    DemandesFetchParams: FetchParamsDto = new FetchParamsDto();
    fetchDemandes = (fetchParams: FetchParamsDto) => this.getDemandes(fetchParams);
    demandesGridApi: GridApi<UserOutputForAdminDataGrid> | undefined;

    DonationsFetchParams: FetchParamsDto = new FetchParamsDto();
    fetchDonations = (fetchParams: FetchParamsDto) => this.getDonations(fetchParams);
    donationsGridApi: GridApi<UserOutputForAdminDataGrid> | undefined;

    SignalementsFetchParams: FetchParamsDto = new FetchParamsDto();
    fetchSignalements = (fetchParams: FetchParamsDto) => this.getSignalements(fetchParams);
    signalementsGridApi: GridApi<UserOutputForAdminDataGrid> | undefined;

    constructor(
        private adminService: AdminService,
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        public dataService: DataGridService,
        private snackBarServices: SnackBarService,
        private dataSharingService: DataSharingService,
        private formService: FormService,
    ) {}

    ngOnInit(): void {
        this.route.params.subscribe((params) => {
            this.utilisateurId = params['id'];
        });

        this.editForm = this.fb.group({
            nom: this.NomFormControl,
            identifiant: this.IdentifiantFormControl,
            statut: this.StatutFormControl,
            email: this.EmailFormControl,
        });

        if (this.utilisateurId) this.getUser(this.utilisateurId);
        this.getDonations(this.DemandesFetchParams);
        this.getDemandes(this.DonationsFetchParams);
        this.getSignalements(this.SignalementsFetchParams);
    }

    retour() {
        this.router.navigate(['/utilisateurs']);
    }

    getUser(id: string): void {
        const params: AdminUtilisateursIdGet$Json$Params = {
            id: id,
        };

        this.adminService.adminUtilisateursIdGet$Json$Response(params).subscribe({
            next: (response) => {
                this.user = response.body;
                this.setFields(this.user);
            },
            error: (error) => {
                this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
            },
        });
    }

    setFields(user: UserOutputForAdminDataGrid) {
        this.NomFormControl.setValue(user.firstName);
        this.IdentifiantFormControl.setValue(user.id);
        this.StatutFormControl.setValue(user.status);
        this.setBanBool(user.status ?? '');
        this.EmailFormControl.setValue(user.email);
        this.CommFormControl.setValue(user.commentaireAdmin);
        this.cdr.detectChanges();
    }

    setBanBool(status: string) {
        this.banBool = status === STATUS_BLOQUE;
    }

    getBanStatut(): string {
        if (this.banBool === undefined) {
            return STATUS_INDEFINI;
        }
        return this.banBool ? STATUS_BLOQUE : STATUS_NON_BLOQUE;
    }

    onSubmit(): void {
        if (this.editMode) {
            if (!this.editForm) return;
            if (this.editForm.valid) {
                this.editMode = false;
            } else {
                this.formService.markAllAsTouched(this.editForm);
            }
        } else {
            this.editMode = true;
        }
    }

    onDonationsGridReady(params: GridReadyEvent) {
        this.donationsGridApi = params.api;
    }

    getDonations(fetchParams: FetchParamsDto) {
        if (!this.utilisateurId) return;

        const params: AdminUtilisateursIdDemandesGet$Json$Params = {
            id: this.utilisateurId,
            $top: fetchParams.$top,
            $skip: fetchParams.$skip,
            $filter: fetchParams.$filter,
            $orderby: fetchParams.$orderby,
        };

        this.adminService.adminUtilisateursIdDonationsGet$Json$Response(params).subscribe({
            next: (response) => {
                this.DonationsFetchParams = fetchParams;
                this.DonationsRawData = response.body;
                this.cdr.detectChanges();
            },
            error: (error) => {
                this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
            },
            complete: () => {},
        });
    }

    onDemandesGridReady(params: GridReadyEvent) {
        this.demandesGridApi = params.api;
    }

    onSignalementsGridReady(params: GridReadyEvent) {
        this.signalementsGridApi = params.api;
    }

    getDemandes(fetchParams: FetchParamsDto) {
        if (!this.utilisateurId) return;

        const params: AdminUtilisateursIdDonationsGet$Json$Params = {
            id: this.utilisateurId,
            $top: fetchParams.$top,
            $skip: fetchParams.$skip,
            $filter: fetchParams.$filter,
            $orderby: fetchParams.$orderby,
        };

        this.adminService.adminUtilisateursIdDemandesGet$Json$Response(params).subscribe({
            next: (response) => {
                this.DemandesFetchParams = fetchParams;
                this.DemandesRawData = response.body;
                this.cdr.detectChanges();
            },
            error: (error) => {
                this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
            },
            complete: () => {},
        });
    }

    getSignalements(fetchParams: FetchParamsDto) {
        if (!this.utilisateurId) return;

        const params: AdminUtilisateursIdSignalementsGet$Json$Params = {
            id: this.utilisateurId,
            $top: fetchParams.$top,
            $skip: fetchParams.$skip,
            $filter: fetchParams.$filter,
            $orderby: fetchParams.$orderby,
        };

        this.adminService.adminUtilisateursIdSignalementsGet$Json$Response(params).subscribe({
            next: (response) => {
                console.log('Signalements : ', response.body);
                this.SignalementsFetchParams = fetchParams;
                this.SignalementsRawData = response.body;
                this.cdr.detectChanges();
            },
            error: (error) => {
                console.log('Erreur personnalisée : ', error);
                this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
            },
            complete: () => {},
        });
    }

    onCancel() {
        this.editMode = false;
        if (this.utilisateurId) this.getUser(this.utilisateurId);
    }

    putBannir() {
        if (!this.utilisateurId) return;
        const params: AdminUtilisateursIdBannirPut$Json$Params = {
            id: this.utilisateurId,
            bannir: !this.banBool,
        };

        this.adminService.adminUtilisateursIdBannirPut$Json$Response(params).subscribe({
            next: (response) => {
                if (response.ok && this.utilisateurId) {
                    this.banBool != this.banBool;
                    this.getUser(this.utilisateurId);
                }
            },
            error: (error) => {
                this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
            },
            complete: () => {},
        });
    }

    export() {
        if (this.utilisateurId !== undefined) {
            const params = { id: this.utilisateurId };
            this.adminService.adminExportDetailsUtilisateurIdGet$Response(params).subscribe((res) => {
                DownloadFile(res);
            });
        }
    }

    contacter() {
        this.dataSharingService.setData(this.user);
        this.router.navigate(['/messagerie']);
    }

    envoyerComm() {
        if (!this.utilisateurId) return;
        const params: AdminUtilisateursIdCommentaireAdminPost$Params = {
            id: this.utilisateurId,
            body: JSON.stringify(this.CommFormControl.value),
        };
        this.adminService.adminUtilisateursIdCommentaireAdminPost$Response(params).subscribe({
            next: (response) => {
                if (response.ok && this.utilisateurId) {
                    this.snackBarServices.openSnackBar('Commentaire envoyé', 'Fermer', false, 2500);
                    this.getUser(this.utilisateurId);
                }
            },
            error: (error) => {
                console.log(error);
                this.snackBarServices.openSnackBar(error.error[''] || 'Une erreur inconnue est survenue', 'Fermer', true, 2500);
            },
            complete: () => {},
        });
    }

    getInitialWithLastName(): string {
        const firstName = this.user?.firstName ?? '';
        const lastName = this.user?.lastName ?? '';

        if (lastName.length > 0) {
            return `${firstName} ${lastName.charAt(0)}.`;
        }

        return lastName;
    }
}
