/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { LivreurXOffreInput } from '../../models/livreur-x-offre-input';

export interface OffresPost$Plain$Params {
  OrderedFileNamesOrURLs?: Array<string>;
  Description: string;
  EtatArticle: number;

/**
 * Si l'offre est créée à partir d'un brouillon, cela permet de le supprimer et de transferer les images
 */
  OffreBrouillonId?: string;
  'Reference.Force'?: string;
  'Reference.Famille'?: string;
  'Reference.SousFamille'?: string;
  'Reference.Groupe'?: string;
  'Reference.Genre'?: string;
  'Reference.SousGroupe'?: string;
  'Reference.Taille'?: string;
  'Reference.Conformation'?: string;
  ModalitePayementsIds?: Array<string>;
  ModaliteLivraisons?: Array<LivreurXOffreInput>;
  LieuxEchangeMainPropre?: Array<string>;
      body?: {
'Files'?: Array<Blob>;
}
}

export function offresPost$Plain(http: HttpClient, rootUrl: string, params: OffresPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, offresPost$Plain.PATH, 'post');
  if (params) {
    rb.query('OrderedFileNamesOrURLs', params.OrderedFileNamesOrURLs, {});
    rb.query('Description', params.Description, {});
    rb.query('EtatArticle', params.EtatArticle, {});
    rb.query('OffreBrouillonId', params.OffreBrouillonId, {});
    rb.query('Reference.Force', params['Reference.Force'], {});
    rb.query('Reference.Famille', params['Reference.Famille'], {});
    rb.query('Reference.SousFamille', params['Reference.SousFamille'], {});
    rb.query('Reference.Groupe', params['Reference.Groupe'], {});
    rb.query('Reference.Genre', params['Reference.Genre'], {});
    rb.query('Reference.SousGroupe', params['Reference.SousGroupe'], {});
    rb.query('Reference.Taille', params['Reference.Taille'], {});
    rb.query('Reference.Conformation', params['Reference.Conformation'], {});
    rb.query('ModalitePayementsIds', params.ModalitePayementsIds, {});
    rb.query('ModaliteLivraisons', params.ModaliteLivraisons, {});
    rb.query('LieuxEchangeMainPropre', params.LieuxEchangeMainPropre, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<string>;
    })
  );
}

offresPost$Plain.PATH = '/offres';
