import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { NotificationsComponent } from './pages/notifications/notifications.component';
import { StatistiquesComponent } from './pages/statistiques/statistiques.component';
import { ReferencesComponent } from './pages/references/references.component';
import { UtilisateursComponent } from './pages/utilisateurs/utilisateurs.component';
import { SignalementsComponent } from './pages/signalements/signalements.component';
import { MessagerieComponent } from './pages/messagerie/messagerie.component';
import { SignalementDetailComponent } from './pages/signalements/signalement-detail/signalement-detail.component';
import { ContenuComponent } from './pages/contenu/contenu.component';
import { ReferenceDetailComponent } from './pages/references/reference-detail/reference-detail.component';
import { UserDetailComponent } from './pages/utilisateurs/user-detail/user-detail.component';
import { NotificationsDetailComponent } from './pages/notifications/notifications-detail/notifications-detail.component';
import { AdressesComponent } from './pages/adresses/adresses.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';

export const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'statistiques', component: StatistiquesComponent },
    { path: 'notifications', component: NotificationsComponent },
    { path: 'notification', component: NotificationsDetailComponent },
    { path: 'notification/:id', component: NotificationsDetailComponent },
    { path: 'references', component: ReferencesComponent },
    // { path: 'reference', component: ReferenceDetailComponent },
    { path: 'reference/:id', component: ReferenceDetailComponent },
    { path: 'utilisateurs', component: UtilisateursComponent },
    { path: 'utilisateur/:id', component: UserDetailComponent },
    { path: 'signalements', component: SignalementsComponent },
    { path: 'signalements/:id', component: SignalementDetailComponent },
    { path: 'messagerie', component: MessagerieComponent },
    { path: 'adresses', component: AdressesComponent },
    { path: 'contenu', component: ContenuComponent },
    { path: 'mot-de-passe/recuperation', component: PasswordRecoveryComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
