import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';



@Component({
    selector: 'app-stat-variation',
    standalone: true,
    imports: [
        CommonModule,
        MatIconModule
    ],
    templateUrl: './stat-variation.component.html',
    styleUrls: ['./stat-variation.component.css'],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatVariationComponent implements OnInit {
    @Input() value = 0 ;
    @Input() positive = false;
    constructor(private cdr: ChangeDetectorRef) {

    }

    ngOnInit(): void {
    }
}
