/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EspeceDtoOuputForListOdataResponse } from '../../models/espece-dto-ouput-for-list-odata-response';

export interface ReferencesEspecesGet$Plain$Params {
  reference?: string;
  force?: string;
  categorieFiltre?: Array<string>;
  familleFiltre?: Array<string>;
  sousfamilleFiltre?: Array<string>;
  groupe?: string;
  genre?: string;
  sousgroupe?: string;
  taille?: string;
  conformation?: string;

/**
 * The max number of records.
 */
  '$top'?: Number;

/**
 * The number of records to skip.
 */
  '$skip'?: Number;

/**
 * A function that must evaluate to true for a record to be returned.
 */
  '$filter'?: String;

/**
 * Determines what values are used to order a collection of records.
 */
  '$orderby'?: String;
}

export function referencesEspecesGet$Plain(http: HttpClient, rootUrl: string, params?: ReferencesEspecesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<EspeceDtoOuputForListOdataResponse>> {
  const rb = new RequestBuilder(rootUrl, referencesEspecesGet$Plain.PATH, 'get');
  if (params) {
    rb.query('reference', params.reference, {});
    rb.query('force', params.force, {});
    rb.query('categorieFiltre', params.categorieFiltre, {});
    rb.query('familleFiltre', params.familleFiltre, {});
    rb.query('sousfamilleFiltre', params.sousfamilleFiltre, {});
    rb.query('groupe', params.groupe, {});
    rb.query('genre', params.genre, {});
    rb.query('sousgroupe', params.sousgroupe, {});
    rb.query('taille', params.taille, {});
    rb.query('conformation', params.conformation, {});
    rb.query('$top', params['$top'], {});
    rb.query('$skip', params['$skip'], {});
    rb.query('$filter', params['$filter'], {});
    rb.query('$orderby', params['$orderby'], {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: 'text/plain', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EspeceDtoOuputForListOdataResponse>;
    })
  );
}

referencesEspecesGet$Plain.PATH = '/references/especes';
