<div class="m-7">
    <h2 class="text-center mb-3">Réinitialiser le mot de passe</h2>

    <form id="form_forgot_password" [formGroup]="forgotPasswordForm" (ngSubmit)="onSubmit()"
        class="flex w-full flex-col">
        <mat-label class="text-primary">Adresse e-mail</mat-label>
        <mat-form-field>
            <input matInput placeholder="-" formControlName="email" autocomplete="email" />
        </mat-form-field>
        @if (forgotPasswordForm.get('email')?.hasError('server')) {
        <mat-error>{{ forgotPasswordForm.get('email')?.getError('server') }}</mat-error>
        }

        <div class="md:mt-7 md:flex md:justify-center">
            <button mat-flat-button class="w-full !bg-primary md:w-52" type="submit">Envoyer</button>
        </div>
    </form>

</div>