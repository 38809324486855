import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, PLATFORM_ID } from '@angular/core';
import { FormPasswordRecoveryComponent } from '@app/components/form-password-recovery/form-password-recovery.component';

@Component({
    selector: 'app-password-recovery',
    standalone: true,
    imports: [CommonModule, FormPasswordRecoveryComponent],
    templateUrl: 'password-recovery.component.html',
    styleUrls: ['./password-recovery.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordRecoveryComponent {
    constructor(@Inject(PLATFORM_ID) private platformId: object) {}

    isMobile(): boolean {
        return isPlatformBrowser(this.platformId) ? window.innerWidth <= 768 : false;
    }
}
