import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { DureeMoyenneEchangesEnJourDto, EspaceStockageRestantDto, KgCo2EvitesDto, NombreArticlesObsoletesDto, NombreEchangesDto, NombreUtilisateursInscritDto, RatioOffresTermineesSurOffreCreeesDto } from '@app/api/models';
import { StatVariationComponent } from "../stat-variation/stat-variation.component";

@Component({
    selector: 'app-number-card',
    standalone: true,
    imports: [
    CommonModule,
    MatIconModule,
    StatVariationComponent
],
    templateUrl: './number-card.component.html',
    styleUrls: ['./number-card.component.css'],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberCardComponent implements OnInit {
    @Input() title = '';
    @Input() value : DureeMoyenneEchangesEnJourDto | EspaceStockageRestantDto | KgCo2EvitesDto | NombreArticlesObsoletesDto | NombreEchangesDto | NombreUtilisateursInscritDto | RatioOffresTermineesSurOffreCreeesDto | null | undefined = null;
    @Input() unit = '';
    @Input() suffix = '';
    @Input() multiple = 1;
    constructor(private cdr: ChangeDetectorRef) {

    }


    ngOnInit(): void {}

    isVariationPositive(): boolean {
        if(this.value?.variation){
            return this.value.variation > 0;
        }
        return false;
    }

}
