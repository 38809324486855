import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule, provideMomentDateAdapter } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MatNativeDateModule, MatOption } from '@angular/material/core';
import { MatDatepickerInputEvent, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { EditSelectFieldComponent } from '../edit-select-field/edit-select-field.component';
import moment from 'moment';
import { momentToDate } from '@app/utils/momentToDate';

@Component({
    selector: 'app-date-field',
    standalone: true,
    imports: [
        CommonModule,
        MatLabel,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        MatMomentDateModule,
        EditSelectFieldComponent,
    ],
    templateUrl: './date-field.component.html',
    styleUrl: './date-field.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'fr' }, provideMomentDateAdapter()],

})
export class DateFieldComponent implements OnInit, ControlValueAccessor {
    @Input() label: string | undefined;
    @Input() datetime = false
    @Input() formControl: FormControl<moment.Moment | null> = new FormControl<moment.Moment | null>(null);
    @Input() nowrap = false;
    @Input() editMode = false;
    @Input() hoursControl: FormControl<MatOption<string> | null | undefined> = new FormControl<MatOption<string> | null | undefined>(null);
    @Input() minutesControl: FormControl<MatOption<string> | null | undefined> = new FormControl<MatOption<string> | null | undefined>(null);
    @Input() hoursOptions: MatOption[] | undefined;
    @Input() minutesOptions: MatOption[] | undefined;

    constructor(private cdr: ChangeDetectorRef) {}

    private onChange: (value: any) => void = () => {};
    private onTouched: () => void = () => {};

    writeValue(value: any): void {
        this.formControl.setValue(value);
    }

    registerOnChange(fn: (value: any) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        if (isDisabled) {
            this.formControl.disable();
        } else {
            this.formControl.enable();
        }
    }

    onDateChange(event: any): void {
        this.onChange(event.value);
        this.onTouched();
    }
    ngOnInit(): void {
        this.formControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
        this.hoursControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
        this.minutesControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    momentToDate(date: moment.Moment | null): Date | undefined {
        return momentToDate(date)
    }

}
