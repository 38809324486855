import { AdminService } from '@app/api/services';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Router } from '@angular/router';
import { SignalementOutputForDetail, SignalementOutputForDetailWithConversation, UserOutputForDetail, OffreDtoOutputForDetail, ConversationDtoOutputForDetail, SignalementAction } from '@app/api/models';
import { AdminSignalementsIdGet$Json$Params } from '@app/api/fn/admin/admin-signalements-id-get-json';
import { AdminSignalementsIdDelete$Params } from '@app/api/fn/admin/admin-signalements-id-delete';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDividerModule } from '@angular/material/divider';
import { EditTextFieldComponent } from '../../../components/fields/edit-text-field/edit-text-field.component';
import { EditSelectFieldComponent } from '../../../components/fields/edit-select-field/edit-select-field.component';
import { MatLabel } from '@angular/material/form-field';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { ContactFieldComponent } from '../../../components/fields/contact-field/contact-field.component';
import { AdminUtilisateursIdBannirPut$Json$Params } from '@app/api/fn/admin/admin-utilisateurs-id-bannir-put-json';
import { SignalementActionComponent } from '@app/components/pop-ups/signalement-action-dialog/signalement-action-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { sign } from 'crypto';
import { OffreDetailsComponent } from '@app/components/offre-details/offre-details.component';
import { log } from 'console';
import { ConversationSignaleeComponent } from '@app/components/conversation-signalee/conversation-signalee.component';
import { MatMenu, MatMenuContent, MatMenuModule } from '@angular/material/menu';
import { AdminSignalementsIdPut$Json$Params } from '@app/api/fn/admin/admin-signalements-id-put-json';

@Component({
    selector: 'app-signalement-detail',
    standalone: true,
    imports: [MatButtonModule, MatIconModule, MatDividerModule, EditTextFieldComponent, OffreDetailsComponent, ConversationSignaleeComponent, EditSelectFieldComponent, MatLabel, MatMenuContent, MatMenu, MatMenuModule, ContactFieldComponent],
    templateUrl: './signalement-detail.component.html',
    styleUrl: './signalement-detail.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignalementDetailComponent implements OnInit {
    public SignalementAction = SignalementAction;
    editForm: FormGroup | undefined;

    signalement: SignalementOutputForDetail | undefined;
    offreDetails: OffreDtoOutputForDetail | undefined;
    conversation: ConversationDtoOutputForDetail | undefined;

    IdFormControl: FormControl = new FormControl('', Validators.required);
    TypeFormControl: FormControl = new FormControl('', Validators.required);
    TypeColorFormControl: FormControl = new FormControl('', Validators.required);
    AuteurFormControl: FormControl<UserOutputForDetail | null | undefined> = new FormControl<UserOutputForDetail | null | undefined>(null, Validators.required);
    MembreFormControl: FormControl<UserOutputForDetail | null | undefined> = new FormControl<UserOutputForDetail | null | undefined>(null, Validators.required);
    DescriptionFormControl: FormControl = new FormControl('', Validators.required);
    StatutFormControl: FormControl = new FormControl('', Validators.required);

    readonly classerSansSuite = "Classer sans suite";
    readonly depublier = "Dépublier l'annonce";
    readonly bloquer = "Bloquer l'utilisateur signalé";

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private adminServices: AdminService,
        private _snackBar: MatSnackBar,
        private fb: FormBuilder,
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.editForm = this.fb.group({
            id: this.IdFormControl,
            type: this.TypeFormControl,
            typeColor: this.TypeColorFormControl,
            auteur: this.AuteurFormControl,
            membre: this.MembreFormControl,
            description: this.DescriptionFormControl,
            statut: this.StatutFormControl,
        });

        this.getSignalement();
    }

    setFields(signalement: SignalementOutputForDetailWithConversation) {
        this.IdFormControl.setValue(signalement.id);
        this.TypeFormControl.setValue(signalement.type?.nom);
        this.TypeColorFormControl.setValue(signalement.type?.couleur);
        this.AuteurFormControl.setValue(signalement.auteur);
        this.MembreFormControl.setValue(signalement.concerne);
        this.DescriptionFormControl.setValue(signalement.raison);
        this.StatutFormControl.setValue(signalement.statut);
    }

    getSignalement(): void {
        const id = this.route.snapshot.paramMap.get('id');
        if (id) {
            const param: AdminSignalementsIdGet$Json$Params = { id: parseInt(id, 10) };
            this.adminServices.adminSignalementsIdGet$Json(param).subscribe({
                next: (result) => {
                    this.signalement = result;
                    this.offreDetails = result.offre as OffreDtoOutputForDetail;
                    this.conversation = result.conversation as ConversationDtoOutputForDetail;
                    this.setFields(this.signalement);
                    this.cdr.detectChanges();
                },
                error: (error) => {
                    console.error('Error fetching signalement:', error);
                    // Handle errors appropriately in your context
                },
            });
        } else {
            console.warn('No ID found in route parameters.');
            // Handle the case when ID is not found or is invalid
        }
    }

    retour() {
        this.router.navigate(['/signalements']);
    }

    archive() {
        if (this.signalement && this.signalement.id) {
            const param: AdminSignalementsIdDelete$Params = { id: this.signalement.id };
            this.adminServices.adminSignalementsIdDelete(param).subscribe({
                error: () => {
                    this._snackBar.open("Un problème est survenu lors de l'archivage du signalement", 'Fermer', {
                        duration: 2500,
                        panelClass: ['red-snackbar', 'snackbar'],
                    });
                },
                complete: () => {
                    this._snackBar.open('Le signalement a été archivé avec succès', 'Fermer', {
                        duration: 2500,
                        panelClass: ['green-snackbar', 'snackbar'],
                    });
                    this.retour();
                },
            });
        } else {
            this._snackBar.open('Aucun signalement trouvé', 'Fermer', {
                duration: 2500,
                panelClass: ['red-snackbar', 'snackbar'],
            });
        }
    }

    isUserOutputForDetail(value: any): value is UserOutputForDetail {
        return value && typeof value.id === 'string';
    }

    changerStatut(action: SignalementAction, nom: string): void {
        const dialogRef = this.dialog.open(SignalementActionComponent, {
            data: {
                signalement: this.signalement,
                nomDuBouton: nom,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                if (this.signalement && this.signalement.id) {
                    const params: AdminSignalementsIdPut$Json$Params = {
                        id: this.signalement.id,
                        input: action,
                    };

                    this.adminServices.adminSignalementsIdPut$Json(params).subscribe({
                        next: (response) => {
                            this._snackBar.open('Statut du signalement mis à jour', 'Fermer', {
                                duration: 2500,
                                panelClass: ['green-snackbar', 'snackbar'],
                            });

                            this.StatutFormControl.setValue(response);
                            this.cdr.markForCheck();
                        },
                        error: () => {
                            this._snackBar.open("Un problème est survenu lors de l'archivage du signalement", 'Fermer', {
                                duration: 2500,
                                panelClass: ['red-snackbar', 'snackbar'],
                            });
                        },
                    });
                } else {
                    this._snackBar.open('Aucun signalement trouvé', 'Fermer', {
                        duration: 2500,
                        panelClass: ['red-snackbar', 'snackbar'],
                    });
                }
            }
        });

    }

    bannir() {
        const dialogRef = this.dialog.open(SignalementActionComponent, {
            data: {
                signalement: this.signalement,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (!result) {
                return;
            }

            if (!this.MembreFormControl.value || !this.isUserOutputForDetail(this.MembreFormControl.value)) {
                this._snackBar.open("L'utilisateur n'est pas valide ou non spécifié", 'Fermer', {
                    duration: 2500,
                    panelClass: ['red-snackbar', 'snackbar'],
                });
                return;
            }

            const param: AdminUtilisateursIdBannirPut$Json$Params = {
                id: this.MembreFormControl.value.id ?? '',
                bannir: true,
            };

            this.adminServices.adminUtilisateursIdBannirPut$Json$Response(param).subscribe({
                error: () => {
                    this._snackBar.open("Un problème est survenu lors de l'archivage du signalement", 'Fermer', {
                        duration: 2500,
                        panelClass: ['red-snackbar', 'snackbar'],
                    });
                },
                complete: () => {
                    this._snackBar.open('Le signalement a été archivé avec succès', 'Fermer', {
                        duration: 2500,
                        panelClass: ['green-snackbar', 'snackbar'],
                    });
                    this.retour();
                },
            });
        });
    }
}
