<div class="flex w-full h-full flex-col rounded-[10px] bg-white py-6 px-8 custom-card gap-3 ">
  <div class="flex flex-col gap-4">
    <p class=" text-sm">{{title}}</p>
    <div class="flex w-full gap-2 items-end">
      <p class="text-3xl font-bold ">{{value?.valeur != null ? ((value?.valeur ?? 0) * multiple | number) : '-'}} {{value?.valeur != null ? unit : ""}}</p>
      <p class="font-bold text-[10px]">{{suffix}}</p>
    </div>
  </div>
  @if(value?.variation) {
    <app-stat-variation class="min-w-14 max-w-24" [positive]="isVariationPositive()" [value]="value?.variation ?? 0"></app-stat-variation>
  }
</div>