<form id="form_login" [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="flex w-full flex-col">
  <mat-label class="text-primary">Identifiant</mat-label>
  <mat-form-field>
    <input matInput placeholder="-" formControlName="email" autocomplete="email" />
  </mat-form-field>
  @if (loginForm.get('email')?.hasError('server')) {
  <mat-error>{{ loginForm.get('email')?.getError('server') }}</mat-error>
  }

  <mat-label class="mb-2 text-sm text-primary">Mot de passe</mat-label>
  <mat-form-field class="w-full flex items-center">
    <input placeholder="*****" matInput formControlName="password" [type]="hide() ? 'password' : 'text'"
      autocomplete="password" />
    <button type="button" mat-icon-button matSuffix (click)="clickEvent($event)" [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="hide()">
      <mat-icon class="text-primary h-full">{{ hide() ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
  </mat-form-field>
  @if (loginForm.get('password')?.hasError('server')) {
  <mat-error>{{ loginForm.get('password')?.getError('server') }}</mat-error>
  }

  <div class="flex justify-end">
    <a class="cursor-pointer text-sm text-primary" (click)="openForgotPasswordDialog()">
      J'ai oublié mon mot de passe
    </a>
  </div>

  <mat-checkbox class="my-3" formControlName="rememberMe">Rester connecté</mat-checkbox>

  <!-- TODO -->
  <div class="md:mt-7 md:flex md:justify-center">
    <button mat-flat-button class="w-full !bg-primary md:w-52" type="submit">Connexion</button>
  </div>
</form>