import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatLabel } from '@angular/material/form-field';
import { OffreDtoOutputForDetail } from '@app/api/models';
import { CommonModule } from '@angular/common';
import { DynamicPhotoGridListComponent } from '../dynamic-photo-grid-list/dynamic-photo-grid-list.component';

@Component({
  selector: 'app-offre-details',
  standalone: true,
  imports: [MatLabel, CommonModule, DynamicPhotoGridListComponent],
  templateUrl: './offre-details.component.html',
  styleUrl: './offre-details.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffreDetailsComponent{
  @Input() offreDetails! : OffreDtoOutputForDetail;
  @Input() label: string | undefined;
}


