import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { NotificationsMesNotificationsIdDelete$Params } from '@app/api/fn/notifications/notifications-mes-notifications-id-delete';
import { NotificationDtoOutputForList } from '@app/api/models';
import { NotificationsService } from '@app/api/services';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { NotificationsMesNotificationsIdPut$Params } from '@app/api/fn/notifications/notifications-mes-notifications-id-put';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { SnackBarService } from '@app/utils/services/snackbar.service';

@Component({
    selector: 'app-notif-card',
    standalone: true,
    imports: [CommonModule, MatCardModule, MatCheckboxModule, MatIconModule, FormsModule, MatButtonModule],
    templateUrl: './notif-card.component.html',
    styleUrl: './notif-card.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotifCardComponent implements OnInit {
    @Input() notification!: NotificationDtoOutputForList;
    @Input() isMenuCard = false;
    @Output() notifDelete = new EventEmitter<string>();
    isChecked!: boolean;

    constructor(
        private notificationServices: NotificationsService,
        private cdr: ChangeDetectorRef,
        private snackBarServices: SnackBarService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        if (!!this.notification.lue) {
            this.isChecked = this.notification.lue;
        }
    }
    deleteNotification(id: string) {
        const param: NotificationsMesNotificationsIdDelete$Params = { id: id };
        // const param: NotificationsMesNotificationsIdDelete$Params = { id: _t37.id };
        this.notificationServices.notificationsMesNotificationsIdDelete(param).subscribe({
            complete: () => {
                this.notifDelete.emit(id);
            },
            error: (error) => {
                this.snackBarServices.openSnackBar('Une erreur inconnue est survenue', 'Fermer', true, 2500);
            },
        });
    }

    checkNotification(isChecked: boolean) {
        if (!!this.notification.id) {
            this.isChecked = isChecked;
            const param: NotificationsMesNotificationsIdPut$Params = {
                id: this.notification.id,
                lue: this.isChecked,
            };
            this.notificationServices.notificationsMesNotificationsIdPut(param).subscribe({});
        }
        this.cdr.detectChanges();
    }

    truncateContent(content: string, limit: number): string {
        return content.length > limit ? content.slice(0, limit) + '...' : content;
    }

    onCardClick(url: string) {
        if (url !== '') {
            window.location.href = url;
        } else {
            this.router.navigate(['profil/mes-alertes']);
        }
    }
}
