import { StrictHttpResponse } from '@app/api/strict-http-response';

export function GetFileNameFromHeaders(response: StrictHttpResponse<Blob>): string {
    try {
        var filename:string = response.headers['headers']
        .get('content-disposition')[0]
        .split(';')
        .map((x: string) => x.trim())
        .find((x: string) => x.startsWith('filename='))
        .split('filename=')[1];
        
        return filename.replace(/"/g, '');

    } catch (error) {
        return '';
    }
}

export default function DownloadFile(res: StrictHttpResponse<Blob>): void {
    let url = window.URL.createObjectURL(res.body);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = GetFileNameFromHeaders(res);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
