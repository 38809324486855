<div class="flex flex-col gap-4 container mx-auto p-8 bg-white rounded shadow-lg my-12">
    <div class="flex flex-row justify-between gap-6">
        <div class="flex flex-row items-center gap-6">
            <button mat-flat-button (click)="retour()" class="!bg-primary">
                <mat-icon>arrow_back</mat-icon>
                Retour
            </button>
            <mat-divider vertical="true" class="!w-px !h-10 !bg-gray-300"></mat-divider>
            <h1 class="text-sm font-bold">{{ LabelControl.value ? LabelControl.value: 'Nouvelle notification'}}</h1>
        </div>

        <div class="flex justify-end space-x-4">
            @if(editMode){
            <button mat-stroked-button class="!border-[#A33333] !text-[#A33333]" (click)="onCancel()">
                Annuler
            </button>
            }
            <button mat-flat-button color="primary" (click)="onSubmit()">
                {{editMode ? 'Enregistrer' : 'Editer'}}
            </button>
        </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <app-edit-text-field label="Label" [IsEditMode]="editMode" [formControl]="LabelControl"
            class="col-span-2"></app-edit-text-field>
        <app-edit-select-field label="Type" [IsEditMode]="editMode" [formControl]="TypeControl"
            [options]="notifTypeOptions"></app-edit-select-field>
        <div class="col-span-2 flex gap-2">
            <app-date-field label="Date de première publication" [editMode]="editMode" [datetime]="true"
                [formControl]="dateDebutControl" [hoursControl]="HoursControl" [hoursOptions]="hoursOptions" [minutesControl]="MinutesControl" [minutesOptions]="minutesOptions"></app-date-field>
        </div>
        <app-delta-time-field class="col-span-2" (newRecurrence)="changeRecurrence($event)" label="Récurrence"
            [IsEditMode]="editMode" [joursControl]="joursControl" [moisControl]="moisControl"
            [modeRecurrence]="modeRecurrence" [dateFinControl]="dateFinControl"></app-delta-time-field>
        <app-edit-area-field label="URL" [IsEditMode]="editMode" [formControl]="urlControl"
            class="col-span-3"></app-edit-area-field>
        <app-edit-area-field label="Contenu" [IsEditMode]="editMode" [formControl]="contenuControl"
            class="col-span-3"></app-edit-area-field>
        <div class="col-span-3 ">
            <app-recursive-tree-filtered-filed label="Cible (géographique)" [editMode]="editMode"
                [formControl]="geoFormControl" [searchBarFormControl]="geoSearchBarFormControl"
                [selectedNodes]="selectedNodes" [nodesData]="regions"></app-recursive-tree-filtered-filed>
        </div>

    </div>

</div>