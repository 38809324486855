import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID, signal } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { SnackBarService } from '@app/utils/services/snackbar.service';
import { take, timer } from 'rxjs';
import { AccountsService } from 'src/app/api/services/accounts.service';
import { ForgotPasswordDialogComponent } from '../forgot-password-dialog/forgot-password-dialog.component';
@Component({
    selector: 'app-form-login',
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatButtonModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatIconModule, RouterLink, RouterLinkActive, RouterOutlet],
    templateUrl: './form-login.component.html',
    styleUrls: ['./form-login.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLoginComponent implements OnInit {
    hide = signal(true);
    loginForm: FormGroup;
    isMobile: boolean | undefined;

    constructor(
        public dialog: MatDialog,
        private fb: FormBuilder,
        private router: Router,
        private accountsService: AccountsService,
        private _snackBar: SnackBarService,
        @Inject(PLATFORM_ID) private platformId: object,
    ) {
        this.loginForm = this.fb.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
            rememberMe: [false],
        });
    }

    ngOnInit(): void {
        localStorage.clear();
        this.isMobile = isPlatformBrowser(this.platformId) && window.innerWidth < 768;
    }

    clickEvent(event: MouseEvent) {
        this.hide.set(!this.hide());
        event.stopPropagation();
    }

    openForgotPasswordDialog(): void {
        const dialogRef = this.dialog.open(ForgotPasswordDialogComponent, {});
    }

    onSubmit() {
        if (this.loginForm.valid) {
            this.accountsService.accountsLoginPost$Json({ body: this.loginForm.value }).subscribe({
                next: (response) => {
                    localStorage.setItem('user', JSON.stringify(response));
                    this.router.navigate(['/statistiques']);
                },
                error: (error) => {
                    this._snackBar.displayErrors(error.error, this.loginForm);
                },
            });
        }
    }
}
