import { Injectable } from '@angular/core';
import { MessagerieService, NotificationsService } from '@app/api/services';
import { Observable, interval, forkJoin, BehaviorSubject, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class NotifsCron {
    private msgNotifSource = new BehaviorSubject<number>(0);
    msgNotifValue$ = this.msgNotifSource.asObservable();
    private notifSource = new BehaviorSubject<number>(0);
    notifValue$ = this.notifSource.asObservable();

    constructor(
        //private messagerieService: MessagerieService,
        private notificationsService: NotificationsService,
    ) {
        this.initializeNotificationPolling();
    }

    private initializeNotificationPolling(): void {
        this.triggerRequests();
        interval(5000).pipe(
            switchMap(() => this.fetchNotificationCounts())
        ).subscribe(({ message, notif }) => {
            //this.msgNotifSource.next(message);
            this.notifSource.next(notif);
        });
    }

    private fetchNotificationCounts(): Observable<{ message: number; notif: number }> {
        return forkJoin({
            message: of(0), //this.messagerieService.messagerieMesMessagesNonLuesCompteGet$Json(),
            notif: this.notificationsService.notificationsMesNotificationsNonLuesCompteGet$Json(),
        });
    }

    private triggerRequests(): void {
        this.fetchNotificationCounts().subscribe(({ message, notif }) => {
            this.msgNotifSource.next(message);
            this.notifSource.next(notif);
        });
    }
}