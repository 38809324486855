<div id="signalement-detail"
    class="flex flex-col w-full container mx-auto bg-white rounded shadow-lg my-12 p-8 m-16 gap-8">
    <div class="flex flex-row justify-between">
        <div class="flex flex-row items-center gap-6">
            <button mat-flat-button (click)="retour()" class="!bg-primary">
                <mat-icon>arrow_back</mat-icon>
                Retour
            </button>
            <mat-divider vertical="true" class="!w-px !h-10 !bg-gray-300"></mat-divider>
            <h1 class="text-sm font-bold">{{IdFormControl.value}}</h1>
        </div>
        <div class="flex flex-row gap-5">
            <button mat-stroked-button (click)="changerStatut(SignalementAction.AucuneAction, classerSansSuite)">{{classerSansSuite}}</button>

            <button mat-flat-button [matMenuTriggerFor]="banMenu" class="!bg-[#A33333] flex flex-row-reverse gap-4">
                <span class="whitespace-nowrap">Prendre des mesures</span>
                <mat-icon>expand_more</mat-icon>
            </button>
            <mat-menu #banMenu="matMenu" class="!border-[#A33333]">
                <button mat-menu-item (click)="changerStatut(SignalementAction.RetirerOffre, depublier)">{{depublier}}</button>
                <button mat-menu-item (click)="changerStatut(SignalementAction.BannirUtilisateur, bloquer)">{{bloquer}}</button>
            </mat-menu>
        </div>
    </div>
    @if(editForm){
    <div class="grid grid-cols-1 overflow-y-auto h-[calc(100vh-304px)] hidden-scrollbar">
        <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
            <!-- <app-edit-text-field label="Id" [formControl]="IdFormControl" [IsEditMode]="false"></app-edit-text-field> -->
            <app-edit-select-field label="Type" [formControl]="TypeFormControl"
                [colorFormControl]="TypeColorFormControl" [IsReadOnly]="true">
            </app-edit-select-field>
            <app-contact-field label="Auteur" [userFormControl]="AuteurFormControl"></app-contact-field>
            <app-contact-field label="Membre concerné" [userFormControl]="MembreFormControl"></app-contact-field>
            <app-edit-text-field label="Statut" [formControl]="StatutFormControl"
            [IsEditMode]="false"></app-edit-text-field>
            <app-edit-text-field label="Description" class="col-span-4" [formControl]="DescriptionFormControl"
                [IsReadOnly]="true">
            </app-edit-text-field>

            <mat-divider class="col-span-full"></mat-divider>

            @if (offreDetails) {
            <app-offre-details label="Offre" class="col-span-4" [offreDetails]="offreDetails"></app-offre-details>
            }


            @if (conversation && conversation.contact) {
            <mat-divider class="col-span-full"></mat-divider>

            <app-conversation-signalee label="Conversation" class="col-span-4" [conversation]="conversation"
                [user]="AuteurFormControl.value"></app-conversation-signalee>
            }
        </div>
    </div>
    }
</div>