<div class="flex flex-col gap-2">
  <mat-label class="text-primary font-bold whitespace-nowrap">{{label}}</mat-label>
  @if(IsEditMode && !IsReadOnly){
  <div class="flex w-fit items-center justify-center gap-2">
    <div class="flex flex-col w-fit justify-center gap-2">
      <mat-radio-group (change)="modeRecurrenceChange($event.value)" [value]="modeRecurrence" class="w-fit"
        aria-label="Sélectionnez une valeur">
        <mat-radio-button value="one">1 seule fois</mat-radio-button>
        <mat-radio-button value="days">En jours</mat-radio-button>
        <mat-radio-button value="months">En mois</mat-radio-button>
      </mat-radio-group>
      <div class="flex gap-4 w-fit items-center justify-center">
        @if(modeRecurrence === 'days'){
        <mat-label class="text-primary font-bold whitespace-nowrap">Tous les </mat-label>
        <mat-form-field class="col-span-1">
          <input matInput #daysInput type="number" [formControl]="joursControl" [min]="0" placeholder="jours">
          <mat-hint align="end">{{ daysInput.value || 0 }} / 5</mat-hint>
        </mat-form-field>
        <mat-label class="text-primary font-bold whitespace-nowrap">jours, prend fin le </mat-label>
        <app-date-field [editMode]="IsEditMode" [formControl]="dateFinControl"></app-date-field>

        }
        @if(modeRecurrence === 'months'){
        <mat-label class="text-primary font-bold whitespace-nowrap">Tout les </mat-label>
        <mat-form-field class="col-span-1">
          <input matInput #monthsInput type="number" [formControl]="moisControl" [min]="0" placeholder="mois">
          <mat-hint align="end">{{ monthsInput.value || 0 }} / 5</mat-hint>
        </mat-form-field>
        <mat-label class="text-primary font-bold whitespace-nowrap">mois, prend fin le </mat-label>
        <app-date-field [editMode]="IsEditMode" [formControl]="dateFinControl"></app-date-field>

        }
      </div>
    </div>


  </div>

  @if(!joursControl.valid && joursControl.touched){
  <mat-error>
    {{ getJoursErrorMessage() }}
  </mat-error>
  }
  @if(!moisControl.valid && moisControl.touched){
  <mat-error>
    {{ getMoisErrorMessage() }}
  </mat-error>
  }
  @if(!dateFinControl.valid && dateFinControl.touched){
    <mat-error>
      Vous devez renseigner une date de fin.
    </mat-error>
    }

  } @else {
  @if(joursControl.value === 0 && moisControl.value === 0){
  <p>Aucun jour et aucun mois</p>
  }
  @else {
  <p class="text-sm">
    <ng-container *ngIf="modeRecurrence === 'one'; else recurring">
      Une seule fois
    </ng-container>
    <ng-template #recurring>
      Tous les
      {{ joursControl.value > 0 ? (joursControl.value + ' ' + (joursControl.value === 1 ? 'jour' : 'jours')) : '' }}
      {{ joursControl.value > 0 && moisControl.value > 0 ? ' et ' : '' }}
      {{ moisControl.value > 0 ? (moisControl.value + ' ' + (moisControl.value === 1 ? 'mois' : 'mois')) : '' }}
      @if(dateFinControl.value){
        et prend fin le {{ momentToDate(dateFinControl.value) | date: 'dd/MM/yyyy' }}
      }
    </ng-template>
  </p>
  }
  }
</div>