<div class="flex flex-col gap-4 container mx-auto p-8 bg-white rounded shadow-lg my-12">

    <div class="flex flex-row justify-between">
        <div class="flex flex-row items-center gap-6">
            <button mat-flat-button (click)="retour()" class="!bg-primary">
                <mat-icon>arrow_back</mat-icon>
                Retour
            </button>
            <mat-divider vertical="true" class="!w-px !h-10 !bg-gray-300"></mat-divider>
            <h1 class="text-sm font-bold">{{ ReferenceId ? libelleFormControl.value: 'Nouvelle référence'}}</h1>
        </div>

        <div class="flex justify-end space-x-4">
            @if(editMode){
            <button mat-stroked-button class="!border-[#A33333] !text-[#A33333]" (click)="onCancel()">
                Annuler
            </button>
            }
            <button mat-flat-button color="primary" (click)="onSubmit()">
                {{editMode ? 'Enregistrer' : 'Editer'}}
            </button>
        </div>
    </div>

    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
        <app-edit-text-field label="Libellé" [IsEditMode]="editMode"
            [formControl]="libelleFormControl"></app-edit-text-field>
        <app-edit-text-field label="Référence" [IsEditMode]="editMode" [IsReadOnly]="!!ReferenceId"
            [formControl]="referenceFormControl"></app-edit-text-field>

        @if(ReferenceId){
        <app-edit-select-field label="Catégorie" [IsEditMode]="editMode" [IsReadOnly]="true"
            [formControl]="categorieFormControl"></app-edit-select-field>
        }

        <app-edit-multi-select-field label="Tailles disponibles" [IsEditMode]="editMode" [IsReadOnly]="true"
            [formControl]="taillesFormControl" errorName="une taille"></app-edit-multi-select-field>

        <app-edit-multi-select-field label="Conformité disponibles" [IsEditMode]="editMode" [IsReadOnly]="true"
            [formControl]="conformitesFormControl" errorName="une conformité"
            [IsDisabled]="!taillesFormControl.valid"></app-edit-multi-select-field>

        <div class="flex flex-col gap-2">
            <mat-label class="text-primary font-bold whitespace-nowrap">Photo</mat-label>
            <app-photo-uploader [thumbnailUrl]="thumbnail" [IsEditMode]="editMode" (thumbnailUrlChange)="photoChanged($event)" ></app-photo-uploader>
        </div>

    </div>

    @if(ReferenceId){
    <mat-divider></mat-divider>

    <!-- @if(DonationsRawData){
    <div class="flex flex-col gap-4">
        <mat-label class="text-primary font-bold whitespace-nowrap">Donations (en cours / archivées)</mat-label>
        <div id="donations-data-grid" class="w-full h-[40vh]">
            <app-datagrid class="h-full" [data]="DonationsRawData" [columns]="DonationsColDefs" [nbRecords]="nbRecords"
                (sortChanged)="dataService.onSortChanged($event, DonationsRawData)"
                (filterChanged)="dataService.onFilterChanged($event, DonationsRawData)"
                (pageSizeChange)="dataService.onPageSizeChange($event, DonationsRawData)"
                (gridReady)="dataService.onGridReady($event)"></app-datagrid>
        </div>
    </div>
    }

    @if(DemandesRawData){
    <div class="flex flex-col gap-4">
        <mat-label class="text-primary font-bold whitespace-nowrap">Demandes (réussies)</mat-label>
        <div id="demandes-data-grid" class="w-full h-[40vh]">
            <app-datagrid class="h-full" [data]="DemandesRawData" [columns]="DemandesColDefs" [nbRecords]="nbRecords"
                (sortChanged)="dataService.onSortChanged($event, DemandesRawData)"
                (filterChanged)="dataService.onFilterChanged($event, DemandesRawData)"
                (pageSizeChange)="dataService.onPageSizeChange($event, DemandesRawData)"
                (gridReady)="dataService.onGridReady($event)"></app-datagrid>
        </div>
    </div>
    } -->
    }
</div>