export function mapAgGridFiltersToOData(filter: string, value: string, column = '', filterType = 'text'): string {
    let odataFilter = '';
    if (filterType === 'text') {
        if (filter === 'equals') {
            odataFilter += `tolower(${column}) eq tolower('${value}')`;
        } else if (filter === 'notEqual') {
            odataFilter += `tolower(${column}) ne tolower('${value}')`;
        } else if (filter === 'contains') {
            odataFilter += `contains(tolower(${column}), tolower('${value}'))`;
        } else if (filter === 'notContains') {
            odataFilter += `contains(tolower(${column}), tolower('${value}'))`;
        } else if (filter === 'startsWith') {
            odataFilter += `startswith(tolower(${column}), tolower('${value}'))`;
        } else if (filter === 'endsWith') {
            odataFilter += `endswith(tolower(${column}), tolower('${value}'))`;
        }
    } else if (filterType === 'number') {
        if (filter === 'equals') {
            odataFilter += `${column} eq ${value}`;
        } else if (filter === 'notEqual') {
            odataFilter += `${column} ne ${value}`;
        } else if (filter === 'lessThan') {
            odataFilter += `${column} lt ${value}`;
        } else if (filter === 'lessThanOrEqual') {
            odataFilter += `${column} le ${value}`;
        } else if (filter === 'greaterThan') {
            odataFilter += `${column} gt ${value}`;
        } else if (filter === 'greaterThanOrEqual') {
            odataFilter += `${column} ge ${value}`;
        }
    } else if (filterType === 'date') {
        if (filter === 'equals') {
            odataFilter += `${column} eq ${value}`;
        } else if (filter === 'notEqual') {
            odataFilter += `${column} ne ${value}`;
        } else if (filter === 'lessThan') {
            odataFilter += `${column} lt ${value}`;
        } else if (filter === 'lessThanOrEqual') {
            odataFilter += `${column} le ${value}`;
        } else if (filter === 'greaterThan') {
            odataFilter += `${column} gt ${value}`;
        } else if (filter === 'greaterThanOrEqual') {
            odataFilter += `${column} ge ${value}`;
        }
    }
    return odataFilter;
}
