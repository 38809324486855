import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';

export class TaskNode {
    public value: MatOption;
    public control: FormControl<boolean | null>;
    public hiddenControl: FormControl<boolean | null>;
    public childrenGetter: (() => Observable<TaskNode[]>) | null | undefined;
    public parent: TaskNode | null;
    public isLoadMore: boolean;
    public children: TaskNode[] = [];

    constructor(
        value: MatOption,
        control: FormControl<boolean | null> = new FormControl<boolean>(false),
        hiddenControl: FormControl<boolean | null> = new FormControl<boolean>(false),
        children: TaskNode[] = [],
        parent: TaskNode | null = null,
        isLoadMore = true,
    ) {
        this.value = value;
        this.control = control;
        this.children = children;
        this.hiddenControl = hiddenControl;
        this.parent = parent;
        this.isLoadMore = isLoadMore;
    }

    public setParent(parent: TaskNode): void {  
        this.parent = parent;
    }
    public setChildren(children: TaskNode[]): void {
        children.forEach((child) => {
            child.setParent(this);
        });
        this.children = children;
    }

    public load(): void {
        if (this.childrenGetter) {
            this.isLoadMore = true;

            this.childrenGetter().subscribe({
                next: (taskNodes) => {
                    // Mettre à jour le cache avec les enfants chargés
                    this.children = taskNodes;
                    this.children.forEach((child) => {
                        child.parent = this;
                    });
                },
                error: (err) => {
                    console.error('Error while loading children:', err);
                    // Gérer les erreurs, par exemple, en affichant un message à l'utilisateur
                },
                complete: () => {
                    // Désactiver l'indicateur de chargement après le chargement
                    this.isLoadMore = false;
                },
            });
        } else {
            // Désactiver l'indicateur de chargement si pas de children à charger
            this.isLoadMore = false;
        }
    }

    // Méthode pour invalider le cache (si nécessaire)
    public invalidateCache(): void {
        this.children = [];
    }
}

export class TaskNodeUtils {
    public static partiallyComplete(node: TaskNode): boolean {
        if (node.children) {
            const children = node.children;
            return children.some((child) => TaskNodeUtils.partiallyComplete(child) || child.control.value) && !children.every((child) => child.control.value);
        }
        return false;
    }
    public static allChildrenAreSelected(node: TaskNode): boolean {
        if (node.children) {
            const children = node.children;
            return children.every((child) => TaskNodeUtils.allChildrenAreSelected(child) && child.control.value);
        }
        return false;
    }
}

@Component({
    selector: 'app-recursive-tree',
    standalone: true,
    imports: [CommonModule, MatTreeModule, MatCheckboxModule, MatIconModule, MatProgressBarModule],
    templateUrl: './recursive-tree.component.html',
    styleUrls: ['./recursive-tree.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTreeComponent implements OnChanges {
    @Input() title: string | undefined;
    @Input() nodesData: TaskNode[] = [];
    @Input() editMode = false;
    @Input() selectedNodes : WritableSignal<TaskNode[]> = signal([]);
    @Output() selectedNodesChange: EventEmitter<TaskNode[]> = new EventEmitter<TaskNode[]>();
    treeControl = new NestedTreeControl<TaskNode>((node) => node.children);
    dataSource = new MatTreeNestedDataSource<TaskNode>();

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(): void {
        this.dataSource.data = this.nodesData;
    }

    hasChild = (_: number, node: TaskNode) => !!node.children && node.children.length > 0;

    isLoadMore = (_: number, node: TaskNode) => node.isLoadMore;

    loadChildren(node: TaskNode): void {
        if (this.treeControl.isExpanded(node)) {
            node.load();
        }
    }

    /** Load more nodes when clicking on "Load more" node. */
    loadOnClick(event: MouseEvent, node: TaskNode) {
        this._loadSiblings(event.target as HTMLElement, node);
    }

    /** Load more nodes on keyboardpress when focused on "Load more" node */
    loadOnKeypress(event: KeyboardEvent, node: TaskNode) {
        if (event.keyCode === ENTER || event.keyCode === SPACE) {
            this._loadSiblings(event.target as HTMLElement, node);
        }
    }

    private _loadSiblings(nodeElement: HTMLElement, node: TaskNode) {
        if (node.parent) {
            // Store a reference to the sibling of the "Load More" node before it is removed from the DOM
            const previousSibling = nodeElement.previousElementSibling;

            // Synchronously load data.
            node.parent.load();

            const focusDesination = previousSibling?.nextElementSibling || previousSibling;

            if (focusDesination) {
                // Restore focus.
                (focusDesination as HTMLElement).focus();
            }
        }
    }

    /// --------------------------- Check Box Functions --------------------------- ///
    checkAll(value:boolean): void {
        this.nodesData.forEach((node) => {
            this.update(node, value, true);
        });
        this.selectedNodesChange.emit(this.selectedNodes());
    }

    update(node: TaskNode, completed: boolean, emitEvent = true): void {
        if (emitEvent) {
            node.control.setValue(completed, { emitEvent: false });
            this.selectedNodes.update((nodes) => {
                if (completed) {
                    nodes.push(node);
                } else {
                    const index = nodes.indexOf(node);
                    if (index > -1) {
                        nodes.splice(index, 1);
                    }
                }
                return nodes;
            });
        }
        if (node.children) {
            node.children.forEach((child) => this.update(child, completed));
        }
        if (node.parent) {
            this.updateParentStatus(node.parent);
        }
        this.selectedNodesChange.emit(this.selectedNodes());
    }

    updateParentStatus(node: TaskNode): void {
        if (node.children) {
            node.control.setValue(node.children.every((child) => child.control.value));
            if (node.parent) {
                this.updateParentStatus(node.parent);
            }
        }
    }

    partiallyComplete(node: TaskNode): boolean {
        return TaskNodeUtils.partiallyComplete(node);
    }

    countHiddenChildren(node: TaskNode): number {
        return node.children?.filter((child) => !child.hiddenControl.value).length ?? 0;
    }

    isSelected(node: TaskNode): boolean {
        const everyChildSelected = node.children?.every((child) => this.selectedNodes().includes(child));
        return this.selectedNodes().includes(node) || (everyChildSelected && node.children?.length > 0);
    }
}
