<div class="flex flex-col h-full w-full">
  <div class="flex flex-col w-full h-full gap-2 2xl:gap-0 justify-between 2xl:flex-row">
    <div class="flex flex-col gap-2">
      <p class="text-primary">Période d'étude</p>
      <app-stat-range [range]="range" (dateChange)="dateChangeEmit($event)"></app-stat-range>
    </div>
    <div class="flex flex-col gap-2">
      <p class="flex gap-4 items-center"> 
        <mat-slide-toggle [hideIcon]="true" [(ngModel)]="activateComparaison" (change)="toggleChange()" aria-label="activer la comparaison de périodes"></mat-slide-toggle>
        <span class="text-primary">Période de comparaison</span>
      </p>
      <app-stat-range [range]="range2" [disabled]="!activateComparaison"
        (dateChange)="dateChangeEmit2($event)"></app-stat-range>
    </div>
  </div>
</div>