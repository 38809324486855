import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { Editor } from 'ngx-editor';
import { RichtextComponent } from '../../components/richtext/richtext.component';
import { AdminService } from '@app/api/services';
import { AdminInformationsRecyclagePost$Json$Params } from '@app/api/fn/admin/admin-informations-recyclage-post-json';
import { MatButtonModule } from '@angular/material/button';
import { firstValueFrom, Observable, of } from 'rxjs';
import { AdminInformationsSymbiosePost$Json$Params } from '@app/api/fn/admin/admin-informations-symbiose-post-json';
import { AppModule } from '@app/app.module';
import { MaterialModule } from '@app/modules/material.module';
import { Router } from '@angular/router';
import { AdminInformationsCguPost$Json$Params } from '@app/api/fn/admin/admin-informations-cgu-post-json';

@Component({
    selector: 'app-contenu',
    standalone: true,
    imports: [MatTabsModule, CommonModule, MatButtonModule, AppModule],
    templateUrl: './contenu.component.html',
    styleUrl: './contenu.component.css',
    providers: [Editor],
})
export class ContenuComponent implements OnInit {
    contenuDuRichTextRecyclage = '';
    contenuDuRichTextSymbiose = '';
    contenuDuRichTextCgu = '';

    tabs = ['Recyclage', 'Symbiose', 'CGU'];
    currentTab = 'Recyclage';

    constructor(
        private adminServices: AdminService,
        private _snackBar: MatSnackBar,
        private cdr: ChangeDetectorRef,
        public editorRecyclage: Editor,
        public editorSymbiose: Editor,
        public editorCgu: Editor,
        private router: Router,
    ) {}

    ngOnInit() {
        this.getContenu();
    }

    save() {
        let contenuRichtext = '';
        let param = {};
        let serviceMethod: Observable<string> = of('');
        switch (this.currentTab) {
            case 'Symbiose':
                contenuRichtext = JSON.stringify(this.contenuDuRichTextSymbiose);
                param = { body: contenuRichtext } as AdminInformationsSymbiosePost$Json$Params;
                serviceMethod = this.adminServices.adminInformationsSymbiosePost$Json(param);
                break;
            case 'CGU':
                contenuRichtext = JSON.stringify(this.contenuDuRichTextCgu);
                param = { body: contenuRichtext } as AdminInformationsCguPost$Json$Params;
                serviceMethod = this.adminServices.adminInformationsCguPost$Json(param);
                break;
            default:
                contenuRichtext = JSON.stringify(this.contenuDuRichTextRecyclage);
                param = { body: contenuRichtext } as AdminInformationsRecyclagePost$Json$Params;
                serviceMethod = this.adminServices.adminInformationsRecyclagePost$Json(param);
                break;
        }

        serviceMethod.subscribe({
            next: (response) => {
                // this.cdr.detectChanges();
            },
            error: (error) => {
                this._snackBar.open("Un problème est survenu lors de l'édition de la page", 'Fermer', {
                    duration: 3000,
                    panelClass: ['red-snackbar', 'snackbar'],
                });
            },
            complete: () => {
                this._snackBar.open(`Page ${this.currentTab} éditée avec succès`, 'Fermer', {
                    duration: 2000,
                    panelClass: ['green-snackbar', 'snackbar'],
                });
            },
        });
    }

    cancel() {
        this.getContenu();
    }

    getContenu() {
        if (this.currentTab === 'Recyclage') {
            this.adminServices.adminInformationsRecyclageGet$Json().subscribe({
                next: (response) => {
                    if (response) {
                        this.contenuDuRichTextRecyclage = response;
                    }
                },
                error: (error) => {
                    console.error('Erreur lors de la récupération du contenu recyclage', error);
                    this._snackBar.open('Une erreur inconnue est survenue', 'Fermer', {
                        duration: 3000,
                        panelClass: ['red-snackbar', 'snackbar'],
                    });
                },
                complete: () => {
                    this.cdr.detectChanges();
                },
            });
        } else if (this.currentTab === 'Symbiose') {
            this.adminServices.adminInformationsSymbioseGet$Json().subscribe({
                next: (response) => {
                    if (response) {
                        this.contenuDuRichTextSymbiose = response;
                    }
                },
                error: (error) => {
                    console.error('Erreur lors de la récupération du contenu symbiose', error);
                    this._snackBar.open('Une erreur inconnue est survenue', 'Fermer', {
                        duration: 3000,
                        panelClass: ['red-snackbar', 'snackbar'],
                    });
                },
                complete: () => {
                    this.cdr.detectChanges();
                },
            });
        } else if (this.currentTab === 'CGU') {
            this.adminServices.adminInformationsCguGet$Json().subscribe({
                next: (response) => {
                    if (response) {
                        this.contenuDuRichTextCgu = response;
                    }
                },
                error: (error) => {
                    console.error('Erreur lors de la récupération du contenu CGU', error);
                    this._snackBar.open('Une erreur inconnue est survenue', 'Fermer', {
                        duration: 3000,
                        panelClass: ['red-snackbar', 'snackbar'],
                    });
                },
                complete: () => {
                    this.cdr.detectChanges();
                },
            });
        }
    }

    changeTab(event: any) {
        this.currentTab = event.index === this.tabs.indexOf('CGU') ? 'CGU' : event.index === this.tabs.indexOf('Symbiose') ? 'Symbiose' : 'Recyclage';
        this.getContenu();
    }
}
