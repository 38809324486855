import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { UserOutputForDetail } from '@app/api/models';
import { DataSharingService } from '@app/utils/services/data-sharing.services';

@Component({
    selector: 'app-contact-field',
    standalone: true,
    imports: [CommonModule, MatLabel, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    templateUrl: './contact-field.component.html',
    styleUrl: './contact-field.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactFieldComponent implements OnInit {
    @Input() label: string | undefined;
    @Input() userFormControl: FormControl<UserOutputForDetail | null | undefined> = new FormControl<UserOutputForDetail | null | undefined>(null);
    @Input() nowrap = false;

    constructor(
        private cdr: ChangeDetectorRef,
        private router: Router,
        private dataSharingService: DataSharingService,
    ) {}

    ngOnInit(): void {
        this.userFormControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    contacter() {
        this.dataSharingService.setData(this.userFormControl.value);
        this.router.navigate(['/messagerie']); // Make sure to handle the route and parameters correctly
    }

    getInitialWithLastName(): string {
        const firstName = this.userFormControl.value?.firstName ?? '';
        const lastName = this.userFormControl.value?.lastName ?? '';

        if (lastName.length > 0) {
            return `${firstName} ${lastName.charAt(0)}.`;
        }

        return lastName; // Si le prénom est vide, on retourne seulement le nom de famille
    }
}
