/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AlerteProgrammeeeDtoOutputForList } from '../models/alerte-programmeee-dto-output-for-list';
import { LieuEchangeDtoOuput } from '../models/lieu-echange-dto-ouput';
import { LieuEchangeDtoOuputAvecFavorisOdataResponse } from '../models/lieu-echange-dto-ouput-avec-favoris-odata-response';
import { LieuEchangeDtoOuputAvecGeolocalisation } from '../models/lieu-echange-dto-ouput-avec-geolocalisation';
import { NotificationDtoOutputForList } from '../models/notification-dto-output-for-list';
import { ProgrammationNotificationDtoOutputForDetail } from '../models/programmation-notification-dto-output-for-detail';
import { UserDtoOutputStatistiqueForProfilInformation } from '../models/user-dto-output-statistique-for-profil-information';
import { usersAdressesAdressesPubliquesGet$Any } from '../fn/users/users-adresses-adresses-publiques-get-any';
import { UsersAdressesAdressesPubliquesGet$Any$Params } from '../fn/users/users-adresses-adresses-publiques-get-any';
import { usersAdressesAdressesPubliquesGet$Json } from '../fn/users/users-adresses-adresses-publiques-get-json';
import { UsersAdressesAdressesPubliquesGet$Json$Params } from '../fn/users/users-adresses-adresses-publiques-get-json';
import { usersAdressesAdressesPubliquesGet$Plain } from '../fn/users/users-adresses-adresses-publiques-get-plain';
import { UsersAdressesAdressesPubliquesGet$Plain$Params } from '../fn/users/users-adresses-adresses-publiques-get-plain';
import { usersAdressesAdressesPubliquesIdFavorisPost$Any } from '../fn/users/users-adresses-adresses-publiques-id-favoris-post-any';
import { UsersAdressesAdressesPubliquesIdFavorisPost$Any$Params } from '../fn/users/users-adresses-adresses-publiques-id-favoris-post-any';
import { usersAdressesAdressesPubliquesIdFavorisPost$Json } from '../fn/users/users-adresses-adresses-publiques-id-favoris-post-json';
import { UsersAdressesAdressesPubliquesIdFavorisPost$Json$Params } from '../fn/users/users-adresses-adresses-publiques-id-favoris-post-json';
import { usersAdressesAdressesPubliquesIdFavorisPost$Plain } from '../fn/users/users-adresses-adresses-publiques-id-favoris-post-plain';
import { UsersAdressesAdressesPubliquesIdFavorisPost$Plain$Params } from '../fn/users/users-adresses-adresses-publiques-id-favoris-post-plain';
import { usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut } from '../fn/users/users-adresses-adresses-publiques-mes-adresses-choisir-adresse-principale-put';
import { UsersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut$Params } from '../fn/users/users-adresses-adresses-publiques-mes-adresses-choisir-adresse-principale-put';
import { usersAdressesMonAdressePrincipaleInformationsGet$Any } from '../fn/users/users-adresses-mon-adresse-principale-informations-get-any';
import { UsersAdressesMonAdressePrincipaleInformationsGet$Any$Params } from '../fn/users/users-adresses-mon-adresse-principale-informations-get-any';
import { usersAdressesMonAdressePrincipaleInformationsGet$Json } from '../fn/users/users-adresses-mon-adresse-principale-informations-get-json';
import { UsersAdressesMonAdressePrincipaleInformationsGet$Json$Params } from '../fn/users/users-adresses-mon-adresse-principale-informations-get-json';
import { usersAdressesMonAdressePrincipaleInformationsGet$Plain } from '../fn/users/users-adresses-mon-adresse-principale-informations-get-plain';
import { UsersAdressesMonAdressePrincipaleInformationsGet$Plain$Params } from '../fn/users/users-adresses-mon-adresse-principale-informations-get-plain';
import { usersAlertesIdDelete } from '../fn/users/users-alertes-id-delete';
import { UsersAlertesIdDelete$Params } from '../fn/users/users-alertes-id-delete';
import { usersAlertesProgrammeesIdActiverPut } from '../fn/users/users-alertes-programmees-id-activer-put';
import { UsersAlertesProgrammeesIdActiverPut$Params } from '../fn/users/users-alertes-programmees-id-activer-put';
import { usersAlertesProgrammeesMesAlertesProgrammeeGet$Any } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-get-any';
import { UsersAlertesProgrammeesMesAlertesProgrammeeGet$Any$Params } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-get-any';
import { usersAlertesProgrammeesMesAlertesProgrammeeGet$Json } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-get-json';
import { UsersAlertesProgrammeesMesAlertesProgrammeeGet$Json$Params } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-get-json';
import { usersAlertesProgrammeesMesAlertesProgrammeeGet$Plain } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-get-plain';
import { UsersAlertesProgrammeesMesAlertesProgrammeeGet$Plain$Params } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-get-plain';
import { usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-id-get-any';
import { UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any$Params } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-id-get-any';
import { usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-id-get-json';
import { UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json$Params } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-id-get-json';
import { usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-id-get-plain';
import { UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain$Params } from '../fn/users/users-alertes-programmees-mes-alertes-programmee-id-get-plain';
import { usersAlertesProgrammeesPost } from '../fn/users/users-alertes-programmees-post';
import { UsersAlertesProgrammeesPost$Params } from '../fn/users/users-alertes-programmees-post';
import { usersMesStatistiquesGet$Any } from '../fn/users/users-mes-statistiques-get-any';
import { UsersMesStatistiquesGet$Any$Params } from '../fn/users/users-mes-statistiques-get-any';
import { usersMesStatistiquesGet$Json } from '../fn/users/users-mes-statistiques-get-json';
import { UsersMesStatistiquesGet$Json$Params } from '../fn/users/users-mes-statistiques-get-json';
import { usersMesStatistiquesGet$Plain } from '../fn/users/users-mes-statistiques-get-plain';
import { UsersMesStatistiquesGet$Plain$Params } from '../fn/users/users-mes-statistiques-get-plain';
import { usersNotificationsIdLuePut } from '../fn/users/users-notifications-id-lue-put';
import { UsersNotificationsIdLuePut$Params } from '../fn/users/users-notifications-id-lue-put';
import { usersNotificationsMesNotificationsCompteGet$Any } from '../fn/users/users-notifications-mes-notifications-compte-get-any';
import { UsersNotificationsMesNotificationsCompteGet$Any$Params } from '../fn/users/users-notifications-mes-notifications-compte-get-any';
import { usersNotificationsMesNotificationsCompteGet$Json } from '../fn/users/users-notifications-mes-notifications-compte-get-json';
import { UsersNotificationsMesNotificationsCompteGet$Json$Params } from '../fn/users/users-notifications-mes-notifications-compte-get-json';
import { usersNotificationsMesNotificationsCompteGet$Plain } from '../fn/users/users-notifications-mes-notifications-compte-get-plain';
import { UsersNotificationsMesNotificationsCompteGet$Plain$Params } from '../fn/users/users-notifications-mes-notifications-compte-get-plain';
import { usersNotificationsMesNotificationsGet$Any } from '../fn/users/users-notifications-mes-notifications-get-any';
import { UsersNotificationsMesNotificationsGet$Any$Params } from '../fn/users/users-notifications-mes-notifications-get-any';
import { usersNotificationsMesNotificationsGet$Json } from '../fn/users/users-notifications-mes-notifications-get-json';
import { UsersNotificationsMesNotificationsGet$Json$Params } from '../fn/users/users-notifications-mes-notifications-get-json';
import { usersNotificationsMesNotificationsGet$Plain } from '../fn/users/users-notifications-mes-notifications-get-plain';
import { UsersNotificationsMesNotificationsGet$Plain$Params } from '../fn/users/users-notifications-mes-notifications-get-plain';

@Injectable({ providedIn: 'root' })
export class UsersService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut()` */
  static readonly UsersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePutPath = '/users/adresses/adresses-publiques/mes-adresses/choisir-adresse-principale';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut$Response(params?: UsersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut(params?: UsersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut$Params, context?: HttpContext): Observable<void> {
    return this.usersAdressesAdressesPubliquesMesAdressesChoisirAdressePrincipalePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersAdressesAdressesPubliquesGet()` */
  static readonly UsersAdressesAdressesPubliquesGetPath = '/users/adresses/adresses-publiques';

  /**
   * Toutes les adresses publique pour choisir des favoris dans le profil.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesAdressesPubliquesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesGet$Json$Response(params?: UsersAdressesAdressesPubliquesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>> {
    return usersAdressesAdressesPubliquesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Toutes les adresses publique pour choisir des favoris dans le profil.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesAdressesPubliquesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesGet$Json(params?: UsersAdressesAdressesPubliquesGet$Json$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecFavorisOdataResponse> {
    return this.usersAdressesAdressesPubliquesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>): LieuEchangeDtoOuputAvecFavorisOdataResponse => r.body)
    );
  }

  /**
   * Toutes les adresses publique pour choisir des favoris dans le profil.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesAdressesPubliquesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesGet$Plain$Response(params?: UsersAdressesAdressesPubliquesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>> {
    return usersAdressesAdressesPubliquesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Toutes les adresses publique pour choisir des favoris dans le profil.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesAdressesPubliquesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesGet$Plain(params?: UsersAdressesAdressesPubliquesGet$Plain$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecFavorisOdataResponse> {
    return this.usersAdressesAdressesPubliquesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>): LieuEchangeDtoOuputAvecFavorisOdataResponse => r.body)
    );
  }

  /**
   * Toutes les adresses publique pour choisir des favoris dans le profil.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesAdressesPubliquesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesGet$Any$Response(params?: UsersAdressesAdressesPubliquesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>> {
    return usersAdressesAdressesPubliquesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Toutes les adresses publique pour choisir des favoris dans le profil.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesAdressesPubliquesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesGet$Any(params?: UsersAdressesAdressesPubliquesGet$Any$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecFavorisOdataResponse> {
    return this.usersAdressesAdressesPubliquesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecFavorisOdataResponse>): LieuEchangeDtoOuputAvecFavorisOdataResponse => r.body)
    );
  }

  /** Path part for operation `usersAdressesAdressesPubliquesIdFavorisPost()` */
  static readonly UsersAdressesAdressesPubliquesIdFavorisPostPath = '/users/adresses/adresses-publiques/{id}/favoris';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesAdressesPubliquesIdFavorisPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesIdFavorisPost$Json$Response(params: UsersAdressesAdressesPubliquesIdFavorisPost$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuput>> {
    return usersAdressesAdressesPubliquesIdFavorisPost$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesAdressesPubliquesIdFavorisPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesIdFavorisPost$Json(params: UsersAdressesAdressesPubliquesIdFavorisPost$Json$Params, context?: HttpContext): Observable<LieuEchangeDtoOuput> {
    return this.usersAdressesAdressesPubliquesIdFavorisPost$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuput>): LieuEchangeDtoOuput => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesAdressesPubliquesIdFavorisPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesIdFavorisPost$Plain$Response(params: UsersAdressesAdressesPubliquesIdFavorisPost$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuput>> {
    return usersAdressesAdressesPubliquesIdFavorisPost$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesAdressesPubliquesIdFavorisPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesIdFavorisPost$Plain(params: UsersAdressesAdressesPubliquesIdFavorisPost$Plain$Params, context?: HttpContext): Observable<LieuEchangeDtoOuput> {
    return this.usersAdressesAdressesPubliquesIdFavorisPost$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuput>): LieuEchangeDtoOuput => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesAdressesPubliquesIdFavorisPost$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesIdFavorisPost$Any$Response(params: UsersAdressesAdressesPubliquesIdFavorisPost$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuput>> {
    return usersAdressesAdressesPubliquesIdFavorisPost$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesAdressesPubliquesIdFavorisPost$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesAdressesPubliquesIdFavorisPost$Any(params: UsersAdressesAdressesPubliquesIdFavorisPost$Any$Params, context?: HttpContext): Observable<LieuEchangeDtoOuput> {
    return this.usersAdressesAdressesPubliquesIdFavorisPost$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuput>): LieuEchangeDtoOuput => r.body)
    );
  }

  /** Path part for operation `usersAlertesProgrammeesPost()` */
  static readonly UsersAlertesProgrammeesPostPath = '/users/alertes/programmees';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  usersAlertesProgrammeesPost$Response(params?: UsersAlertesProgrammeesPost$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return usersAlertesProgrammeesPost(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  usersAlertesProgrammeesPost(params?: UsersAlertesProgrammeesPost$Params, context?: HttpContext): Observable<void> {
    return this.usersAlertesProgrammeesPost$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersAlertesProgrammeesMesAlertesProgrammeeGet()` */
  static readonly UsersAlertesProgrammeesMesAlertesProgrammeeGetPath = '/users/alertes/programmees/mes-alertes-programmee';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesMesAlertesProgrammeeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeGet$Json$Response(params?: UsersAlertesProgrammeesMesAlertesProgrammeeGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AlerteProgrammeeeDtoOutputForList>>> {
    return usersAlertesProgrammeesMesAlertesProgrammeeGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesMesAlertesProgrammeeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeGet$Json(params?: UsersAlertesProgrammeesMesAlertesProgrammeeGet$Json$Params, context?: HttpContext): Observable<Array<AlerteProgrammeeeDtoOutputForList>> {
    return this.usersAlertesProgrammeesMesAlertesProgrammeeGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AlerteProgrammeeeDtoOutputForList>>): Array<AlerteProgrammeeeDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesMesAlertesProgrammeeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeGet$Plain$Response(params?: UsersAlertesProgrammeesMesAlertesProgrammeeGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AlerteProgrammeeeDtoOutputForList>>> {
    return usersAlertesProgrammeesMesAlertesProgrammeeGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesMesAlertesProgrammeeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeGet$Plain(params?: UsersAlertesProgrammeesMesAlertesProgrammeeGet$Plain$Params, context?: HttpContext): Observable<Array<AlerteProgrammeeeDtoOutputForList>> {
    return this.usersAlertesProgrammeesMesAlertesProgrammeeGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AlerteProgrammeeeDtoOutputForList>>): Array<AlerteProgrammeeeDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesMesAlertesProgrammeeGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeGet$Any$Response(params?: UsersAlertesProgrammeesMesAlertesProgrammeeGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AlerteProgrammeeeDtoOutputForList>>> {
    return usersAlertesProgrammeesMesAlertesProgrammeeGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesMesAlertesProgrammeeGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeGet$Any(params?: UsersAlertesProgrammeesMesAlertesProgrammeeGet$Any$Params, context?: HttpContext): Observable<Array<AlerteProgrammeeeDtoOutputForList>> {
    return this.usersAlertesProgrammeesMesAlertesProgrammeeGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AlerteProgrammeeeDtoOutputForList>>): Array<AlerteProgrammeeeDtoOutputForList> => r.body)
    );
  }

  /** Path part for operation `usersAlertesProgrammeesMesAlertesProgrammeeIdGet()` */
  static readonly UsersAlertesProgrammeesMesAlertesProgrammeeIdGetPath = '/users/alertes/programmees/mes-alertes-programmee/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json$Response(params: UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>> {
    return usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json(params: UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForDetail> {
    return this.usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>): ProgrammationNotificationDtoOutputForDetail => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain$Response(params: UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>> {
    return usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain(params: UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForDetail> {
    return this.usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>): ProgrammationNotificationDtoOutputForDetail => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any$Response(params: UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>> {
    return usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any(params: UsersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any$Params, context?: HttpContext): Observable<ProgrammationNotificationDtoOutputForDetail> {
    return this.usersAlertesProgrammeesMesAlertesProgrammeeIdGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProgrammationNotificationDtoOutputForDetail>): ProgrammationNotificationDtoOutputForDetail => r.body)
    );
  }

  /** Path part for operation `usersAlertesProgrammeesIdActiverPut()` */
  static readonly UsersAlertesProgrammeesIdActiverPutPath = '/users/alertes/programmees/{id}/activer';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesProgrammeesIdActiverPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesIdActiverPut$Response(params: UsersAlertesProgrammeesIdActiverPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return usersAlertesProgrammeesIdActiverPut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesProgrammeesIdActiverPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesProgrammeesIdActiverPut(params: UsersAlertesProgrammeesIdActiverPut$Params, context?: HttpContext): Observable<void> {
    return this.usersAlertesProgrammeesIdActiverPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersAlertesIdDelete()` */
  static readonly UsersAlertesIdDeletePath = '/users/alertes/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAlertesIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesIdDelete$Response(params: UsersAlertesIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return usersAlertesIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAlertesIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAlertesIdDelete(params: UsersAlertesIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.usersAlertesIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersMesStatistiquesGet()` */
  static readonly UsersMesStatistiquesGetPath = '/users/mes-statistiques';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersMesStatistiquesGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersMesStatistiquesGet$Json$Response(params?: UsersMesStatistiquesGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoOutputStatistiqueForProfilInformation>> {
    return usersMesStatistiquesGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersMesStatistiquesGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersMesStatistiquesGet$Json(params?: UsersMesStatistiquesGet$Json$Params, context?: HttpContext): Observable<UserDtoOutputStatistiqueForProfilInformation> {
    return this.usersMesStatistiquesGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoOutputStatistiqueForProfilInformation>): UserDtoOutputStatistiqueForProfilInformation => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersMesStatistiquesGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersMesStatistiquesGet$Plain$Response(params?: UsersMesStatistiquesGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoOutputStatistiqueForProfilInformation>> {
    return usersMesStatistiquesGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersMesStatistiquesGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersMesStatistiquesGet$Plain(params?: UsersMesStatistiquesGet$Plain$Params, context?: HttpContext): Observable<UserDtoOutputStatistiqueForProfilInformation> {
    return this.usersMesStatistiquesGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoOutputStatistiqueForProfilInformation>): UserDtoOutputStatistiqueForProfilInformation => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersMesStatistiquesGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersMesStatistiquesGet$Any$Response(params?: UsersMesStatistiquesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDtoOutputStatistiqueForProfilInformation>> {
    return usersMesStatistiquesGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersMesStatistiquesGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersMesStatistiquesGet$Any(params?: UsersMesStatistiquesGet$Any$Params, context?: HttpContext): Observable<UserDtoOutputStatistiqueForProfilInformation> {
    return this.usersMesStatistiquesGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDtoOutputStatistiqueForProfilInformation>): UserDtoOutputStatistiqueForProfilInformation => r.body)
    );
  }

  /** Path part for operation `usersNotificationsMesNotificationsGet()` */
  static readonly UsersNotificationsMesNotificationsGetPath = '/users/notifications/mes-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersNotificationsMesNotificationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsGet$Json$Response(params?: UsersNotificationsMesNotificationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationDtoOutputForList>>> {
    return usersNotificationsMesNotificationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersNotificationsMesNotificationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsGet$Json(params?: UsersNotificationsMesNotificationsGet$Json$Params, context?: HttpContext): Observable<Array<NotificationDtoOutputForList>> {
    return this.usersNotificationsMesNotificationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationDtoOutputForList>>): Array<NotificationDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersNotificationsMesNotificationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsGet$Plain$Response(params?: UsersNotificationsMesNotificationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationDtoOutputForList>>> {
    return usersNotificationsMesNotificationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersNotificationsMesNotificationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsGet$Plain(params?: UsersNotificationsMesNotificationsGet$Plain$Params, context?: HttpContext): Observable<Array<NotificationDtoOutputForList>> {
    return this.usersNotificationsMesNotificationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationDtoOutputForList>>): Array<NotificationDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersNotificationsMesNotificationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsGet$Any$Response(params?: UsersNotificationsMesNotificationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationDtoOutputForList>>> {
    return usersNotificationsMesNotificationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersNotificationsMesNotificationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsGet$Any(params?: UsersNotificationsMesNotificationsGet$Any$Params, context?: HttpContext): Observable<Array<NotificationDtoOutputForList>> {
    return this.usersNotificationsMesNotificationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationDtoOutputForList>>): Array<NotificationDtoOutputForList> => r.body)
    );
  }

  /** Path part for operation `usersNotificationsMesNotificationsCompteGet()` */
  static readonly UsersNotificationsMesNotificationsCompteGetPath = '/users/notifications/mes-notifications/compte';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersNotificationsMesNotificationsCompteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsCompteGet$Json$Response(params?: UsersNotificationsMesNotificationsCompteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return usersNotificationsMesNotificationsCompteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersNotificationsMesNotificationsCompteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsCompteGet$Json(params?: UsersNotificationsMesNotificationsCompteGet$Json$Params, context?: HttpContext): Observable<number> {
    return this.usersNotificationsMesNotificationsCompteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersNotificationsMesNotificationsCompteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsCompteGet$Plain$Response(params?: UsersNotificationsMesNotificationsCompteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return usersNotificationsMesNotificationsCompteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersNotificationsMesNotificationsCompteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsCompteGet$Plain(params?: UsersNotificationsMesNotificationsCompteGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.usersNotificationsMesNotificationsCompteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersNotificationsMesNotificationsCompteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsCompteGet$Any$Response(params?: UsersNotificationsMesNotificationsCompteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return usersNotificationsMesNotificationsCompteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersNotificationsMesNotificationsCompteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsMesNotificationsCompteGet$Any(params?: UsersNotificationsMesNotificationsCompteGet$Any$Params, context?: HttpContext): Observable<number> {
    return this.usersNotificationsMesNotificationsCompteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `usersNotificationsIdLuePut()` */
  static readonly UsersNotificationsIdLuePutPath = '/users/notifications/{id}/lue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersNotificationsIdLuePut()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsIdLuePut$Response(params: UsersNotificationsIdLuePut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return usersNotificationsIdLuePut(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersNotificationsIdLuePut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersNotificationsIdLuePut(params: UsersNotificationsIdLuePut$Params, context?: HttpContext): Observable<void> {
    return this.usersNotificationsIdLuePut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `usersAdressesMonAdressePrincipaleInformationsGet()` */
  static readonly UsersAdressesMonAdressePrincipaleInformationsGetPath = '/users/adresses/mon-adresse-principale/informations';

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesMonAdressePrincipaleInformationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesMonAdressePrincipaleInformationsGet$Json$Response(params?: UsersAdressesMonAdressePrincipaleInformationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>> {
    return usersAdressesMonAdressePrincipaleInformationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesMonAdressePrincipaleInformationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesMonAdressePrincipaleInformationsGet$Json(params?: UsersAdressesMonAdressePrincipaleInformationsGet$Json$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisation> {
    return this.usersAdressesMonAdressePrincipaleInformationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>): LieuEchangeDtoOuputAvecGeolocalisation => r.body)
    );
  }

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesMonAdressePrincipaleInformationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesMonAdressePrincipaleInformationsGet$Plain$Response(params?: UsersAdressesMonAdressePrincipaleInformationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>> {
    return usersAdressesMonAdressePrincipaleInformationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesMonAdressePrincipaleInformationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesMonAdressePrincipaleInformationsGet$Plain(params?: UsersAdressesMonAdressePrincipaleInformationsGet$Plain$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisation> {
    return this.usersAdressesMonAdressePrincipaleInformationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>): LieuEchangeDtoOuputAvecGeolocalisation => r.body)
    );
  }

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `usersAdressesMonAdressePrincipaleInformationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesMonAdressePrincipaleInformationsGet$Any$Response(params?: UsersAdressesMonAdressePrincipaleInformationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>> {
    return usersAdressesMonAdressePrincipaleInformationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * Log the user out.
   *
   * Remove the Login cookie
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `usersAdressesMonAdressePrincipaleInformationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  usersAdressesMonAdressePrincipaleInformationsGet$Any(params?: UsersAdressesMonAdressePrincipaleInformationsGet$Any$Params, context?: HttpContext): Observable<LieuEchangeDtoOuputAvecGeolocalisation> {
    return this.usersAdressesMonAdressePrincipaleInformationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<LieuEchangeDtoOuputAvecGeolocalisation>): LieuEchangeDtoOuputAvecGeolocalisation => r.body)
    );
  }

}
