/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CategorieDictionaryWithTailles } from '../../models/categorie-dictionary-with-tailles';

export interface ReferencesCategoriesTaillesGet$Any$Params {
  categorieFiltre?: Array<string>;
}

export function referencesCategoriesTaillesGet$Any(http: HttpClient, rootUrl: string, params?: ReferencesCategoriesTaillesGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CategorieDictionaryWithTailles>>> {
  const rb = new RequestBuilder(rootUrl, referencesCategoriesTaillesGet$Any.PATH, 'get');
  if (params) {
    rb.query('categorieFiltre', params.categorieFiltre, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'application/octet-stream', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<CategorieDictionaryWithTailles>>;
    })
  );
}

referencesCategoriesTaillesGet$Any.PATH = '/references/categories-tailles';
