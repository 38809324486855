<div id="contenu" class="flex h-full w-full flex-col md:gap-5 md:px-10 md:py-8">
    <div class="w-full bg-white px-5 md:rounded-xl md:py-6">
        <div class="flex h-full w-full flex-col">
            <mat-tab-group class="w-full" (selectedTabChange)="changeTab($event)">
                <mat-tab label="Recyclage">
                    <div class="m-5 flex flex-row items-center justify-between">
                        <h2 class="whitespace-nowrap text-lg font-bold text-primary">Edition de la page</h2>
                        <div class="flex gap-5">
                            <button mat-stroked-button class="w-full !border-[#A33333] !text-[#A33333]"
                                    (click)="cancel()">Annuler
                            </button>
                            <button mat-flat-button class="w-full !bg-secondary" (click)="save()">Enregistrer</button>
                        </div>
                    </div>
                    <app-richtext #recyclageRichtext [(contenu)]="contenuDuRichTextRecyclage"></app-richtext>
                </mat-tab>
                <mat-tab label="Symbiose">
                    <div class="m-5 flex flex-row items-center justify-between">
                        <h2 class="whitespace-nowrap text-lg font-bold text-primary">Edition de la page</h2>
                        <div class="flex gap-5">
                            <button mat-stroked-button class="w-full !border-[#A33333] !text-[#A33333]"
                                    (click)="cancel()">Annuler
                            </button>
                            <button mat-flat-button class="w-full !bg-secondary" (click)="save()">Enregistrer</button>
                        </div>
                    </div>
                    <app-richtext #symbioseRichtext [(contenu)]="contenuDuRichTextSymbiose"></app-richtext>
                </mat-tab>
                <mat-tab label="CGU">
                    <div class="m-5 flex flex-row items-center justify-between">
                        <h2 class="whitespace-nowrap text-lg font-bold text-primary">Edition de la page</h2>
                        <div class="flex gap-5">
                            <button mat-stroked-button class="w-full !border-[#A33333] !text-[#A33333]"
                                    (click)="cancel()">Annuler
                            </button>
                            <button mat-flat-button class="w-full !bg-secondary" (click)="save()">Enregistrer</button>
                        </div>
                    </div>
                    <app-richtext #cguRichtext [(contenu)]="contenuDuRichTextCgu"></app-richtext>
                </mat-tab>
            </mat-tab-group>
        </div>
    </div>
</div>