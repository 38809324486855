import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
    selector: 'app-edit-area-field',
    standalone: true,
    imports: [CommonModule, MatLabel, FormsModule, MatFormFieldModule, MatInputModule, ReactiveFormsModule],
    templateUrl: './edit-area-field.component.html',
    styleUrl: './edit-area-field.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAreaFieldComponent {
    @Input() IsEditMode = false;
    @Input() IsReadOnly = false;
    @Input() label: string | undefined;
    @Input() formControl: FormControl = new FormControl();
    @Input() nowrap = false;
    @Input() rows = 1;

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
    }
}
