import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private data = new BehaviorSubject<any>(null);
  public resizeRequired: Subject<void> = new Subject<void>();

  setData(value: any) {
    this.data.next(value);
  }

  getData() {
    return this.data.asObservable();
  }
}