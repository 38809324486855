<div class="w-full h-full flex flex-col gap-2">
  <div class="font-semibold text-gray-700">{{ title }}</div>

  <div class="h-full rounded hidden-scrollbar">
    <mat-tree class="example-tree" [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node  *matTreeNodeDef="let node;" >
          <p>{{ node.value.id }}</p>
        <button mat-icon-button disabled></button>
      </mat-tree-node>

      <!-- This is the tree node template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle>
        <div class="w-full flex flex-col gap-2">
          <div class="w-full flex flex-row justify-between mb-2">
              {{ node.value.id }}
              @if (node.isLoading) {
              <mat-progress-bar mode="indeterminate" class="example-tree-progress-bar"></mat-progress-bar>
              }
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
              </mat-icon>
            </button>
          </div>
        </div>
        <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
          <ng-container matTreeNodeOutlet></ng-container>
        </div>
      </mat-nested-tree-node>

    </mat-tree>
  </div>
</div>