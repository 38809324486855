import { SizeColumnsToFitGridStrategy, SizeColumnsToFitProvidedWidthStrategy, SizeColumnsToContentStrategy } from '@ag-grid-community/core';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import {
    LieuEchangeDtoOuputAvecFavorisOdataResponse,
    ProgrammationNotificationDtoOutputForListOdataResponse,
    ReferenceDtoOutputForAdminDatagridForListOdataResponse,
    SignalementOutputForListOdataResponse,
    UserOutputForAdminDataGridOdataResponse,
} from '@app/api/models';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { ColDef, FilterChangedEvent, GridReadyEvent, SortChangedEvent } from 'ag-grid-community';
import { AG_GRID_LOCALE_FR } from '@ag-grid-community/locale';
import { MatPaginatorIntl, MatPaginatorModule, PageEvent } from '@angular/material/paginator';

import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { FrenchMatPaginatorIntl } from '@app/utils/french-mat-paginator';

@Component({
    selector: 'app-datagrid',
    standalone: true,
    imports: [AgGridAngular, MatInputModule, MatIconModule, FormsModule, MatPaginatorModule],
    templateUrl: './datagrid.component.html',
    providers: [{ provide: MatPaginatorIntl, useClass: FrenchMatPaginatorIntl }],
    styleUrls: ['./datagrid.component.css'],
})
export class DatagridComponent implements OnInit {
    //ajouter le type a chaque fois que l'on utilise la grille dans un nouveau composant
    @Input() data!:
        | UserOutputForAdminDataGridOdataResponse
        | LieuEchangeDtoOuputAvecFavorisOdataResponse
        | ProgrammationNotificationDtoOutputForListOdataResponse
        | SignalementOutputForListOdataResponse
        | ReferenceDtoOutputForAdminDatagridForListOdataResponse;
    @Input() columns: ColDef[] = [];
    @Input() nbRecords = 50;
    @Input() hasChild = false;
    @Output() gridReady = new EventEmitter<GridReadyEvent<any, any>>();
    @Output() pageSizeChange = new EventEmitter<PageEvent>();
    @Output() sortChanged = new EventEmitter<SortChangedEvent>();
    @Output() filterChanged = new EventEmitter<FilterChangedEvent>();
    pageIndex = 0;
    filterOptions = ['contains', 'notContains', 'startsWith', 'endsWith', 'equals'];
    filterOptionsNumberAndDate = ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'];
    ngOnInit(): void {
        // ajouter les filteroptions pour chaque colonne
        this.columns.forEach((col) => {
            if(col.type === 'number') {
                col.filterParams = {
                    filterOptions: this.filterOptionsNumberAndDate,
                    maxNumConditions: 1,
                };
            }
            else if(col.type === 'date') {
                col.filter = 'agDateColumnFilter';
                col.filterParams = {
                    filterOptions: this.filterOptionsNumberAndDate,
                    maxNumConditions: 1,
                };
            }
            else if (col.filter) {
                col.filterParams = {
                    filterOptions: this.filterOptions,
                    maxNumConditions: 1,
                };
            }
        });
    }

    public autoSizeStrategy: SizeColumnsToFitGridStrategy | SizeColumnsToFitProvidedWidthStrategy | SizeColumnsToContentStrategy = {
        type: 'fitGridWidth',
    };
    public localeText: Record<string, string> = AG_GRID_LOCALE_FR;

    gridReadyHandler(event: GridReadyEvent<any, any>) {
        this.gridReady.emit(event);
    }
    pageChangeHandler(event: PageEvent) {
        // si c'est un changement de nombre d'éléments affichés, on remets la page à 0 sinon on garde la page actuelle
        if (event.pageSize !== this.nbRecords) {
            this.pageIndex = 0;
        } else {
            this.pageIndex = event.pageIndex;
        }
        this.pageSizeChange.emit(event);
    }
    sortChangedHandler(event: SortChangedEvent) {
        this.sortChanged.emit(event);
    }
    filterChangeHandler(event: FilterChangedEvent) {
        this.pageIndex = 0;
        this.filterChanged.emit(event);
    }
}
