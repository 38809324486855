<div #myDiv class="w-full hidden lg:block">
    <mat-grid-list [cols]="nbCols" [rowHeight]="rowHeight" gutterSize="8px">
        @for (image of getImages(); track image) {
        <mat-grid-tile class="rounded-xl" [colspan]="image.cols" [rowspan]="image.rows"
            [style.background]="image.color">
            <button type="button" class="w-full h-full" (click)="OpenCarrousel(image.index)">
                @if(image.url){
                <img class="h-full w-full object-cover" [src]="image.url">
                }@else(){
                <div class="text-white text-3xl">{{ image.text }}</div>
                }
            </button>
        </mat-grid-tile>
        }
    </mat-grid-list>
</div>

<div class="relative lg:hidden w-full aspect-square">
    <button type="button" class="absolute inset-0 w-full h-full" (click)="OpenCarrousel(0)">
        <img class="h-full w-full object-cover" [src]="getImages()[0].url">
    </button>


    <button mat-ripple class="absolute top-3 left-3 h-10 w-10 md:hidden" (click)="goBack()">
        <div class="bg-white rounded-full p-2 shadow-md z-10 h-full w-full flex justify-center items-center">
            <!-- <mat-icon>arrow_back</mat-icon> -->
            <img src="assets/icons/arrow-left.svg" alt="arrow back" class="h-full w-full" />
        </div>
    </button>

    <button mat-ripple class="absolute top-3 right-3 h-10 w-10 md:hidden" (click)="share()">
        <div class="bg-white rounded-full p-2 shadow-md z-10 h-full w-full flex justify-center items-center">
            <!-- <mat-icon>share</mat-icon> -->
            <img src="assets/icons/share.svg" alt="share" class="h-full w-full" />
        </div>
    </button>
</div>