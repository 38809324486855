import { NestedTreeControl } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { TaskNode } from '../recursive-tree/recursive-tree.component';



@Component({
    selector: 'app-recursive-tree-resume',
    standalone: true,
    imports: [CommonModule, MatTreeModule, MatCheckboxModule, MatIconModule, MatProgressBarModule],
    templateUrl: './recursive-tree-resume.component.html',
    styleUrls: ['./recursive-tree-resume.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecursiveTreeResumeComponent implements OnChanges {
    @Input() title: string | undefined;
    @Input() nodesData: TaskNode[] = [];
    @Input() editMode = false;
    @Input() allNodes: TaskNode[] = [];
    @Input() date: Date = new Date();
    treeControl = new NestedTreeControl<TaskNode>((node) => node.children);
    dataSource = new MatTreeNestedDataSource<TaskNode>();
    constructor(private cdr: ChangeDetectorRef) {}

    ngOnChanges(changes:SimpleChanges): void {
        this.dataSource.data = this.nodesData.filter((node) => node.parent).sort((a, b) => a.value.value.nom.localeCompare(b.value.value.nom));
    }

    hasChild(_: number, node: TaskNode) {
        return !!node.children && node.children.length > 0;
    } 


}

