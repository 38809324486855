import { ChangeDetectionStrategy, ChangeDetectorRef, Component, computed, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatOption, MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocomplete, MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { LevenshteinService } from '../services/levenshtein.service';

@Component({
    selector: 'app-edit-multi-select-autocomplete-field',
    standalone: true,
    imports: [CommonModule, MatFormFieldModule, MatSelectModule, FormsModule, ReactiveFormsModule, MatIconModule, MatChipsModule, MatAutocompleteModule, MatInputModule],
    templateUrl: './edit-multi-select-autocomplete-field.component.html',
    styleUrl: './edit-multi-select-autocomplete-field.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditMultiSelectAutocompleteFieldComponent implements OnInit {
    @Input() IsEditMode = false;
    @Input() IsReadOnly = false;
    @Input() label: string | undefined;
    @Input() errorName: string | undefined = 'un élément';
    @Input() formControl: FormControl<MatOption[] | null> = new FormControl<MatOption[]>([]);
    @Input() autocompleteFormControl = new FormControl('');
    @Input() options: MatOption[] | undefined;
    @Input() IsDisabled = false;

    readonly filteredOptions = computed(() => {
        const currentOption = this.autocompleteFormControl.value?.toLowerCase();
        const filterOpt = currentOption ? this._filter(currentOption) : this.formControl.value;
        return filterOpt;
    });

    @ViewChild('auto') matAutocomplete!: MatAutocomplete;

    constructor(
        private cdr: ChangeDetectorRef,
        private levenshteinService: LevenshteinService,
    ) {}

    ngOnInit(): void {
        this.formControl.valueChanges.subscribe(() => {
            this.cdr.detectChanges();
        });
    }

    private _filter(value: string): MatOption[] {
        const filterValue = value.toLowerCase();

        const filterOptions = this.options?.filter((option) => !this.formControl.value?.includes(option));

        // Priorité aux correspondances exactes avec includes
        const exactMatches = filterOptions?.filter((option) => this.levenshteinService.normalizeString(option.id.toLowerCase()).includes(filterValue)) || [];

        // Si des correspondances exactes existent, les retourner
        if (exactMatches.length > 0) {
            return exactMatches;
        }

        // Sinon, utiliser la distance de Levenshtein pour trouver les correspondances les plus proches
        return (
            filterOptions
                ?.map((option) => ({
                    option,
                    distance: this.levenshteinService.calculateLevenshteinDistance(option.id.toLowerCase(), filterValue),
                }))
                .filter((item) => item.distance <= 15) // Ajustez ce seuil en fonction de la tolérance souhaitée
                .sort((a, b) => a.distance - b.distance) // Optionnel: trier par distance croissante
                .map((item) => item.option) ?? []
        );
    }

    remove(taille: MatOption): void {
        if (this.formControl && this.formControl.value) {
            const index = this.formControl.value.indexOf(taille);

            if (index >= 0) {
                const value = this.formControl.value.slice();
                value.splice(index, 1); // Correctly remove the item using splice
                this.formControl.setValue(value);
            }
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        const currentValue: MatOption[] = this.formControl.value || []; // Récupère la valeur actuelle ou un tableau vide si null
        const newOption = this.options?.find((option) => option.id === event.option.value);
        if (!newOption) return;
        this.formControl.setValue([...currentValue, newOption]); // Ajoute le nouvel objet MatOption à la liste
        this.autocompleteFormControl.setValue(null);
        event.option.deselect(); // Désélectionne l'option après l'ajout
    }

    onEnterKey(event: Event): void {
        if (this.matAutocomplete && this.matAutocomplete.options.first) {
            const firstOption = this.matAutocomplete.options.first;
            if (firstOption && !firstOption.disabled) {
                firstOption.select(); // Sélectionner la première option si elle n'est pas désactivée
            }
        }
    }

    getFormattedValue(): string {
        const values = this.formControl.value;
        if (values && Array.isArray(values)) {
            return values.map((val) => val.id).join(', ');
        }
        return '';
    }
}
