import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LevenshteinService {
    constructor() {}

    calculateLevenshteinDistance(a: string, b: string): number {
        const matrix: number[][] = [];

        // Initialisation de la première colonne
        for (let i = 0; i <= b.length; i++) {
            matrix[i] = [i];
        }

        // Initialisation de la première ligne
        for (let j = 0; j <= a.length; j++) {
            matrix[0][j] = j;
        }

        // Remplissage de la matrice
        for (let i = 1; i <= b.length; i++) {
            for (let j = 1; j <= a.length; j++) {
                if (b.charAt(i - 1) === a.charAt(j - 1)) {
                    matrix[i][j] = matrix[i - 1][j - 1];
                } else {
                    matrix[i][j] = Math.min(
                        matrix[i - 1][j - 1] + 1, // Substitution
                        matrix[i][j - 1] + 1, // Insertion
                        matrix[i - 1][j] + 1, // Suppression
                    );
                }
            }
        }

        return matrix[b.length][a.length];
    }

    normalizeString(input: string): string {
        // Remplacer les accents en utilisant la normalisation Unicode
        const withoutAccents = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

        // Remplacer les tirets par des espaces
        const result = withoutAccents.replace(/-/g, ' ');

        return result;
    }
}
