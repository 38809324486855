/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { NotificationDtoOutputForList } from '../models/notification-dto-output-for-list';
import { notificationsMesNotificationsGet$Any } from '../fn/notifications/notifications-mes-notifications-get-any';
import { NotificationsMesNotificationsGet$Any$Params } from '../fn/notifications/notifications-mes-notifications-get-any';
import { notificationsMesNotificationsGet$Json } from '../fn/notifications/notifications-mes-notifications-get-json';
import { NotificationsMesNotificationsGet$Json$Params } from '../fn/notifications/notifications-mes-notifications-get-json';
import { notificationsMesNotificationsGet$Plain } from '../fn/notifications/notifications-mes-notifications-get-plain';
import { NotificationsMesNotificationsGet$Plain$Params } from '../fn/notifications/notifications-mes-notifications-get-plain';
import { notificationsMesNotificationsIdDelete } from '../fn/notifications/notifications-mes-notifications-id-delete';
import { NotificationsMesNotificationsIdDelete$Params } from '../fn/notifications/notifications-mes-notifications-id-delete';
import { notificationsMesNotificationsIdPut } from '../fn/notifications/notifications-mes-notifications-id-put';
import { NotificationsMesNotificationsIdPut$Params } from '../fn/notifications/notifications-mes-notifications-id-put';
import { notificationsMesNotificationsNonLuesCompteGet$Any } from '../fn/notifications/notifications-mes-notifications-non-lues-compte-get-any';
import { NotificationsMesNotificationsNonLuesCompteGet$Any$Params } from '../fn/notifications/notifications-mes-notifications-non-lues-compte-get-any';
import { notificationsMesNotificationsNonLuesCompteGet$Json } from '../fn/notifications/notifications-mes-notifications-non-lues-compte-get-json';
import { NotificationsMesNotificationsNonLuesCompteGet$Json$Params } from '../fn/notifications/notifications-mes-notifications-non-lues-compte-get-json';
import { notificationsMesNotificationsNonLuesCompteGet$Plain } from '../fn/notifications/notifications-mes-notifications-non-lues-compte-get-plain';
import { NotificationsMesNotificationsNonLuesCompteGet$Plain$Params } from '../fn/notifications/notifications-mes-notifications-non-lues-compte-get-plain';
import { notificationsTypeDeNotificationsGet$Any } from '../fn/notifications/notifications-type-de-notifications-get-any';
import { NotificationsTypeDeNotificationsGet$Any$Params } from '../fn/notifications/notifications-type-de-notifications-get-any';
import { notificationsTypeDeNotificationsGet$Json } from '../fn/notifications/notifications-type-de-notifications-get-json';
import { NotificationsTypeDeNotificationsGet$Json$Params } from '../fn/notifications/notifications-type-de-notifications-get-json';
import { notificationsTypeDeNotificationsGet$Plain } from '../fn/notifications/notifications-type-de-notifications-get-plain';
import { NotificationsTypeDeNotificationsGet$Plain$Params } from '../fn/notifications/notifications-type-de-notifications-get-plain';
import { NotificationTypeDtoOutput } from '../models/notification-type-dto-output';

@Injectable({ providedIn: 'root' })
export class NotificationsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `notificationsMesNotificationsGet()` */
  static readonly NotificationsMesNotificationsGetPath = '/notifications/mes-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsGet$Json$Response(params?: NotificationsMesNotificationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationDtoOutputForList>>> {
    return notificationsMesNotificationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsGet$Json(params?: NotificationsMesNotificationsGet$Json$Params, context?: HttpContext): Observable<Array<NotificationDtoOutputForList>> {
    return this.notificationsMesNotificationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationDtoOutputForList>>): Array<NotificationDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsGet$Plain$Response(params?: NotificationsMesNotificationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationDtoOutputForList>>> {
    return notificationsMesNotificationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsGet$Plain(params?: NotificationsMesNotificationsGet$Plain$Params, context?: HttpContext): Observable<Array<NotificationDtoOutputForList>> {
    return this.notificationsMesNotificationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationDtoOutputForList>>): Array<NotificationDtoOutputForList> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsGet$Any$Response(params?: NotificationsMesNotificationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationDtoOutputForList>>> {
    return notificationsMesNotificationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsGet$Any(params?: NotificationsMesNotificationsGet$Any$Params, context?: HttpContext): Observable<Array<NotificationDtoOutputForList>> {
    return this.notificationsMesNotificationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationDtoOutputForList>>): Array<NotificationDtoOutputForList> => r.body)
    );
  }

  /** Path part for operation `notificationsMesNotificationsNonLuesCompteGet()` */
  static readonly NotificationsMesNotificationsNonLuesCompteGetPath = '/notifications/mes-notifications/non-lues/compte';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsNonLuesCompteGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsNonLuesCompteGet$Json$Response(params?: NotificationsMesNotificationsNonLuesCompteGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return notificationsMesNotificationsNonLuesCompteGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsNonLuesCompteGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsNonLuesCompteGet$Json(params?: NotificationsMesNotificationsNonLuesCompteGet$Json$Params, context?: HttpContext): Observable<number> {
    return this.notificationsMesNotificationsNonLuesCompteGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsNonLuesCompteGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsNonLuesCompteGet$Plain$Response(params?: NotificationsMesNotificationsNonLuesCompteGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return notificationsMesNotificationsNonLuesCompteGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsNonLuesCompteGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsNonLuesCompteGet$Plain(params?: NotificationsMesNotificationsNonLuesCompteGet$Plain$Params, context?: HttpContext): Observable<number> {
    return this.notificationsMesNotificationsNonLuesCompteGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsNonLuesCompteGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsNonLuesCompteGet$Any$Response(params?: NotificationsMesNotificationsNonLuesCompteGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return notificationsMesNotificationsNonLuesCompteGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsNonLuesCompteGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsNonLuesCompteGet$Any(params?: NotificationsMesNotificationsNonLuesCompteGet$Any$Params, context?: HttpContext): Observable<number> {
    return this.notificationsMesNotificationsNonLuesCompteGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `notificationsMesNotificationsIdPut()` */
  static readonly NotificationsMesNotificationsIdPutPath = '/notifications/mes-notifications/{id}';

  /**
   * Marque une notification comme "lue".
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsIdPut()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsIdPut$Response(params: NotificationsMesNotificationsIdPut$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationsMesNotificationsIdPut(this.http, this.rootUrl, params, context);
  }

  /**
   * Marque une notification comme "lue".
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsIdPut$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsIdPut(params: NotificationsMesNotificationsIdPut$Params, context?: HttpContext): Observable<void> {
    return this.notificationsMesNotificationsIdPut$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationsMesNotificationsIdDelete()` */
  static readonly NotificationsMesNotificationsIdDeletePath = '/notifications/mes-notifications/{id}';

  /**
   * Supprime une notification.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsMesNotificationsIdDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsIdDelete$Response(params: NotificationsMesNotificationsIdDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return notificationsMesNotificationsIdDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Supprime une notification.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsMesNotificationsIdDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsMesNotificationsIdDelete(params: NotificationsMesNotificationsIdDelete$Params, context?: HttpContext): Observable<void> {
    return this.notificationsMesNotificationsIdDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `notificationsTypeDeNotificationsGet()` */
  static readonly NotificationsTypeDeNotificationsGetPath = '/notifications/type-de-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsTypeDeNotificationsGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsTypeDeNotificationsGet$Json$Response(params?: NotificationsTypeDeNotificationsGet$Json$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationTypeDtoOutput>>> {
    return notificationsTypeDeNotificationsGet$Json(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsTypeDeNotificationsGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsTypeDeNotificationsGet$Json(params?: NotificationsTypeDeNotificationsGet$Json$Params, context?: HttpContext): Observable<Array<NotificationTypeDtoOutput>> {
    return this.notificationsTypeDeNotificationsGet$Json$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationTypeDtoOutput>>): Array<NotificationTypeDtoOutput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsTypeDeNotificationsGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsTypeDeNotificationsGet$Plain$Response(params?: NotificationsTypeDeNotificationsGet$Plain$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationTypeDtoOutput>>> {
    return notificationsTypeDeNotificationsGet$Plain(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsTypeDeNotificationsGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsTypeDeNotificationsGet$Plain(params?: NotificationsTypeDeNotificationsGet$Plain$Params, context?: HttpContext): Observable<Array<NotificationTypeDtoOutput>> {
    return this.notificationsTypeDeNotificationsGet$Plain$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationTypeDtoOutput>>): Array<NotificationTypeDtoOutput> => r.body)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `notificationsTypeDeNotificationsGet$Any()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsTypeDeNotificationsGet$Any$Response(params?: NotificationsTypeDeNotificationsGet$Any$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<NotificationTypeDtoOutput>>> {
    return notificationsTypeDeNotificationsGet$Any(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `notificationsTypeDeNotificationsGet$Any$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  notificationsTypeDeNotificationsGet$Any(params?: NotificationsTypeDeNotificationsGet$Any$Params, context?: HttpContext): Observable<Array<NotificationTypeDtoOutput>> {
    return this.notificationsTypeDeNotificationsGet$Any$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NotificationTypeDtoOutput>>): Array<NotificationTypeDtoOutput> => r.body)
    );
  }

}
