import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { DataSharingService } from '@app/utils/services/data-sharing.services';
import { UserOutputForDetail } from '@app/api/models';

@Component({
    selector: 'app-contact-button',
    standalone: true,
    templateUrl: './contact-button.component.html',
    imports: [MatIconModule],
    styleUrls: ['./contact-button.component.css'],
})
export class ContactButtonComponent implements ICellRendererAngularComp {
    params: ICellRendererParams | undefined;
    @Input() user: UserOutputForDetail | undefined;
    constructor(
        private router: Router,
        private dataSharingService: DataSharingService,
    ) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }

    buttonClicked() {
        const navigationData = this.params?.data ?? this.user;
        if (!navigationData) {
            return;
        }
        this.dataSharingService.setData(navigationData);
        this.router.navigate(['/messagerie']);
    }
}
