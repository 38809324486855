import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { DataSharingService } from '@app/utils/services/data-sharing.services';
import { CategorieDtoOutputForDatagrid, CategoryGroupingDtoOutputForCatalogue } from '@app/api/models';
import { MatTreeModule, MatTreeNestedDataSource } from '@angular/material/tree';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';

@Component({
    standalone: true,
    templateUrl: './category-children.component.html',
    imports: [MatIconModule, MatIconModule, MatTreeModule],
    styleUrls: ['./category-children.component.css'],
})
export class CategorieChildrenComponent implements ICellRendererAngularComp {
    params: ICellRendererParams | undefined;
    categorie: CategorieDtoOutputForDatagrid | undefined;
    showChildren = false;
    constructor(
        private router: Router,
        private dataSharingService: DataSharingService,
    ) {}

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.categorie = params.data;
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        return true;
    }
    toggleView(event: MouseEvent) {
        event.stopPropagation();
        this.showChildren = !this.showChildren;
        this.dataSharingService.resizeRequired.next();
    }
    getLibelle(libelle: string): string {
        if (libelle.length > 50) {
            return libelle.substring(0, 50) + '...';
        }
        return libelle;
    }
    rowClick(reference: string | undefined | null) {
        if (reference) {
            this.router.navigate(['/reference', reference]);
        }
    }
}
