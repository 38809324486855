<div class="h-full">
  <ag-grid-angular class="ag-theme-quartz h-4/5" [alwaysMultiSort]="true" [localeText]="localeText"
    [rowData]="data.value" [columnDefs]="columns" [autoSizeStrategy]="autoSizeStrategy"
    (sortChanged)="sortChangedHandler($event)" (filterChanged)="filterChangeHandler($event)"
    [rowClass]="hasChild ?'cursor-pointer childGrid':  'cursor-pointer'" (gridReady)="gridReadyHandler($event)" />
    <mat-paginator id="paginatorDatagrid" [length]="data.count"
    [pageSize]="nbRecords"
    [pageSizeOptions]="[10, 25, 50, 100]"
    (page)="pageChangeHandler($event)"
    [pageIndex]="pageIndex"
    aria-label="Selecteur de page">
</mat-paginator>
</div>