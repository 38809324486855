<div id="login" class="mx-auto flex h-screen w-screen flex-col overflow-hidden bg-white md:flex-row-reverse">
    <div class="flex basis-2/3 items-center justify-center bg-primary custom-background">
        <img src="assets/logo-unichange-blanc-admin.svg" alt="logo" class="max-w-[70%] xl:max-w-full" />
    </div>

    <div class="container relative basis-1/3 p-7 md:p-16"> 
        <img src="assets/logo-ministere.svg" alt="logo" class="absolute hidden h-28 w-40 md:block" />

        <div class="flex h-full w-full flex-col justify-center gap-14">
            <img src="assets/logo-unichange-admin.svg" alt="logo" class="mx-auto hidden h-12 w-72 md:block" />

            <app-form-login></app-form-login>
        </div>
        <div style="text-align: center;">{{this.version}}</div>
    </div>
</div>
