<div class="w-full h-full flex justify-center items-center" id="carrousel">
    <div class="flex overflow-hidden items-center justify-center h-full w-full">
        @for (image of imageList; track $index) {
        <div [class.hidden]="$index !== currentIndex"
            class="transition-opacity duration-1000 flex justify-center items-center relative mx-16 h-full w-full">
            <img [src]='image' alt="" class="h-full w-full object-contain">
        </div>
        }

        @if(imageList.length > 1){
        <button (click)="prev()"
            class="absolute top-1/2 left-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 flex items-center justify-center hover:bg-gray-700 transition-colors duration-200">
            <mat-icon>chevron_left</mat-icon>
        </button>
        <button (click)="next()"
            class="absolute top-1/2 right-0 transform -translate-y-1/2 bg-gray-800 text-white p-2 flex items-center justify-center hover:bg-gray-700 transition-colors duration-200">
            <mat-icon>chevron_right</mat-icon>
        </button>
        }
    </div>

</div>