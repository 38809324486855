import { P } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ConversationDtoOutputWithLastMessageForList, UserOutputForDetail } from '@app/api/models';
import { RealTimeClientService } from '@app/utils/services/realtime-client.services';

@Component({
    selector: 'app-messagerie-wall',
    standalone: true,
    imports: [MatIconModule, CommonModule],
    templateUrl: './messagerie-wall.component.html',
    styleUrl: './messagerie-wall.component.css',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagerieWallComponent {
    @Input() conversations: ConversationDtoOutputWithLastMessageForList[] = [];
    @Output() selectedConversation = new EventEmitter<ConversationDtoOutputWithLastMessageForList>();
    @Input() selected: ConversationDtoOutputWithLastMessageForList | null = null;
    constructor(private cdr: ChangeDetectorRef) {}

    conversationSelected(conversation: ConversationDtoOutputWithLastMessageForList) {
        this.selectedConversation.emit(conversation);
    }

    getContactName(contact: UserOutputForDetail | undefined) {
        if (!contact) {
            return '';
        }
        if (!contact.lastName) {
            return contact.firstName;
        }
        return contact.firstName + ' ' + contact.lastName.charAt(0).toUpperCase() + '.';
    }

    isSelected(conversation: ConversationDtoOutputWithLastMessageForList) {
        return this.selected === conversation;
    }

    isRead(conv: ConversationDtoOutputWithLastMessageForList) {
        return !conv.messages?.[0]?.lu && !conv.messages?.[0]?.envoyeParMoi && !this.isSelected(conv);
    }
    getTrimedMessage(message: string | null | undefined) {
        if (!message) {
            return 'Aucun message';
        }
        return message.length > 30 ? message.substring(0, 30) + '...' : message;
    }
}
