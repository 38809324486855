import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { provideMomentDateAdapter } from '@angular/material-moment-adapter';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatOptionModule } from '@angular/material/core';
import 'moment/locale/fr';
import moment from 'moment';
import { MatSelectModule } from '@angular/material/select';
import { StatRangeComponent } from "../stat-range/stat-range.component";
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

export interface RangeType {
    name: string;
    value: string;
}

export interface Range {
    start: Date;
    end: Date;
}

@Component({
    selector: 'app-stats-header',
    standalone: true,
    imports: [
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    RouterLink,
    RouterLinkActive,
    RouterOutlet,
    MatOptionModule,
    MatDatepickerModule,
    FormsModule,
    MatSelectModule,
    StatRangeComponent,
    MatSlideToggleModule
],
    templateUrl: './stats-header.component.html',
    styleUrls: ['./stats-header.component.css'],
    providers: [{ provide: MAT_DATE_LOCALE, useValue: 'fr' }, provideMomentDateAdapter()],

    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsHeaderComponent {
    @Input() range: FormGroup = new FormGroup({
        start: new FormControl<moment.Moment | null>(moment().subtract(1, 'year')),
        end: new FormControl<moment.Moment | null>(moment()),
    }); 
    @Input() range2: FormGroup = new FormGroup({
        start: new FormControl<moment.Moment | null>(moment().subtract(1, 'year')),
        end: new FormControl<moment.Moment | null>(moment()),
    });
    @Output() toggleComparaison = new EventEmitter<boolean>();
    @Output() dateChange = new EventEmitter();
    @Output() date2Change = new EventEmitter();

    activateComparaison = false;
    constructor(private cdr: ChangeDetectorRef) {

    }

    dateChangeEmit(event: Range){
        this.cdr.detectChanges();
        this.dateChange.emit(event);
    } 
    dateChangeEmit2(event: Range){
        this.cdr.detectChanges();
        this.date2Change.emit(event);
    }

    toggleChange(){
        this.cdr.detectChanges();
        this.toggleComparaison.emit(this.activateComparaison);
    }

}
