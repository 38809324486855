<div class="flex flex-col w-full container mx-auto bg-white rounded shadow-lg my-12 p-8 m-16 gap-8">
    <div class="flex flex-row justify-between">
        <div class="flex flex-row items-center gap-6">
            <button mat-flat-button (click)="retour()" class="!bg-primary">
                <mat-icon>arrow_back</mat-icon>
                Retour
            </button>
            <mat-divider vertical="true" class="!w-px !h-10 !bg-gray-300"></mat-divider>
            <h1 class="text-sm font-bold">{{getInitialWithLastName()}}</h1>
        </div>

        <div class="flex justify-end space-x-4">
            <!-- @if(editMode){
            <button mat-stroked-button class="!border-[#A33333] !text-[#A33333]" (click)="onCancel()">Annuler</button>
            } -->

            <button mat-stroked-button (click)="export()"
                class="!border-primary !text-primary flex items-center !w-fit gap-2">
                Exporter
            </button>
            <!-- <button mat-flat-button color="primary" (click)="onSubmit()">
                {{editMode ? 'Editer' : 'Enregistrer'}}
            </button> -->
        </div>
    </div>

    <div class="grid grid-cols-1 gap-8 overflow-y-auto h-[calc(100vh-304px)] hidden-scrollbar">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
            <app-edit-text-field label="Nom" [IsEditMode]="editMode" [formControl]="NomFormControl"
                [IsReadOnly]="true"></app-edit-text-field>
            <app-edit-text-field label="Identifiant" [IsEditMode]="editMode" [formControl]="IdentifiantFormControl"
                [IsReadOnly]="true"></app-edit-text-field>
            <app-edit-select-field label="Statut" [IsEditMode]="editMode" [options]="statuts"
                [formControl]="StatutFormControl" [IsReadOnly]="true"></app-edit-select-field>
            <app-edit-text-field label="Email" [IsEditMode]="editMode" [formControl]="EmailFormControl"
                [IsReadOnly]="false"></app-edit-text-field>
            <!-- <div class="flex flex-col gap-2">
                <mat-label class="text-primary font-bold whitespace-nowrap">Mot de passe</mat-label>
                <button mat-stroked-button class="!border-primary !text-primary flex items-center !w-fit gap-2">
                    Générer un mot de passe
                </button>
            </div> -->
            <div class="flex flex-col gap-2">
                <mat-label class="text-primary font-bold whitespace-nowrap">Bloquer l'utilisateur</mat-label>
                <button mat-stroked-button [hidden]="!this.banBool" class="!border-[#A33333] !text-[#A33333] !w-fit"
                    (click)="putBannir()">
                    Débloquer
                </button>
                <button mat-flat-button [hidden]="this.banBool" class="!bg-[#A33333] !w-fit" (click)="putBannir()">
                    Bloquer
                </button>
            </div>
            <div class="flex flex-col gap-2">
                <mat-label class="text-primary font-bold whitespace-nowrap">Contacter</mat-label>
                <button mat-stroked-button (click)="contacter()"
                    class="!border-primary !text-primary flex items-center !w-fit gap-2">
                    <span class="flex flex-row items-center"><img src="assets/icons/email.svg" alt="contact"
                            class="h-5 aspect-square mr-3" />
                        Contacter</span>
                </button>
            </div>
            <div class="flex flex-col gap-3 col-span-full long-form-field-container">
                <app-edit-area-field label="Commentaires administrateurs" [rows]=3 [IsEditMode]="true"
                    [formControl]="CommFormControl" [IsReadOnly]="false">
                </app-edit-area-field>
                <button mat-stroked-button (click)="envoyerComm()"
                    class="!border-primary !text-primary flex items-center !w-fit gap-2">
                    <span class="flex items-center">Enregistrer</span>
                </button>
            </div>
        </div>

        <mat-divider></mat-divider>

        @if(DonationsRawData){
        <div class="flex flex-col gap-4">
            <mat-label class="text-primary font-bold whitespace-nowrap">Donations (en cours / archivées)</mat-label>
            <div id="donations-data-grid" class="w-full h-[40vh]">
                <app-datagrid class="h-full" [data]="DonationsRawData" [columns]="DonationsColDefs"
                    [nbRecords]="DonationsFetchParams.$top"
                    (sortChanged)="dataService.onSortChanged($event, fetchDonations, DonationsFetchParams, donationsGridApi)"
                    (filterChanged)="dataService.onFilterChanged($event, fetchDonations, DonationsFetchParams, donationsGridApi)"
                    (pageSizeChange)="dataService.onPageSizeChange($event, DonationsRawData, fetchDonations, DonationsFetchParams)"
                    (gridReady)="onDonationsGridReady($event)"></app-datagrid>
            </div>
        </div>
        }

        @if(DemandesRawData){
        <div class="flex flex-col gap-4">
            <mat-label class="text-primary font-bold whitespace-nowrap">Demandes (réussies)</mat-label>
            <div id="demandes-data-grid" class="w-full h-[40vh]">
                <app-datagrid class="h-full" [data]="DemandesRawData" [columns]="DemandesColDefs"
                    [nbRecords]="DemandesFetchParams.$top"
                    (sortChanged)="dataService.onSortChanged($event, fetchDemandes, DemandesFetchParams, demandesGridApi)"
                    (filterChanged)="dataService.onFilterChanged($event, fetchDemandes, DemandesFetchParams, demandesGridApi)"
                    (pageSizeChange)="dataService.onPageSizeChange($event, DemandesRawData, fetchDemandes, DemandesFetchParams)"
                    (gridReady)="onDemandesGridReady($event)">
                </app-datagrid>
            </div>
        </div>
        }

        @if(SignalementsRawData) {
        <div class="flex flex-col gap-4">
            <mat-label class="text-primary font-bold whitespace-nowrap">Signalements reçus</mat-label>
            <div id="signalements-data-grid" class="w-full h-[40vh]">
                <app-datagrid class="h-full" [data]="SignalementsRawData" [columns]="SignalementsColDefs"
                    [nbRecords]="SignalementsFetchParams.$top"
                    (sortChanged)="dataService.onSortChanged($event, fetchSignalements, SignalementsFetchParams, signalementsGridApi)"
                    (filterChanged)="dataService.onFilterChanged($event, fetchSignalements, SignalementsFetchParams, signalementsGridApi)"
                    (pageSizeChange)="dataService.onPageSizeChange($event, SignalementsRawData, fetchSignalements, SignalementsFetchParams)"
                    (gridReady)="onSignalementsGridReady($event)">
                </app-datagrid>
            </div>
        </div>
        }

    </div>
</div>