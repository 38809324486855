<div class="flex w-full flex-col rounded-[10px] bg-white py-8 px-6 custom-card">
  <p class=" text-base">Références les <span
      [ngClass]=" greenValue ? 'text-[#2CBE7E]': 'text-[#BE2C2C]' ">{{title}}</span></p>
  <p class="font-thin text-xs">Nombre d'annonces</p>
  <div class="flex flex-col gap-2">
    @for (item of data; track $index) {
    <div class="flex justify-between items-center  w-full">
      <div class="flex flex-col ">
        <p class="text-xs">{{getLibelle(item.reference?.libelle ?? '')}}</p>
        <p class="font-thin text-xs">{{item.reference?.reference}}</p>
      </div>
      <div class="flex gap-2 items-center justify-center">
        <p class="text-sm">{{item.nombreOffres}}</p>
        @if(item.variation) {
        <app-stat-variation [positive]="greenValue" [value]="item.variation"></app-stat-variation>
        }
      </div>
    </div>
    }
  </div>
</div>